import React from 'react'
import moment from 'moment'

import formatEndTime from './format-end-time'

import { hideModal, showModal, takeOnCallById } from 'components/store/actions'

import TakeTimerForm from './take-timer-form'

function handleSuccess(dispatch) {
  // this is where the toast will fire when its ready
  dispatch(hideModal())
}

function handleError(dispatch, error) {
  const supportLink = (
    <a href='https://login.splunk.com/'>Splunk Support Portal</a>
  )
  const notOnCallError = 'Cannot take from user that is not currently on call'
  const notOnCallMessage =
    'You are trying to take on-call for a user that is no longer on-call. If you believe you are seeing this message incorrectly please contact support via live chat, or open a case on the '
  const standardMessage =
    'We were unable to update on-call. Try again or contact support via live chat, or open a case on the '
  const displayMessage =
    error === notOnCallError ? notOnCallMessage : standardMessage
  const modalConfig = {
    modalType: 'confirm',
    modalProps: {
      component: (
        <span>
          {displayMessage} {supportLink}
        </span>
      ),
      cancelButtonText: 'Ok',
      cancelButtonType: 'info',
      title: 'Oh Snap!',
    },
  }

  dispatch(showModal(modalConfig))
}

/*
  because we don't get any new info from server while taking on-call, we don't know how long
  a take from an "endless" call lasts. reading it from the server would be more work and a delay,
  so in the short term, we're assuming that anything with no end time is 30 days

  (on the back-end, this value is hard-coded to 30 days at the moment)
*/
function getDisplayEndTime(modalConfirmText, endTime) {
  if (modalConfirmText === 'Take On-call' && endTime === null) {
    return moment()
      .add(30, 'day')
      .valueOf()
  }

  if (modalConfirmText === 'Clear Take') {
    return null
  }

  return endTime
}

function handleSuccessModal({
  dispatch,
  endTime,
  modalConfirmText,
  policyName,
  takeFromUsername,
  takeToUsername,
  teamName,
}) {
  const modalTitle =
    modalConfirmText === 'Clear Take'
      ? 'Clear Take Confirmation'
      : 'On-call Confirmation'

  let introText =
    modalConfirmText === 'Clear Take'
      ? `@${takeToUsername} is on-call for`
      : `You are now on-call for @${takeFromUsername} in`

  if (modalConfirmText === 'Take Back') {
    introText = 'You are now on-call for'
  }

  const displayEndTime = getDisplayEndTime(modalConfirmText, endTime)

  const modalBody = (
    <p>
      {introText}{' '}
      <span className='take-timer-policyname'>{`${teamName}: ${policyName}`}</span>
      {formatEndTime(displayEndTime)}.
    </p>
  )

  const modalConfig = {
    modalType: 'confirm',
    modalProps: {
      cancelButtonText: 'Ok',
      cancelButtonType: 'info',
      component: modalBody,
      title: modalTitle,
    },
  }
  dispatch(showModal(modalConfig))
}

export function _confirmationModal(dispatch) {
  return function({
    endTime,
    isClear,
    manualTakeTimer,
    modalConfirmText,
    modalTitle,
    policyName,
    policySlug,
    takeFromUsername,
    takeToUsername,
    teamName,
  }) {
    if (manualTakeTimer) {
      const onCancel = () => {
        dispatch(hideModal())
      }

      const onConfirm = endTime => {
        const confirmationPayload = {
          payload: {
            isClear,
            policySlug,
            takeFromUsername,
            takeToUsername,
          },
          success: () =>
            handleSuccessModal({
              dispatch,
              endTime,
              modalConfirmText,
              takeFromUsername,
              takeToUsername,
              policyName,
              teamName,
            }),
          error: error => handleError(dispatch, error),
        }
        dispatch(takeOnCallById(confirmationPayload))
      }

      const modalConfig = {
        modalType: 'confirm',
        modalProps: {
          title: modalTitle,
          actionBar: false,
          component: (
            <TakeTimerForm
              {...{
                endTime,
                modalConfirmText,
                onCancel,
                onConfirm,
                policyName,
                takeFromUsername,
                takeToUsername,
                teamName,
              }}
            />
          ),
        },
      }
      return dispatch(showModal(modalConfig))
    } else {
      const confirmationPayload = {
        payload: {
          isClear: false,
          policySlug,
          takeFromUsername,
          takeToUsername,
        },
        success: () => handleSuccess(dispatch),
        error: () => handleError(dispatch),
      }

      const modalConfig = {
        modalType: 'confirm',
        modalProps: {
          confirmButtonText: 'Take On-call',
          confirmButtonType: 'primary',
          onCancel: () => dispatch(hideModal()),
          onConfirm: () => {
            dispatch(takeOnCallById(confirmationPayload))
          },
          title: `Take On-call From @${takeFromUsername}?`,
          body: `You will be on-call for remainder of their shift in the ${teamName} team's ${policyName} escalation policy.`,
        },
      }
      return dispatch(showModal(modalConfig))
    }
  }
}
