import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { compose, withHandlers, withProps, withState } from 'recompose'

import debug from 'debug'

// lib
import { IncidentActions } from './incident-actions'
import RerouteForm from './reroute-form'
import AddRespondersForm from './add-responders-form'

import {
  getPickerOptions,
  makeGetFilteredPickerOptions,
  makeGetIncidentById,
  makeGetFeatureFlag,
  makeGetPreviousRoutes,
} from 'components/store/selectors'

import {
  getTeamsWithPolicies,
  hideModal,
  incidentRerouting,
  rerouteIncidentWithModal,
  addRespondersWithModal,
  showModal,
  updateIncidentsState,
  putConferenceBridgeDetails,
} from 'components/store/actions'

import { showSnoozeForm } from './snooze/snooze-helpers'

import { IncidentActions as VictoryActions } from '@victorops/victory'

import timedEventTracker from 'util/timedEventTracker'
import { ProcessIncident } from 'components/store/reducers/timelines/timelines-helpers/message-preprocessors'

const incidentActionLogger = timedEventTracker('incidents:actions')

// ---------------------------------------------------------------------------

const error = debug('VO:utils/formatting:error')

// ---------------------------------------------------------------------------

function makeRerouteContainer(_Form) {
  return compose(
    withProps({
      placeholder: 'Search for Escalation Policies and Users',
    }),
    withState('filter', '_handleFilterChange', ''),
    withState('selectedOptions', '_handleSelectedChange', []),
    withHandlers({
      onFilterChange: ({ _handleFilterChange }) => test =>
        _handleFilterChange(() => test),
      onSelectedChange: ({ _handleSelectedChange }) => next =>
        _handleSelectedChange(() => next),
    }),
    connect(
      (state, own) => ({
        options: getPickerOptions(state),
        previous: makeGetPreviousRoutes()(state, { id: own.incidentId }),
      }),
      dispatch => ({
        onCancel: () => dispatch(hideModal()),
        onReroute: payload => () => dispatch(rerouteIncidentWithModal(payload)),
      })
    )
  )(_Form)
}

export function makeAddRespondersContainer(_Form) {
  const getFeatureFlag = makeGetFeatureFlag()
  const getFilteredPickerOptions = makeGetFilteredPickerOptions()

  return compose(
    withProps({
      placeholder: 'Search for Escalation Policies and Users',
    }),
    withState('filter', '_handleFilterChange', ''),
    withState('selectedOptions', '_handleSelectedChange', []),
    withHandlers({
      onFilterChange: ({ _handleFilterChange }) => test =>
        _handleFilterChange(() => test),
      onSelectedChange: ({ _handleSelectedChange }) => next =>
        _handleSelectedChange(() => next),
    }),
    connect(
      (state, own) => ({
        entityDisplayName: state.incidentDetails.getIn([
          own.incidentId,
          'voFields',
          'entity_display_name',
        ]),
        entityID: state.incidentDetails.getIn([
          own.incidentId,
          'voFields',
          'entity_id',
        ]),
        hasSuggestedResponders: getFeatureFlag(state, {
          id: 'feature:suggestedresponders',
        }),
        hostName: state.incidentDetails.getIn([
          own.incidentId,
          'voFields',
          'host_name',
        ]),
        monitoringTool: state.incidentDetails.getIn([
          own.incidentId,
          'voFields',
          'monitoring_tool',
        ]),
        options: getFilteredPickerOptions(state, own.incidentId),
        previous: makeGetPreviousRoutes()(state, { id: own.incidentId }),
        routingKey: state.incidentDetails.getIn([
          own.incidentId,
          'voFields',
          'routing_key',
        ]),
      }),
      dispatch => ({
        onCancel: () => dispatch(hideModal()),
        onAddResponders: payload => {
          dispatch(addRespondersWithModal(payload))
        },
        onAddConferenceBridge: payload => {
          dispatch(putConferenceBridgeDetails(payload))
        },
      })
    )
  )(_Form)
}

function showRerouteModal(incidentId, isMuted, dispatch) {
  const Component = makeRerouteContainer(RerouteForm)

  const modalConfig = {
    modalType: 'confirm',
    modalProps: {
      suppressOutsideClick: true, // @TODO: make this default
      modalClass: 'reroute-modal',
      actionBar: false,
      component: <Component incidentId={incidentId} isMuted={isMuted} />,
      onCancel: () => dispatch(hideModal()),
      onConfirm: () => null,
      title: `Reroute Incident #${incidentId}`,
    },
  }

  return () => dispatch(showModal(modalConfig))
}

export function showAddRespondersModal(incidentId, isMuted, dispatch) {
  const Component = makeAddRespondersContainer(AddRespondersForm)

  const modalConfig = {
    modalType: 'confirm',
    modalProps: {
      suppressOutsideClick: true, // @TODO: make this default
      modalClass: 'reroute-modal',
      actionBar: false,
      component: <Component incidentId={incidentId} isMuted={isMuted} />,
      onCancel: () => dispatch(hideModal()),
      onConfirm: () => null,
      title: 'Add Responders',
    },
  }

  return () => dispatch(showModal(modalConfig))
}

// Connect
// ---------------------------------------------------------------------------

function mapStateToProps(state, ownprops) {
  const getFeatureFlag = makeGetFeatureFlag()
  const getIncident = makeGetIncidentById()
  const incidentData = getIncident(state, { id: ownprops.incidentId })

  return {
    incident: incidentData.get('data', '')
      ? ProcessIncident(incidentData.get('data', ''))
      : Map({}),
    hasMultiResponders: getFeatureFlag(state, {
      id: 'feature:multiresponders',
    }),
    hasSnooze: getFeatureFlag(state, { id: 'feature:snoozeincidents' }),
    isMuted: incidentData.getIn(['componentProps', 'isMuted'], false),
    ackData: state.incidents.getIn([ownprops.incidentId, 'data', 'ACK_DATA']),
    ...ownprops,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onActionIncident: function onActionIncident({ id, phase }) {
      switch (phase) {
        case 'UNACKED':
          return dispatch(
            updateIncidentsState({ incidentIds: [id], toPhase: 'ack' })
          )
        case 'ACKED':
          return dispatch(
            updateIncidentsState({ incidentIds: [id], toPhase: 'resolve' })
          )
        default:
          return error('Cannot dispatch for this phase')
      }
    },

    handleReroute: (incidentId, isMuted) => () =>
      dispatch(
        getTeamsWithPolicies({
          callback: showRerouteModal(incidentId, isMuted, dispatch),
        })
      ),
    handleAddResponders: (incidentId, isMuted) => () =>
      dispatch(
        getTeamsWithPolicies({
          callback: showAddRespondersModal(incidentId, isMuted, dispatch),
        })
      ),

    onReroute: function onReroute({ incidentId, showRerouteTool, recipients }) {
      return e => {
        const rect = e.target.getBoundingClientRect()

        const coords = {
          width: rect.width,
          height: rect.height,

          top: rect.top,
          right: rect.right,
          bottom: rect.bottom,
          left: rect.left,
        }

        return dispatch(
          incidentRerouting({
            incidentId,
            showRerouteTool,
            recipients,
            coords: coords,
          })
        )
      }
    },

    onSnooze: function onSnooze(incidentId) {
      return showSnoozeForm(incidentId, dispatch)
    },
  }
}

function getShouldOverrideProps(
  isMultiResponder,
  incidentPhase,
  ackData = Map({})
) {
  const ackUsers = ackData.get('ACK_USERS')
  const isAckedByCurrentUser =
    ackUsers && ackUsers.includes(window.VO_CONFIG.auth.user.username)
  const isNotResolved = incidentPhase !== 'RESOLVED'

  return isMultiResponder && isAckedByCurrentUser && isNotResolved
}

function getActionComponent(props) {
  const { ackData, incidentPhase, isMultiResponder } = props

  const shouldOverrideProps = getShouldOverrideProps(
    isMultiResponder,
    incidentPhase,
    ackData
  )

  const propsOverride = shouldOverrideProps
    ? {
        ...props,
        incidentPhase: 'ACKED',
      }
    : props
  if (props.isIncidentDetails) return <IncidentActions {...propsOverride} />
  else
    return (
      <VictoryActions
        {...propsOverride}
        incidentActionLogger={incidentActionLogger}
      />
    )
}

// Export
// ---------------------------------------------------------------------------

export default connect(mapStateToProps, mapDispatchToProps)(getActionComponent)
