import { List, Map as iMap, OrderedMap as iOrderedMap, fromJS } from 'immutable'

import {
  TIMELINE_MESSAGES_ADD_SEQUENCE,
  TIMELINE_MESSAGES_CLEAR,
  TIMELINE_MESSAGES_SET_END_OF_HISTORY,
  TIMELINE_MESSAGES_FLUSH,
  TIMELINE_MESSAGES_SHOW,
  TIMELINE_SET_PENDING_TRANSACTION,
  TIMELINE_TOGGLE_MESSAGE_EXPAND,
} from 'components/store/actions'

import { preprocessMessage, sortTimelineMessages } from './timelines-helpers'

export default function timelineMessagesReducer(state = iMap({}), action) {
  switch (action.type) {
    case TIMELINE_MESSAGES_ADD_SEQUENCE:
      return _addTimelineMessageSequence(state, action.payload)
    case TIMELINE_MESSAGES_CLEAR:
      return _clearTimelineMessages(state, action.payload)
    case TIMELINE_MESSAGES_SET_END_OF_HISTORY:
      return _setTimelineEndOfHistory(state, action.payload)
    case TIMELINE_SET_PENDING_TRANSACTION:
      return _setHasPendingTransaction(state, action.payload)
    case TIMELINE_MESSAGES_FLUSH:
      return _flushTimelineMessages(state, action.payload)
    case TIMELINE_MESSAGES_SHOW:
      return _showNewTimelineMessages(state, action.payload)
    case TIMELINE_TOGGLE_MESSAGE_EXPAND:
      return _toggleTimelineMessageExpand(state, action.payload)
    default:
      return state
  }
}

function _addTimelineMessageSequence(passedState, payload) {
  if (!payload.messageSequence) {
    return passedState
  }

  const { roomId, messageSequence } = payload
  const state = passedState.has(roomId)
    ? passedState
    : passedState.set(roomId, iMap())
  const timelineList = state.getIn([roomId, 'messages'], iOrderedMap())
  const newTimelineList = state.getIn([roomId, 'newMessages'], iOrderedMap())

  const orderedMessageSequence = messageSequence.reduce((oMap, el) => {
    if (
      timelineList.get(el[1].SEQUENCING.SEQUENCE) ||
      newTimelineList.get(el[1].SEQUENCING.SEQUENCE)
    ) {
      // if we can find this message in the other list
      // then we don't need to add it again
      return oMap
    }
    // VIR-400 Temporarily dropping Stakeholder messages
    if (el[0] === 'STAKEHOLDER_MESSAGE') {
      return oMap
    }
    const processedEl = preprocessMessage(el[0], fromJS(el[1]))
    return oMap.set(processedEl.get('sequence'), processedEl)
  }, iMap({}))

  // If timeline is empty just add whatever we have
  if (timelineList.isEmpty()) {
    return state.setIn(
      [roomId, 'messages'],
      sortTimelineMessages(orderedMessageSequence)
    )
  }

  if (
    orderedMessageSequence.first() &&
    timelineList.last().get('sequence') >
      orderedMessageSequence.first().get('sequence')
  ) {
    const messagesIntoTimeline = sortTimelineMessages(
      timelineList.merge(orderedMessageSequence)
    )
    return state.setIn([roomId, 'messages'], messagesIntoTimeline)
  }

  // Handle new messages into growler
  if (!state.getIn([roomId, 'showNewTimelineMessages'])) {
    const allNewMessages = sortTimelineMessages(
      newTimelineList.merge(orderedMessageSequence)
    )

    return state.setIn([roomId, 'newMessages'], allNewMessages)
  }

  return state.setIn(
    [roomId, 'messages'],
    sortTimelineMessages(timelineList.merge(orderedMessageSequence))
  )
}

function _clearTimelineMessages(state, payload) {
  return state.clear()
}

function _flushTimelineMessages(state, roomId) {
  const allNewMessages = state.setIn(
    [roomId, 'messages'],
    state
      .getIn([roomId, 'newMessages'])
      .merge(state.getIn([roomId, 'messages']))
  )

  const resetMessages = allNewMessages.setIn(
    [roomId, 'newMessages'],
    iOrderedMap()
  )
  return resetMessages
}

function _showNewTimelineMessages(state, payload) {
  return state.setIn(
    [payload.roomId, 'showNewTimelineMessages'],
    payload.shouldShowNewMessages
  )
}

function _setHasPendingTransaction(passedState, payload) {
  const roomId = payload.roomId
  const state = passedState.has(roomId)
    ? passedState
    : passedState.set(roomId, iMap())

  return state.setIn(
    [roomId, 'hasPendingTransaction'],
    payload.transactionPending
  )
}

function _setTimelineEndOfHistory(passedState, payload) {
  const roomId = payload.roomId
  const state = passedState.has(roomId)
    ? passedState
    : passedState.set(roomId, iMap())

  return state.setIn([roomId, 'endOfHistory'], payload.endOfHistory)
}

function _toggleTimelineMessageExpand(state, payload) {
  const { roomId, sequence } = payload
  const expandedMessages = state.getIn([roomId, 'expandedMessages'], List())

  return expandedMessages.includes(sequence)
    ? state.setIn(
        [roomId, 'expandedMessages'],
        expandedMessages.filter(
          expandedSequence => expandedSequence !== sequence
        )
      )
    : state.setIn([roomId, 'expandedMessages'], expandedMessages.push(sequence))
}
