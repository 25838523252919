// vendor
import $ from 'jquery'
import Backbone from 'backbone'
import { bind, bindAll, find, forEach, map } from 'lodash'

// lib
import // log,
// trace,
'util/_console'
import { logError } from '../../util/monitoringService'

export default Backbone.View.extend({
  initialize(options) {
    this.options = options

    bindAll(this, [
      'render',
      'onClick',
      'onClickItem',
      'onKeydown',
      'onKeypress',
      'onKeyup',
      'onMouseenterItem',
      'onToggleHint',
      'canShowAutocomplete',
      'docOnClick',
      'getTriggerIndex',
      'hide',
      'highlightSearch',
      'insertSelected',
      'search',
      'show',
      'correctOverflow',
      'showSubset',
      'unhighlightSearch',
    ])

    this.render()
  },

  // Overrides
  // -------------------------------------------------------------------

  events: {
    click: 'onClick',
    'keydown .js-autocomplete': 'onKeydown',
    'keypress .js-autocomplete': 'onKeypress',
    'keyup .js-autocomplete': 'onKeyup',
    mouseenter: 'onMouseenter',

    'blur .js-autocomplete': 'onToggleHint',
    'focus .js-autocomplete': 'onToggleHint',
    'mouseenter .js-autocomplete': 'onToggleHint',
    'mouseleave .js-autocomplete': 'onToggleHint',
  },

  render() {
    this.lists = this.options.lists
    this.triggers = map(this.lists, list => list.trigger)
    this.singleTrigger = find(this.triggers, trigger => trigger.length === 1)
    this.doubleTrigger = find(this.triggers, trigger => trigger.length === 2)

    this.$dropdown = this.options.$dropdown || this.$('.js-dropdown')

    var $dropdown = this.$dropdown

    this.$input = this.$('.js-autocomplete')

    $dropdown.empty()

    $dropdown.on('mouseenter', '> li', this.onMouseenterItem)
    $dropdown.on('click', '> li', this.onClickItem)

    forEach(
      this.lists,
      bind(function(list) {
        list.collection.sort(function compare(a, b) {
          if (a.mainText < b.mainText) {
            return -1
          }

          if (a.mainText > b.mainText) {
            return 1
          }

          return 0
        })

        forEach(
          list.collection,
          bind(function(item, index) {
            item.className = list.className
            item.trigger = list.trigger

            var $li = $(this.template(item))

            if (index === 0) {
              $li.addClass('hover')

              if (list.listHeading) {
                $dropdown.append(`
                            <li class="js-heading list-heading" data-trigger="${item.trigger}">
                                <span class="${list.iconClass}"></span>

                                ${list.listHeading}
                            </li>
                        `)
              }
            }

            $dropdown.append($li)
          }, this)
        )
      }, this)
    )

    this.$items = $dropdown.find('> li').not('.js-heading')

    var context = this
    this.$('.js-heading').each(function() {
      var $el = $(this)

      if ($el.data('trigger').length === 1) {
        context.$singleHeading = $el
      } else {
        context.$doubleHeading = $el
      }
    })

    $(document).on({
      click: this.docOnClick,
    })
  },

  template: require('t/autocomplete_item.tpl'),

  // Custom Events
  // -------------------------------------------------------------------

  onClick() {
    if (this.options.clickToToggle) {
      this.$dropdown.toggle()

      this.$el.addClass('hover')
    }
  },

  onClickItem(event) {
    this.insertSelected($(event.currentTarget))
  },

  onKeydown(e) {
    var key = this.keyIs(e)

    if (this.$items) {
      var $items = this.$items.filter(':visible')
      var $selected = $items.filter('.hover')
      var selectedIndex = $items.index($selected)

      // down arrow
      if (key.DOWN && $items.length) {
        e.preventDefault()

        $items.removeClass('hover')

        if (selectedIndex + 1 < $items.length) {
          $items.eq(selectedIndex + 1).addClass('hover')
        } else {
          $items.eq(0).addClass('hover')
        }
      } else if (key.UP && $items.length) {
        // up arrow
        e.preventDefault()

        $items.removeClass('hover')
        $items.eq(selectedIndex - 1).addClass('hover')
      } else if (key.TAB && this.isOpen) {
        // tab
        e.preventDefault()

        this.insertSelected()
      } else if (key.BACKSPACE) {
        // backspace
        e.isBackspace = true

        return this.onKeypress(e)
      }
    }
  },

  onKeypress(e) {
    if (this.keyIs(e).ENTER) {
      e.preventDefault()

      if (this.isOpen) {
        e.stopPropagation()

        this.insertSelected()
      }
    }
  },

  onKeyup(e) {
    var val = this.$input.val()
    var key = this.keyIs(e)
    var startSearchString = this.getTriggerIndex(this.singleTrigger, val)
    var searchString = val.substring(startSearchString, val.length)
    var isDouble = false

    // if the list has a double trigger
    if (this.doubleTrigger) {
      // if the double trigger is found in the val and not followed by the single trigger preceded by a space
      var doubleIndex = this.getTriggerIndex(this.doubleTrigger, val)
      isDouble =
        doubleIndex && doubleIndex > val.lastIndexOf(` ${this.singleTrigger}`)
      if (isDouble) {
        searchString = val.substring(doubleIndex, val.length)
      }
    }

    if (!key.ESC && this.canShowAutocomplete()) {
      this.show(key.UP || key.DOWN)

      if (!key.UP && !key.DOWN) {
        this.search(searchString, isDouble)
      }
    } else {
      this.hide()
    }
  },

  onMouseenterItem(e) {
    var $item = $(e.currentTarget)

    if (!$item.hasClass('js-heading')) {
      this.$items.removeClass('hover')

      $item.addClass('hover')
    }
  },

  onToggleHint(event) {
    var $el = $(event.currentTarget)

    if (
      this.options.hint &&
      (event.type === 'mouseenter' ||
        $(document.activeElement).hasClass('js-autocomplete'))
    ) {
      $el.attr('placeholder', this.options.hint)
    } else {
      $el.attr('placeholder', 'post an update...')
    }
  },

  // Custom
  // -------------------------------------------------------------------

  canShowAutocomplete() {
    var $input = this.$input
    var val = $input.val()
    var indexUnderCursor = $input[0].selectionStart
    var lastSpace = val.lastIndexOf(' ', indexUnderCursor)
    var lastNewline = val.lastIndexOf('\n', indexUnderCursor)
    var wordStartUnderCursor =
      lastSpace > lastNewline ? lastSpace + 1 : lastNewline + 1 // determine if the delimiter to show autocomplete is a space or a newline
    var wordEndUnderCursor = val.indexOf(' ', wordStartUnderCursor + 1)

    if (wordEndUnderCursor === -1) {
      wordEndUnderCursor = val.length
    }

    var wordUnderCursor = val.slice(wordStartUnderCursor, wordEndUnderCursor)
    var inputContainsTrigger =
      wordUnderCursor.slice(0, 1) === this.singleTrigger
    var exceededTrigger =
      wordUnderCursor.indexOf(this.singleTrigger + this.doubleTrigger) !== -1 // More than two trigger chars in sequence

    return inputContainsTrigger && !exceededTrigger
  },

  docOnClick(e) {
    var isChild = $(e.target).closest(this.$el).length

    if (!isChild) {
      this.hide()
    }
  },

  getTriggerIndex(trigger, str) {
    var lastSpace = str.lastIndexOf(' ')
    var lastNewline = str.lastIndexOf('\n')
    var lastTrigger = str.lastIndexOf(trigger)
    var strBeforeLastTrigger = str.slice(0, lastTrigger)
    var prevLastIndexOfTrigger = strBeforeLastTrigger.lastIndexOf(trigger)

    if (
      trigger === '@' &&
      prevLastIndexOfTrigger !== -1 &&
      prevLastIndexOfTrigger + 1 === lastTrigger
    ) {
      // getTriggerIndex gets called for each trigger regardless of what is actually present
      // and we need to make a distinction between the '@' trigger and '@@'
      // this returns if we say trigger is '@' but '@@' is actually present
      return
    }

    if (lastTrigger <= 0 || lastTrigger - 1 === (lastSpace || lastNewline)) {
      // if trigger is the first index or has whitespace in fronttreat it like normal
      // (no email as username detected)
      return (
        str.lastIndexOf(trigger) !== -1 &&
        str.lastIndexOf(trigger) + trigger.length
      )
    } else {
      // This covers the email as username use case where the '@' has
      // other text in front of it that is not the team trigger '@@'
      return prevLastIndexOfTrigger + trigger.length
    }
  },

  hide() {
    var $dropdown = this.$dropdown
    var $items = this.$items

    if ($dropdown.is(':visible')) {
      $dropdown.hide(0, () => $dropdown.css({ height: 0, overflow: 'hidden' }))

      this.$el.removeClass('hover')

      if ($items) {
        $items.removeClass('hover')
      }

      this.isOpen = false
    }
  },

  highlightSearch(search, isDouble) {
    var matches = {
      double: 0,
      single: 0,
    }

    forEach(this.$items, function(item) {
      var $item = $(item)
      var $mainText = $item.find('.js-main')
      var $subText = $item.find('.js-sub')
      var regex

      try {
        regex = new RegExp('(' + search + ')', 'i')
      } catch (err) {
        logError(err, { groupBy: 'autocomplete:highlightSearch' })
      }

      var canShow =
        (isDouble && $item.data('trigger').length === 2) || !isDouble

      if (regex && $mainText.text().match(regex) && canShow) {
        var match = $mainText.text().replace(regex, '<strong>$1</strong>')

        $item.show()
        $mainText.html(match)
      }

      if (regex && $subText.text().match(regex) && canShow) {
        $item.show()
      }

      if (
        regex &&
        ($subText.text().match(regex) || $mainText.text().match(regex)) &&
        canShow
      ) {
        if ($item.data('trigger').length === 2) {
          matches.double++
        } else {
          matches.single++
        }
      }
    })

    if (this.$doubleHeading) {
      this.$doubleHeading.toggle(matches.double > 0)
    }

    this.showSubset(true)

    return matches
  },

  insertSelected($li) {
    if ($li && $li.hasClass('js-heading')) {
      return
    }

    // target element is passed in when clicked
    // retrieved with .hover class when selected via keyboard
    $li = $li || this.$items.filter('.hover').filter(':visible')

    var $input = this.$input
    var val = $input.val()
    var trigger = $li.data('trigger')

    var lastTrigger = val.lastIndexOf(trigger)
    var strBeforeLastTrigger = val.slice(0, lastTrigger)
    var prevLastIndexOfTrigger = strBeforeLastTrigger.lastIndexOf(trigger)

    var trimmedInput =
      val !== trigger ? $.trim(val).substring(0, val.lastIndexOf(trigger)) : ''

    if (trigger && trigger.length === 2 && val.lastIndexOf(trigger) === -1) {
      trimmedInput =
        val !== trigger
          ? $.trim(val).substring(0, val.lastIndexOf(trigger[0]))
          : ''
    }

    if (
      trigger === '@' &&
      prevLastIndexOfTrigger !== -1 &&
      !(prevLastIndexOfTrigger + 1 === lastTrigger)
    ) {
      // Email as username detected
      // Trim input to before email username instead of until the '@' inside the email
      trimmedInput = $.trim(val).substring(0, prevLastIndexOfTrigger)
    }

    var autocompleteText = $li.data('autocomplete-value')
    var output = trimmedInput + trigger + autocompleteText

    if (trigger !== '#') {
      output += ' '
    }

    $input.focus().val(output)

    this.hide()
  },

  keyIs(e) {
    return {
      ENTER: e.which === 13,
      ESC: e.which === 27,
      DOWN: e.which === 40,
      UP: e.which === 38,
      BACKSPACE: e.which === 8,
      TAB: e.which === 9,
    }
  },

  search(str, isDouble) {
    var $items = this.$items
    var $singleHeading = this.$singleHeading

    str = str.toLowerCase()

    $items.removeClass('hover').hide()

    if (str.length) {
      let matches = this.highlightSearch(str, isDouble)
      let resultsHeight =
        $items.first().height() * (matches.double + matches.single + 1)
      let headingsHeight = isDouble
        ? $singleHeading.height() + this.$doubleHeading.height()
        : $singleHeading.height()

      let totalHeight = headingsHeight + resultsHeight

      this.correctOverflow(this.$dropdown, totalHeight)

      if (matches.single === 0 && $singleHeading) {
        $singleHeading.hide()
      }

      if (!$items.filter(':visible').length) {
        this.hide()
      } else {
        $items
          .filter(':visible')
          .eq(0)
          .addClass('hover')
      }
    } else {
      $items
        .removeClass('hover')
        .eq(0)
        .addClass('hover')

      this.unhighlightSearch()

      if (isDouble) {
        if ($singleHeading) {
          $singleHeading.hide()
        }

        forEach($items, function(item) {
          var $item = $(item)

          if ($item.data('trigger').length === 2) {
            $item.show()
          }
        })

        $items
          .filter(':visible')
          .first()
          .addClass('hover')

        if (this.$doubleHeading) {
          this.$doubleHeading.css('border-width', '0')
        }

        this.showSubset(true)
      } else {
        this.showSubset()

        if ($singleHeading) {
          $singleHeading.show()
        }

        if (this.$doubleHeading) {
          this.$doubleHeading.css('border-width', '1px')
        }
      }
    }
  },

  show(arrowKeys) {
    var $dropdown = this.$dropdown
    var $items = this.$items

    if (!arrowKeys) {
      this.unhighlightSearch()

      $items.show()
    }

    if (this.$doubleHeading && !arrowKeys) {
      this.$doubleHeading.show()
    }

    $dropdown.show(this.correctOverflow(this.$dropdown))

    this.isOpen = true
  },

  correctOverflow($elem, innerHeight) {
    let elemHeight = innerHeight || $elem.height()
    let elemWindowDiff = elemHeight - window.innerHeight
    let overflowAmount = elemWindowDiff + $elem.offset().top + 45 // +40 for footer

    if (overflowAmount > 0) {
      $elem.css({
        height: elemHeight - overflowAmount,
        overflow: 'scroll',
      })
    } else {
      $elem.css({
        height: '',
        overflow: 'hidden',
      })
    }
  },

  // Call this with onlyVisible = true from each search function after the
  // results are show()n, to constrain the list size to fit the UI.
  showSubset(onlyVisible) {
    var $items = onlyVisible ? this.$items.filter(':visible') : this.$items
    var $single = $items.filter(function() {
      return $(this).data('trigger').length === 1
    })
    var $double = $items.filter(function() {
      return $(this).data('trigger').length === 2
    })
    var subsetSize = this.options.dropdownOptions || { user: 10, team: 10 }

    if (onlyVisible) {
      $items.hide()
    }

    $single.slice(0, subsetSize.user).show()
    $double.slice(0, subsetSize.team).show()
  },

  unhighlightSearch() {
    forEach(this.$items, function(item) {
      var $item = $(item)
      var $main = $item.find('.js-main')
      var text = $main
        .html()
        .replace('<strong>', '')
        .replace('</strong>', '')

      $main.text(text)
    })
  },
})
