import moment from 'moment'
import Event from '../models/event'

// file: event.alert.js
// alert event

export default Event.extend({
  defaults: {
    eventType: 'alert',
  },

  alertTime: function() {
    var data = this.get('VO_ALERT_RCV_TIME')
    var number = Number(data)
    if (number > 1356998400 && number < 4102444800) {
      number = number * 1000
    }

    return new Date(number)
  },

  displayField: function(data) {
    // is it a time?
    var number = Number(data)
    if (number > 1356998400 && number < 4102444800) {
      return this.formatTime(new Date(number * 1000)) /* seconds */
    }
    if (number > 1356998400000 && number < 4102444800000) {
      return this.formatTime(new Date(number), true) /* millis */
    }

    return data
  },

  // handle the new data formatters
  formatAlert: function(str, format) {
    try {
      if (format === 'tme' || format === 'tse') {
        var millis = format === 'tse' ? +str * 1000 : +str
        var date = moment.utc(millis)
        if (date.isValid()) {
          return {
            class: 'timestamp',
            text: this.formatTime(date.toDate()),
          }
        }
      } else {
        return {
          class: '',
          text: str,
        }
      }
    } catch (e) {
      // nop, fall through
    }

    return { class: format, text: str }
  },
})
