import { browserHistory } from 'react-router'
import { connect } from 'react-redux'

import ManualIncidentForm from './manual-incident-form'

import {
  clearCreateIncidentStatus,
  createIncident,
  getTeamsWithPolicies,
  hideModal,
  resetManualIncident,
  resetRecipients,
  showModal,
} from 'components/store/actions'

import {
  getIncidentCreateErrorText,
  getIncidentCreateErrorStatus,
  getFeatureFlags,
  makeGetFeatureFlag,
  makeGetMaintenance,
} from 'components/store/selectors'

import config from 'components/__utils/config'

function mapStateToProps(state) {
  const getFeatureFlag = makeGetFeatureFlag()

  return {
    maintenance: makeGetMaintenance()(state),
    status: getIncidentCreateErrorStatus(state),
    responseText: getIncidentCreateErrorText(state),
    isConferenceBridgesEnabled: getFeatureFlags(state).get(
      'feature:conferencebridges'
    ),
    multiRespondersEnabled: getFeatureFlag(state, {
      id: 'feature:multiresponders',
    }),
    config: config,
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    submitForm: function submitForm() {
      return ({
        summary,
        details,
        userName,
        targets,
        isMultiResponder,
        conferenceBridge,
      }) =>
        dispatch(
          createIncident({
            summary,
            details,
            userName,
            targets,
            isMultiResponder,
            conferenceBridge,
          })
        )
    },

    clearError: function clearError() {
      return () => dispatch(clearCreateIncidentStatus({}))
    },

    resetFormAndErrors: function reset() {
      return () => dispatch(resetManualIncident({}))
    },

    resetRecipients: () => dispatch(resetRecipients()),

    onResult: function() {
      const formatErrors = responseText => {
        const errorMsg = {
          Details: 'Missing Incident Body',
          Summary: 'Missing Incident Description',
          Targets:
            'Please select a User or Escalation Policy in the Send To field',
        }

        try {
          const errMsg = JSON.parse(responseText)
          if (errMsg.error === 'Missing required data') {
            return errMsg.data
              .map(err => {
                return errorMsg[err]
              })
              .join(', ')
          } else {
            return 'An unknown error occurred when creating this incident. Please try again later, and contact support if the condition persists.'
          }
        } catch (err) {
          console.error(err)
          return 'The incident could not be created at this time, due to an unknown error. Please try again later, and contact support if the condition persists.'
        }
      }

      return function onResult({ status, responseText }) {
        if (!status) {
          return dispatch(hideModal())
        }

        const isSuccess = status < 300
        const title = isSuccess ? 'Success' : 'Error'
        const body = isSuccess
          ? `Incident ${responseText.get(
              'incidentNumber'
            )} Created Successfully`
          : `Incident Creation Failed: ${formatErrors(responseText)}`

        const action = () => {
          dispatch(clearCreateIncidentStatus({}))

          if (isSuccess) {
            props.isLegacyView
              ? document
                  .querySelector('#create-incident')
                  .classList.add('hidden')
              : browserHistory.goBack()
          }
          return dispatch(hideModal())
        }

        const modalConfig = {
          modalType: 'acknowledge',
          modalProps: {
            title: title,
            body: body,
            hideCancel: true,
            onCancel: () => action(),
            onConfirm: () => action(),
            confirmButtonText: 'OK',
            confirmButtonType: 'info',
            suppressOutsideClick: true,
          },
        }

        return dispatch(showModal(modalConfig))
      }
    },

    getOptions: callback => () => dispatch(getTeamsWithPolicies({ callback })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualIncidentForm)
