import Event from '../models/event'

// file: event.resume.js
// resume event

export default Event.extend({
  defaults: {
    eventType: 'resume',
  },
})
