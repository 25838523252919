// vendor
import Marionette from 'lib/marionette'
import 'lib/bootstrap'

export default Marionette.Region.extend({
  onShow: function(view) {
    view.on('destroy', this.hideModal, this)

    this.$el.css('z-index', 10000)

    this.$el.modal()

    this.$el.on('hidden', () => this.destroy()) // ??? Did this ever work?
    this.$el.on('hidden.bs.modal', () => this.destroy())
  },

  onDestroy: function() {
    this.$el.css('z-index', -10000)
    this.trigger('destroy')
  },

  hideModal: function() {
    this.$el.modal('hide')
  },
})
