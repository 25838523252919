/**
 * Use this for pre-processing text from the server, such as escaping HTML tags
 * or surrounding plain text in a PRE tag.
 **/
import _ from 'lodash'
import stringUtil from 'util/String'

var Formatter = function Formatter(inputText) {
  this.inputText = inputText
}

Formatter.prototype.applyAll = function(input) {
  if (input) {
    this.inputText = input
  }

  for (var prop in this) {
    if (prop === 'applyAll' || !_.isFunction(this[prop])) {
      continue
    }
    this[prop](this.inputText)
  }
  return this.inputText
}

// just wrap the input text in a PRE tag
Formatter.prototype.preformattedText = function(input) {
  if (stringUtil.isPreformatted(input)) {
    this.inputText = '<pre>' + input + '</pre>'
  }
  return this
}

Formatter.prototype.value = function() {
  return this.inputText
}

export default Formatter
