// vendor
import debug from 'debug'
import { curry } from 'lodash'

import { takeEvery, takeLatest, call, put } from 'redux-saga/effects'

// lib
import {
  flushIncidents,
  hideModal,
  incidentDetailsAdd,
  showModal,
  updateCreateIncidentStatus,
  updateIncidentsPane,
  updatingIncidents,
  INCIDENT_DETAILS_REQUEST,
  INCIDENT_REROUTE_W_MODAL,
  ADD_RESPONDERS_W_MODAL,
  INCIDENT_REROUTE,
  INCIDENT_SNOOZE,
  INCIDENTS_PANE_UPDATE_FLUSH,
  INCIDENTS_UPDATE_STATE,
  MANUAL_INCIDENT_CREATE,
} from 'components/store/actions'

import config from 'components/__utils/config'

import { sendChunks } from 'components/__utils/send-chunks'

import { create } from 'components/__utils/xhr'

import { toPhase } from './helpers'

import xlog from 'util/extendedLog'

const error = debug('VO:sagas:error')
const userId = config.socketAuth.USER_ID.toLowerCase()
const companyId = config.socketAuth.COMPANY_ID

// Watchers
// ---------------------------------------------------------------------------

export function* watchIncidentDetailsRequest(voSocket) {
  yield takeEvery(INCIDENT_DETAILS_REQUEST, _incidentDetailsRequest(voSocket))
}

export function* watchUpdateAndFlushIncidentsPane() {
  yield takeEvery(INCIDENTS_PANE_UPDATE_FLUSH, _updateAndFlushIncidentsPane)
}

export function watchUpdateIncidentRouting(voSocket) {
  return function* watchUpdateIncidentRoutingGen() {
    yield takeEvery(INCIDENT_REROUTE, _updateIncidentRouting(voSocket))
  }
}

export function _watchUpdateIncidentRouting(voSocket) {
  return function*() {
    yield takeEvery(INCIDENT_REROUTE_W_MODAL, __updateIncidentRouting(voSocket))
  }
}

export function _watchUpdateIncidentResponders(voSocket) {
  return function*() {
    yield takeEvery(
      ADD_RESPONDERS_W_MODAL,
      __updateIncidentResponders(voSocket)
    )
  }
}

export function watchUpdateIncidentsState(voSocket) {
  return function* watchUpdateIncidentsStateGen() {
    yield takeEvery(INCIDENTS_UPDATE_STATE, _updateIncidentsState(voSocket))
  }
}

export function* watchCreateIncident() {
  yield takeLatest(MANUAL_INCIDENT_CREATE, _createIncident)
}

export function watchSnoozeIncident(voSocket) {
  return function* watchSnoozeIncidentGen() {
    yield takeEvery(INCIDENT_SNOOZE, _snoozeIncident(voSocket))
  }
}

// ---------------------------------------------------------------------------

function _incidentDetailsRequest(voSocket) {
  return function*(action) {
    const incidentDetailsPayload = {
      MESSAGE: 'INCIDENT_DETAIL_REQUEST_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        INCIDENT_ID: action.payload,
      },
    }

    try {
      const resp = yield call(voSocket.tSend(10000), incidentDetailsPayload)
      if (resp.PAYLOAD) {
        resp.PAYLOAD.INCIDENT_DETAILS.INCIDENT_ID = resp.PAYLOAD.INCIDENT_ID
      }
      yield put(incidentDetailsAdd(resp.PAYLOAD ? resp.PAYLOAD : resp))
    } catch (err) {
      error(err)
    }
  }
}

function _updateIncidentsState(voSocket) {
  const createAckIncidentMessage = curry(function createAckIncidentMessage(
    to,
    ids
  ) {
    return {
      MESSAGE: 'ACK_INCIDENTS_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        ACK_MSG: '',
        COMPANY_ID: companyId,
        INCIDENT_NAMES: ids,
        RESOLVE: to,
        USER_ID: userId,
      },
    }
  })

  return function*(action) {
    try {
      yield put(hideModal())
      yield put(updatingIncidents(action.payload.incidentIds))

      const msg = createAckIncidentMessage(toPhase(action.payload.toPhase))

      yield call(sendChunks(voSocket.send, msg), action.payload.incidentIds)
    } catch (err) {
      error(err)

      yield put(hideModal())
      yield put(updatingIncidents(action.payload.incidentIds))
    }
  }
}

function _updateIncidentRouting(voSocket) {
  return function*(action) {
    const { incidentId, toTeam, targets } = action.payload
    let msg = {
      MESSAGE: 'ROUTE_INCIDENT_REQUEST_MESSAGE',
      PAYLOAD: {
        INCIDENT_NAME: [incidentId],
      },
    }

    if (targets) {
      msg.PAYLOAD.TARGETS = targets
    } else {
      msg.PAYLOAD.TO_TEAM = toTeam
    }

    try {
      yield put(updatingIncidents([incidentId]))

      yield call(voSocket.tSend(10000), msg)
    } catch (err) {
      error(err)

      yield put(updatingIncidents([incidentId]))
    }
  }
}

function __updateIncidentRouting(voSocket) {
  return function*(action) {
    const incidentId = action.payload.incidentId

    try {
      const _targets = action.payload.selected.map(el => ({
        SLUG: el.id,
        TYPE: el.type === 'policy' ? 'EscalationPolicy' : 'User',
      }))

      const msg = {
        MESSAGE: 'ROUTE_INCIDENT_REQUEST_MESSAGE',
        PAYLOAD: {
          INCIDENT_NAME: [incidentId],
          TARGETS: _targets,
        },
      }

      yield put(updatingIncidents([incidentId]))

      yield put(hideModal())

      const reply = yield call(voSocket.tSend(10000), msg)

      if (reply.STATUS === '200') {
        yield put(showModal(action.payload.successModal))
      } else {
        throw new Error(`Could not reroute incident #${incidentId}`)
      }
    } catch (err) {
      xlog('socketError', { socketAction: 're-route' })
      error(err)

      yield put(updatingIncidents([incidentId]))

      yield put(showModal(action.payload.failureModal))
    }
  }
}

function __updateIncidentResponders(voSocket) {
  return function*(action) {
    const incidentId = action.payload.incidentId

    try {
      const _targets = action.payload.selected.map(el => ({
        SLUG: el.id,
        TYPE: el.type === 'policy' ? 'EscalationPolicy' : 'User',
      }))

      const msg = {
        MESSAGE: 'ROUTE_INCIDENT_REQUEST_MESSAGE',
        PAYLOAD: {
          INCIDENT_NAME: [incidentId],
          TARGETS: _targets,
          ADD_TARGETS: true,
        },
      }

      yield put(updatingIncidents([incidentId]))

      yield put(hideModal())

      const reply = yield call(voSocket.tSend(10000), msg)

      if (reply.STATUS === '200') {
        if (action.payload.addRespondersPromise) {
          action.payload.addRespondersPromise.resolve()
        }

        yield put(updatingIncidents([incidentId]))
        yield put(showModal(action.payload.successModal))
      } else {
        throw new Error(`Could not add responders to incident #${incidentId}`)
      }
    } catch (err) {
      if (action.payload.addRespondersPromise) {
        action.payload.addRespondersPromise.reject()
      }

      xlog('socketError', { socketAction: 're-route' })
      error(err)

      yield put(updatingIncidents([incidentId]))

      yield put(showModal(action.payload.failureModal))
    }
  }
}

function* _createIncident(action) {
  try {
    const results = yield call(
      create,
      `/api/v1/org/${config.orgslug}/incidents`,
      action.payload
    )
    yield put(
      updateCreateIncidentStatus({ status: 200, responseText: results })
    )
  } catch (err) {
    error(err)
    yield put(updateCreateIncidentStatus(err))
  }
}

function* _updateAndFlushIncidentsPane(action) {
  try {
    yield put(flushIncidents())
    yield put(updateIncidentsPane(action.payload))
  } catch (err) {
    error(err)
  }
}

function _snoozeIncident(voSocket) {
  return function*(action) {
    const snoozeMessage = {
      MESSAGE: 'SNOOZE_REQUEST_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        INCIDENT_NAMES: action.payload.incidentIds,
        SNOOZE_UNTIL: action.payload.snoozeUntil,
      },
    }

    try {
      yield put(updatingIncidents(action.payload.incidentIds))
      yield call(voSocket.tSend(10000), snoozeMessage)
      yield call(action.payload.success)
    } catch (err) {
      yield put(updatingIncidents(action.payload.incidentIds))
      yield call(action.payload.error)
      error(err)
    }
  }
}

export const Test = {
  _createIncident,
  _updateAndFlushIncidentsPane,
  _updateIncidentRouting,
  _updateIncidentsState,
}
