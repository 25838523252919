// vendor
import moment from 'moment-timezone'

import { every } from 'lodash'

import React from 'react'

import { connect } from 'react-redux'

import { fromJS } from 'immutable'

// lib
import {
  getActiveAudience,
  getActivePhase,
  getFeatureFlags,
  getIntegrationIcons,
  getNonVisibleIncidentCount,
  getPoliciesPaged,
  getSort,
  getVisibleIncidents,
  makeGetFeatureFlag,
} from 'components/store/selectors'

import {
  flushIncidents,
  sortIncidents,
  toggleIncident,
} from 'components/store/actions'

import IncidentsTable from './incidents-table'

import { showSnoozeForm } from './incident-actions/snooze/snooze-helpers'

import { Growler } from '@victorops/victory'

import config from 'components/__utils/config'

import { normalizeString } from 'util/normalize-string'

// Helpers
// ---------------------------------------------------------------------------
const timezone = moment.tz.guess()

function getGridType(containerWidth) {
  if (containerWidth < 800) {
    return 'small-card'
  } else if (containerWidth < 1200) {
    return 'medium-card'
  } else {
    return 'table'
  }
}

const getGrowlerVisibility = function(state) {
  const flags = getFeatureFlags(state)

  if (flags && flags.get('feature:disableincidentpanegrowler')) {
    return false
  }

  return getNonVisibleIncidentCount(state) > 0
}

const createGrowler = function(scrollTop, onClickCounter, newMessageCount) {
  return scrollTop > 0 && newMessageCount > 0 ? (
    <Growler
      growlerPosition={'incident'}
      messageCount={newMessageCount}
      scrollFunction={onClickCounter}
    />
  ) : null
}

const getReactTimeline = state => {
  const features = getFeatureFlags(state)
  return features.get('feature:reacttimeline')
}

// @TODO: Is this necessary?
//
// Research a bit more of react-virtualized scroll props. This function
// essentially winds up in `scrollToIndex`, though there are other props that
// might work better.
const isResetScroll = (function isResetScroll() {
  let audience, growler, phase

  return state => () => {
    const activePhase = getActivePhase(state)
    const activeAudience = getActiveAudience(state)
    const growlerVisibility = getGrowlerVisibility(state)

    // Reset the incident pane scroll position if any of these states
    // change. Check if they changed by caching previous values with
    // a closure, and then comparing them to the current state.
    const reset = !every(
      [
        activePhase === phase,
        activeAudience === audience,

        // The growler should only reset the scroll if it's going from
        // visible to non-visible.
        growler ? growlerVisibility === growler : true,
      ],
      isSame => isSame === true
    )

    // Cache
    audience = activeAudience
    growler = growlerVisibility
    phase = activePhase

    return reset
  }
})()

// Connect
// ---------------------------------------------------------------------------

function mapStateToProps(state, ownProps) {
  const { containerHeight, containerWidth } = ownProps
  const getFeatureFlag = makeGetFeatureFlag()

  const integrationIcons = getIntegrationIcons(state)
  const incidentsByFeature = () => {
    if (getReactTimeline(state)) {
      return getVisibleIncidents(state)
        .toList()
        .map(function(incident) {
          const normalizedMonitorTool = normalizeString(
            incident.getIn(['data', 'MONITOR_TYPE'])
          )
          return fromJS({
            // Cells
            alertCount: incident.getIn(['data', 'ALERT_COUNT']),
            annotationCount: incident.getIn(['data', 'ANNOTATION_COUNT']),
            host: incident.getIn(['componentProps', 'host']),
            incidentId: incident.getIn(['data', 'INCIDENT_NAME']),
            incidentMessage: incident.getIn([
              'componentProps',
              'incidentMessage',
            ]),
            incidentPolicies: incident.getIn([
              'componentProps',
              'incidentPolicies',
            ]),
            incidentTimestamp: incident.getIn([
              'componentProps',
              'incidentTimestamp',
            ]),
            integrationName: integrationIcons.getIn([
              normalizedMonitorTool,
              'name',
            ]),
            integrationIconPath: integrationIcons.getIn([
              normalizedMonitorTool,
              'icon_url',
            ]),
            monitorName: incident.getIn(['data', 'MONITOR_NAME']),
            monitorType: incident.getIn(['data', 'MONITOR_TYPE']),
            snoozedUntil: incident.getIn(['componentProps', 'snoozedUntil']),
            snoozedUntilTimestamp: incident.getIn(
              ['data', 'SNOOZED_UNTIL'],
              null
            ),
            userAcked: incident.getIn(['data', 'ACK_USER']),
            usersPaged: incident.getIn(['componentProps', 'usersPaged']),
            ackData: incident.getIn(['data', 'ACK_DATA'], []),

            // For CSS, etc.
            incidentPhase: incident.getIn(['data', 'CURRENT_ALERT_PHASE']),
            currentState: incident.getIn(['data', 'CURRENT_STATE']),
            isMultiResponder: incident.getIn(['data', 'IS_MULTI_RESPONDER']),
            isMuted: incident.getIn(['componentProps', 'isMuted'], false),
            isSelected: incident.getIn(['viewState', 'isSelected']),
            isUpdating: incident.getIn(['viewState', 'isUpdating']),

            // Sorting
            timestamp: incident.getIn(['data', 'INCIDENT_TIMESTAMP']),
          })
        })
    } else {
      return getVisibleIncidents(state)
        .toList()
        .map(function(incident) {
          const alertCount = incident.getIn(['data', 'ALERT_COUNT'])
          const host = incident.getIn(['data', 'HOST'])
          const monitorType = incident.getIn(['data', 'MONITOR_TYPE'])
          const name = incident.getIn(['data', 'INCIDENT_NAME'])
          const policiesPaged = getPoliciesPaged(incident)
          const service = incident.getIn(['data', 'SERVICE'])
          const teamsPaged = incident.getIn(['data', 'TEAMS_PAGED'])
          const timestamp = incident.getIn(['data', 'INCIDENT_TIMESTAMP'])
          const usersPaged = incident.getIn(['data', 'USERS_PAGED'])

          return fromJS({
            // Cells
            description: `${monitorType}: ${service}${host ? `/${host}` : ''}`,
            id: name,
            policies: policiesPaged,
            name: `#${name}/${alertCount} alert${alertCount !== 1 ? 's' : ''}`,
            teams: teamsPaged.sort().join(', '),
            time: moment(timestamp).format(`MM/DD/YYYY H:mm`),
            users: usersPaged.sort().join(', '),

            // For CSS, etc.
            incidentPhase: incident.getIn(['data', 'CURRENT_ALERT_PHASE']),
            incidentState: incident.getIn(['data', 'CURRENT_STATE']),
            isSelected: incident.getIn(['viewState', 'isSelected']),
            isUpdating: incident.getIn(['viewState', 'isUpdating']),

            // Sorting
            timestamp: timestamp,
          })
        })
    }
  }

  return {
    containerHeight: containerHeight,
    containerWidth: containerWidth,
    newMessageCount: getNonVisibleIncidentCount(state),
    createGrowler: (scrollTop, onClickCounter, newMessageCount) => {
      return createGrowler(scrollTop, onClickCounter, newMessageCount)
    },
    featureFlags: getFeatureFlags(state),
    gridType: getGridType(containerWidth),
    growlerVisible: getGrowlerVisibility(state),
    hasSnooze: getFeatureFlag(state, { id: 'feature:snoozeincidents' }),
    incidents: incidentsByFeature(),
    isResetScroll: isResetScroll(state),
    loggedInUser: config.auth.user.username,
    reactTimelineFeature: getReactTimeline(state),
    sort: getSort(state),
    timezone: moment()
      .tz(timezone)
      .format(`z`),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onClickCounter: () => dispatch(flushIncidents()),
    onShowIncident: id => () => (window.location = `#/incident/${id}`),
    onSort: nextSort => dispatch(sortIncidents(nextSort)),
    onToggleIncident: id => () => dispatch(toggleIncident(id)),
    onUpdateSnooze: (incidentId, snoozedUntil, snoozedUntilTimestamp) => {
      const modifySnooze = true
      return showSnoozeForm(
        incidentId,
        dispatch,
        modifySnooze,
        snoozedUntil,
        snoozedUntilTimestamp
      )
    },
  }
}

// Export
// ---------------------------------------------------------------------------

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsTable)
