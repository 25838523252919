import {
  addTimelineMessage,
  addTimelineMessageSequence,
} from 'components/store/actions'

// These are only here because private chats are in backbone still
export function receiveNewTimelineMessage(type, data, dispatcher) {
  const room = data.ROOM_ID || '*'

  if (room.charAt(0) === '*') {
    return dispatcher(
      addTimelineMessage({ type: type, data: data, roomId: room })
    )
  } else {
    return false
  }
}

export function receiveTimelineMessageSequence(
  roomId,
  messageSequence,
  dispatcher
) {
  if (roomId.charAt(0) === '*') {
    return dispatcher(addTimelineMessageSequence({ roomId, messageSequence }))
  } else {
    return false
  }
}
