import Backbone from 'lib/backbone'
import Marionette from 'lib/marionette'

import 'lib/stickit'

export default Marionette.View.extend({
  template: require('./templates/reauth.tpl'),
  className: 'vo-modal-dialog',

  initialize: function() {
    var self = this
    self.model = new Backbone.Model({ duration: 10 })
  },

  bindings: {
    '.js-duration': {
      observe: 'duration',
      onSet: function(value) {
        return +value
      },
    },
  },

  onRender: function() {
    var self = this
    self.stickit()
  },
})
