import { connect } from 'react-redux'

import { NoConferenceBridgeDisplayView } from './no-conference-bridge-display-view'
import { showAddRespondersModal } from 'components/incidents/view/incidents-table/incident-actions'

import { getTeamsWithPolicies } from 'components/store/actions'

function mapDispatchToProps(dispatch) {
  return {
    handleAddResponders: (incidentId, isMuted) => () =>
      dispatch(
        getTeamsWithPolicies({
          callback: showAddRespondersModal(incidentId, isMuted, dispatch),
        })
      ),
  }
}

export const NoConferenceBridgeDisplayContainer = connect(
  null,
  mapDispatchToProps
)(NoConferenceBridgeDisplayView)
