import alerts from './data/managers/alerts'
import controlcalls from './data/managers/controlcall'
import features from './data/managers/features'
import groups from './data/managers/groups'
import incidents from './data/managers/incidents'
import meta from 'util/meta'
import orgstate from './data/managers/orgstate'
import timelines from './data/managers/timelines'
import users from './data/managers/users'

export default {
  alerts,
  controlcalls,
  features,
  groups,
  incidents,
  meta,
  orgstate,
  timelines,
  users,
}
