import { connect } from 'react-redux'
import { fromJS } from 'immutable'

import { ToolbarView } from './toolbar-view'
import { incidentDetailsSetActiveIntegration } from 'components/store/actions'
import {
  getFeatureFlags,
  makeGetIncidentDetailsIntegrations,
} from 'components/store/selectors'
import {
  ConferenceBridgeDisplay,
  NoConferenceBridgeDisplay,
  ServiceNowDisplay,
} from './incident-integration-displays'

const mapStateToProps = (state, { incident }) => {
  const servicenowIDRegex = /servicenow-/
  const servicenowID = incident
    .get('TAGS')
    .filter(x => servicenowIDRegex.test(x))
    .first()

  const conferenceBridgeId = incident
    .get('TAGS')
    .filter(x => x === 'featurecustomconferencebridge')
    .first()

  /**
   * Add new integration buttons here with the following format:
   * display: component that gets toggled on the button's click
   * linkedIconUrl: colored logo
   * linkedID: id used to link the incident to the integration
   * title: title on button for hover and screenreaders
   * unlinkedIconURL: gray logo
   */
  const availableIntegrations = fromJS({
    servicenow2: {
      display: ServiceNowDisplay,
      linkedIconURL: '/integrations/logos/servicenow-logo.svg',
      linkedID: servicenowID && servicenowID.replace('servicenow-', ''),
      title: 'ServiceNow',
      unlinkedIconURL: '/integrations/logos/servicenow-logo-gray.svg',
    },
  })

  const conferencebridgesDisplay = {
    conferencebridges: {
      display: ConferenceBridgeDisplay,
      linkedIconURL: '/integrations/logos/conference-bridge-active.svg',
      linkedID: conferenceBridgeId,
      title: 'Conference Bridges',
      unlinkedIconURL: '/integrations/logos/conference-bridge-add.svg',
      unlinkedDisplay: NoConferenceBridgeDisplay,
    },
  }

  const getIntegrationsFromJson = makeGetIncidentDetailsIntegrations(
    availableIntegrations
  )

  const isConferenceBridgesEnabled = getFeatureFlags(state).get(
    'feature:conferencebridges'
  )

  const incidentPhase = incident.get('CURRENT_ALERT_PHASE', '')

  const getIntegrations =
    isConferenceBridgesEnabled && incidentPhase !== 'RESOLVED'
      ? Object.assign(
          {},
          getIntegrationsFromJson(state),
          conferencebridgesDisplay
        )
      : getIntegrationsFromJson(state)

  const incidentName = incident.get('INCIDENT_NAME')

  return {
    activeIntegration: state.incidentDetails.getIn([
      'activeIntegrations',
      incidentName,
    ]),
    integrations: getIntegrations,
  }
}

const mapDispatchToProps = (dispatch, { incident }) => {
  const incidentName = incident.get('INCIDENT_NAME')

  return {
    incidentDetailsSetActiveIntegration: integration =>
      dispatch(
        incidentDetailsSetActiveIntegration({
          incident: incidentName,
          integration,
        })
      ),
  }
}

export const ToolbarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolbarView)
