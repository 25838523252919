import React from 'react'
import { Iterable } from 'immutable'

/**
 * Higher-Order Component to easily and consistently use toJS() as needed when using immutable.js
 * in state being passed to presentational components through props.
 *
 * Use suggested by redux documentation here:
 * https://redux.js.org/recipes/usingimmutablejs#never-use-immutable-js-in-your-dumb-components
 *
 * @param {JSX.Element} WrappedComponent Container component, connected to state
 * @returns {(wrappedComponentProps) => JSX.Element} WrappedComponent component with props as JS
 */

export const toJSHOC = WrappedComponent => {
  return function toJS(wrappedComponentProps) {
    const key = 0
    const value = 1

    const propsJS = Object.entries(wrappedComponentProps).reduce(
      (newProps, wrappedComponentProp) => {
        newProps[wrappedComponentProp[key]] = Iterable.isIterable(
          wrappedComponentProp[value]
        )
          ? wrappedComponentProp[value].toJS()
          : wrappedComponentProp[value]
        return newProps
      },
      {}
    )

    return <WrappedComponent {...propsJS} />
  }
}
