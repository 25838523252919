import React from 'react'
import { ConferenceBridgeDisplayContainer } from './conference-bridge-display-container'
import { ConferenceBridgeDisplayErrorBoundary } from './conference-bridge-display-error-boundary'

const Fallback = () => <div>Failed to retrieve conference bridge</div>

export const ConferenceBridgeDisplay = props => (
  <ConferenceBridgeDisplayErrorBoundary Fallback={<Fallback />}>
    <ConferenceBridgeDisplayContainer {...props} />
  </ConferenceBridgeDisplayErrorBoundary>
)
