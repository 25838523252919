import moment from 'moment'

// Will be used when the data shape is fixed
// import {
//   trim
// } from 'lodash'

export default function processPage(timelineMessage) {
  const _timestamp = moment(
    timelineMessage.getIn(['SEQUENCING', 'SERVICE_TIME'])
  ).format('MMM. D - h:mm A')

  if (timelineMessage.get('CANCELLATION')) {
    return timelineMessage.withMutations(message => {
      message
        .setIn(
          ['componentProps', 'displayMessage'],
          `Paging cancelled for ${timelineMessage.get('USER_ID')}`
        )
        .setIn(['componentProps', 'eventType'], 'system')
        .setIn(['componentProps', 'filterType'], 'action-page')
        .setIn(['componentProps', 'timestamp'], _timestamp)
    })
  } else if (!timelineMessage.has('METHODS')) {
    return timelineMessage
  } else {
    const newMsgObj = {
      // the data shape is not fit for us to use currently
      user: null, // timelineMessage.get('USER_ID'),
      incidentId: null, // trim(timelineMessage.get('ID'), '#'),
      incidentName: null, // timelineMessage.get('ID')
    }

    return timelineMessage.withMutations(message => {
      message
        .setIn(['componentProps', 'eventType'], 'system')
        .setIn(['componentProps', 'filterType'], 'action-page')
        .setIn(['componentProps', 'timestamp'], _timestamp)
        .setIn(
          ['componentProps', 'methods'],
          message.get('METHODS').reduce((str, method) => {
            if (str.length > 0) {
              return str + ', ' + method.get('TYPE')
            } else {
              return method.get('TYPE')
            }
          }, '')
        )
        .setIn(['componentProps', 'pagingObj'], newMsgObj)

      if (message.get('ATTEMPT_NUMBER') > 1) {
        // TODO: ATTEMPT_NUMBER is always 0
        message.setIn(
          ['componentProps', 'displayMessage'],
          `Attempt ${message.get(
            'ATTEMPT_NUMBER'
          )}: Trying to contact ${message.get('USER_ID')} for ${message.get(
            'ID'
          )}, sending ${message.getIn(['componentProps', 'methods'])}`
        )
      } else {
        message.setIn(
          ['componentProps', 'displayMessage'],
          `Trying to contact ${message.get('USER_ID')} for ${message.get(
            'ID'
          )}, sending ${message.getIn(['componentProps', 'methods'])}`
        )
      }
    })
  }
}
