export function incidentsComparator({ sortBy, sortDirection }) {
  function sortAsc(a, b) {
    if (a === b) {
      return 0
    } else if (a > b) {
      return 1
    } else {
      return -1
    }
  }

  function sortDesc(a, b) {
    if (a === b) {
      return 0
    } else if (a > b) {
      return -1
    } else {
      return 1
    }
  }

  return function(a, b) {
    let _a, _b

    switch (sortBy) {
      case 'name':
        _a = a.get('id')
        _b = b.get('id')
        break

      case 'time':
        _a = a.get('timestamp')
        _b = b.get('timestamp')
        break

      default:
        _a = a.get(sortBy)
        _b = b.get(sortBy)
        break
    }

    if (sortDirection === 'ASC') {
      return sortAsc(_a, _b)
    } else {
      return sortDesc(_a, _b)
    }
  }
}

export function getRowClassNames(incidents) {
  return function({ index }) {
    if (index === -1 || !incidents) {
      return
    }
    const incident = incidents[index]

    const isUpdating = incident.get('isUpdating')
    const incidentState = incident.get('incidentState')

    return `incident-row incident-state--${incidentState.toLowerCase()} ${
      isUpdating ? 'is-updating' : ''
    }`
  }
}

export function getScrollToIndex(isResetScroll) {
  return isResetScroll ? 0 : undefined
}

export function getOffset(growlerVisible) {
  return growlerVisible ? 181 : 152
}

export function getRow(list) {
  return ({ index }) => {
    return list[index].toObject()
  }
}

export function getSortState(incidents, state) {
  const getUserPhase = () => {
    const phase = incidents.getIn([0, 'incidentPhase'])

    switch (phase) {
      case 'UNACKED':
        return 'usersPaged'
      default:
        return 'userAcked'
    }
  }

  const getSortByField = () => {
    switch (state.active) {
      case 'incident':
        return 'incidentId'
      case 'description':
        return 'monitorType'
      case 'policy':
        return 'incidentPolicies'
      case 'user':
        return getUserPhase()
      default:
        return 'timestamp'
    }
  }

  const sortByField = getSortByField()

  const sortedIncidents = incidents.sort((a, b) => {
    const oneA =
      sortByField === 'monitorType'
        ? a.get(sortByField) + a.get('incidentMessage')
        : a.get(sortByField)
    const twoB =
      sortByField === 'monitorType'
        ? b.get(sortByField) + b.get('incidentMessage')
        : b.get(sortByField)

    if (sortByField === 'timestamp' || sortByField === 'incidentId') {
      if (parseInt(oneA, 10) > parseInt(twoB, 10)) {
        return state.order === 'dec' ? -1 : 1
      } else {
        return state.order === 'dec' ? 1 : -1
      }
    } else {
      if (oneA.toLowerCase() > twoB.toLowerCase()) {
        return state.order === 'dec' ? 1 : -1
      } else {
        return state.order === 'dec' ? -1 : 1
      }
    }
  })

  return sortedIncidents
}
