// import module from 'module'
import Backbone from 'backbone'

export default Backbone.Model.extend({
  idAttribute: 'id',
  defaults: {
    body: '',
    created_at: '',
    display_at: '',
    incident_id: '',
    status: '',
    // no... would like to see the following broken out into a Channel object, and put a collection of them here...
    twitter_updated_at: '',
    updated_at: '',
    wants_twitter_update: '',
  },
})
