import React from 'react'
import { Link } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/fontawesome-pro-solid'

// const warn = debug('VO:manual-incident-container')
const clearfix = { clear: 'both' }

export default class PopoutHeader extends React.Component {
  handleCancel() {
    if (this.props.isLegacyView) {
      return (
        <a
          href='#'
          className='action-link'
          onClick={e => {
            e.preventDefault()
            document.querySelector('#create-incident').classList.add('hidden')
          }}
        >
          <span className='icon'>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </a>
      )
    } else {
      return (
        <Link to={`/client/${this.props.slug}`} className='action-link'>
          <span className='icon'>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </Link>
      )
    }
  }

  render() {
    return (
      <div className='popout-header'>
        <div className='pull-left bold-label'>{this.props.title}</div>
        <div className='pull-right'>{this.handleCancel()}</div>
        <div style={clearfix} />
      </div>
    )
  }
}
