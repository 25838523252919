import _ from 'lib/underscore'
import Event from '../models/event'

// file: event.page.js
// page event

export default Event.extend({
  defaults: {
    eventType: 'page',
  },

  methods: function() {
    return _.map(this.get('METHODS'), function(method) {
      return method.TYPE
    }).join(', ')
  },
})
