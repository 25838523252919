import moment from 'moment'

export default function processAlertResume(timelineMessage) {
  const _timestamp = moment(
    timelineMessage.getIn(['SEQUENCING', 'SERVICE_TIME'])
  ).format('MMM. D - h:mm A')

  const isGlobalMessage = timelineMessage.get('MAINTENANCE_MODE_INSTANCE')
    ? timelineMessage.getIn(['MAINTENANCE_MODE_INSTANCE', 'IS_GLOBAL'])
      ? 'for ALL Routing Keys'
      : 'for select Routing Key(s)'
    : ''

  return timelineMessage.withMutations(message => {
    const messageResumedBy = message.get('RESUMED_BY')
    const resumedBy =
      messageResumedBy === 'PUBLICAPI' ? 'Public API' : `@${messageResumedBy}`

    message
      .setIn(['componentProps', 'eventType'], 'system')
      .setIn(['componentProps', 'filterType'], 'action-maintmode')
      .setIn(
        ['componentProps', 'displayMessage'],
        `Maintenance Mode ended by ${resumedBy} ${isGlobalMessage}`
      )
      .setIn(['componentProps', 'timestamp'], _timestamp)
  })
}
