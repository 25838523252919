import moment from 'moment'

import { List } from 'immutable'

import { decodeHtml as _decodeHtml } from 'components/__utils/html'

function _getPoliciesPagedFormat(policiesPaged) {
  const teamPolicy = policiesPaged.reduce((arr, el) => {
    if (
      el.get('TEAM') &&
      !el.getIn(['POLICY', 'SLUG']).includes('directUserPolicySlug')
    ) {
      const nextArr = arr.set(
        arr.size,
        ` ${el.getIn(['TEAM', 'NAME'])} : ${el.getIn(['POLICY', 'NAME'])}`
      )
      return nextArr
    } else {
      return arr
    }
  }, List([]))

  return teamPolicy.join(',')
}

export default function processIncident(timelineMessage) {
  const _host = _decodeHtml(timelineMessage.get('HOST'))
  const _incidentMessage = _decodeHtml(timelineMessage.get('SERVICE'))
  const _isMuted = timelineMessage.get('IS_MUTED', false)
  const _policiesPaged = _getPoliciesPagedFormat(
    timelineMessage.get('POLICIES_PAGED', List([]))
  )
  const _snoozedUntil = timelineMessage.get('SNOOZED_UNTIL')
    ? moment(timelineMessage.get('SNOOZED_UNTIL')).format(`MMM. D, h:mm A`)
    : null
  const _usersPaged = timelineMessage.get('USERS_PAGED', List([])).join(', ')
  const _timestamp = moment(
    timelineMessage.getIn(['SEQUENCING', 'SERVICE_TIME']) ||
      timelineMessage.get('INCIDENT_TIMESTAMP')
  ).format('MMM. D - h:mm A')

  return timelineMessage.withMutations(message => {
    message
      .setIn(['componentProps', 'eventType'], 'incident')
      .setIn(['componentProps', 'filterType'], 'action-incident')
      .setIn(['componentProps', 'host'], _host)
      .setIn(['componentProps', 'incidentMessage'], _incidentMessage)
      .setIn(['componentProps', 'isMuted'], _isMuted)
      .setIn(['componentProps', 'policiesPaged'], _policiesPaged)
      .setIn(['componentProps', 'snoozedUntil'], _snoozedUntil)
      .setIn(['componentProps', 'usersPaged'], _usersPaged)
      .setIn(['componentProps', 'timestamp'], _timestamp)
  })
}
