// vendor
import { fromJS } from 'immutable'

import {
  RELATED_INCIDENTS_SET_LOADING,
  RELATED_INCIDENTS_UPDATE,
} from 'components/store/actions/related-incidents'

const defaultState = fromJS({
  isLoading: true,
})

// Reducer
// ---------------------------------------------------------------------------

export default function relatedIncidents(state = defaultState, action) {
  switch (action.type) {
    case RELATED_INCIDENTS_SET_LOADING:
      return setLoading(state, action.payload)
    case RELATED_INCIDENTS_UPDATE:
      return update(state, action.payload)
    default:
      return state
  }
}

// Helpers
// ---------------------------------------------------------------------------

export function setLoading(state, payload) {
  return state.set('isLoading', payload)
}

export function update(state, payload) {
  return state.set(payload.incidentId, fromJS(payload.data))
}
