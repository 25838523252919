export default (nextOffCallList, currentTake, policySlug, teamSlug) => {
  if (currentTake !== undefined) {
    return currentTake.get('end_time')
  }

  const nextOffCall = nextOffCallList.find(
    offCall =>
      offCall.get('slug') === teamSlug &&
      offCall.get('policySlug') === policySlug
  )

  if (nextOffCall !== undefined) {
    return nextOffCall.get('when')
  }

  return null
}
