/* eslint-disable no-useless-rename */
import fontawesome from '@fortawesome/fontawesome'

import {
  faAndroid as faAndroidSolid,
  faApple as faAppleSolid,
  faGithub,
} from '@fortawesome/fontawesome-free-brands'

import {
  faArrowAltDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBars,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faCog,
  faComment,
  faCommentAlt,
  faExclamationTriangle,
  faExternalLink,
  faFilter,
  faHandPaper,
  faMinus,
  faPencilAlt,
  faPlus,
  faSearch,
  faStrikethrough,
  faTimes,
  faUser,
  faUsers,
  faWrench,
} from '@fortawesome/fontawesome-pro-solid'

import {
  faCalendarAlt as faCalendarAltRegular,
  faCheckCircle as faCheckCircleRegular,
  faSquare as faSquareRegular,
  faCheckSquare as faCheckSquareRegular,
  faCircle as faCircleRegular,
  faClipboard as faClipboardRegular,
  faClock as faClockRegular,
  faFilter as faFilterRegular,
  faInfoCircle as faInfoCircleRegular,
  faPaperclip as faPaperclipRegular,
  faPhone as faPhoneRegular,
  faQuestionCircle as faQuestionCircleRegular,
  faStrikethrough as faStrikethroughRegular,
} from '@fortawesome/fontawesome-pro-regular'

import {
  faCode,
  faComments,
  faFilter as faFilterLight,
  faLightbulb as faLightbulbLight,
  faPaperclip as faPaperclipLight,
  faStrikethrough as faStrikethroughLight,
} from '@fortawesome/fontawesome-pro-light'
/* eslint-enable no-useless-rename */

fontawesome.config = {
  autoReplaceSvg: true,
  observeMutations: true,
}

export default function() {
  fontawesome.library.add(
    faAndroidSolid,
    faAppleSolid,
    faArrowAltDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBars,
    faCaretDown,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronRight,
    faCode,
    faCog,
    faComment,
    faComments,
    faCommentAlt,
    faExclamationTriangle,
    faExternalLink,
    faFilter,
    faFilterLight,
    faGithub,
    faHandPaper,
    faMinus,
    faPencilAlt,
    faPlus,
    faSearch,
    faStrikethrough,
    faTimes,
    faUser,
    faUsers,
    faWrench,
    faCalendarAltRegular,
    faCheckCircleRegular,
    faSquareRegular,
    faCheckSquareRegular,
    faCircleRegular,
    faClipboardRegular,
    faClockRegular,
    faFilterRegular,
    faInfoCircleRegular,
    faPaperclipRegular,
    faPhoneRegular,
    faQuestionCircleRegular,
    faStrikethroughRegular,
    faLightbulbLight,
    faPaperclipLight,
    faStrikethroughLight
  )
}
