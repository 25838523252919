// vendor
import Marionette from 'marionette'
import 'backbone.stickit'

// lib
// import log from 'util/_console'
import vent from 'util/vent'

export default Marionette.View.extend({
  initialize: function() {
    this.listenTo(this.model, 'change:state', function(model) {
      if (model.get('state')) {
        vent.trigger('components:add', this.model.get('_id'))
      } else {
        vent.trigger('components:remove', this.model.get('_id'))
      }
    })
  },

  className: function() {
    return this.model.get('group_id')
      ? 'statuspage-alert statuspage-component-child js-statuspage-alert'
      : 'statuspage-alert statuspage-component-parent js-statuspage-alert'
  },

  template: require('t/statuspage/modals/incident.component.tpl'),

  bindings: {
    '.js-statuspage-alert-state': {
      attributes: [
        {
          name: 'class',

          observe: 'state',

          onGet: function(val) {
            return val ? 'statuspage-alert-on' : 'statuspage-alert-off'
          },
        },
      ],
    },
  },

  events: {
    click: function() {
      var currentState = this.model.get('state')
      var nextState = !currentState

      this.model.set('state', nextState)
    },
  },

  onRender: function() {
    this.stickit()
  },
})
