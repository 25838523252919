// vendor
import { connect } from 'react-redux'

// lib
import {
  getActiveAudience,
  makeGetFeatureFlag,
} from 'components/store/selectors'

import {
  showModal,
  updateAndFlushIncidentsPane,
} from 'components/store/actions'

import GranularIncidentsNav from './granular-incidents-nav'

import { hasNeededRole } from '@victorops/utils'
const config = window.VO_CONFIG

function mapStateToProps(state, ownProps) {
  const { width } = ownProps
  const getFeatureFlag = makeGetFeatureFlag()
  return {
    audience: getActiveAudience(state),
    hasConferenceBridges: getFeatureFlag(state, {
      id: 'feature:conferencebridges',
    }),
    hasControlCall: getFeatureFlag(state, { id: 'feature:confcall' }),
    hasMaintenanceModePermission: hasNeededRole(config.context.roles, [
      'admin',
      'alert_admin',
    ]),
    width: width,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: function onClick({ type, value }) {
      return () => dispatch(updateAndFlushIncidentsPane({ [type]: value }))
    },
    showModal: modalConfig => dispatch(showModal(modalConfig)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GranularIncidentsNav)
