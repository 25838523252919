// vendor
import createSagaMiddleware from 'redux-saga'
import immutableTransform from 'redux-persist-transform-immutable'

import { persistStore, autoRehydrate } from 'redux-persist'

import { applyMiddleware, createStore, compose } from 'redux'

// lib
import rootReducer from './root-reducer'
import createSagas from './root-saga'
import { api } from 'components/__utils/xhr'

// Helpers
import config from 'components/__utils/config'

// ---------------------------------------------------------------------------

const sagaMiddleware = createSagaMiddleware()

const enhancer = compose(
  autoRehydrate(),
  applyMiddleware(sagaMiddleware),
  global && global.devToolsExtension ? global.devToolsExtension() : f => f
)

// Store
// ---------------------------------------------------------------------------

export default function store(voSocket, server) {
  const voStore = createStore(rootReducer, enhancer)

  sagaMiddleware.run(createSagas(voSocket, api, server))

  persistStore(voStore, {
    whitelist: ['incidentsNav'],
    keyPrefix: `${config.auth.org.slug}:${config.auth.user.username}:`,
    transforms: [immutableTransform()],
  })

  return voStore
}
