import 'react-virtualized/styles.css'

import React, { Component } from 'react'

import {
  List as VirtualList,
  CellMeasurerCache,
  CellMeasurer,
} from 'react-virtualized'

import { compose, withState, withHandlers } from 'recompose'

import { decodeHtml as _decodeHtml } from 'components/__utils/html'

import NoItems from './no-items'

import {
  getOffset,
  getRowClassNames,
  getScrollToIndex,
  incidentsComparator,
} from './incidents-helpers'

import Actions from './incident-actions'

import { IncidentPaneIncidentCard } from '@victorops/victory'

function createSmallCard(cardConfig, timezone, incidents, cellMeasurerCache) {
  function Card({ index, parent, style, key }) {
    if (cardConfig.reactTimelineFeature) {
      const {
        alertCount,
        annotationCount,
        currentState,
        host,
        incidentId,
        incidentMessage,
        incidentPhase,
        incidentPolicies,
        incidentTimestamp,
        integrationName,
        integrationIconPath,
        isMultiResponder,
        isMuted,
        isSelected,
        isUpdating,
        monitorName,
        monitorType,
        snoozedUntil,
        snoozedUntilTimestamp,
        timestamp,
        userAcked,
        usersPaged,
        ackData,
      } = cardConfig.incidents[index].toJS()

      return (
        <CellMeasurer
          cache={cellMeasurerCache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          <div style={style}>
            <IncidentPaneIncidentCard
              actionButtons={
                <Actions
                  hasSnooze={cardConfig.hasSnooze}
                  incidentId={incidentId}
                  incidentPhase={incidentPhase}
                  isMultiResponder={isMultiResponder}
                  isUpdating={isUpdating}
                  snoozedUntil={snoozedUntil}
                />
              }
              alertCount={alertCount}
              annotationCount={annotationCount}
              currentState={currentState}
              hasSnooze={cardConfig.hasSnooze}
              host={host}
              incidentId={incidentId}
              incidentMessage={incidentMessage}
              incidentPhase={incidentPhase}
              incidentPolicies={incidentPolicies}
              incidentTimestamp={incidentTimestamp}
              integrationName={integrationName}
              integrationIconPath={integrationIconPath}
              isMultiResponder={isMultiResponder}
              isMuted={isMuted}
              isSelected={isSelected}
              isUpdating={isUpdating}
              loggedInUser={cardConfig.loggedInUser}
              monitorName={monitorName}
              monitorType={monitorType}
              onToggle={cardConfig.onToggleIncident}
              onUpdateSnooze={cardConfig.onUpdateSnooze}
              snoozedUntil={snoozedUntil}
              snoozedUntilTimestamp={snoozedUntilTimestamp}
              timestamp={timestamp}
              userAcked={userAcked}
              usersPaged={usersPaged}
              ackData={ackData}
            />
          </div>
        </CellMeasurer>
      )
    } else {
      const {
        id,
        isSelected,
        description,
        incidentPhase,
        name,
        policies,
        users,
        time,
      } = cardConfig.incidents[index].toObject()

      const _description = _decodeHtml(description)

      return (
        <div key={id} className={getRowClassNames(incidents)({ index })}>
          <div style={style} className='incident-card--small'>
            <div className='l-select'>
              <div className='incident-cell--select'>
                <input
                  type='checkbox'
                  onChange={cardConfig.onToggleIncident(id)}
                  checked={isSelected}
                />
              </div>
            </div>

            <div className='l-content'>
              <div className='l-first'>
                <div className='incident-cell--name'>
                  <div className='incident-name'>
                    <span
                      className='incident-name--open'
                      onClick={cardConfig.onShowIncident(id)}
                    >
                      {name}
                    </span>
                    <a
                      className='incident-name--popout'
                      href={`https://${window.location.hostname}/ui/${window.VO_CONFIG.orgslug}/incident/${id}/details/`}
                      target='_blank'
                      title='Open incident in new window'
                    >
                      View
                    </a>
                  </div>
                </div>

                <div className='incident-cell--time'>{time}</div>
              </div>

              <div className='l-second'>
                <div className='l-left'>
                  <div
                    className='incident-cell--description'
                    title={_description}
                  >
                    {_description}
                  </div>

                  <div className='incident-cell--policies' title={policies}>
                    <span className='card-row-label'>Policies: </span>
                    {policies}
                  </div>

                  <div className='incident-cell--users' title={users}>
                    <span className='card-row-label'>Users: </span>
                    {users}
                  </div>
                </div>

                <div className='l-right'>
                  <div className='incident-cell--actions'>
                    {incidentPhase !== 'RESOLVED' ? (
                      <Actions incidentId={id} incidentPhase={incidentPhase} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return Card
}

class SmallPane extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cellMeasurerCache: new CellMeasurerCache({
        defaultHeight: 140,
        fixedWidth: true,
      }),
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.state.cellMeasurerCache.clearAll()
    if (nextProps.newMessageCount > this.props.newMessageCount) {
      if (this.props.scrollTop < 1) {
        this.props.onClickCounter()
      }
    }
  }

  onScroll(calculateScrollTop, scrollTop, onClickCounter, newMessageCount) {
    calculateScrollTop(scrollTop)
    if (scrollTop === 0 && newMessageCount > 0) {
      onClickCounter()
    }
  }

  render() {
    const {
      containerHeight,
      containerWidth,
      growlerVisible,
      hasSnooze,
      integrationIcons,
      isResetScroll,
      loggedInUser,
      newMessageCount,
      scrollTop,

      // incidents
      incidents,
      timezone,

      // actions
      calculateScrollTop,
      createGrowler,
      onClickCounter,
      onShowIncident,
      onToggleIncident,
      onUpdateSnooze,
      reactTimelineFeature,
    } = this.props

    const { cellMeasurerCache } = this.state

    const _incidents = incidents
      .sort(incidentsComparator({ sortBy: 'timestamp', sortDirection: 'DESC' }))
      .toArray()

    const cardConfig = {
      hasSnooze: hasSnooze,
      incidents: _incidents,
      integrationIcons: integrationIcons,
      loggedInUser: loggedInUser,
      onShowIncident: onShowIncident,
      onToggleIncident: onToggleIncident,
      onUpdateSnooze: onUpdateSnooze,
      reactTimelineFeature: reactTimelineFeature,
      timezone: timezone,
    }

    return (
      <div>
        {createGrowler(scrollTop, onClickCounter, newMessageCount)}
        <VirtualList
          onScroll={({ scrollTop }) => {
            this.onScroll(
              calculateScrollTop,
              scrollTop,
              onClickCounter,
              newMessageCount
            )
          }}
          className='incidents-table virtual-list'
          deferredMeasurementCache={cellMeasurerCache}
          rowClassName={
            reactTimelineFeature ? '' : getRowClassNames(_incidents)
          }
          noRowsRenderer={() => <NoItems />}
          rowCount={_incidents.length}
          rowRenderer={createSmallCard(
            cardConfig,
            timezone,
            _incidents,
            cellMeasurerCache
          )}
          scrollToIndex={getScrollToIndex(isResetScroll())}
          columnWidth={100}
          height={containerHeight - getOffset(growlerVisible)}
          rowHeight={cellMeasurerCache.rowHeight}
          width={containerWidth - 20}
        />
      </div>
    )
  }
}

const enhance = compose(
  withState('scrollTop', 'setscrollTop', null),
  withHandlers({
    calculateScrollTop: function({ setscrollTop }) {
      return scrollTop => setscrollTop(scrollTop)
    },
  })
)

export default enhance(SmallPane)
