import Backbone from 'backbone'

export default Backbone.Model.extend({
  urlRoot: '',
  idAttribute: 'id',
  defaults: {
    name: '',
    body: '',
    title: '',
    page_id: '',
  },
})
