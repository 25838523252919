// vendor
import Backbone from 'lib/backbone'
import LocalStorage from 'lib/backbone.localstorage'

// Model
// -----------------------------------------------------------------------

var Route = Backbone.Model.extend({
  defaults: {
    routeIsNew: true,

    routeGroupKey: undefined,

    state: 'off',
  },

  idAttribute: 'routeKey',
})

// Collection
// -----------------------------------------------------------------------

export default function(slug) {
  return Backbone.Collection.extend({
    model: Route,

    localStorage: new LocalStorage(slug + ':Routes'),

    comparator: 'routeKey',

    initialize: function() {
      if (
        !(
          window.Storage &&
          window.sessionStorage.getItem('disable-filter-persistence') === 'true'
        )
      ) {
        this.listenTo(this, 'remove', this._destroy, this)
        this.listenTo(this, 'add change', this._save, this)
      } else {
        this.fetch = function() {} // noop
      }
    },

    // Overrides
    // ---------------------------------------------------------------

    _save: function(model) {
      model.save()
    },

    _destroy: function(model) {
      this.localStorage.destroy(model)
    },
  })
}
