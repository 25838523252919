import env from './env'

function debug() {
  function checkEnv() {
    if (env === 'stage') {
      return true
    } else if (env === 'dev') {
      return true
    } else {
      return false
    }
  }

  function hasFlag() {
    try {
      return window.sessionStorage.getItem('log_reporting')
    } catch (e) {
      return null
    }
  }

  if (hasFlag()) {
    return function isDebug() {
      return true
    }
  } else {
    return checkEnv
  }
}

export default debug
