import styled from 'styled-components'

export const Toolbar = styled.div`
  background-color: white;
  box-shadow: inset 0 -1px #eaeaea;
  display: flex;
  flex: 0 0 auto;
  height: 35px;
  min-height: 35px;
  padding: 2px 0;
`
