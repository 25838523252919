import React, { Component } from 'react'

import Select from 'react-select'

export default class DateTime extends Component {
  render() {
    return (
      <div className='col-9 col-xs-9'>
        <Select
          className={this.props.passedClass}
          clearable={false}
          onChange={this.props.onSelect}
          options={this.props.timeList}
          searchable={false}
          type='select'
          value={this.props.timeValue}
          valueRenderer={this.props.valueRenderer}
        />
      </div>
    )
  }
}
