import Backbone from 'lib/backbone'

// file: events.js
// base model for events

export default Backbone.Model.extend({
  idAttribute: 'sequence',

  initialize: function() {
    var sequencing = this.get('SEQUENCING')
    if (sequencing) {
      this.set('series', sequencing.SERIES)
      this.set('sequence', sequencing.SEQUENCE)
      this.set('seriesseq', '' + sequencing.SERIES + ':' + sequencing.SEQUENCE)
    }

    this.set('eventType', this.defaults.eventType)
  },

  timestamp: function() {
    var seq = this.get('SEQUENCING')
    if (seq) {
      return new Date(seq.SERVICE_TIME)
    }
  },
})
