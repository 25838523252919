import { BACKBONE_TIMELINE_CONTAINER_ACTIVE } from 'components/store/actions'

// This is meant to house something (I would recommend timestamps)
// that can be used to notify a CC that backbone has updated in a way
// that would otherwise be invisible to react.  It is... unfortunate.
// This shouldn't be permanent, and could go away after a rewrite of filters and the pane switcher.

// Currently used to notify:
//   - timeline: when routes need to update from local storage at start of day
//   - timeline: when the main app panes shift visibility with display:none
export default function backBoneTimeilneContainerActiveReducer(
  state = false,
  action
) {
  switch (action.type) {
    case BACKBONE_TIMELINE_CONTAINER_ACTIVE:
      return action.payload === 'active'
    default:
      return state
  }
}
