export function toPhase(phase) {
  switch (phase) {
    case 'ack':
      return false
    case 'resolve':
      return true
    default:
      throw new Error('State can no longer change for this incident')
  }
}
