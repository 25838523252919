// vendor
import 'react-virtualized/styles.css'

import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  Column as VirtualColumn,
  Table as VirtualTable,
  List as VirtualList,
  CellMeasurerCache,
  CellMeasurer,
} from 'react-virtualized'
import { getFeatureFlags } from 'components/store/selectors'

import { compose, withState, withHandlers } from 'recompose'

import { decodeHtml as _decodeHtml } from 'components/__utils/html'

import NoItems from './no-items'

import {
  getOffset,
  getRow,
  getRowClassNames,
  getScrollToIndex,
  getSortState,
  incidentsComparator,
} from './incidents-helpers'

import Actions from './incident-actions'

import {
  IncidentPaneTableCard,
  IncidentTabularHeader,
} from '@victorops/victory'

function createSelect(onToggleIncident) {
  function Select({ cellData, rowData }) {
    const { incidentPhase, isSelected } = rowData

    if (incidentPhase !== 'RESOLVED') {
      return (
        <div>
          <input
            type='checkbox'
            onChange={onToggleIncident(cellData)}
            checked={isSelected}
          />
        </div>
      )
    } else {
      return null
    }
  }

  return Select
}

function createName(onShowIncident) {
  function Name({ cellData, rowData }) {
    const { id } = rowData

    return (
      <div className='incident-name'>
        <span className='incident-name--open' onClick={onShowIncident(id)}>
          {cellData}
        </span>
        <a
          className='incident-name--popout'
          href={`https://${window.location.hostname}/ui/${window.VO_CONFIG.orgslug}/incident/${id}/details/`}
          target='_blank'
          title='Open incident in new window'
        >
          View
        </a>
      </div>
    )
  }

  return Name
}

function RowActions({ cellData, rowData }) {
  const { incidentPhase, id } = rowData

  return (
    <Actions key={id} incidentId={cellData} incidentPhase={incidentPhase} />
  )
}

function createLargeCard(cardConfig, timezone, cellMeasurerCache) {
  function Card({ index, style, parent, key }) {
    const {
      alertCount,
      annotationCount,
      currentState,
      host,
      incidentId,
      incidentMessage,
      incidentPhase,
      incidentPolicies,
      incidentTimestamp,
      integrationName,
      integrationIconPath,
      isMultiResponder,
      isMuted,
      isSelected,
      isUpdating,
      monitorName,
      monitorType,
      snoozedUntil,
      snoozedUntilTimestamp,
      timestamp,
      userAcked,
      usersPaged,
      ackData,
    } = cardConfig.incidents[index].toJS()

    return (
      <CellMeasurer
        cache={cellMeasurerCache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={style}>
          <IncidentPaneTableCard
            actionButtons={
              <Actions
                hasSnooze={cardConfig.hasSnooze}
                incidentId={incidentId}
                incidentPhase={incidentPhase}
                isMultiResponder={isMultiResponder}
                isUpdating={isUpdating}
                snoozedUntil={snoozedUntil}
              />
            }
            alertCount={alertCount}
            annotationCount={annotationCount}
            currentState={currentState}
            hasSnooze={cardConfig.hasSnooze}
            host={host}
            incidentId={incidentId}
            incidentMessage={incidentMessage}
            incidentPhase={incidentPhase}
            incidentPolicies={incidentPolicies}
            incidentTimestamp={incidentTimestamp}
            integrationName={integrationName}
            integrationIconPath={integrationIconPath}
            isMultiResponder={isMultiResponder}
            isMuted={isMuted}
            isSelected={isSelected}
            isUpdating={isUpdating}
            loggedInUser={cardConfig.loggedInUser}
            monitorName={monitorName}
            monitorType={monitorType}
            onToggle={cardConfig.onToggleIncident}
            onUpdateSnooze={cardConfig.onUpdateSnooze}
            snoozedUntil={snoozedUntil}
            snoozedUntilTimestamp={snoozedUntilTimestamp}
            timestamp={timestamp}
            userAcked={userAcked}
            usersPaged={usersPaged}
            ackData={ackData}
          />
        </div>
      </CellMeasurer>
    )
  }

  return Card
}

class LargePane extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cellMeasurerCache: new CellMeasurerCache({
        defaultHeight: 40,
        fixedWidth: true,
      }),
      active: 'date',
      order: 'dec',
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.state.cellMeasurerCache.clearAll()
    if (nextProps.newMessageCount > this.props.newMessageCount) {
      if (this.props.scrollTop < 1) {
        this.props.onClickCounter()
      }
    }
  }

  onScroll(calculateScrollTop, scrollTop, onClickCounter, newMessageCount) {
    calculateScrollTop(scrollTop)
    if (scrollTop === 0 && newMessageCount > 0) {
      onClickCounter()
    }
  }

  render() {
    const {
      containerHeight,
      containerWidth,
      growlerVisible,
      hasSnooze,
      integrationIcons,
      isResetScroll,
      loggedInUser,
      newMessageCount,
      scrollTop,
      sort,

      // incidents
      incidents,
      timezone,

      // actions
      calculateScrollTop,
      createGrowler,
      onClickCounter,
      onShowIncident,
      onSort,
      onToggleIncident,
      onUpdateSnooze,
      reactTimelineFeature,
    } = this.props

    const { cellMeasurerCache } = this.state

    const { sortBy, sortDirection } = sort

    const _incidents = incidents
      .sort(
        incidentsComparator({ sortBy: sortBy, sortDirection: sortDirection })
      )
      .toArray()

    const updateSortState = newActiveState => {
      const checkToggle = () => {
        if (this.state.active === newActiveState) {
          return this.state.order === 'dec' ? 'asc' : 'dec'
        } else {
          return 'dec'
        }
      }
      return this.setState({
        active: newActiveState,
        order: checkToggle(),
      })
    }

    if (reactTimelineFeature) {
      const sortedIncidents = getSortState(incidents, this.state).toArray()

      const cardConfig = {
        hasSnooze: hasSnooze,
        incidents: sortedIncidents,
        integrationIcons: integrationIcons,
        loggedInUser: loggedInUser,
        onShowIncident: onShowIncident,
        onToggleIncident: onToggleIncident,
        onUpdateSnooze: onUpdateSnooze,
        reactTimelineFeature: reactTimelineFeature,
        timezone: timezone,
      }
      if (sortedIncidents.length) {
        return (
          <div>
            <IncidentTabularHeader
              active={this.state.active}
              hasSnooze={hasSnooze}
              incidentPhase={sortedIncidents[0].get('incidentPhase')}
              sortOrder={this.state.order}
              toggleSort={updateSortState}
            />

            {createGrowler(scrollTop, onClickCounter, newMessageCount)}

            <VirtualList
              className='incidents-table'
              deferredMeasurementCache={cellMeasurerCache}
              rowClassName={
                reactTimelineFeature ? '' : getRowClassNames(sortedIncidents)
              }
              onScroll={({ scrollTop }) => {
                this.onScroll(
                  calculateScrollTop,
                  scrollTop,
                  onClickCounter,
                  newMessageCount
                )
              }}
              noRowsRenderer={() => <NoItems />}
              rowCount={sortedIncidents.length}
              rowRenderer={createLargeCard(
                cardConfig,
                timezone,
                cellMeasurerCache
              )}
              scrollToIndex={getScrollToIndex(isResetScroll())}
              columnWidth={100}
              height={containerHeight - getOffset(growlerVisible) - 40}
              rowHeight={cellMeasurerCache.rowHeight}
              width={containerWidth - 20}
            />
          </div>
        )
      } else {
        return (
          <VirtualList
            className='incidents-table'
            rowClassName={
              reactTimelineFeature ? '' : getRowClassNames(sortedIncidents)
            }
            noRowsRenderer={() => <NoItems />}
            rowCount={sortedIncidents.length}
            rowRenderer={createLargeCard(cardConfig, timezone)}
            scrollToIndex={getScrollToIndex(isResetScroll())}
            columnWidth={100}
            height={containerHeight - getOffset(growlerVisible)}
            rowHeight={41}
            width={containerWidth - 20}
          />
        )
      }
    } else {
      return (
        <VirtualTable
          className='incidents-table virtual-table'
          noRowsRenderer={() => <NoItems />}
          rowCount={_incidents.length}
          rowGetter={getRow(_incidents)}
          rowClassName={getRowClassNames(_incidents)}
          sort={onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          scrollToIndex={getScrollToIndex(isResetScroll())}
          headerClassName={'virtual-table-header'}
          headerHeight={30}
          height={containerHeight - getOffset(growlerVisible)}
          rowHeight={32}
          width={containerWidth - 20}
        >
          <VirtualColumn
            dataKey='id'
            cellRenderer={createSelect(onToggleIncident)}
            className='incident-cell--select'
            disableSort
            headerClassName='incidents-column--select'
            label='Select'
            flexShrink={0}
            width={30}
          />

          <VirtualColumn
            dataKey='name'
            cellRenderer={createName(onShowIncident)}
            className='incident-cell--name'
            headerClassName='incidents-column--name'
            label='Incident #'
            flexShrink={0}
            width={180}
          />

          <VirtualColumn
            dataKey='description'
            cellRenderer={({ cellData }) => _decodeHtml(cellData)}
            className='incident-cell--description'
            headerClassName='incidents-column--description'
            label='Description'
            flexGrow={5}
            width={350}
          />

          <VirtualColumn
            dataKey='policies'
            className='incident-cell--policies'
            disableSort
            headerClassName='incidents-column--policies'
            label='Policies'
            flexGrow={2}
            width={150}
          />

          <VirtualColumn
            dataKey='users'
            className='incident-cell--users'
            disableSort
            headerClassName='incidents-column--users'
            label='Users'
            flexGrow={2}
            width={150}
          />

          <VirtualColumn
            dataKey='time'
            className='incident-cell--time'
            headerClassName='incidents-column--time'
            label='time'
            flexGrow={1}
            width={110}
          />

          <VirtualColumn
            dataKey='id'
            cellRenderer={RowActions}
            className='incident-cell--actions'
            disableSort
            headerClassName='incidents-column--actions'
            label='Actions'
            flexShrink={0}
            width={60}
          />
        </VirtualTable>
      )
    }
  }
}

const enhance = compose(
  withState('scrollTop', 'setscrollTop', null),
  withHandlers({
    calculateScrollTop: function({ setscrollTop }) {
      return scrollTop => setscrollTop(scrollTop)
    },
  })
)

export default enhance(LargePane)
