import Backbone from 'backbone'
import CallUsers from '../collections/controlcall.users'

export default Backbone.Model.extend({
  idAttribute: 'CALL_ID',
  defaults: function() {
    return {
      CALL_ID: undefined,
      DIAL_IN: undefined,
      INITIATOR: undefined,
      INITIATOR_FULLNAME: undefined,
      ACCESS_CODE: undefined,
      PARTICIPANT_COUNT: 0,
      NAME: undefined,
      STATE: undefined,
      PARTICIPANTS: new CallUsers(),
    }
  },

  validation: {
    NAME: {
      required: true,
    },
  },
})
