import React from 'react'

function NoItems({ phase }) {
  const _phase = (function getReadablePhase(phase) {
    switch (phase) {
      case 'UNACKED':
        return 'Triggered'
      case 'ACKED':
        return 'Acked'
      case 'RESOLVED':
        return 'Resolved'
    }
  })(phase)

  return (
    <div className='incidents-empty'>
      <div className='inner'>
        <p>{`#${_phase}IncidentsZero`}</p>
      </div>
    </div>
  )
}

export default NoItems
