var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="statuspage-incident">\n    <div class="statuspage-incident-header statuspage-incident-header-' +
__e( status ) +
'">\n        <h1 class="statuspage-incident-title text--bold">' +
__e( name ) +
'</h1>\n        <a href=\'#\'\n          class="statuspage-incident-button statuspage-incident-button-header js-edit-incident"\n          data-ext="statuspage--update-incident"\n        >Update</a>\n    </div>\n    <div class="statuspage-incident-div">\n        <div>\n            <div class="statuspage-incident-field">\n                <div class="statuspage-incident-fieldlabel">STATUS: </div>\n                <div class="statuspage-incident-fielddata">' +
__e( status ) +
'</div>\n            </div>\n\n            <div class="statuspage-incident-field">\n                <div class="statuspage-incident-fieldlabel">MESSAGE: </div>\n                <div class="statuspage-incident-fielddata">' +
__e( message ) +
'</div>\n            </div>\n\n            <div class="statuspage-incident-field">\n                <div class="statuspage-incident-fieldlabel">LAST UPDATE: </div>\n                <div class="statuspage-incident-fielddata ">' +
__e( updated_at ) +
'</div>\n            </div>\n\n            <div class="statuspage-incident-field">\n                <div class="statuspage-incident-fieldlabel">UPDATED BY: </div>\n                <div class="statuspage-incident-fielddata ">' +
__e( created_by ) +
'</div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}