// vendor
import Marionette from 'marionette'
import 'backbone.stickit'

// lib
// import log from 'util/_console'
// import vent from 'util/vent'

export default Marionette.View.extend({
  // Overrides
  // -------------------------------------------------------------------

  className: function() {
    if (!this.model) {
      return 'statuspage-component-control'
    }

    return this.model.get('group_id')
      ? 'statuspage-component-control statuspage-component-child-modal'
      : 'statuspage-component-control statuspage-component-parent-modal'
  },

  template: require('t/statuspage/modals/component.tpl'),

  ui: {
    radios: 'input[type=radio].js-statuspage-component-status',
  },

  bindings: {
    'input[type=radio].js-statuspage-component-status': {
      observe: 'status',
    },
  },

  onRender: function() {
    this.stickit()
  },
})
