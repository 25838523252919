// vendor
import Rx from 'rxjs'

import { head, map } from 'lodash'

// victorops
import { hotpathProtocol } from '@victorops/message-protocol'

export function _orgStateInitProtocol(source) {
  return Rx.Observable.create(function createObserver(observer) {
    hotpathProtocol('STATE_NOTIFY_MESSAGE', source).subscribe(
      function next(el) {
        const type = head(el)

        switch (type) {
          case 'SYSTEM_ALERT_SUSPENSIONS':
            observer.next(el)
            break
          default:
            break
        }
      },

      function onError(err) {
        observer.error(err)
      },

      function onCompleted() {
        observer.complete()
      }
    )
  })
}

export function _orgStateProtocol(source) {
  const hotStreams = map(
    ['SYSTEM_ALERT_SUSPENDED_NOTIFY', 'SYSTEM_ALERT_RESUMED_NOTIFY'],
    type => hotpathProtocol(type, source)
  )

  return Rx.Observable.merge(...hotStreams)
}
