import Event from '../models/event'

// file: event.oncall.js
// oncall event

export default Event.extend({
  defaults: {
    eventType: 'oncall',
  },
})
