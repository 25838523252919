// vendor
// ---------------------------------------------------------------------------

import { takeEvery, put, call } from 'redux-saga/effects'

import debug from 'debug'

// lib
// ---------------------------------------------------------------------------

import {
  DELETE_POLICY,
  POLICIES_GET,
  POST_POLICY,
  PUT_POLICY,
  TEAMS_WITH_POLICIES_GET,
  removePolicy,
  updatePolicies,
  updatePolicyActions,
  updatePolicySteps,
  updateTeamsWithPolicies,
  setRoutes,
} from 'components/store/actions'

import config from 'components/__utils/config'

const errorVO = debug('VO:sagas:error')

// ---------------------------------------------------------------------------

function _getTeamsWithPolicies(api) {
  return function*(action) {
    const { callback } = action.payload

    try {
      const response = yield call(
        api.fetch,
        `/api/v2/org/${config.orgslug}/teams?include=policies`
      )
      yield put(updateTeamsWithPolicies(response))
      yield call(callback)
    } catch (err) {
      errorVO(err)
    }
  }
}

function _getPolicies(api) {
  function* _getPoliciesForTeams(fetch, teams) {
    try {
      for (let team of teams) {
        const response = yield call(
          fetch,
          `/api/v1/org/${config.orgslug}/teams/${team}/policies`
        )
        yield put(updatePolicies(team, response))
        yield put(updatePolicyActions(response))
        yield put(updatePolicySteps(response))
      }
    } catch (err) {
      errorVO(err)
    }
  }

  return function*(action) {
    const { callback, teams } = action.payload

    try {
      yield* _getPoliciesForTeams(api.fetch, teams)
      yield call(callback)
    } catch (err) {
      errorVO(err)
    }
  }
}

function _sendPolicy(api) {
  return function*(action) {
    const { verb } = action.meta
    const {
      payload: { values, success, error },
    } = action

    try {
      if (verb === 'PUT') {
        yield call(
          api.update,
          `/api/v1/org/${config.orgslug}/policies/${values.policyId}`,
          values
        )
      } else {
        yield call(api.create, `/api/v1/org/${config.orgslug}/policies`, values)
      }
      // @TODO: deprecate when response changes
      const response = yield call(
        api.fetch,
        `/api/v1/org/${config.orgslug}/teams/${values.teamSlug}/policies`
      )

      // @TODO: Add routes
      // @TODO: Fetch new routes state
      yield put(updatePolicies(values.teamSlug, response))
      yield put(updatePolicySteps(response))
      yield put(updatePolicyActions(response))

      // Update teamsWithPolicies in store
      const resp = yield call(
        api.fetch,
        `/api/v2/org/${config.orgslug}/teams?include=policies`
      )
      yield put(updateTeamsWithPolicies(resp))

      yield call(success)
    } catch (err) {
      yield call(error, err)
      yield call(errorVO, err)
    }
  }
}

function _deletePolicy(api) {
  return function*(action) {
    const {
      payload: { values, success, error },
    } = action
    const endpoint = `/api/v1/org/${config.orgslug}/policies/${values.policySlug}`
    const routesEndpoint = `/api/v2/org/${config.orgslug}/routes`
    try {
      yield call(api.destroy, endpoint)
      yield put(removePolicy(values.policyID))
      // Make sure routes are kept in sync since deleting an
      // escpol can result in a route getting deleted on the server
      const routes = yield call(api.fetch, routesEndpoint)
      yield put(setRoutes(routes))

      // Update teamsWithPolicies in store
      const resp = yield call(
        api.fetch,
        `/api/v2/org/${config.orgslug}/teams?include=policies`
      )
      yield put(updateTeamsWithPolicies(resp))

      yield call(success)
      // @TODO: need to clean up policy steps and actions
    } catch (err) {
      yield call(error, err)
      errorVO(err)
    }
  }
}

export const Test = {
  _deletePolicy,
  _getTeamsWithPolicies,
  _getPolicies,
  _sendPolicy,
}

// Watchers
// ---------------------------------------------------------------------------

export function watchDeletePolicy(api) {
  return function*() {
    yield takeEvery(DELETE_POLICY, _deletePolicy(api))
  }
}

export function watchPostPolicy(api) {
  return function*() {
    yield takeEvery(POST_POLICY, _sendPolicy(api))
  }
}

export function watchPutPolicy(api) {
  return function*() {
    yield takeEvery(PUT_POLICY, _sendPolicy(api))
  }
}

export function watchGetPolicies(api) {
  return function*() {
    yield takeEvery(POLICIES_GET, _getPolicies(api))
  }
}

export function watchGetTeamsWithPolicies(api) {
  return function*() {
    yield takeEvery(TEAMS_WITH_POLICIES_GET, _getTeamsWithPolicies(api))
  }
}
