import { Map } from 'immutable'

import { INTEGRATION_ICON_UPDATE } from 'components/store/actions'

export default function integrationIconReducer(state = Map(), action) {
  switch (action.type) {
    case INTEGRATION_ICON_UPDATE:
      return updateIntegrationIcons(state, action.payload)
    default:
      return state
  }
}

function updateIntegrationIcons(state, payload) {
  return state.merge(payload)
}
