// vendor
var Backbone = require('lib/backbone')
var Marionette = require('lib/marionette')
var when = require('when')
require('lib/stickit')

export default Marionette.View.extend({
  template: require('./templates/ack-resolve.tpl'),

  className: 'vo-modal-dialog',

  initialize: function(options) {
    this.promise = when.promise((resolve, reject) => {
      this.delegateEvents({
        'click .js-cancel': reject,
        'click .js-perform-action': resolve,
      })
    })
    this.model = new Backbone.Model({ msg: options.msg, title: options.title })
  },

  bindings: {
    '.js-ackresolve': {
      observe: 'msg',
      onSet: function(value) {
        return value
      },
    },
    '.js-ackres-title': {
      observe: 'title',
      onSet: function(value) {
        return value
      },
    },
  },

  onRender: function() {
    this.stickit()
  },
})
