import Backbone from 'lib/backbone'
import Component from '../../models/statuspage/component'
import { negate, set } from 'lib/lodash'
import { pipe, filter, sortBy, reduce, flatten, map } from 'ramda'

//  isParent :: Object -> Boolean
var isParent = x => x.attributes.group_id === null

//  isChild :: Object -> Boolean
var isChild = negate(isParent)

//  getParents :: [model] -> [parents]
var getParents = pipe(
  filter(isParent),
  sortBy(x => x.attributes.position)
)

//  getChildren :: [model] -> [children]
var getChildren = pipe(
  filter(isChild),
  sortBy(x => x.attributes.position)
)

//  groupBySiblings :: [children] -> Object (GroupID Siblings)
var groupBySiblings = reduce(function(siblings, child) {
  var parentGroup = siblings[child.attributes.group_id]

  if (!parentGroup) {
    parentGroup = []
  }

  return set(siblings, child.attributes.group_id, parentGroup.concat([child]))
}, {})

//  concatSiblingsToParent :: Object (GroupID Siblings) -> [model] -> [[Component]]
var concatSiblingsToParent = siblings =>
  map(function(parent) {
    const children = siblings[parent.id]

    if (children) {
      return [parent].concat(children)
    } else {
      return parent
    }
  })

//  getSorted :: [models] -> [models]
var getSorted = function(models) {
  var siblings = groupBySiblings(getChildren(models))
  var parents = getParents(models)

  return flatten(concatSiblingsToParent(siblings)(parents))
}

export default Backbone.Collection.extend({
  initialize: function() {
    this.listenTo(
      this,
      'sort',
      function() {
        this.reset(getSorted(this.models))
      }.bind(this)
    )
  },

  // Override
  // --------------------------------------------------------------------

  url: '/api/v1/org/' + window.VO_CONFIG.orgslug + '/statuspage/components',

  model: Component,

  save: function(options) {
    var myModel = new Backbone.Collection(
      this.clone().filter(function(m) {
        return m.hasChanged()
      })
    )
    myModel.url = this.url
    return Backbone.sync.call(myModel, 'update', myModel, options)
  },
})
