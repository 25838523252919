import React, { memo } from 'react'

import { Container } from './integration-display-styles'

export const IncidentIntegrationContainer = memo(
  ({
    activeIntegration,
    incident,
    IntegrationDisplay,
    UnlinkedIntegrationDisplay,
    linkedID,
  }) => {
    return (
      <Container>
        {linkedID ? (
          <IntegrationDisplay
            activeIntegration={activeIntegration}
            incident={incident}
            linkedID={linkedID}
          />
        ) : (
          <UnlinkedIntegrationDisplay
            activeIntegration={activeIntegration}
            incident={incident}
            linkedID={linkedID}
          />
        )}
      </Container>
    )
  }
)
