import _ from 'lib/underscore'
import ControlCalls from '../collections/controlcalls'
import server from '../../server'
import xlog from 'util/extendedLog'
import { logMessage } from '../../../util/monitoringService'

var controlcalls = new ControlCalls()
var endedcalls = []

server.on('state:controlcall', function(data) {
  controlcalls.set(data)
})

server.on('notify:controlcall', function(call) {
  if (call.STATE === 'FAILED') {
    xlog('controlcall:bridge-failed', 'Conference bridge is down.')
    logMessage('Conference bridge is down.')
    // noop here - no call to keep track of...
  } else if (call.STATE === 'ENDED') {
    controlcalls.remove(call.CALL_ID)
    endedcalls.push(call.CALL_ID)
  } else if (!_.includes(endedcalls, call.CALL_ID)) {
    var model = controlcalls.findWhere({ CALL_ID: call.CALL_ID })
    if (model) {
      _.each(call, function(value, key) {
        model.set(key, value)
      })
    } else {
      controlcalls.set(call)
    }
  }
})

export default controlcalls
