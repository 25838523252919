import { connect } from 'react-redux'

import { makeGetSuggestedResponders } from 'components/store/selectors/suggested-responders'
import { SuggestedRespondersView } from './suggested-responders-view'
import { fetchSuggestedResponders } from 'components/store/actions/suggested-responders'
import { getOnCallUsers } from 'components/store/selectors/on-call'

const mapStateToProps = (state, { entityID, selectedOptions }) => {
  const getSuggestedResponders = makeGetSuggestedResponders()

  return {
    responders: getSuggestedResponders(state, { entityID, selectedOptions }),
    onCall: getOnCallUsers(state),
  }
}

const mapDispatchToProps = {
  fetchSuggestedResponders,
}

export const SuggestedResponders = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestedRespondersView)
