// vendor
import React, { Fragment } from 'react'
import { Picker, Dropdown } from '@victorops/victory'
import { List } from 'immutable'
import { connect } from 'react-redux'

import { keys, merge, reduce } from 'ramda'

import { compose, withHandlers, withProps, withState } from 'recompose'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/fontawesome-pro-solid'

// lib
import { PopoutFooter } from 'components/popout-footer'
import { PopoutHeader } from 'components/popout-header'

import CBDetails from 'components/incidents/view/incidents-table/incident-actions/cb-details'

import {
  CB_DETAILS_STATES,
  checkDetailsValidity,
} from 'components/incidents/view/incidents-table/incident-actions/cb-details/cb-details-utils'

import {
  getIncidentPolicyRecipients,
  getIncidentTeamRecipients,
  getIncidentUserRecipients,
  getMICSelected,
  getPickerOptions,
} from 'components/store/selectors'

import { updateManualIncidentRecipients } from 'components/store/actions'

const _MICPicker = props => (
  <Picker
    filter={props.filter}
    options={props.options}
    placeholder={props.placeholder}
    selectedOptions={props.selectedOptions}
    onFilterChange={props.onFilterChange}
    onSelectedChange={props.onSelectedChange}
  />
)

const MICPicker = compose(
  withProps({
    placeholder: 'Search for Escalation Policies and Users',
  }),
  withState('filter', '_handleFilterChange', ''),
  withState('selectedOptions', '_handleSelectedChange', []),
  withHandlers({
    onFilterChange: ({ _handleFilterChange }) => test =>
      _handleFilterChange(() => test),
    _onSelectedChange: ({ _handleSelectedChange }) => next =>
      _handleSelectedChange(() => next),
  }),
  connect(
    state => ({
      MICSelected: getMICSelected(state),
      options: getPickerOptions(state),
    }),
    dispatch => ({
      _dispatchFormUpdate: (previous, next) => {
        const users = next
          .filter(el => el.type === 'username')
          .map(el => el.id)
          .reduce((acc, el) => {
            acc[el] = true

            return acc
          }, {})

        const policies = next
          .filter(el => el.type === 'policy')
          .map(el => el.id)
          .reduce((acc, el) => {
            acc[el] = true

            return acc
          }, {})

        const _check = dict =>
          reduce(function(acc, el) {
            if (dict[el]) {
              return acc
            } else {
              acc[el] = false
            }

            return acc
          }, {})

        const payload = {
          policies: merge(
            policies,
            _check(policies)(keys(previous.policies.toJS()))
          ),
          users: merge(users, _check(users)(keys(previous.users.toJS()))),

          policiesByTeam: {},
          teams: {},
          usersByTeam: {},
        }

        dispatch(updateManualIncidentRecipients(payload))
      },
    }),
    (state, actions, own) => {
      return {
        onSelectedChange: next => {
          own._onSelectedChange(next)
          actions._dispatchFormUpdate(state.MICSelected, next)
        },
        ...own,
        ...state,
        ...actions,
      }
    }
  )
)(_MICPicker)

class ManualIncidentForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasData: false,
      isMultiResponder: false,
      detailsAreValid: CB_DETAILS_STATES.valid,
      cbUrl: '',
      cbPhone: '',
      cbNotes: '',
    }

    this.getOptions = this.props.getOptions(() =>
      this.setState({ hasData: true })
    )

    this.description = React.createRef()
    this.body = React.createRef()
  }

  componentDidMount() {
    this.props.resetFormAndErrors()()
    this.getOptions()
  }

  componentWillUnmount() {
    this.props.resetFormAndErrors()()
  }

  componentDidUpdate(prevProps) {
    if (this.props.status && prevProps.states !== this.props.status) {
      this.props.onResult()(this.props)
    }
  }

  _getMultiRespondersDropdown() {
    const { multiRespondersEnabled } = this.props

    const singleRespondersText =
      'Stop paging after a single Acknowledge from any Escalation Policy or User'
    const multiRespondersText =
      'Continue paging until each Escalation Policy or User above has Acknowledged'

    const setMultiRespondersState = targetState => () => {
      this.setState({ isMultiResponder: targetState })
    }
    const dropdownItems = [
      {
        label: singleRespondersText,
        handleClick: setMultiRespondersState(false),
      },
      {
        label: multiRespondersText,
        handleClick: setMultiRespondersState(true),
      },
    ]

    const buttonLabel = this.state.isMultiResponder
      ? multiRespondersText
      : singleRespondersText
    const buttonLabelComponent = (
      <Fragment>
        {buttonLabel}
        <FontAwesomeIcon icon={faCaretDown} />
      </Fragment>
    )

    const multiResponderDropdown = (
      <div>
        <div className='bold-label top-label'>Acknowledge Behavior</div>
        <Dropdown
          labelComponent={buttonLabelComponent}
          dropdownItems={dropdownItems}
          triggerClasses={['wide-oneline', 'light-form-el']}
        />
      </div>
    )

    return multiRespondersEnabled ? multiResponderDropdown : null
  }

  _getMaintenanceWarningCopy = ({ maintenance }) => {
    const isInMaintenance = maintenance && Boolean(maintenance.count())
    const isInGlobalMaintenance =
      isInMaintenance &&
      Boolean(maintenance.find(mm => mm.get('isGlobal'), null, false))
    const dynamicMaintenanceCopy = isInGlobalMaintenance
      ? 'All incidents are currently muted'
      : 'Maintenance Mode is active for some Routing Keys'

    return (
      isInMaintenance && (
        <p className='alert alert-warning margin-top-8'>
          {dynamicMaintenanceCopy}. Creating this incident will exclude it from
          Maintenance Mode and start paging the selected escalation policies
          and/or users.
        </p>
      )
    )
  }

  validateDetails = () => {
    this.setState({
      detailsAreValid: checkDetailsValidity(
        this.state.cbUrl,
        this.state.cbPhone,
        this.state.cbNotes
      ),
    })
  }

  setUrl = urlStr => {
    this.setState({ cbUrl: urlStr }, () => {
      this.validateDetails()
    })
  }

  setPhone = phoneStr => {
    this.setState({ cbPhone: phoneStr }, () => {
      this.validateDetails()
    })
  }

  setNotes = notesStr => {
    this.setState({ cbNotes: notesStr }, () => {
      this.validateDetails()
    })
  }

  render() {
    if (!this.state.hasData) {
      return null
    }

    function getUserDisplayName(props) {
      return `${props.auth.user.firstName} ${props.auth.user.lastName}`
    }

    const Footer = this.getFooter()

    return (
      <div className='module-incidents-pane'>
        <div className='manual-incident-container'>
          <PopoutHeader
            slug={this.props.config.auth.org.slug}
            title='Create New Incident'
            isLegacyView={this.props.isLegacyView}
          />

          {this._getMaintenanceWarningCopy(this.props)}

          <div className='bold-label'>
            Monitor Type: <span>Manual</span>
          </div>

          <div className='bold-label'>
            Created By:{' '}
            <span className='pill'>
              {getUserDisplayName(this.props.config)}
              <span className='itemtag'>U</span>
            </span>
          </div>

          <div>
            <div className='bold-label top-label'>Send To</div>
            <MICPicker />
          </div>

          {this._getMultiRespondersDropdown()}

          <div className='bold-label top-label'>Incident Description</div>

          <div>
            <input
              className='wide-oneline light-form-el'
              type='text'
              ref={this.description}
            />
          </div>

          <div className='bold-label top-label'>Incident Body</div>

          <textarea className='wide-multiline light-form-el' ref={this.body} />

          {this.props.isConferenceBridgesEnabled && (
            <div className='cb-details-container'>
              <CBDetails
                headerText='Conference Bridge'
                setUrl={this.setUrl}
                setPhone={this.setPhone}
                setNotes={this.setNotes}
              />
            </div>
          )}

          <Footer
            slug={this.props.config.auth.org.slug}
            submitForm={this.submitForm}
            isLegacyView={this.props.isLegacyView}
          />
        </div>
      </div>
    )
  }

  // -------------------------------------------------------------------------

  getFooter() {
    return connect(state => {
      return {
        submitButtonEnabled:
          this.state.detailsAreValid !== CB_DETAILS_STATES.invalid,
        teamRecipients: getIncidentTeamRecipients(state),
        userRecipients: getIncidentUserRecipients(state),
        policyRecipients: getIncidentPolicyRecipients(state),
      }
    })(PopoutFooter)
  }

  // reshape the recipient selections into the format expected by the API
  getRecipients(props) {
    function process(map, type) {
      return map
        .filter(x => x)
        .map((_, key) => ({ type: type, slug: key }))
        .toList()
    }

    return List([])
      .concat(process(props.teamRecipients, 'EscalationPolicy'))
      .concat(process(props.policyRecipients, 'EscalationPolicy'))
      .concat(process(props.userRecipients, 'User'))
  }

  submitForm = args => {
    const { teamRecipients, userRecipients, policyRecipients } = args

    return () => {
      const recipients = this.getRecipients({
        teamRecipients,
        userRecipients,
        policyRecipients,
      }).toJS()

      let data = {
        summary: this.description.current.value,
        details: this.body.current.value,
        targets: recipients,
        isMultiResponder: this.state.isMultiResponder,
        userName: this.props.config.auth.user.username,
      }

      // Append the conf bridge info to payload, but only if it is valid and at least phone/url included
      if (
        this.state.detailsAreValid &&
        (this.state.cbPhone.length > 0 || this.state.cbUrl.length > 0)
      ) {
        data = {
          ...data,
          conferenceBridge: {
            url: this.state.cbUrl,
            phone: this.state.cbPhone,
            notes: this.state.cbNotes,
          },
        }
      }

      this.props.submitForm()(data)
    }
  }
}

export default ManualIncidentForm
