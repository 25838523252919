import { connect } from 'react-redux'

import { getModalType, getModalProps } from 'components/store/selectors'

import { hideModal } from 'components/store/actions'
import { ModalConfirm } from '@victorops/victory'

function mapStateToProps(state) {
  const props = getModalProps(state)

  return {
    isVisible: !!getModalType(state),
    modalType: getModalType(state),
    ...props,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    defaultCancel: () => dispatch(hideModal()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirm)
