// vendor
import $ from 'jquery'
import Backbone from 'lib/backbone'
import EventEmitter from 'events'
import MQueue from '../../lib/mqueue'
import _ from 'lib/underscore'
import momentTimeZone from 'moment-timezone'

// lib
import check from 'util/check'
import Connection from './connection'
import MessageProtocol from './protocol'
import TransactionMap from './transmap'
import log from 'util/_console'
import mq from './mq'
import timings from 'util/timings'
import xlog from 'util/extendedLog'
import { delayWrapper } from 'util/testing-aids'
import { logPerformance } from '@victorops/utils'
import { isIE } from 'util/ie'
import { isSocketCompressionDisabled } from 'util/configOverrides'
import { logError, logMessage } from '../../util/monitoringService'

var config = window.VO_CONFIG

// optionally induce lag in socket message processing to simulate flaky network
// see util/testing-aids/delayWrapper
var hotelNetwork = delayWrapper('hotel-mode', 'hotel-mode-lag')

config.routes = window.VO_ROUTES

window.VO_SOCKET_EMITTER = new EventEmitter()

var timeoutInterval = 60000

// we want to force a disconnect and reconnect logic
// if we fail to ping the server. see: https://code.google.com/p/chromium/issues/detail?id=76358
var nopongCount = 0
var nopongThresh = 3

// these act as backoff timers, retrying power of 5s
var reauthTimer = 1
var reconnectTimer = 1
var reconnectsTotal = 0

var makeHistoryQueues = function() {
  var queues = {
    heard: [],
    said: [],
  }

  var addTo = function(data, which) {
    var queue = queues[which]

    // What.the.fuck.
    try {
      queue.push(data)

      // limit length of queue
      if (queue.length > 25) {
        queue.shift()
      }
    } catch (e) {
      logError(e, { data, groupBy: ['historyQueue'] })
    }
  }

  return {
    addTo: addTo,
    yield: function() {
      return queues
    },
  }
}

var socketHistory = makeHistoryQueues()

let hasLoggedInitialTimelineLoad = false

/** VO Chat Server
 * Listens to socket messages come from the server and emits finer grained events
 */
function Server() {
  _.bindAll(
    this,
    'onConnected',
    'onDisconnected',
    'onMessage',
    'onHistory',
    'doPing',
    'processMessage'
  )

  this.conn = new Connection()
  this.txns = new TransactionMap()
  this.trans = new MessageProtocol()
  this.mqueue = new MQueue(this.processMessage)
  this.config = config
  this.version = 'init'

  // count websocket reconnections and how long this session has been open
  this.connectCount = 0
  this.sessionDurationMinutes = 0
  this.sessionStart = new Date().getTime()
  this.timeZone = momentTimeZone.tz.guess()

  this.listenTo(this.conn, 'connected', this.onConnected)
  this.listenTo(this.conn, 'message', hotelNetwork(this.onMessage, this))

  // set up ping pong for connection manager
  this.on(
    'protocol:authenticated protocol:reauthenticated',
    hotelNetwork(this.doPing, this)
  )

  this.on('protocol:authenticated', hotelNetwork(this.resolveAfterAuth, this))

  // pattern from http://lea.verou.me/2016/12/resolve-promises-externally-with-this-one-weird-trick/
  this.deferredAuthHandler = {}
  this.deferredAuthHandler.promise = new Promise(resolve => {
    this.deferredAuthHandler.resolve = resolve
  })

  this.listenTo(this, 'protocol:version', hotelNetwork(this.onVersion, this))

  // set up reconnection manager
  this.listenTo(this.conn, 'disconnected', this.onDisconnected)

  // how long has this session been open?
  setInterval(
    _.bind(function updateSessionDuration() {
      this.sessionDurationMinutes++
    }, this),
    60000
  )

  document.addEventListener('visibilitychange', this.onVisible.bind(this))

  mq.subscribe(
    'history',
    function() {
      this.mqueue.processAll(mq.next('history'))
    }.bind(this)
  )
  mq.subscribe(
    'state',
    function() {
      this.mqueue.processAll(mq.next('state'))
    }.bind(this)
  )
  mq.subscribe(
    'protocol',
    function() {
      this.mqueue.processAll(mq.next('protocol'))
    }.bind(this)
  )
  mq.subscribe(
    'notify',
    function() {
      this.mqueue.processAll(mq.next('notify'))
    }.bind(this)
  )
  mq.subscribe(
    'default',
    function() {
      this.mqueue.processAll(mq.next('default'))
    }.bind(this)
  )
}

Server.prototype = _.extend(Server.prototype, Backbone.Events, {
  // the message queue calls this to handle a message
  processMessage: function(msg) {
    this.trigger(msg[0], msg[1], msg[2])
  },

  connect: function(address) {
    check
      .feature('socketCompression')
      .then(hasFeature => {
        const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
        let chathost = `${wsProtocol}//${window.location.host}/chatsession${
          hasFeature && !isSocketCompressionDisabled() && !isIE()
            ? '?compress=1'
            : ''
        }`
        this.conn.connect(address || chathost)
      })
      .catch(e => {
        logError(e)
      })
  },

  disconnect: function() {
    this.conn.disconnect()
  },

  isConnected: function() {
    return this.conn.connected()
  },

  onVisible: function() {
    if (!this.conn.connected()) {
      reconnectTimer = 1

      this.reconnect()
    }
  },

  reconnect: function(now = false) {
    reconnectsTotal++
    if (this.isConnected() || reconnectsTotal > 60) return

    const triggerReconnect = (reconnectFn, timerValue) => {
      if (!now && reauthTimer < 125) {
        reauthTimer *= 2
      }

      const timer = setTimeout(reconnectFn, now ? 0 : timerValue * 1000)

      this.trigger(
        'socket:reconnecting',
        now ? 0 : timerValue,
        this.reconnectNow.bind(this, timer)
      )
    }

    const successCb = data => {
      if ('RECONNECTS' in data) {
        data['RECONNECTS'] = this.connectCount
        data['SESSIONDURATION'] = this.sessionDurationMinutes
        data['SESSIONSTART'] = this.sessionStart
        data['TZ'] = this.timeZone
      } else {
        _.assign(data, {
          RECONNECTS: this.connectCount,
          SESSIONDURATION: this.sessionDurationMinutes,
          SESSIONSTART: this.sessionStart,
          TZ: this.timeZone,
        })
      }

      config.socketAuth = data

      if (Storage && sessionStorage.getItem('socketproxy')) {
        const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
        this.connect(
          `${wsProtocol}//${
            window.location.hostname
          }/chaos${sessionStorage.getItem('socketproxy')}`
        )
      } else {
        this.connect()
      }
    }

    const reconFn = () => {
      // this is basically a noop, for SEC-3 - deprecating the old nonce
      // authentication without being too disruptive.
      $.Deferred()
        .resolve(config.socketAuth)
        .done(successCb)
    }

    // reconnect -------------------------------------------------------------

    if (this.conn.connected()) {
      return
    }

    this.connectCount = this.connectCount + 1

    triggerReconnect(reconFn, reconnectTimer * 2)
  },

  // As the name suggests, stop the timers and try reconnecting immediately
  reconnectNow: function(timer) {
    clearTimeout(timer)
    reconnectsTotal = 0
    this.reconnect(true)
  },

  send: function(msg) {
    socketHistory.addTo(msg, 'said')
    this.conn.send(msg)
  },

  request: function(msg, timeout, success, error) {
    var deferred = this.txns.push(
      timeout,
      hotelNetwork(success, this),
      hotelNetwork(error, this)
    )
    msg.TRANSACTION_ID = deferred.tid

    timings.start('transactions', msg.TRANSACTION_ID)

    this.conn.send(msg)

    return deferred
  },

  getUsers: function() {
    return $.getJSON(
      config.routes['controllers.api.v1.Users'].list(
        config.socketAuth.COMPANY_ID
      ).url
    )
  },

  getTeams: function() {
    return $.getJSON(
      config.routes['controllers.api.v1.Teams'].list(
        config.socketAuth.COMPANY_ID
      ).url
    )
  },
  // specific handlers

  sendChat: function(room, text, oncall) {
    var msg = {
      MESSAGE: 'CHAT_ACTION_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        CHAT: {
          ROOM_ID: room,
          TEXT: text,
          IS_ROBOT: false,
          IS_ONCALL: !!oncall, // handle undefined & null == false
        },
      },
    }

    this.send(msg)
  },

  sendChatCheckpoint: function(room) {
    var msg = {
      MESSAGE: 'CHAT_CHECKPOINT_ACTION_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        ROOM_ID: room,
      },
    }

    this.send(msg)
  },

  requestUser: function(uid, successCallback) {
    var msg = {
      MESSAGE: 'USER_REQUEST_MESSAGE',
      PAYLOAD: {
        USER_ID: uid,
      },
    }

    const hasUserData = data => {
      if (data.PAYLOAD.USER) {
        successCallback(data)
      } else {
        xlog('socketError', { socketAction: 'USER_REQUEST_MESSAGE' })
        xlog('server:requestUser', { uid: uid, err: data.PAYLOAD })
        logMessage('server:requestUser', {
          data: { uid: uid, err: data.PAYLOAD },
        })
      }
    }

    return this.request(msg, timeoutInterval, hasUserData, function(err) {
      xlog('server:requestUser', { uid: uid, err: err })
      logMessage('server:requestUser', { data: { uid: uid, err: err } })
    })
  },

  requestAlert: function(uuid, callback) {
    var msg = {
      MESSAGE: 'ALERT_DETAIL_REQUEST_MESSAGE',
      PAYLOAD: {
        VO_UUID: uuid,
      },
    }

    return this.request(
      msg,
      timeoutInterval,
      function(data) {
        callback(data.PAYLOAD.ALERT_DETAILS)
      },
      function(err) {
        xlog('server:requestAlert', { uuid: uuid, err: err })
        logError(err, {
          attributes: { uuid: uuid },
          groupBy: ['server:requestAlert'],
        })
      }
    )
  },

  requestHistory: function(room, count) {
    // we only want to log the initial timeline load, nothing else
    // this is stop()ped in server/protocal.js
    if (hasLoggedInitialTimelineLoad === false) {
      logPerformance.start({ eventName: 'LoadTimelineMessages' })
      hasLoggedInitialTimelineLoad = true
    }

    var msg = {
      MESSAGE: 'TIMELINE_LIST_REQUEST_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        ROOM_ID: room,
        SELECT_FROM_END: {
          LIMIT_SEQUENCE: 0,
          MAGNITUDE: count || 250,
        },
      },
    }

    return this.request(msg, timeoutInterval, this.onHistory, function(err) {
      xlog('server:requestHistory', { room: room, err: err })
      logMessage('server:requestHistory', { data: { room: room, err: err } })
    })
  },

  requestHistoryFrom: function(room, last) {
    var msg = {
      MESSAGE: 'TIMELINE_LIST_REQUEST_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        ROOM_ID: room,
        SELECT_FROM_END: {
          LIMIT_SEQUENCE: last,
          MAGNITUDE: 50,
        },
      },
    }

    return this.request(msg, timeoutInterval, this.onHistory, function(err) {
      xlog('server:requestHistoryFrom', { room: room, err: err })
      logError(err, { data: { room }, groupBy: ['server:requestHistoryFrom'] })
    })
  },

  requestHistoryTo: function(room, upto) {
    var msg = {
      MESSAGE: 'TIMELINE_LIST_REQUEST_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        ROOM_ID: room,
        SELECT_BY_SEQUENCE_RANGE: {
          REF_SEQUENCE: upto,
          LIMIT_SEQUENCE: 0,
          MAGNITUDE: 25,
        },
      },
    }

    return this.request(msg, timeoutInterval, this.onHistory, function(err) {
      xlog('server:requestHistoryTo', { room: room, err: err })
      logError(err, { data: { room }, groupBy: ['server:requestHistoryTo'] })
    })
  },

  requestFilteredHistory: function(room, tags, count) {
    var msg = {
      MESSAGE: 'TIMELINE_LIST_REQUEST_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        ROOM_ID:
          room +
          '&' +
          _.map(tags, function(t) {
            return 'tag=' + t
          }).join('&'),
        SELECT_FROM_END: {
          LIMIT_SEQUENCE: 0,
          LIMIT_SERIES: 0,
          MAGNITUDE: count || 250,
        },
      },
    }

    return this.request(msg, timeoutInterval, this.onHistory, function(err) {
      xlog('server:requestFilteredHistory', {
        room: room,
        tags: tags,
        err: err,
      })
      logError(err, {
        data: { room },
        groupBy: ['server:requestHistoryTo', ...tags],
      })
    })
  },

  requestTakeOnCall: function(uid, gid) {
    var msg = {
      MESSAGE: 'ONCALL_ACTION_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        TAKE_FROM_USER: '' + uid,
        TAKE_FROM_GROUP: '' + gid,
      },
    }

    this.send(msg)
  },

  enableMaintenanceMode: function() {
    var msg = {
      MESSAGE: 'SUSPEND_NOTIFICATIONS_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        DURATION_MINS: null,
        REMIND_INTERVAL: 30,
        TEAM_LIST: [],
      },
    }

    this.send(msg)
  },

  disableMaintenanceMode: function() {
    var msg = {
      MESSAGE: 'RESUME_NOTIFICATIONS_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        TEAM_LIST: [],
      },
    }

    this.send(msg)
  },

  acknowledgeIncident: function(incidentName, message, resolve) {
    var incidentNames = incidentName
    if (!_.isArray(incidentNames)) {
      incidentNames = [incidentNames]
    }

    var msg = {
      MESSAGE: 'ACK_INCIDENTS_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        USER_ID: config.socketAuth.USER_ID.toLowerCase(),
        COMPANY_ID: config.socketAuth.COMPANY_ID,
        INCIDENT_NAMES: incidentNames,
        ACK_MSG: message || '',
        RESOLVE: resolve || false,
      },
    }

    this.send(msg)
  },

  escalateIncident: function(incidentName) {
    var incidentNames = incidentName
    if (!_.isArray(incidentNames)) {
      incidentNames = [incidentName]
    }

    var msg = {
      MESSAGE: 'ESCALATE_INCIDENT_REQUEST_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        INCIDENT_NAME: incidentNames,
      },
    }

    return this.request(
      msg,
      timeoutInterval,
      function() {},
      function(err) {
        xlog('server:escalateIncident', {
          incidentNames: incidentNames,
          err: err,
        })
        logError(err, {
          data: { incidentNames },
          groupBy: ['server:escalateIncident'],
        })
      }
    )
  },

  rerouteIncident: function(incidentName, teams) {
    var incidentNames = incidentName
    if (!_.isArray(incidentNames)) {
      incidentNames = [incidentName]
    }

    var teamNames = teams
    if (!_.isArray(teamNames)) {
      teamNames = [teams]
    }

    var msg = {
      MESSAGE: 'ROUTE_INCIDENT_REQUEST_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        INCIDENT_NAME: incidentNames,
        TO_TEAM: teamNames,
      },
    }

    return this.request(
      msg,
      timeoutInterval,
      function() {},
      function(err) {
        xlog('server:rerouteIncident', {
          incidentNames: incidentNames,
          teams: teams,
          err: err,
        })
        logError(err, {
          data: { incidentNames, teams },
          groupBy: ['server:rerouteIncident'],
        })
      }
    )
  },

  resolveAfterAuth: function() {
    this.deferredAuthHandler.resolve()
  },

  getIncidents: function(incidentNames, callback) {
    var msg = {
      MESSAGE: 'INCIDENT_REQUEST',
      TRANSACTION_ID: '',
      PAYLOAD: {
        INCIDENT_NAMES: incidentNames,
      },
    }

    return this.deferredAuthHandler.promise.then(() =>
      this.request(msg, timeoutInterval, callback, function(err) {
        xlog('server:getIncidents', { incidentNames: incidentNames, err: err })
        logError(err, {
          data: { incidentNames },
          groupBy: ['server:getIncidents'],
        })
      })
    )
  },

  initControlCall: function(name, usernames, callback, onError) {
    var msg = {
      MESSAGE: 'CONF_CALL_INITIATE',
      PAYLOAD: {
        NAME: name,
        INVITE_USERS: usernames,
        // "INVITE_TEAMS": ...
      },
    }

    return this.request(msg, 60000, callback, function(err) {
      onError('Timed out.')
      xlog('server:initControlCall', { err: err })
      logError(err, { groupBy: ['server:initControlCall'] })
    })
  },

  endControlCall: function(id) {
    var msg = {
      MESSAGE: 'CONF_CALL_FORCE_END',
      PAYLOAD: {
        CALL_ID: id,
      },
    }

    return this.request(
      msg,
      60000,
      function() {},
      function(err) {
        xlog('server:endControlCall', { id: id, err: err })
        logError(err, { data: { id }, groupBy: ['server:endControlCall'] })
      }
    )
  },

  unsubscribeFromRoom: function(id) {
    var msg = {
      MESSAGE: 'UNSUBSCRIBE_ACTION_MESSAGE',
      TRANSACTION_ID: '',
      PAYLOAD: {
        ROOM_ID: id,
      },
    }

    this.send(msg)
  },

  // send a ping, and wait for a ping response
  // if we've already disconnected, the last ping doesn't get sent, because the connection fails in that case
  doPing: function() {
    var msg = { MESSAGE: 'PING', TRANSACTION_ID: '' }
    this.request(
      msg,
      5000,
      _.bind(this.onPong, this),
      _.bind(this.onNoPong, this)
    )
  },

  // when we're doing fine, send pings out every 5 seconds
  onPong: function() {
    setTimeout(this.doPing, 5000)
  },

  // if we're getting no response to our pong requests, try up to some threshold, and then
  // manually disconnect. see https://code.google.com/p/chromium/issues/detail?id=76358
  // basically, chrome waits until the OS declares the socket closed, which is waaaaaay too long.
  onNoPong: function() {
    // return // removed to isolate one thing at a time
    log.warn('no pong ' + nopongCount + '/' + nopongThresh)

    if (nopongCount < nopongThresh) {
      nopongCount++
      this.doPing()
    } else {
      // manually disconnect and cause the reconnect cycle to start up...
      log.warn('no pong giving up, disconnecting')
      xlog('server:noPong', socketHistory.yield())
      logMessage('server:noPong', {
        data: { socketHistory: socketHistory.yield() },
      })

      this.conn.disconnect()
      this.trigger('socket:timeout')
    }
  },

  // once we've connected to the socket, attempt to authenticate
  onConnected: function() {
    _.extend(config.socketAuth, { TZ: this.timeZone })

    logPerformance.start({ eventName: 'LoadUserPaneUsers' })
    logPerformance.start({ eventName: 'LoadUserPaneTeams' })

    this.trigger('protocol:authenticated')

    nopongCount = 0
    reauthTimer = 1
    reconnectTimer = 1

    this.trigger('socket:connected')
  },

  // when we've been disconnected, attempt to reconnect
  onDisconnected: function(error) {
    _.delay(() => {
      this.trigger('socket:disconnected', error)

      this.reconnect()
    }, Math.min([500 * this.connectCount, 20000]))
  },

  // check version
  onVersion: function(version) {
    log.info('version: ', version)

    if (this.version === 'init') {
      this.version = version
    } else if (this.version !== version) {
      this.version = version
      // We do not automatically want to show the version update
      // modal whenever we deploy chat. We might still want the
      // thing to show up in some cases, but not through this
      // mechanism when we deploy everything separately. -j
      //
      // this.trigger('protocol:version_update');
    }
  },

  // when we recieve a message from the server, translate it to events and fire them
  onMessage: function(message) {
    if (!_.isNull(message) && !_.isUndefined(message)) {
      var deferred = this.txns.pop(message.TRANSACTION_ID)

      // if it was a transaction response, use that callback
      if (deferred) {
        if (
          window.Storage &&
          window.sessionStorage.getItem('disable-users') === 'true' &&
          message.MESSAGE === 'USER_REPLY_MESSAGE'
        ) {
          return
        }
        check.feature('reactTimeline').then(hasFeature => {
          const isTimelineListReply =
            message.MESSAGE === 'TIMELINE_LIST_REPLY_MESSAGE'
          const isPrivateRoom =
            isTimelineListReply && message.PAYLOAD.ROOM_ID.charAt(0) !== '*'

          if (isTimelineListReply) {
            this.trigger('socket:TIMELINE_LIST_REPLY_MESSAGE', message.PAYLOAD)
          }
          // Skip reacttimeline messages that are for primary timelines
          if (!hasFeature || !isTimelineListReply || isPrivateRoom) {
            deferred.resolve(message)
          }
        })
      } else {
        // otherwise, translate the message and send the event
        var evs = this.trans.translate(message)

        var type = 'notify'
        switch (true) {
          case /^state:.*/.test(evs[0]):
            type = 'state'
            break
          case /^notify:.*/.test(evs[0]):
            type = 'notify'
            break
          case /^protocol:.*/.test(evs[0]):
            type = 'protocol'
            break
          default:
            type = 'default'
        }

        mq.push(type, evs)
      }
    }
  },

  // when we recieve history, we play them back as special types of events
  onHistory: function(data) {
    var self = this

    _.each(data.PAYLOAD.TIMELINE_LIST, function(data) {
      // push recent history into a queue, for logging
      socketHistory.addTo(data, 'heard')

      var evs = self.trans.translate_history(data)

      mq.push('history', evs)
    })
  },
})

export default Server
