// vendor
import $ from 'jquery'
import _ from 'lodash'

// lib
var config = window.VO_CONFIG

var metastuff = {
  orgmeta: false,
  usermeta: false,
}

// need to create a config module that does this
if (_.isUndefined(config.routes)) {
  config.routes = window.VO_ROUTES
}

var routes = config.routes

var getUserMeta = function() {
  var route = routes['controllers.api.v1.Meta'].getUserData()

  if (!metastuff.usermeta) {
    metastuff.usermeta = $.ajax(route.url, {
      method: route.method,
      contentType: 'application/json',
    })
  }
  return metastuff.usermeta
}

var putUserMeta = function(data) {
  var route = routes['controllers.api.v1.Meta'].setUserData()
  metastuff.usermeta = false
  return $.ajax(route.url, {
    method: route.method,
    contentType: 'application/json',
    data: JSON.stringify(data),
  })
}

var getOrgMeta = function() {
  var route = routes['controllers.api.v1.Meta'].getOrganizationData(
    config.socketAuth.COMPANY_ID
  )

  if (!metastuff.orgmeta) {
    metastuff.orgmeta = $.ajax(route.url, {
      method: route.method,
      contentType: 'application/json',
    })
  }
  return metastuff.orgmeta
}

var putOrgMeta = function(data) {
  var route = routes['controllers.api.v1.Meta'].setOrganizationData(
    config.socketAuth.COMPANY_ID
  )
  metastuff.orgmeta = false
  return $.ajax(route.url, {
    method: route.method,
    contentType: 'application/json',
    data: JSON.stringify(data),
  })
}

export default {
  getOrgMeta: getOrgMeta,
  getUserMeta: getUserMeta,
  putOrgMeta: putOrgMeta,
  putUserMeta: putUserMeta,
}
