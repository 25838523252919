// vendor
import _ from 'lib/underscore'
import $ from 'jquery'

// lib
import Groups from '../collections/groups'
import log from 'util/_console'
import server from '../../server'
import vent from 'util/vent'

var groups = new Groups()

groups.deferredOnCall = new $.Deferred()

var sortGroups = _.debounce(
  function(groups) {
    log.info('data/managers/groups:sortGroups')
    groups.sort()
  },
  500,
  {
    // leading: true
  }
)

var setGroupOnCall = function(uid, gid, on) {
  var group = groups.getDeferred(gid)

  return $.when(group).then(function(g) {
    g.setOnCall(uid, on === 'ON')
    sortGroups(groups)
  })
}

server.on('state:groups', function(data) {
  var all = _.chain(data)
    .map(function(g) {
      g.uids = g.USER_ID_LIST
      delete g.USER_ID_LIST
      return g
    })
    .value()

  groups.reset(all)

  vent.trigger('server:groups:done')
  vent.trigger('peoplePane:teamsLoaded', groups.length)
})

// handle the state changed in the beginning, but not later (we use the notifies to get OFFs)
server.once('state:oncall', function(data) {
  var onCalls = _.map(data, function(data) {
    setGroupOnCall(data.USER_ID, data.GROUP_ID, data.STATE)
  })

  $.when(onCalls).done(function() {
    groups.deferredOnCall.resolve()
  })
})

// handle on call notifies (this lets us handle OFF and ON states)
server.on('notify:oncall', function(data) {
  setGroupOnCall(data.USER_ID, data.GROUP_ID, data.STATE)
})

export default groups
