/* globals performance, Storage, localStorage */
import vent from './vent'
import xlog from './extendedLog'
import { logPerformance } from '@victorops/utils'
import { info as logInfo } from './_console'

export default function() {
  const appStartTime = performance.now()
  const userId = window.VO_CONFIG.context.user.username
  const companyId = window.VO_CONFIG.context.organization.slug
  const environment = window.VO_ENV.isProd
    ? 'PROD'
    : window.VO_ENV.isProdOrStage
    ? 'STAGE'
    : 'DEV'

  let authenticationTime

  vent.on('incidentsLoadTimer', ({ count, authenticated }) => {
    if (authenticated) {
      authenticationTime = performance.now() - appStartTime
    }
    if (count) {
      const incidentsLoadTime = performance.now() - appStartTime
      const incidentsNavIdentifier = `${window.VO_CONFIG.orgslug}:${window.VO_CONFIG.context.user.username}:incidentsNav`
      const incidentsNavData = Storage
        ? localStorage.getItem(incidentsNavIdentifier)
        : undefined
      const incidentAudience = incidentsNavData
        ? incidentsNavData.match(/(all|team|your)/) &&
          incidentsNavData.match(/(all|team|your)/)[0].toUpperCase()
        : undefined
      const incidentPhase = incidentsNavData
        ? incidentsNavData.match(/(RESOLVED|UNACKED|ACKED)/) &&
          incidentsNavData.match(/(RESOLVED|UNACKED|ACKED)/)[0]
        : undefined

      xlog('incidents:loaded', {
        loadTime: incidentsLoadTime,
        authenticationTime: authenticationTime,
        count: count,
        incidentAudience: incidentAudience,
        incidentPhase: incidentPhase,

        companyId: companyId,
        environment: environment,
        userId: userId,
      })

      vent.off('incidentsLoadTimer')
    }
  })

  vent.once('timelineFirstMessage', () => {
    logInfo(`first timeline message at ${performance.now()}`)

    xlog('timeline:firstMessage', {
      loadTime: performance.now() - appStartTime,

      companyId: companyId,
      environment: environment,
      userId: userId,
    })
  })

  vent.once('peoplePane:usersLoaded', userCount => {
    logInfo(`incident pane users loaded at ${performance.now()}`)

    logPerformance.stop(xlog, {
      eventName: 'LoadUserPaneUsers',
      itemCount: userCount,
    })

    xlog('peoplePane:usersLoaded', {
      loadTime: performance.now() - appStartTime,
      usersLoaded: userCount,

      companyId: companyId,
      environment: environment,
      userId: userId,
    })
  })

  vent.once('peoplePane:teamsLoaded', teamCount => {
    logInfo(`incident pane teams loaded at ${performance.now()}`)

    logPerformance.stop(xlog, {
      eventName: 'LoadUserPaneTeams',
      itemCount: teamCount,
    })

    xlog('peoplePane:teamsLoaded', {
      loadTime: performance.now() - appStartTime,
      teamsloaded: teamCount,

      companyId: companyId,
      environment: environment,
      userId: userId,
    })
  })

  setTimeout(() => {
    vent.off('incidentsLoadTimer')
  }, 10000) // handle zero incidents case
}
