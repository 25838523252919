// vendor
import Erd from 'element-resize-detector'
import React from 'react'
import { Map } from 'immutable'

// Helpers
// ---------------------------------------------------------------------------

const { Component } = React

// Export
// ---------------------------------------------------------------------------

export default function ComposedComponent(
  CC,
  wrapperClassName = 'scroll-container'
) {
  return class Wrapper extends Component {
    constructor(props) {
      super(props)

      const init = { height: 0, width: 0 }

      this.state = {
        dimensions: Map(init),
      }

      this.erd = Erd({ strategy: 'scroll' })
    }

    componentDidMount() {
      this.calc(this.wrapper)
    }

    render() {
      const { height, width } = this.state.dimensions.toObject()

      return (
        <div
          className={wrapperClassName}
          data-ext={wrapperClassName}
          ref={node => {
            this.wrapper = node
          }}
        >
          <CC {...this.props} containerHeight={height} containerWidth={width} />
        </div>
      )
    }

    // Custom
    // -----------------------------------------------------------------------

    setDimensions({ height, width }) {
      const nextHeight = { height: height, width: width }

      if (!this.state.dimensions.equals(nextHeight)) {
        this.setState(function updateDimensions() {
          return {
            dimensions: Map(nextHeight),
          }
        })
      }
    }

    calc(node) {
      const init = Map({ height: 0, width: 0 })

      if (node && node.clientHeight > 0) {
        // @TODO: Remove listener
        //
        // Need to verify that there aren't too many listeners getting
        // bound after re-renders.
        if (this.state.dimensions.equals(init)) {
          this.erd.listenTo(node, el =>
            this.setDimensions({
              height: el.clientHeight,
              width: el.clientWidth,
            })
          )

          this.setDimensions({
            height: node.clientHeight,
            width: node.clientWidth,
          })
        }
      } else {
        setTimeout(() => this.calc(node), 1000)
      }
    }
  }
}
