import React from 'react'
import { Link } from 'react-router'

export default class PopoutFooter extends React.Component {
  handleCancel() {
    if (this.props.isLegacyView) {
      return (
        <a
          href='#'
          className='btn btn-sm btn-secondary'
          onClick={e => {
            e.preventDefault()
            document.querySelector('#create-incident').classList.add('hidden')
          }}
        >
          Cancel
        </a>
      )
    } else {
      return (
        <Link
          to={`/client/${this.props.slug}`}
          className='btn btn-sm btn-secondary'
        >
          Cancel
        </Link>
      )
    }
  }

  render() {
    return (
      <div className='footer-controls-right'>
        {this.handleCancel()}
        <button
          className='btn btn-sm btn-outline-primary'
          disabled={!this.props.submitButtonEnabled}
          onClick={this.props.submitForm({
            teamRecipients: this.props.teamRecipients,
            userRecipients: this.props.userRecipients,
            policyRecipients: this.props.policyRecipients,
          })}
        >
          Create
        </button>
      </div>
    )
  }
}
