/* stylelint-disable plugin/stylelint-no-indistinguishable-colors */
// disabling because the color from splunk ui is too close to white to pass

import styled from 'styled-components'
import { spaceValue } from 'style-vars/sizes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Menu from '@splunk/react-ui/Menu'

export const MenuItem = styled(Menu.Item)`
  &:focus {
    outline: none;
    box-shadow: none !important;
    /* copied from splunk ui's hover state */
    background-color: #f2f4f5;
  }
`
export const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  margin-top: ${spaceValue}px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const NotesContainer = styled.div`
  width: 100%;
  white-space: pre-line;
  max-height: 200px;
  overflow-y: auto;
  margin-top: ${spaceValue}px;
`

export const MenuEllipses = styled(FontAwesomeIcon)`
  color: #7e7e7e;
  height: 16px;
  width: 10px;
`

export const MenuToggle = styled.div`
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 50%;

  &:hover {
    background-color: #d6d6d6;
    svg {
      color: white;
    }
  }
`
