/* global tpl */
import Marionette from 'lib/marionette'
import applicationTpl from 't/application.tpl'
import ModalRegion from '../lib/modalregion'
import _ from 'lib/underscore'

export const BaseView = Marionette.View.extend({
  template: applicationTpl,

  regions: {
    headerLeft: '.header-segment.header-left',
    headerCenter: '.header-segment.header-center',
    headerRight: '.header-segment.header-right',
    incident: '#incidentdetails',
    person: '#contactdetails',
    modalform: {
      selector: '#js-modal-form',
      regionClass: ModalRegion,
    },
    modalreg: {
      selector: '#js-modal',
      regionClass: ModalRegion,
    },
    modalsmall: {
      selector: '#js-modal-small',
      regionClass: ModalRegion,
    },
    rolodex: '.js-rolodex-region',
    statusPageNotifications: '.js-statuspage-notifications',
    timeline: '.js-primary-timeline',
    userInvite: {
      selector: '.js-user-invite-mode',
    },
    incidentsOldContainer: '#incidents',
  },
})
