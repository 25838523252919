import _console from 'util/_console'
import _ from 'lib/underscore'
import Backbone from 'lib/backbone'
import server from '../../server'
import $ from 'jquery'

import users from './users'
var log = _console.log

// this model represents the singleton orgstate container

var orgstate = new Backbone.Model({ suspended: false })

server.on('state:suspension notify:suspend notify:resume', function(data) {
  var suspended = false
  var suspendedUntil = 0

  if (!_.isUndefined(data.COMPANY_SUSPENDED)) {
    suspended = data.COMPANY_SUSPENDED
    suspendedUntil = data.COMPANY_SUSPENDED_UNTIL
  } else if (!_.isUndefined(data.COMPANY_RESUMED)) {
    suspended = !data.COMPANY_RESUMED
  } else {
    log('unknown suspension payload:', data)
    return
  }

  var username = data.SUSPENDED_BY ? data.SUSPENDED_BY : null
  var suspendedAt = data.SUSPENDED_AT ? data.SUSPENDED_AT : null
  var deferred = users.getDeferred(username)

  orgstate.set({
    suspended_at: suspendedAt,
    suspended: suspended,
    suspendedUntil: suspendedUntil || null,
  })

  $.when(deferred).then(function(user) {
    orgstate.set('suspended_by', user || null)
  })
})

export default orgstate
