// Action types
// ---------------------------------------------------------------------------

export const ALERT_DETAILS = 'ALERT_DETAILS'
export const ALERT_DETAILS_ADD = 'ALERT_DETAILS_ADD'

export const INCIDENTS_PANE_SORT = 'INCIDENTS_PANE_SORT'
export const INCIDENTS_PANE_UPDATE = 'INCIDENTS_PANE_UPDATE'
export const INCIDENTS_PANE_UPDATE_FLUSH = 'INCIDENTS_PANE_UPDATE_FLUSH'

export const INCIDENTS_FLUSH = 'INCIDENTS_FLUSH'
export const INCIDENTS_UPDATE = 'INCIDENTS_UPDATE'
export const INCIDENTS_UPDATE_STATE = 'INCIDENTS_UPDATE_STATE'
export const INCIDENTS_UPDATING = 'INCIDENTS_UPDATING'

export const INCIDENT_DETAILS_ADD = 'INCIDENT_DETAILS_ADD'
export const INCIDENT_DETAILS_REQUEST = 'INCIDENT_DETAILS_REQUEST'
export const INCIDENT_DETAILS_SET_ACTIVE_INTEGRATION =
  'INCIDENT_DETAILS_SET_ACTIVE_INTEGRATION'
export const SET_SERVICE_NOW_DETAILS = 'SET_SERVICE_NOW_DETAILS'
export const SET_SERVICE_NOW_DETAILS_ERROR = 'SET_SERVICE_NOW_DETAILS_ERROR'
export const FETCH_SERVICE_NOW_DETAILS = 'FETCH_SERVICE_NOW_DETAILS'
export const SET_CONFERENCE_BRIDGE_DETAILS = 'SET_CONFERENCE_BRIDGE_DETAILS'
export const SET_CONFERENCE_BRIDGE_DETAILS_ERROR =
  'SET_CONFERENCE_BRIDGE_DETAILS_ERROR'
export const SET_CONFERENCE_BRIDGE_SHOW_DETAILS_VIEW =
  'SET_CONFERENCE_BRIDGE_SHOW_DETAILS_VIEW'
export const FETCH_CONFERENCE_BRIDGE_DETAILS = 'FETCH_CONFERENCE_BRIDGE_DETAILS'
export const PUT_CONFERENCE_BRIDGE_DETAILS = 'PUT_CONFERENCE_BRIDGE_DETAILS'

export const INCIDENT_REROUTE_W_MODAL = 'INCIDENT_REROUTE_W_MODAL'
export const ADD_RESPONDERS_W_MODAL = 'ADD_RESPONDERS_W_MODAL'

export const INCIDENT_REROUTE = 'INCIDENT_REROUTE'
export const INCIDENT_REROUTING = 'INCIDENT_REROUTING'
export const INCIDENT_PUSH_TO_REROUTE = 'INCIDENT_PUSH_TO_REROUTE'
export const INCIDENT_TOGGLE = 'INCIDENT_TOGGLE'
export const INCIDENT_UPDATE = 'INCIDENT_UPDATE'
export const INCIDENT_UPDATING = 'INCIDENT_UPDATING'
export const INCIDENT_SNOOZE = 'INCIDENT_SNOOZE'

export const TEAM_UPDATE = 'TEAM_UPDATE'
export const TEAMS_UPDATE = 'TEAMS_UPDATE'

export const CONTACT_METHODS_CREATE = 'CONTACT_METHODS_CREATE'
export const CONTACT_METHODS_DELETE = 'CONTACT_METHODS_DELETE'
export const CONTACT_METHODS_RESET = 'CONTACT_METHODS_RESET'
export const CONTACT_METHODS_SET_VERIFIED = 'CONTACT_METHODS_SET_VERIFIED'
export const CONTACT_METHODS_TEST = 'CONTACT_METHODS_TEST'
export const CONTACT_METHODS_UPDATE = 'CONTACT_METHODS_UPDATE'
export const CONTACT_METHODS_VERIFY = 'CONTACT_METHODS_VERIFY'

export const USER_PROFILE_MAKE_ADMIN = 'USER_PROFILE_MAKE_ADMIN'
export const USER_PROFILE_REORDER_POLICIES = 'USER_PROFILE_REORDER_POLICIES'
export const USER_PROFILE_RESET = 'USER_PROFILE_RESET'
export const USER_PROFILE_SET_NAME = 'USER_PROFILE_SET_NAME'
export const USER_PROFILE_SET_PASSWORD = 'USER_PROFILE_SET_PASSWORD'
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE'

export const USER_UPDATE = 'USER_UPDATE'
export const USERS_UPDATE = 'USERS_UPDATE'

export const RECOVERY_NOTIFICATION_METHOD_RESET =
  'RECOVERY_NOTIFICATION_METHOD_RESET'
export const RECOVERY_NOTIFICATION_METHOD_SET =
  'RECOVERY_NOTIFICATION_METHOD_SET'
export const RECOVERY_NOTIFICATION_METHOD_UPDATE =
  'RECOVERY_NOTIFICATION_METHOD_UPDATE'

export const MANUAL_INCIDENT_CREATE = 'MANUAL_INCIDENT_CREATE'
export const MANUAL_INCIDENT_UPDATE = 'MANUAL_INCIDENT_UPDATE'
export const MANUAL_INCIDENT_CREATE_FAILED = 'MANUAL_INCIDENT_CREATE_FAILED'
export const MANUAL_INCIDENT_CLEAR_STATUS = 'MANUAL_INCIDENT_CLEAR_STATUS'
export const MANUAL_INCIDENT_RECIPIENTS = 'MANUAL_INCIDENT_RECIPIENTS'
export const MANUAL_INCIDENT_RESET = 'MANUAL_INCIDENT_RESET'
export const MANUAL_INCIDENT_RECIPIENTS_RESET =
  'MANUAL_INCIDENT_RECIPIENTS_RESET'

export const MODAL_SHOW = 'MODAL_SHOW'
export const MODAL_HIDE = 'MODAL_HIDE'

export const NEXT_ON_CALL_UPDATE = 'NEXT_ON_CALL_UPDATE'
export const NEXT_ON_CALL_FETCH = 'NEXT_ON_CALL_FETCH'

export const ON_CALL_UPDATE = 'ON_CALL_UPDATE'
export const ON_CALL_LIST_UPDATE = 'ON_CALL_LIST_UPDATE'
export const ON_CALL_TAKE = 'ON_CALL_TAKE'

export const ORG_META_FETCH = 'ORG_META_FETCH'
export const ORG_META_UPDATE = 'ORG_META_UPDATE'
export const ORG_STATE = 'ORG_STATE'

export const HELP_CONTENT_FETCH = 'HELP_CONTENT_FETCH'
export const HELP_CONTENT_UPDATE = 'HELP_CONTENT_UPDATE'

export const USER_STATUS = 'USER_STATUS'

// Routes
export const ROUTE_ADD = 'ROUTE_ADD'
export const ROUTE_REMOVE = 'ROUTE_REMOVE'
export const ROUTES_UPDATE = 'ROUTES_UPDATE'
export const ROUTES_SET = 'ROUTES_SET'
export const POST_ROUTE = 'POST_ROUTE'
export const PUT_ROUTE = 'PUT_ROUTE'
export const UPDATE_ROUTES = 'UPDATE_ROUTES'
export const DELETE_ROUTE = 'DELETE_ROUTE'

// Integrations
export const INTEGRATIONS_UPDATE = 'INTEGRATIONS_UPDATE'
export const INTEGRATION_DISABLE = 'INTEGRATION_DISABLE'
export const INTEGRATION_ENABLE = 'INTEGRATION_ENABLE'
export const INTEGRATION_OPTS_PUT = 'INTEGRATION_OPTS_PUT'
export const INTEGRATION_OPTS_UPDATE = 'INTEGRATION_OPTS_UPDATE'
export const INTEGRATION_REMOVE = 'INTEGRATION_REMOVE'
export const INTEGRATION_SETTINGS_PUT = 'INTEGRATION_SETTINGS_PUT'
export const INTEGRATION_SETTINGS_UPDATE = 'INTEGRATION_SETTINGS_UPDATE'
export const INTEGRATIONS_GRID_UPDATE = 'INTEGRATIONS_GRID_UPDATE'
export const INTEGRATION_ICON_FETCH = 'INTEGRATION_ICON_FETCH'
export const INTEGRATION_ICON_UPDATE = 'INTEGRATION_ICON_UPDATE'

// Picklist
export const PICKLIST_UPDATE_ACTIVE_INDEX = 'PICKLIST_UPDATE_ACTIVE_INDEX'
export const PICKLIST_UPDATE_FILTER = 'PICKLIST_UPDATE_FILTER'

// PolicyMeta
export const USERS_UPDATE_SEARCH_TERM = 'USERS_UPDATE_SEARCH_TERM' // TODO: Kill this whole block and reducer with fire and restructure the data in the users reducer.

// Statuspage.io
export const STATUS_PAGE_GET_PAGES = 'STATUS_PAGE_GET_PAGES'
export const STATUS_PAGE_OPTS_PUT = 'STATUS_PAGE_OPTS_PUT'
export const STATUS_PAGE_REMOVE_PAGES = 'STATUS_PAGE_REMOVE_PAGES'
export const STATUS_PAGE_UPDATE_PAGES = 'STATUS_PAGE_UPDATE_PAGES'

// Teams
export const TEAM_ADD = 'TEAM_ADD'
export const TEAM_ADD_USER = 'TEAM_ADD_USER'
export const TEAM_CREATE = 'TEAM_CREATE'
export const TEAM_CREATE_ADMIN = 'TEAM_CREATE_ADMIN'
export const TEAM_CREATE_USER = 'TEAM_CREATE_USER'
export const TEAM_CREATE_UNINVITED_USER = 'TEAM_CREATE_UNINVITED_USER'
export const TEAM_DELETE = 'TEAM_DELETE'
export const TEAM_DELETE_ADMIN = 'TEAM_DELETE_ADMIN'
export const TEAM_DELETE_USER = 'TEAM_DELETE_USER'
export const TEAM_PATCH = 'TEAM_PATCH'
export const TEAM_REMOVE = 'TEAM_REMOVE'
export const TEAM_REMOVE_USER = 'TEAM_REMOVE_USER'
export const TEAM_UPDATE_USERS = 'TEAM_UPDATE_USERS'

// Users
export const USER_CREATE = 'USER_CREATE'
export const USER_DELETE = 'USER_DELETE'
export const USER_REMOVE = 'USER_REMOVE'

// Features
export const FEATURE_UPDATE = 'FEATURE_UPDATE'

// Policies
export const REMOVE_POLICY = 'REMOVE_POLICY'
export const REMOVE_POLICY_ACTION = 'REMOVE_POLICY_ACTION'
export const REMOVE_POLICY_STEP = 'REMOVE_POLICY_STEP'
export const UPDATE_POLICIES = 'UPDATE_POLICIES'
export const UPDATE_POLICY_ACTIONS = 'UPDATE_POLICY_ACTIONS'
export const UPDATE_POLICY_STEPS = 'UPDATE_POLICY_STEPS'

export const POLICIES_GET = 'POLICIES_GET'

export const DELETE_POLICY = 'DELETE_POLICY'
export const POST_POLICY = 'POST_POLICY'
export const PUT_POLICY = 'PUT_POLICY'

// Rotations
export const DELETE_ROTATION = 'DELETE_ROTATION'
export const REMOVE_ROTATION = 'REMOVE_ROTATION'
export const ROTATION_PATCH = 'ROTATION_PATCH'
export const REMOVE_ROTATION_MEMBER = 'REMOVE_ROTATION_MEMBER'
export const REMOVE_ROTATION_PERIOD = 'REMOVE_ROTATION_PERIOS'
export const REMOVE_ROTATION_SHIFT = 'REMOVE_ROTATION_SHIFT'
export const ADD_ROTATION_BACKBONE_OPTIONS = 'ADD_ROTATION_BACKBONE_OPTIONS'
export const UPDATE_ROTATION_BACKBONE_OPTIONS =
  'UPDATE_ROTATION_BACKBONE_OPTIONS'
export const UPDATE_ROTATIONS = 'UPDATE_ROTATIONS'
export const UPDATE_ROTATION = 'UPDATE_ROTATION'
export const UPDATE_ROTATION_MEMBERS = 'UPDATE_ROTATION_MEMBERS'
export const UPDATE_ROTATION_PERIODS = 'UPDATE_ROTATION_PERIODS'
export const UPDATE_ROTATION_SHIFTS = 'UPDATE_ROTATION_SHIFTS'

// Expanding State
export const APP_UPDATE_EXPANDING_CARD = 'APP_UPDATE_EXPANDING_CARD'

// Calendar Links
export const CALENDAR_UPDATE_LINK = 'CALENDAR_UPDATE_LINK'
export const CALENDAR_REQUEST_LINK = 'CALENDAR_REQUEST_LINK'

// Teams with policies
export const TEAMS_WITH_POLICIES_GET = 'TEAMS_WITH_POLICIES_GET'
export const TEAMS_WITH_POLICIES_UPDATE = 'TEAMS_WITH_POLICIES_UPDATE'
// TODO: This should replace the above actions
export const TEAM_POLICIES_UPDATE = 'TEAM_POLICIES_UPDATE'

// Overrides
export const OVERRIDES_UPDATE = 'OVERRIDES_UPDATE'
export const OVERRIDE_ASSIGNMENT_UPDATE = 'OVERRIDE_ASSIGNMENT_UPDATE'
export const OVERRIDE_DELETE = 'OVERRIDE_DELETE'
export const OVERRIDE_POST = 'OVERRIDE_POST'
export const OVERRIDE_PUT = 'OVERRIDE_PUT'
export const OVERRIDE_REMOVE = 'OVERRIDE_REMOVE'
export const OVERRIDE_UPDATE = 'OVERRIDE_UPDATE'

// Onboarding
export const ONBOARDING_PROFILE_UPDATE = 'ONBOARDING_PROFILE_UPDATE'

// Post Mortem Reports
export const POST_MORTEM_ACTION_ITEMS_GET = 'POST_MORTEM_ACTION_ITEMS_GET'
export const POST_MORTEM_ACTION_ITEMS_CREATE = 'POST_MORTEM_ACTION_ITEMS_CREATE'
export const POST_MORTEM_ACTION_ITEMS_REMOVE = 'POST_MORTEM_ACTION_ITEMS_REMOVE'
export const POST_MORTEM_TIMELINE_NOTES_GET = 'POST_MORTEM_TIMELINE_NOTES_GET'
export const POST_MORTEM_TIMELINE_NOTES_UPDATE =
  'POST_MORTEM_TIMELINE_NOTES_UPDATE'
export const POST_MORTEM_REPORT_UPDATE = 'POST_MORTEM_REPORT_UPDATE'
export const POST_MORTEM_ACTION_ITEMS_UPDATE = 'POST_MORTEM_ACTION_ITEMS_UPDATE'

export const TIMELINE_FILTERS_UPDATE = 'TIMELINE_FILTERS_UPDATE'
export const BACKBONE_CONTAINER_RENDERED = 'BACKBONE_CONTAINER_RENDERED'
export const BACKBONE_TIMELINE_CONTAINER_ACTIVE =
  'BACKBONE_TIMELINE_CONTAINER_ACTIVE'

export const TIMELINE_MESSAGES_ADD = 'TIMELINE_MESSAGES_ADD'
export const TIMELINE_MESSAGES_SET_END_OF_HISTORY =
  'TIMELINE_MESSAGES_SET_END_OF_HISTORY'
export const TIMELINE_MESSAGES_ADD_SEQUENCE = 'TIMELINE_MESSAGES_ADD_SEQUENCE'
export const TIMELINE_MESSAGES_BLANK_SLATE = 'TIMELINE_MESSAGES_BLANK_SLATE'
export const TIMELINE_MESSAGES_REQUEST = 'TIMELINE_MESSAGES_REQUEST'
export const TIMELINE_MESSAGES_CLEAR = 'TIMELINE_MESSAGES_CLEAR'
export const TIMELINE_MESSAGES_FLUSH = 'TIMELINE_MESSAGES_FLUSH'
export const TIMELINE_MESSAGES_SHOW = 'TIMELINE_MESSAGES_SHOW'
export const TIMELINE_SET_PENDING_TRANSACTION =
  'TIMELINE_SET_PENDING_TRANSACTION'
export const TIMELINE_TOGGLE_MESSAGE_EXPAND = 'TIMELINE_TOGGLE_MESSAGE_EXPAND'

export const MAINTENANCE_START = 'MAINTENANCE_START'
export const MAINTENANCE_UPDATE = 'MAINTENANCE_UPDATE'

export const TOGGLE_OFFLINE_MODE = 'TOGGLE_OFFLINE_MODE'

// Incidents Pane
//
// TODO: A brief description of what effect each of these have on the system
//       if possible.
// ---------------------------------------------------------------------------

export function updateOverride(payload) {
  return {
    type: OVERRIDE_UPDATE,
    payload,
  }
}

export function putOverride(payload) {
  return {
    type: OVERRIDE_PUT,
    payload,
  }
}

export function postOverride(payload) {
  return {
    type: OVERRIDE_POST,
    payload,
  }
}

export function updateOverrideAssignment(payload) {
  return {
    type: OVERRIDE_ASSIGNMENT_UPDATE,
    payload,
  }
}

export function updateOverrides(payload) {
  return {
    type: OVERRIDES_UPDATE,
    payload,
  }
}

export function removeOverride(payload) {
  return {
    type: OVERRIDE_REMOVE,
    payload,
  }
}

export function deleteOverride(payload) {
  return {
    type: OVERRIDE_DELETE,
    payload,
  }
}

export function requestAlertDetails(payload) {
  return {
    type: ALERT_DETAILS,
    payload,
  }
}

export function addAlertDetails(payload) {
  return {
    type: ALERT_DETAILS_ADD,
    payload,
  }
}

export function sortIncidents(payload) {
  return {
    type: INCIDENTS_PANE_SORT,
    payload,
  }
}

export function updateAndFlushIncidentsPane(payload) {
  return {
    type: INCIDENTS_PANE_UPDATE_FLUSH,
    payload,
  }
}

export function updateIncidentsPane(payload) {
  return {
    type: INCIDENTS_PANE_UPDATE,
    payload,
  }
}

// Incidents
// ---------------------------------------------------------------------------

export function incidentDetailsAdd(payload) {
  return {
    type: INCIDENT_DETAILS_ADD,
    payload,
  }
}

export function incidentDetailsRequest(payload) {
  return {
    type: INCIDENT_DETAILS_REQUEST,
    payload,
  }
}

export const incidentDetailsSetActiveIntegration = payload => ({
  payload,
  type: INCIDENT_DETAILS_SET_ACTIVE_INTEGRATION,
})

export const setServiceNowDetails = payload => ({
  payload,
  type: SET_SERVICE_NOW_DETAILS,
})

export const fetchServiceNowDetails = payload => ({
  payload,
  type: FETCH_SERVICE_NOW_DETAILS,
})

export const setServiceNowDetailsError = payload => ({
  payload,
  type: SET_SERVICE_NOW_DETAILS_ERROR,
})

export const setConferenceBridgeDetails = payload => ({
  payload,
  type: SET_CONFERENCE_BRIDGE_DETAILS,
})

export const setConferenceBridgeDetailsError = payload => ({
  payload,
  type: SET_CONFERENCE_BRIDGE_DETAILS_ERROR,
})

export const fetchConferenceBridgeDetails = payload => ({
  payload,
  type: FETCH_CONFERENCE_BRIDGE_DETAILS,
})

export const putConferenceBridgeDetails = payload => ({
  payload,
  type: PUT_CONFERENCE_BRIDGE_DETAILS,
})

export const setConferenceBridgeShowDetailsView = payload => ({
  payload,
  type: SET_CONFERENCE_BRIDGE_SHOW_DETAILS_VIEW,
})

export function pushToReroute(payload) {
  return {
    type: INCIDENT_PUSH_TO_REROUTE,
    payload,
  }
}

export function updateIncidentsState(payload) {
  return {
    type: INCIDENTS_UPDATE_STATE,
    payload,
  }
}

export function flushIncidents() {
  return {
    type: INCIDENTS_FLUSH,
  }
}

export function rerouteIncident(payload) {
  // these should be reversed, tbh
  return {
    type: INCIDENT_REROUTE,
    payload,
  }
}

export function rerouteIncidentWithModal(payload) {
  return {
    type: INCIDENT_REROUTE_W_MODAL,
    payload,
  }
}

export function addRespondersWithModal(payload) {
  return {
    type: ADD_RESPONDERS_W_MODAL,
    payload,
  }
}

export function incidentRerouting(payload) {
  return {
    type: INCIDENT_REROUTING,
    payload,
  }
}

export function toggleIncident(payload) {
  return {
    type: INCIDENT_TOGGLE,
    payload,
  }
}

export function updateIncident(payload) {
  return {
    type: INCIDENT_UPDATE,
    payload,
  }
}

export function updatingIncident(payload) {
  return {
    type: INCIDENT_UPDATING,
    payload,
  }
}

export function updateIncidents(payload) {
  return {
    type: INCIDENTS_UPDATE,
    payload,
  }
}

export function updatingIncidents(payload) {
  return {
    type: INCIDENTS_UPDATING,
    payload,
  }
}

export function createIncident(payload) {
  return {
    type: MANUAL_INCIDENT_CREATE,
    payload,
  }
}

export function snoozeIncident(payload) {
  return {
    type: INCIDENT_SNOOZE,
    payload,
  }
}

export function updateCreateIncidentForm(payload) {
  return {
    type: MANUAL_INCIDENT_UPDATE,
    payload,
  }
}

export function updateCreateIncidentStatus(payload) {
  return {
    type: MANUAL_INCIDENT_CREATE_FAILED,
    payload,
  }
}

export function updateManualIncidentRecipients(payload) {
  return {
    type: MANUAL_INCIDENT_RECIPIENTS,
    payload,
  }
}

export function clearCreateIncidentStatus(payload) {
  return {
    type: MANUAL_INCIDENT_CLEAR_STATUS,
    payload,
  }
}

export function resetManualIncident(payload) {
  return {
    type: MANUAL_INCIDENT_RESET,
    payload,
  }
}

export function resetRecipients(payload) {
  return {
    type: MANUAL_INCIDENT_RECIPIENTS_RESET,
    payload,
  }
}

// Teams
// ---------------------------------------------------------------------------

export function updateTeam(payload) {
  return {
    type: TEAM_UPDATE,
    payload,
  }
}

export function updateTeams(payload) {
  return {
    type: TEAMS_UPDATE,
    payload,
  }
}

// Users
// ---------------------------------------------------------------------------

export function updateUser(payload) {
  return {
    type: USER_UPDATE,
    payload,
  }
}

export function updateUsers(payload) {
  return {
    type: USERS_UPDATE,
    payload,
  }
}

export function updateOnCallList(payload) {
  return {
    type: ON_CALL_LIST_UPDATE,
    payload,
  }
}

export function updateOnCall(payload) {
  return {
    type: ON_CALL_UPDATE,
    payload,
  }
}

export function updateUserStatus(payload) {
  return {
    type: USER_STATUS,
    payload,
  }
}

// Contact methods
// ---------------------------------------------------------------------------

export const contactMethodsCreate = payload => ({
  type: CONTACT_METHODS_CREATE,
  payload,
})

export const contactMethodsDelete = payload => ({
  type: CONTACT_METHODS_DELETE,
  payload,
})

export const contactMethodsReset = payload => ({
  type: CONTACT_METHODS_RESET,
  payload,
})

export const contactMethodsSetVerified = payload => ({
  type: CONTACT_METHODS_SET_VERIFIED,
  payload,
})

export const contactMethodsTest = payload => ({
  type: CONTACT_METHODS_TEST,
  payload,
})

export const contactMethodsUpdate = payload => ({
  type: CONTACT_METHODS_UPDATE,
  payload,
})

export const contactMethodsVerify = payload => ({
  type: CONTACT_METHODS_VERIFY,
  payload,
})

// Recovery notifications
// ---------------------------------------------------------------------------

export const recoveryNotificationMethodReset = payload => ({
  type: RECOVERY_NOTIFICATION_METHOD_RESET,
  payload,
})

export const recoveryNotificationMethodSet = payload => ({
  type: RECOVERY_NOTIFICATION_METHOD_SET,
  payload,
})

export const recoveryNotificationMethodUpdate = payload => ({
  type: RECOVERY_NOTIFICATION_METHOD_UPDATE,
  payload,
})

// User profile
// ---------------------------------------------------------------------------

export const userProfileMakeAdmin = payload => ({
  type: USER_PROFILE_MAKE_ADMIN,
  payload,
})

export const userProfileReset = payload => ({
  type: USER_PROFILE_RESET,
  payload,
})

export const userProfileSetName = payload => ({
  type: USER_PROFILE_SET_NAME,
  payload,
})

export const userProfileSetPassword = payload => ({
  type: USER_PROFILE_SET_PASSWORD,
  payload,
})

export const userProfileUpdate = payload => ({
  type: USER_PROFILE_UPDATE,
  payload,
})

export const userProfileReorderPolicies = payload => ({
  type: USER_PROFILE_REORDER_POLICIES,
  payload,
})
// Modal
// ---------------------------------------------------------------------------

export function showModal(payload) {
  return {
    type: MODAL_SHOW,
    payload,
  }
}

export function hideModal(payload) {
  return {
    type: MODAL_HIDE,
    payload,
  }
}

// next-on-call
// ---------------------------------------------------------------------------

export function getNextOnCall(payload = []) {
  return {
    type: NEXT_ON_CALL_FETCH,
    payload,
  }
}

export function updateNextOnCall(payload = {}) {
  return {
    type: NEXT_ON_CALL_UPDATE,
    payload,
  }
}

// on-call TAKE
// ---------------------------------------------------------------------------

export function takeOnCallById(payload = {}) {
  return {
    type: ON_CALL_TAKE,
    payload,
  }
}

// OrgMeta
// ---------------------------------------------------------------------------

export function getOrgMeta(payload) {
  return {
    type: ORG_META_FETCH,
    payload,
  }
}

export function updateOrgMeta(payload) {
  return {
    type: ORG_META_UPDATE,
    payload,
  }
}

// OrgState
// ---------------------------------------------------------------------------

export function updateOrgState(payload) {
  return {
    type: ORG_STATE,
    payload,
  }
}

export function getHelpContent(payload) {
  return {
    type: HELP_CONTENT_FETCH,
    payload,
  }
}

export function updateHelpContent(payload) {
  return {
    type: HELP_CONTENT_UPDATE,
    payload,
  }
}

export function addRoute(payload) {
  return {
    type: ROUTE_ADD,
    payload,
  }
}

export function removeRoute(payload) {
  return {
    type: ROUTE_REMOVE,
    payload,
  }
}

export function updateRoutes(payload) {
  return {
    type: ROUTES_UPDATE,
    payload,
  }
}
export function setRoutes(payload) {
  return {
    type: ROUTES_SET,
    payload,
  }
}

export function updateFeature(payload) {
  return {
    type: FEATURE_UPDATE,
    payload,
  }
}

export function disableIntegration(payload) {
  return {
    type: INTEGRATION_DISABLE,
    payload,
  }
}

export function enableIntegration(payload) {
  return {
    type: INTEGRATION_ENABLE,
    payload,
  }
}

export function updateIntegrations(payload) {
  return {
    type: INTEGRATIONS_UPDATE,
    payload,
  }
}

export function updateIntegrationSettings(payload) {
  return {
    type: INTEGRATION_SETTINGS_UPDATE,
    payload,
  }
}

export function putIntegrationSettings(payload) {
  return {
    type: INTEGRATION_SETTINGS_PUT,
    payload,
  }
}

export function putIntegrationOptions(payload) {
  return {
    type: INTEGRATION_OPTS_PUT,
    payload,
  }
}

export function putStatusPageOptions(payload) {
  return {
    type: STATUS_PAGE_OPTS_PUT,
    payload,
  }
}

export function updateIntegrationOptions(payload) {
  return {
    type: INTEGRATION_OPTS_UPDATE,
    payload,
  }
}

export function removeIntegration(payload) {
  return {
    type: INTEGRATION_REMOVE,
    payload,
  }
}

export function updateIntegrationsGrid(payload) {
  return {
    type: INTEGRATIONS_GRID_UPDATE,
    payload,
  }
}

export function getIntegrationIcons(payload) {
  return {
    type: INTEGRATION_ICON_FETCH,
    payload,
  }
}

export function updateIntegrationIcons(payload) {
  return {
    type: INTEGRATION_ICON_UPDATE,
    payload,
  }
}

export function updatePicklistActiveIndex(payload) {
  return {
    type: PICKLIST_UPDATE_ACTIVE_INDEX,
    payload,
  }
}

export function updatePicklistFilter(payload) {
  return {
    type: PICKLIST_UPDATE_FILTER,
    payload,
  }
}

export function updateSearchTerm(payload) {
  return {
    type: USERS_UPDATE_SEARCH_TERM,
    payload,
  }
}

export function getSPPages(payload) {
  return {
    type: STATUS_PAGE_GET_PAGES,
    payload,
  }
}

export function updateSPPages(payload) {
  return {
    type: STATUS_PAGE_UPDATE_PAGES,
    payload,
  }
}

export function removeSPPages() {
  return {
    type: STATUS_PAGE_REMOVE_PAGES,
  }
}

export function addTeam(payload) {
  return {
    type: TEAM_ADD,
    payload,
  }
}

export function addUserToTeam(payload) {
  return {
    type: TEAM_ADD_USER,
    payload,
  }
}

export function createTeam(payload) {
  return {
    type: TEAM_CREATE,
    payload,
  }
}

export function createTeamAdmin(payload) {
  return {
    type: TEAM_CREATE_ADMIN,
    payload,
  }
}

export function deleteTeamAdmin(payload) {
  return {
    type: TEAM_DELETE_ADMIN,
    payload,
  }
}

export function createUninvitedUserInTeam(payload) {
  return {
    type: TEAM_CREATE_UNINVITED_USER,
    payload,
  }
}

export function createUserInTeam(payload) {
  return {
    type: TEAM_CREATE_USER,
    payload,
  }
}

export function deleteUserInTeam(payload) {
  return {
    type: TEAM_DELETE_USER,
    payload,
  }
}

export function updateUsersInTeam(payload) {
  return {
    type: TEAM_UPDATE_USERS,
    payload,
  }
}

export function deleteTeam(payload) {
  return {
    type: TEAM_DELETE,
    payload,
  }
}

export function removeTeam(payload) {
  return {
    type: TEAM_REMOVE,
    payload,
  }
}

export function patchTeam(payload) {
  return {
    type: TEAM_PATCH,
    payload,
  }
}

export function removeUserFromTeam(payload) {
  return {
    type: TEAM_REMOVE_USER,
    payload,
  }
}

export function createUser(payload) {
  return {
    type: USER_CREATE,
    payload,
  }
}

export function deleteUser(payload) {
  return {
    type: USER_DELETE,
    payload,
  }
}

export function removeUser(payload) {
  return {
    type: USER_REMOVE,
    payload,
  }
}
// Escalation policies
// ---------------------------------------------------------------------------

export function updatePolicies(slug, data) {
  return {
    type: UPDATE_POLICIES,
    payload: {
      slug,
      data,
    },
  }
}

export function removePolicy(payload) {
  return {
    type: REMOVE_POLICY,
    payload,
  }
}

export function updatePolicySteps(payload) {
  return {
    type: UPDATE_POLICY_STEPS,
    payload,
  }
}

export function removePolicyStep(payload) {
  return {
    type: REMOVE_POLICY_STEP,
    payload,
  }
}

export function updatePolicyActions(payload) {
  return {
    type: UPDATE_POLICY_ACTIONS,
    payload,
  }
}

export function removePolicyAction(payload) {
  return {
    type: REMOVE_POLICY_ACTION,
    payload,
  }
}

export function postPolicy(payload) {
  return {
    meta: { verb: 'POST' },
    payload,
    type: POST_POLICY,
  }
}

export function putPolicy(payload) {
  return {
    meta: { verb: 'PUT' },
    payload,
    type: PUT_POLICY,
  }
}

export function deletePolicy(payload) {
  return {
    type: DELETE_POLICY,
    payload,
  }
}

export function addRotationBackboneOptions(payload) {
  return {
    type: ADD_ROTATION_BACKBONE_OPTIONS,
    payload,
  }
}
export function updateRotationBackboneOptions(payload) {
  return {
    type: UPDATE_ROTATION_BACKBONE_OPTIONS,
    payload,
  }
}

export function updateRotations(payload) {
  return {
    type: UPDATE_ROTATIONS,
    payload,
  }
}

export function updateRotation(payload) {
  return {
    type: UPDATE_ROTATION,
    payload,
  }
}

export function removeRotation(payload) {
  return {
    type: REMOVE_ROTATION,
    payload,
  }
}

export function deleteRotation(payload) {
  return {
    type: DELETE_ROTATION,
    payload,
  }
}

export function patchRotation(payload) {
  return {
    type: ROTATION_PATCH,
    payload,
  }
}

export function removeRotationPeriod(payload) {
  return {
    type: REMOVE_ROTATION_PERIOD,
    payload,
  }
}

export function removeRotationShift(payload) {
  return {
    type: REMOVE_ROTATION_SHIFT,
    payload,
  }
}

export function removeRotationMember(payload) {
  return {
    type: REMOVE_ROTATION_MEMBER,
    payload,
  }
}

export function updateRotationMembers(payload) {
  return {
    type: UPDATE_ROTATION_MEMBERS,
    payload,
  }
}

export function updateRotationShifts(payload) {
  return {
    type: UPDATE_ROTATION_SHIFTS,
    payload,
  }
}

export function updateRotationPeriods(payload) {
  return {
    type: UPDATE_ROTATION_PERIODS,
    payload,
  }
}

export function uiUpdateExpandingCard(payload) {
  return {
    type: APP_UPDATE_EXPANDING_CARD,
    payload,
  }
}

export function putRoute(payload) {
  return {
    type: PUT_ROUTE,
    payload,
  }
}

export function postRoute(payload) {
  return {
    type: POST_ROUTE,
    payload,
  }
}

export function deleteRoute(payload) {
  return {
    type: DELETE_ROUTE,
    payload,
  }
}

export function getPolicies(payload) {
  return {
    type: POLICIES_GET,
    payload,
  }
}

export function getTeamsWithPolicies(payload) {
  return {
    type: TEAMS_WITH_POLICIES_GET,
    payload,
  }
}

export function updateTeamsWithPolicies(payload) {
  return {
    type: TEAMS_WITH_POLICIES_UPDATE,
    payload,
  }
}

export function updateTeamPolicies(payload) {
  return {
    type: TEAM_POLICIES_UPDATE,
    payload,
  }
}

export function updateOnboardingProfile(payload) {
  return {
    type: ONBOARDING_PROFILE_UPDATE,
    payload,
  }
}

// Post Mortems
// ---------------------------------------------------------------------------
export function updatePostMortemReport(payload) {
  return {
    type: POST_MORTEM_REPORT_UPDATE,
    payload,
  }
}

export function getPostMortemActionItems(payload) {
  return {
    type: POST_MORTEM_ACTION_ITEMS_GET,
    payload,
  }
}

export function createPostMortemActionItem(payload) {
  return {
    type: POST_MORTEM_ACTION_ITEMS_CREATE,
    payload,
  }
}

export function updatePostMortemActionItems(payload) {
  return {
    type: POST_MORTEM_ACTION_ITEMS_UPDATE,
    payload,
  }
}

export function removePostMortemActionItem(payload) {
  return {
    type: POST_MORTEM_ACTION_ITEMS_REMOVE,
    payload,
  }
}

export function getPostMortemTimelineNotes(payload) {
  return {
    type: POST_MORTEM_TIMELINE_NOTES_GET,
    payload,
  }
}

export function updatePostMortemTimelineNotes(payload = {}) {
  return {
    type: POST_MORTEM_TIMELINE_NOTES_UPDATE,
    payload,
  }
}

export function updateTimelineFilters(payload) {
  return {
    type: TIMELINE_FILTERS_UPDATE,
    payload,
  }
}

export function updateBackboneContainerRendered(payload) {
  return {
    type: BACKBONE_CONTAINER_RENDERED,
    payload,
  }
}

export function updateBackboneTimelineContainerActive(payload) {
  return {
    type: BACKBONE_TIMELINE_CONTAINER_ACTIVE,
    payload,
  }
}

export function addTimelineMessage(payload) {
  return {
    type: TIMELINE_MESSAGES_ADD,
    payload,
  }
}

export function addTimelineMessageSequence(payload) {
  return {
    type: TIMELINE_MESSAGES_ADD_SEQUENCE,
    payload,
  }
}

export function setTimelineEndOfHistory(payload) {
  return {
    type: TIMELINE_MESSAGES_SET_END_OF_HISTORY,
    payload,
  }
}

export function requestTimelineMessages(payload) {
  return {
    type: TIMELINE_MESSAGES_REQUEST,
    payload,
  }
}

export function clearTimelineMessages(payload) {
  return {
    type: TIMELINE_MESSAGES_CLEAR,
    payload,
  }
}

export function flushTimelineMessages(payload) {
  return {
    type: TIMELINE_MESSAGES_FLUSH,
    payload,
  }
}

export function showNewTimelineMessages(payload) {
  return {
    type: TIMELINE_MESSAGES_SHOW,
    payload,
  }
}

export function setTimelineHasPendingTransaction(payload) {
  return {
    type: TIMELINE_SET_PENDING_TRANSACTION,
    payload,
  }
}

export function toggleTimelineMessageExpand(payload) {
  return {
    type: TIMELINE_TOGGLE_MESSAGE_EXPAND,
    payload,
  }
}

export function updateCalendarLink(payload) {
  return {
    type: CALENDAR_UPDATE_LINK,
    payload,
  }
}

export function requestCalendarLink(payload) {
  return {
    type: CALENDAR_REQUEST_LINK,
    payload,
  }
}

// Maintenance Mode
// -----------------------------------------------------------------
export function maintenanceStart(payload) {
  return {
    type: MAINTENANCE_START,
    payload,
  }
}

export function maintenanceUpdate(payload) {
  return {
    type: MAINTENANCE_UPDATE,
    payload,
  }
}

export function toggleOfflineMode(payload) {
  return {
    type: TOGGLE_OFFLINE_MODE,
    payload,
  }
}
