import _ from 'lib/underscore'
import server from '../../server'
import filter from '../filter'
import MQueue from '../../../lib/mqueue'

import Incidents from '../collections//incidents'

// file: incidents.js
// singleton collection for incidents (independant of the timeline)

var handleIncident = function(data) {
  if (data.incidentName === '') {
    return // bail
  }

  incidents.set(data, { add: true, remove: false, merge: true })
  incidents.trigger('incidents:batchadd')
}

var fromDigest = function(phase) {
  return function(digest) {
    return {
      isDigest: true,
      INCIDENT_NAME: digest.NAME,
      INCIDENT_TIMESTAMP: digest.TS,
      ENTITY_ID: digest.ENTITY_ID,
      TEAMS_PAGED: digest.TEAMS_PAGED,
      USERS_PAGED: digest.USERS_PAGED,
      CURRENT_ALERT_PHASE: phase,
      LAST_UUID: digest.LAST_UUID,
    }
  }
}

var incidents = new Incidents()
var mqueue = new MQueue(handleIncident)
var initLoad = true // if this is true, this is the start-of-day

incidents.triggered = filter(
  incidents,
  function(incident) {
    return incident.get('CURRENT_ALERT_PHASE') === 'UNACKED'
  },
  { key: 'triggered' }
)

incidents.acknowledged = filter(
  incidents,
  function(incident) {
    return incident.get('CURRENT_ALERT_PHASE') === 'ACKED'
  },
  { key: 'ackd' }
)

incidents.resolved = filter(
  incidents,
  function(incident) {
    return incident.get('CURRENT_ALERT_PHASE') === 'RESOLVED'
  },
  { key: 'resolved' }
)

// when we first come up, we get a SYSTEM_INCIDENT_STATE message that gives us digests for
// all the incidents in the system, and details for a smaller set. We use this to only lazily
// request details for incidents we can actually render.
server.on('state:incidents', function(data) {
  var digests = []

  // run through the digests first
  digests = digests.concat(
    _.map(data.TRIGGERED_INCIDENT_NAMES, fromDigest('UNACKED'))
  )
  digests = digests.concat(
    _.map(data.ACKNOWLEDGED_INCIDENT_NAMES, fromDigest('ACKED'))
  )
  digests = digests.concat(
    _.map(data.RESOLVED_INCIDENT_NAMES, fromDigest('RESOLVED'))
  )

  // this fires events which cause placeholder rendering
  if (initLoad) {
    incidents.reset(digests)
    initLoad = false
  } else {
    var tmpCol = incidents.clone()
    _.each(digests, function(dmodel) {
      var existingIncident = tmpCol.findWhere({
        INCIDENT_NAME: dmodel.INCIDENT_NAME,
      })
      if (!existingIncident) {
        // if we don't have it, need to set it in the collection
        tmpCol.add(dmodel)
      } else if (
        existingIncident.get('CURRENT_ALERT_PHASE') !==
        dmodel.CURRENT_ALERT_PHASE
      ) {
        // if we already have it, but the status has changed, replace what we have and update the details
        tmpCol.get(dmodel.INCIDENT_NAME).set(dmodel)
      }
      // if we already have it, and the current state hasn't changed, ignore the digest
    })

    // if we have some that are not in the digests load, throw them out
    var digestIds = _.map(digests, 'INCIDENT_NAME')
    var incidentIds = incidents.pluck('INCIDENT_NAME')
    var staleIncidentIds = _.difference(incidentIds, digestIds)
    _.each(staleIncidentIds, function(id) {
      tmpCol.remove(tmpCol.findWhere({ INCIDENT_NAME: id }))
    })
    incidents.reset(tmpCol.models)
  }

  // update with data after the browser's had a chance to render the placeholders
  // server doesn't send resolves, we get them through the 'normal' update process
  mqueue.processAll(data.TRIGGERED_INCIDENTS)
  mqueue.processAll(data.ACKNOWLEDGED_INCIDENTS)
})

// everytime there's a new incident, or one is updated, we get this message.
// If IS_UPDATE = false, then this is a resync message, and we should reset
// our incidents collection with the new incidents.
server.on('notify:incidents', function(data) {
  if (data.IS_UPDATE) {
    mqueue.processAll(data.SYSTEM_ALERT_STATE_LIST)
  } else {
    incidents.reset(data.SYSTEM_ALERT_STATE_LIST)
  }
})

export default incidents
