// vendor
import $ from 'jquery'
import _ from 'lodash'
import Backbone from 'backbone'
import 'jquery.cookie'

// lib
import Incident from '../models/incident'
import server from '../../server'
import users from '../managers/users'

var config = window.VO_CONFIG

// file: incidents.js
// incidents collection (independant of timeline)

export default Backbone.Collection.extend({
  model: Incident,

  initialize: function(models, options) {
    options = options || {}
    var self = this

    this.comparator = function(model) {
      return -model.get('INCIDENT_TIMESTAMP')
    }
    this.filterator = this.filterAll
    this.key = options.key

    this.filterAll.key = 'all'
    this.filterAll.split = false

    this.filterMine.key = 'mine'
    this.filterMine.split = true

    this.filterTeams.key = 'teams'
    this.filterTeams.split = true

    this.filterAckedMe.key = 'ackme'
    this.filterAckedMe.split = true

    this.currentPos = 0

    self.username = config.auth.user.username.toLowerCase()
    self.userDeferred = false

    // waiting for user and his groups to load
    self.userDeferred = users.getDeferred(self.username)
    $.when(self.userDeferred).then(function() {
      self.trigger('reset', self)
    })
  },

  cookieKey: function() {
    return this.key + '_sorting'
  },

  filterCookieKey: function() {
    return this.key + '_filter'
  },

  getFilterKey: function() {
    return this.filterator.key
  },

  filterAll: function() {
    return true
  },
  filterMine: function(model) {
    var paged = _.clone(model.get('USERS_PAGED'))
    var ackuser = _.clone(model.get('ACK_USER'))

    for (var i = 0; i < paged.length; i++) {
      paged[i] = paged[i].toLowerCase()
    }
    if (ackuser) {
      ackuser = ackuser.toLowerCase()
    }

    return _.includes(paged, this.username) || ackuser === this.username
  },
  filterTeams: function(model) {
    var self = this
    var user = users.get(self.username)

    if (!self.userDeferred || self.userDeferred.state() !== 'resolved') {
      // the user record isn't available yet, we'll retry when it is.
      return false
    }

    var myGroups = user.get('group-slugs')
    var pgGroups = model.get('TEAMS_PAGED')

    return _.intersection(myGroups, pgGroups).length > 0
  },
  filterAckedMe: function(model) {
    var ackuser = _.clone(model.get('ACK_USER'))
    if (ackuser) {
      ackuser = ackuser.toLowerCase()
    }
    return ackuser === this.username
  },

  changeSort: function(sortkey) {
    var comparators = [
      this.comparatorNewest,
      this.comparatorOldest,
      this.comparatorAckNewest,
      this.comparatorAckOldest,
    ]
    var comparator = _.find(comparators, function(c) {
      return c.sortkey === sortkey
    })
    if (!_.isUndefined(comparator)) {
      $.cookie(this.cookieKey(), sortkey, { expires: 365 })
      this.comparator = comparator
    }
  },

  changeFilter: function(filterkey) {
    var filters = [
      this.filterAll,
      this.filterMine,
      this.filterTeams,
      this.filterAckedMe,
    ]
    var filter = _.find(filters, function(f) {
      return f.key === filterkey
    })
    if (!_.isUndefined(filter)) {
      $.cookie(this.filterCookieKey(), filterkey, { expires: 365 })
      this.filterator = filter
    }
  },

  ackInSlices: function(arr, start, size, message, resolve, end = size) {
    var slice = arr.slice(start, end)
    if (slice.length > 0) {
      setTimeout(() => {
        server.acknowledgeIncident(slice, (message = ''), resolve)
        this.ackInSlices(arr, end, size, message, resolve, end + size)
      }, 0)
    }
  },

  escalate: function(incidentNames) {
    if (!incidentNames.length) {
      incidentNames = this.pluck('INCIDENT_NAME')
    }

    server.escalateIncident(incidentNames)
  },

  reroute: function(incidentNames, teamSlugs) {
    if (!incidentNames.length) {
      incidentNames = this.pluck('INCIDENT_NAME')
    }

    server.rerouteIncident(incidentNames, teamSlugs)
  },

  acknowledge: function(incidentNames, message = '', resolve = false) {
    this.ackOrResolve(incidentNames, message, resolve)
  },

  resolve: function(incidentNames, message = '', resolve = true) {
    this.ackOrResolve(incidentNames, message, resolve)
  },

  ackOrResolve: function(incidentNames, message, resolve) {
    var eventName = resolve === true ? 'incidents:resolved' : 'incidents:acked'
    if (!incidentNames.length) {
      incidentNames = this.pluck('INCIDENT_NAME')
    }
    var models = _.filter(this.models, function(model) {
      return _.includes(incidentNames, model.get('INCIDENT_NAME'))
    })

    this.ackInSlices(incidentNames, 0, 5, message, resolve)
    this.trigger(eventName, models, this)
    if (incidentNames.length > 1) {
      this.remove(models)
    }
  },
})
