import React, { Component } from 'react'

import { getDefaultTime, timesList } from './snooze-helpers'

import TimeSelect from 'components/selectors/time-select'

export default class SnoozeForm extends Component {
  constructor(props) {
    super(props)
    const parseSnoozeTime = (snoozedUntil, snoozedUntilTimestamp) => {
      const split = snoozedUntil.split(' ')
      const time = split[split.length - 2]
      const amPm = split[split.length - 1].toLowerCase()

      return { value: snoozedUntilTimestamp, label: `${time} ${amPm}` }
    }

    const currentSnooze = props.snoozedUntil
      ? parseSnoozeTime(props.snoozedUntil, props.snoozedUntilTimestamp)
      : null

    const defaultTime = currentSnooze || getDefaultTime()

    function getTimesList(timesList) {
      const defaultTime = getDefaultTime()
      const timesListClone = timesList.slice(0)
      const splitIndex = timesList.reduce((acc, el, i) => {
        if (el.value === defaultTime.value) {
          return i
        }
        return acc
      }, undefined)

      // 49 ensures we will grab everything after the splitting index
      const shiftList = timesListClone.splice(splitIndex, 49)

      return shiftList
        .concat([
          {
            value: 'Tomorrow',
            label: 'Tomorrow',
            disabled: true,
          },
        ])
        .concat(timesListClone)
    }

    this.state = {
      timeValue: {
        value: defaultTime.value,
        label: defaultTime.label,
      },
      isSelectionTomorrow: false,
      timeList: getTimesList(timesList),
    }

    this.onSelect = this.onSelect.bind(this)
  }

  onSelect(val) {
    const indexValues = this.state.timeList.reduce(
      (acc, el, i) => {
        if (el.value === 'Tomorrow') {
          return { optionValue: acc.optionValue, tomorrow: i }
        } else if (el.value === val.value) {
          return { optionValue: i, tomorrow: acc.tomorrow }
        }
        return acc
      },
      { optionValue: undefined, tomorrow: undefined }
    )

    if (indexValues.optionValue > indexValues.tomorrow) {
      return this.setState({
        timeValue: { value: val.value, label: val.label + ' tomorrow' },
        isSelectionTomorrow: true,
        timeList: this.state.timeList,
      })
    } else {
      return this.setState({
        timeValue: { value: val.value, label: val.label },
        isSelectionTomorrow: false,
        timeList: this.state.timeList,
      })
    }
  }

  renderSnoozeValue(option) {
    return <span>{option.label}</span>
  }

  render() {
    const { incidentId, modifySnooze, onCancel, onConfirm } = this.props

    return (
      <div>
        <div className='snooze-modal-body'>
          <div className='snooze-modal-toprow'>
            <span>Snooze incident until</span>
            <TimeSelect
              passedClass={`snooze-modal-picker ${
                this.state.isSelectionTomorrow ? 'snooze-tomorrow' : ''
              }`}
              onSelect={this.onSelect}
              timeList={this.state.timeList}
              timeValue={this.state.timeValue}
              valueRenderer={this.renderSnoozeValue}
            />
          </div>
          <span>We will begin actively paging again at this time.</span>
        </div>
        <ul className='button-list right'>
          <li>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onCancel}
            >
              Cancel
            </button>
          </li>

          <li>
            <button
              type='button'
              className='btn btn-outline-primary sel-snooze-incident-confirm'
              onClick={onConfirm(incidentId, this.state.timeValue.value)}
            >
              {modifySnooze ? 'Update' : 'Snooze'}
            </button>
          </li>
        </ul>
      </div>
    )
  }
}
