import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faClipboard } from '@fortawesome/fontawesome-pro-light'

import { Button, Popover } from '@victorops/victory'

class CopyToClipboard extends Component {
  constructor(props) {
    super(props)

    this.copyToClipboard = this.copyToClipboard.bind(this)
  }

  copyToClipboard(e) {
    this.ref.select()
    document.execCommand('copy')
    e.target.focus()
  }

  render() {
    const { icon, inputText } = this.props

    const ButtonComponent = () => {
      const iconType = icon === 'clipboard' ? faClipboard : faCalendarAlt

      return (
        <span onClick={e => this.copyToClipboard(e)}>
          <Button
            type='btn-secondary copy-clipboard--button'
            content={() => <FontAwesomeIcon icon={iconType} size='lg' />}
          />
        </span>
      )
    }

    return (
      <form className='copy-clipboard copy-clipboard--form row text-center'>
        <input
          className='copy-clipboard--input'
          readOnly
          ref={(ref = { inputText }) => {
            this.ref = ref
          }}
          type='text'
          value={inputText}
        />
        {document.queryCommandSupported('copy') && (
          <div className='text-center'>
            <Popover
              component={<ButtonComponent />}
              popoverPositionClass='popover-top'
              targetAttachment='top'
              title='Copied!'
            />
          </div>
        )}
      </form>
    )
  }
}

export default CopyToClipboard
