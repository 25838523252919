import React from 'react'
import { connect } from 'react-redux'

import { getOnCallUsers } from 'components/store/selectors/on-call'

import OffCallWarningView from './off-call-warning-view'

const mapStateToProps = state => {
  return {
    onCall: getOnCallUsers(state),
  }
}

export default connect(mapStateToProps)(OffCallWarningView)
