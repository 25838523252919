import * as Sentry from '@sentry/react'

/**
 * This utility provides an abstraction to the 3rd party logging/monitoring provider currently used.
 * The provided interfaces are designed to be generic so that a change to the specific logging provider can be done
 * here without having to change other code throughout this application.
 */

/**
 * Log an error to external service
 *
 * @param error - Either a javascript Error or a String
 * @param data - Data in use at the time of the error.
 * @param attributes - metadata related to the error.  Env, configuration, conditions, etc.
 * @param groupBy - array of strings to help group similar errors together.
 */
export const logError = (error, { data, attributes, groupBy }) => {
  Sentry.captureException(error, {
    tags: attributes,
    extras: { data },
    fingerprint: groupBy,
  })
}

/**
 * Log a message to external service.
 * Use this if you want to report and group events but don't want to report as errors.
 *
 * @param message
 * @param data - Data in use at the time of the error.
 * @param attributes - metadata related to the message.  Env, configuration, conditions, etc.
 * @param groupBy - array of strings to help group similar messages together.
 */
export const logMessage = (message, { data, attributes, groupBy }) => {
  Sentry.captureMessage(message, {
    tags: attributes,
    extras: { data },
    fingerprint: groupBy,
  })
}
