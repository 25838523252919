// vendor
import $ from 'jquery'
import Backbone from 'backbone'
import { extend } from 'lodash'
import { Subject } from 'rxjs/Rx'

// lib
import xlog from 'util/extendedLog'
import { info } from 'util/_console'
import { compress, decompress } from 'util/compression'
import check from 'util/check'
import { isIE } from 'util/ie'
import { isSocketCompressionDisabled } from 'util/configOverrides'
import { logError } from '../../util/monitoringService'

function Connection() {
  this._ws = null
  this.status = new Subject()
  this.messageQueue = [] // messages attempted to be sent before the LOGIN_REPLY_MESSAGE comes back
  this.isSocketAuthenticated = false
}

extend(Connection.prototype, Backbone.Events, {
  // is the socket connected?
  connected() {
    const isConnected = this._ws !== null && this._ws.readyState < 2

    if (isConnected) {
      this.status.next({ state: 'connected' })
    }

    return isConnected
  },

  // does the current environment support sockets?
  // detection algo from modernizr
  supported() {
    return 'WebSocket' in window && window.WebSocket.CLOSING === 2
  },

  // connect to the socket
  connect(address) {
    if (this.connected() || !this.supported()) {
      return // bail
    }

    this.status.next({ state: 'connecting' })

    // @see components/__entry
    var ws = new global.WebSocket(address)
    this._ws = ws

    window.VO_SOCKET_EMITTER.emit('socket:connect', ws)

    this._ws.onopen = () => {
      this.status.next({ state: 'connected' })

      info('ws open')

      this.trigger('connected')
    }

    this._ws.onclose = e => {
      this.status.next({ state: 'closed', meta: { error: e } })

      const extraInfo =
        e.code > 4999 || e.code < 0 ? ' - server stopped responding' : ''
      const err = {
        error: e,
        extraInfo: extraInfo,
      }

      info('ws close', err)
      logError(e, { attributes: { action: 'onclose' } })

      this.isSocketAuthenticated = false

      this.trigger('disconnected', e)
    }

    this._ws.onmessage = messageEvent => {
      if (typeof messageEvent.data === 'string') {
        const message = messageEvent.data
        this.handleMessage(message)
      } else {
        decompress(messageEvent.data)
          .then(message => {
            this.handleMessage(message)
          })
          .catch(e => {
            logError(e, { attributes: 'action: onmessage' })
          })
      }
    }

    this._ws.onerror = e => {
      this.status.next({ state: 'errored', meta: { error: e } })

      info('ws error', e)

      this.trigger('error', e)

      // try to figure out what went wrong by asking for a resource
      // that will give us proper http status codes back...
      $.get('/api/config.json')
        .done(function() {
          // noop; don't know why we can't connect
        })
        .fail(function(e) {
          // because we aren't logged in/session expired...
          if (e.status === 401) {
            window.location = window.location.origin + '/membership/'
          } else if (e.status >= 500) {
            // TODO: handle server error
          } else if (e.status >= 300) {
            // TODO: follow the redirect
          }
        })
    }

    window.voSocket = this._ws
  },

  // disconnect from the socket
  disconnect() {
    this.status.next({ state: 'disconnecting' })

    this._ws.close()

    // because Chrome...
    // see https://code.google.com/p/chromium/issues/detail?id=76358
    this.trigger('disconnected')
  },

  // send data to the server over the socket
  // you probably want to make sure any changes made to this socket send are mirrored
  // in the component/socket/index.js send
  send(data) {
    // queue the message until LOGIN_REPLY_MESSAGE comes from server.
    if (!this.isSocketAuthenticated) {
      this.messageQueue.push(data)
    } else {
      check
        .feature('socketCompression')
        .then(hasFeature => {
          const outMessage =
            hasFeature && !isIE() && !isSocketCompressionDisabled()
              ? compress(data)
              : JSON.stringify(data)
          this._ws.send(outMessage)
        })
        .catch(e => {
          const err = {
            err: e,
            message: data,
          }

          xlog('connection:send', err)
          logError(err, { attributes: { action: 'send' } })
        })
    }
  },

  /**
   * @function handleMessage
   * handles messages coming in from the socket.
   */
  handleMessage(message) {
    const data = JSON.parse(message.slice(message.indexOf('{')))
    this.checkForLoginReplyMessage(data)
    this.trigger('message', data)
  },

  /**
   * @function checkForLoginReplyMessage
   * We need to verify we got the LOGIN_REPLY_MESSAGE back before sending any front channel socket messages.
   */
  checkForLoginReplyMessage(data) {
    if (data.MESSAGE === 'LOGIN_REPLY_MESSAGE') {
      this.isSocketAuthenticated = true
      this.sendMessageQueue()
    }
  },

  /**
   * @function sendMessageQueue
   * after we get the LOGIN_REPLY_MESSAGE we now can send any messages that tried to send before we got that backchannel message.
   */
  sendMessageQueue() {
    this.messageQueue.forEach(message => {
      this.send(message)
    })

    this.messageQueue = []
  },
})

export default Connection
