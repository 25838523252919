import React from 'react'
import { Popover } from '@victorops/victory'
import { faQuestionCircle } from '@fortawesome/fontawesome-pro-regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function HelpPopover() {
  return (
    <Popover
      component={
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className='maintenance-mode-banner_icon-help'
          data-test-key='maintenance-mode-banner_help'
        />
      }
      content={
        <>
          <p>
            Learn more about Maintenance Mode in the{' '}
            <a
              href={`https://help.victorops.com/knowledge-base/maintenance-mode/`}
              target='_blank'
              title='Knowledge Base'
            >
              Knowledge Base
            </a>
            .
          </p>
        </>
      }
      popoverPositionClass='popover-bottom'
      targetAttachment='top'
      title={'Maintenance Mode'}
    />
  )
}

export default HelpPopover
