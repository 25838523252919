import React from 'react'
import config from 'components/__utils/config'
import { Link } from 'react-router'

export default class CreateButton extends React.Component {
  render = () => (
    <Link
      to={`/client/${config.auth.org.slug}/createIncident`}
      className='manual-incident-open'
    >
      <button className='btn btn-sm btn-outline-primary nowrap'>
        Create Incident
      </button>
    </Link>
  )
}
