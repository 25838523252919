var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="l-card rolodex-card">\n\n  <div class="l-card-left">\n    <div class="js-rolodex-contact-engagement rolodex-contact-engagement">\n      <div><span></span></div>\n    </div>\n\n    <div class="rolodex-contact-fullname">\n      <span>' +
__e( fullname ) +
'</span>\n    </div>\n\n    <div class="rolodex-contact-username" data-ext="rolodex-contact-username">\n      <span>@' +
__e( USER_ID ) +
'</span>\n    </div>\n  </div> <!-- // l-card-left -->\n\n  <div class="l-card-right">\n    <div class="rolodex-indicators">\n      <div class="rolodex-contact-oncall-icon">\n        <svg\n          data-test-key=\'people-pane-oncall-icon\'\n          height="16"\n          width="16"\n        >\n          <image\n            height="16"\n            src="/static/img/vo-logo-graphic.svg"\n            width="16"\n            xlink:href="/static/img/vo-logo-graphic.svg"\n          />\n        </svg>\n      </div>\n\n      <span class="js-missed"></span>\n    </div>\n  </div> <!-- // l-card-right -->\n\n</div> <!-- // l-card -->\n';

}
return __p
}