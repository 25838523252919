// vendor
import Backbone from 'lib/backbone'
import LocalStorage from 'lib/backbone.localstorage'

// Model
// -----------------------------------------------------------------------

var Group = Backbone.Model.extend({
  defaults: {
    state: 'all',
  },

  idAttribute: 'groupKey',
})

// Collection
// -----------------------------------------------------------------------

export default function(slug) {
  return Backbone.Collection.extend({
    model: Group,

    localStorage: new LocalStorage(slug + ':FiltersGroup'),

    initialize: function() {
      this.listenTo(this, 'remove', function(model) {
        model.destroy()
      })

      this.listenTo(this, 'add change', function(model) {
        model.save()
      })
    },
  })
}
