import React from 'react'
import { NoConferenceBridgeDisplayContainer } from './no-conference-bridge-display-container'
import { NoConferenceBridgeDisplayErrorBoundary } from './no-conference-bridge-display-error-boundary'

const Fallback = () => <div>Failed to add conference bridge</div>

export const NoConferenceBridgeDisplay = props => (
  <NoConferenceBridgeDisplayErrorBoundary Fallback={<Fallback />}>
    <NoConferenceBridgeDisplayContainer {...props} />
  </NoConferenceBridgeDisplayErrorBoundary>
)
