// vendor
import Backbone from 'backbone'
import Marionette from 'marionette'
import _ from 'lodash'
import 'backbone.stickit'
import $ from 'jquery'

// lib
import ComponentsView from './incident.components'
// import log from 'util/_console'
import vent from 'util/vent'
var map = _.curryRight(_.map)(null)

var makeOptions = map(function(template) {
  var vals = {
    name: template.get('name'),
    label: template.get('title'),
  }

  var html = _.template('<option value="<%= name %>"><%= label %></option>')

  return html(vals)
})

var ComponentsModel = Backbone.Model.extend({
  defaults: {
    label: 'A component',
    state: false,
  },
})

var ComponentsCollection = Backbone.Collection.extend({
  model: ComponentsModel,
})

function createComponents(options) {
  return _.map(options.components, function(el) {
    var _id = el.get('id')
    var label = el.get('name')
    var state = _.includes(options.component_ids, _id)

    return {
      _id: _id,
      group_id: el.get('group_id'),
      label: label,
      state: state,
    }
  })
}

function whichComponentState(e) {
  var state = $(e.currentTarget).text()

  // This is a bit nasty, but works for the moment. We're checking
  // DOM string to see which direction we should move a component's
  // state...
  if (state === '(check all)') {
    return true
  } else {
    return false
  }
}

export default Marionette.View.extend({
  // Overrides
  // -------------------------------------------------------------------

  initialize: function(options) {
    this.templates = options.templates
    this.components = options.components

    // Binding the $.Deferreds produced by fetch to "this" will allow
    // some introspection of the $.Deferred during testing.
    this.pTemplates = this.templates.fetch()
    this.pComponents = this.components.fetch()

    this.listenTo(vent, 'components:add', this.onAddComponent)
    this.listenTo(vent, 'components:remove', this.onRemoveComponent)
  },

  regions: {
    componentsSub: '.js-statuspage-alerts',
  },

  serializeData: function() {
    // We don't actually need model data to be passed to the view,
    // since we'll be setting and updating that with Backbone.stickit
    // bindings. Instead, we just want to know if the view has been
    // saved or not, as this will indicate whether or not an incident
    // is being created or updated. If the incident is being updated,
    // we need to print the existing title in the view, otherwise,
    // nope.
    return {
      isNew: this.model.isNew(),
      name: this.model.get('name'),
    }
  },

  template: require('t/statuspage/modals/incident.tpl'),

  ui: {
    componentsToggle: '.js-statuspage-toggle-all',
    incidentCancel: '.js-statuspage-cancel',
    incidentSubmit: '.js-statuspage-submit-incident',
    incidentEditName: '.js-statuspage-title .trigger',
    incidentUpdateName: '.js-statuspage-title-edit .trigger',
    templateCancel: '.js-statuspage-templates-cancel',
    templateSelect: '.js-statuspage-templates',
  },

  events: {
    'change @ui.templateSelect': 'onSetTemplate',
    'click @ui.componentsToggle': 'onToggleComponents',
    'click @ui.incidentCancel': 'onCancel',
    'click @ui.incidentSubmit': 'onSubmit',
    'click @ui.incidentEditName': 'onEditName',
    'click @ui.incidentUpdateName': 'onUpdateName',
    'click @ui.templateCancel': 'onCancelTemplate',
  },

  bindings: {
    'input.js-statuspage-title-edit-input': {
      observe: 'name',
    },

    '.js-statuspage-incident-name': {
      observe: 'name',
    },

    '.js-statuspage-toggle-all': {
      observe: 'component_ids',

      onGet: function(components) {
        // Change toggle wording depending on whether or not
        // components are currently in the collection.
        if (!components || components.length === 0) {
          return '(check all)'
        } else if (components.length === this.components.length) {
          return '(uncheck all)'
        } else {
          return '(check all)'
        }
      },
    },

    'input.js-statuspage-name': {
      observe: 'name',
      events: ['blur'],
    },

    'input[type=radio].js-statuspage-status': {
      observe: 'status',
    },

    'textarea.js-statuspage-message': {
      observe: 'message',
      events: ['blur'],
    },
  },

  onRender: function() {
    this.pTemplates.done(_.bind(this.showTemplates, this))
    this.pComponents.done(_.bind(this.showComponents, this))

    this.stickit()
  },

  // Custom Event Callbacks
  // -------------------------------------------------------------------

  onSubmit: function(e) {
    e.preventDefault() // wtf?

    this.model.save()

    vent.trigger('statuspage:container:empty')
    // vent.trigger('statuspage:prompt:show');
  },

  onCancel: function(e) {
    e.preventDefault()

    vent.trigger('statuspage:container:empty')
  },

  onEditName: function(e) {
    e.preventDefault()

    var $name = this.$('.js-statuspage-title')
    var $edit = this.$('.js-statuspage-title-edit')

    $name.addClass('hidden')
    $edit.removeClass('hidden')
  },

  onUpdateName: function(e) {
    e.preventDefault()

    var $name = this.$('.js-statuspage-title')
    var $edit = this.$('.js-statuspage-title-edit')

    $name.removeClass('hidden')
    $edit.addClass('hidden')
  },

  onSetTemplate: function() {
    var $selected = this.ui.templateSelect.find(':selected')
    var tplName = $selected.val()

    var template = this.templates.findWhere({ name: tplName })

    if (this.model.isNew()) {
      // Update model name only for new models
      this.model.set('name', template.get('title'))
    }

    this.model.set('message', template.get('body'))
  },

  onCancelTemplate: function() {
    this.ui.templateSelect.prop('selectedIndex', 0)

    if (this.model.isNew()) {
      // Reset model name only for new models
      this.model.set('name', null)
    }

    this.model.set('message', null)
  },

  onAddComponent: function(componentID) {
    var components = _.clone(this.model.get('component_ids'))
    var componentsUpdated = components.concat(componentID)

    this.model.set('component_ids', componentsUpdated)
  },

  onRemoveComponent: function(componentID) {
    var components = _.clone(this.model.get('component_ids'))
    var componentsUpdated = components.concat(componentID)

    this.model.set(
      'component_ids',
      _.filter(componentsUpdated, function(el) {
        return componentID !== el
      })
    )
  },

  onToggleComponents: function(e) {
    var state = whichComponentState(e)

    if (this.componentsSubColl) {
      this.componentsSubColl.each(function(model) {
        model.set('state', state)
      })
    }
  },

  // Custom
  // -------------------------------------------------------------------

  showComponents: function() {
    var collectionOptions, sub // init

    // Don't need to do any work if there are no components
    if (this.components.models.length > 0) {
      collectionOptions = {
        component_ids: this.model.get('component_ids'),
        components: this.components.models,
      }

      this.componentsSubColl = new ComponentsCollection()
      this.componentsSubColl.set(createComponents(collectionOptions))

      sub = new ComponentsView({ collection: this.componentsSubColl })

      this.componentsSub.show(sub)
    } else {
      this.ui.componentsToggle.addClass('hidden')
    }
  },

  showTemplates: function() {
    var $templateSelect, $templateOptions // init

    // If there are no templates associated with this StatusPage.io,
    // then we should not surface the option to select one.
    if (this.templates.models.length > 0) {
      $templateSelect = this.ui.templateSelect
      $templateOptions = makeOptions(this.templates.models)

      $templateSelect.append($templateOptions)
    } else {
      this.$('.js-statuspage-control-group-templates').addClass('hidden')
    }
  },
})
