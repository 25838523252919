// vendor
import Marionette from 'marionette'

// lib
import incidentDetails from 'components/__entry'
import rIncidentDetails from 't/r-incident-details.tpl'

export default Marionette.View.extend({
  template: rIncidentDetails,

  onAttach: function() {
    incidentDetails.attach('incidentDetails', this.options)
  },

  // Need to unmount React
  onDestroy: function() {
    incidentDetails.detach('incidentDetails', this.options)
  },
})
