// vendor
import { Map as iMap, OrderedMap } from 'immutable'

import { decodeHtml as _decodeHtml } from 'components/__utils/html'

import { ALERT_DETAILS_ADD } from 'components/store/actions'

import dedupeAlertAndIncidentFields from 'components/store/reducers/__utils/dedupe-alert-and-incident-fields'

// Reducer
// ---------------------------------------------------------------------------

export default function alertDetailsReducer(state = iMap({}), action) {
  switch (action.type) {
    case ALERT_DETAILS_ADD:
      return _addAlertDetails(state, action.payload)

    default:
      return state
  }
}

const unescapeFields = fields =>
  new OrderedMap(fields).map(data => _decodeHtml(data))

function _addAlertDetails(state, payload) {
  if (payload) {
    let unescapedPayload = iMap({
      nonVoFields: unescapeFields(payload.ALERT_DETAILS),
      voFields:
        payload.VO_ALERT_DETAILS === null
          ? null
          : unescapeFields(payload.VO_ALERT_DETAILS),
    })

    unescapedPayload = dedupeAlertAndIncidentFields(unescapedPayload)

    return state.merge({ [payload.ALERT_DETAILS.VO_UUID]: unescapedPayload })
  } else {
    return state
  }
}
