// vendor
import _ from 'lib/underscore'
import Backbone from 'lib/backbone'

// lib
import Alert from '../models/event.alert'
import AlertResume from '../models/event.resume'
import AlertSuspend from '../models/event.suspend'
import Chat from '../models/event.chat'
import ControlCall from '../models/event.controlcall'
import Events from '../collections/events'
import Incident from '../models/event.incident'
import OnCall from '../models/event.oncall'
import Page from '../models/event.page'
import ThirdParty from '../models/event.thirdparty'
import server from '../../server'
import vent from 'util/vent'

const config = window.VO_CONFIG

export default Backbone.Model.extend({
  idAttribute: 'ROOM_ID',

  defaults: {
    MISSED: 0,
    LATEST: 0,
  },

  initialize: function(options) {
    if ('tags' in options) {
      this.tags = options.tags
    }

    var history = 50
    if ('history' in options) {
      history = options.history
    }

    this.events = new Events()
    var self = this

    server.requestHistory(this.get('ROOM_ID'), history).then(function() {
      self.trigger('initialized')
      vent.trigger('timeline:initialized')
    })
  },

  roomid: function() {
    if (this.get('ROOM_ID') === '*') {
      return 'all'
    } else {
      return this.get('ROOM_ID').replace(/;/g, '_')
    }
  },

  participants: function() {
    var id = this.get('ROOM_ID')
    return _.without(id.split(';'), config.socketAuth.USER_ID.toLowerCase())
  },

  buildEvent: function(type, data) {
    switch (type) {
      case 'chat':
        return new Chat(data)
      case 'alert':
        return new Alert(data)
      case 'oncall':
        return new OnCall(data)
      case 'page':
        return new Page(data)
      case 'incident':
        return new Incident(data)
      case 'suspend':
        return new AlertSuspend(data)
      case 'resume':
        return new AlertResume(data)
      case 'controlcall':
        return new ControlCall(data)
      case 'thirdparty':
        return new ThirdParty(data)
      default:
    }
  },

  push: function(type, data) {
    this.set('LATEST', data.SEQUENCING.SEQUENCE)

    var model = this.buildEvent(type, data)

    if (model.hasOwnProperty('id')) {
      this.events.add(model)
    }
  },
})
