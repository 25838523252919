// vendor
import { connect } from 'react-redux'

// lib
import {
  getNonVisibleIncidentCount,
  getActivePhase,
  getFeatureFlags,
} from 'components/store/selectors'

import { flushIncidents } from 'components/store/actions'

import IncidentsNavCounter from './incidents-nav-counter'

function mapStateToProps(state) {
  function getReadablePhase(phase) {
    switch (phase) {
      case 'UNACKED':
        return 'Triggered'
      case 'ACKED':
        return 'Acked'
      case 'RESOLVED':
        return 'Resolved'
    }
  }

  return {
    count: getNonVisibleIncidentCount(state),
    phase: getReadablePhase(getActivePhase(state)),
    reactTimeline: getFeatureFlags(state).get('feature:reacttimeline'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onClickCounter: () => dispatch(flushIncidents()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsNavCounter)
