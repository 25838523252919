// vendor
import Backbone from 'backbone'
import LocalStorage from 'backbone.localstorage'

// Model
// -----------------------------------------------------------------------

const Pane = Backbone.Model.extend({
  defaults: {
    view_state: 'active',
  },

  idAttribute: 'pane_id',
})

// Collection
// -----------------------------------------------------------------------

export default function(slug) {
  return Backbone.Collection.extend({
    localStorage: new LocalStorage(slug + ':Panes'),

    model: Pane,
  })
}
