import Rx from 'rxjs'

import { head, tail } from 'lodash'

// victorops
import { hotpathProtocol } from '@victorops/message-protocol'

export function _onCallProtocol(source) {
  return Rx.Observable.create(function createObserver(observer) {
    hotpathProtocol('STATE_NOTIFY_MESSAGE', source).subscribe(
      function next(el) {
        const type = head(el)

        switch (type) {
          case 'ONCALL_LIST':
            observer.next(head(tail(el)))
            break
          default:
            break
        }
      },

      function onError(err) {
        observer.error(err)
      },

      function onCompleted() {
        observer.complete()
      }
    )
  })
}
