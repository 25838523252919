import $ from 'lib/jquery'
import Backbone from 'lib/backbone'
import _ from 'lib/underscore'
import when from 'when'
import 'lib/stickit'

import ConfirmView from './modals/ack-resolve'
import vent from 'util/vent'
import data from '../data'

var triggered = data.incidents.triggered
var acked = data.incidents.acknowledged
var resolved = data.incidents.resolved

export default Backbone.View.extend({
  initialize: function(options) {
    var undef = []

    this.rangeSelectStart = null

    this.$ack = this.$('.js-ack')
    this.$resolve = this.$('.js-resolve')
    this.$escalate = this.$('.js-escalate')

    this.$trig = this.$('.js-load-trig')
    this.$ackd = this.$('.js-load-ackd')
    this.$trig.toggle(false)
    this.$ackd.toggle(false)

    // load more setup
    var updateTrig = this.updateLoad.bind(null, this.$trig)
    var updateAckd = this.updateLoad.bind(null, this.$ackd)

    // init
    updateTrig(false)
    updateAckd(false)

    // listen to collection events and modify load more button
    this.listenTo(triggered, 'add', function() {
      updateTrig(true)
    })
    this.listenTo(acked, 'add', function() {
      updateAckd(true)
    })
    this.listenTo(triggered, 'reset', function() {
      updateTrig(false)
    })
    this.listenTo(acked, 'reset', function() {
      updateAckd(false)
    })

    this.updateCount('.incidentcounter-triggered', undef, [])
    this.updateCount('.incidentcounter-acknowledged', undef, [])
    this.listenTo(
      triggered,
      'incidentcount:delta',
      this.updateLoadCount.bind(null, this.$trig)
    )
    this.listenTo(
      acked,
      'incidentcount:delta',
      this.updateLoadCount.bind(null, this.$ackd)
    )

    this.listenTo(
      triggered,
      'add remove filterator toggleFiltered',
      _.throttle(
        _.bind(this.updateCount, this, '.incidentcounter-triggered'),
        250
      )
    )
    this.listenTo(
      acked,
      'add remove filterator toggleFiltered',
      _.throttle(
        _.bind(this.updateCount, this, '.incidentcounter-acknowledged'),
        250
      )
    )

    this.listenTo(
      triggered,
      'add remove filterator',
      _.throttle(_.bind(this.updateAckButton, this), 250)
    )
    this.listenTo(
      triggered,
      'add remove filterator',
      _.throttle(_.bind(this.updateEscalateButton, this), 250)
    )
    this.listenTo(
      acked,
      'add remove',
      _.throttle(_.bind(this.updateResolveButton, this), 250)
    )

    this.listenTo(
      triggered,
      'reset filterator',
      _.bind(this.updateCountReset, this, '.incidentcounter-triggered')
    )
    this.listenTo(
      acked,
      'reset filterator',
      _.bind(this.updateCountReset, this, '.incidentcounter-acknowledged')
    )

    this.listenTo(
      triggered,
      'reset filterator',
      _.bind(this.updateAckButtonReset, this)
    )
    this.listenTo(
      triggered,
      'reset filterator',
      _.bind(this.updateEscalateButtonReset, this)
    )
    this.listenTo(
      acked,
      'reset filterator',
      _.bind(this.updateResolveButtonReset, this)
    )

    options.vent.on('changefilter', this.triggerFilter, this)
  },

  events: {
    'click .js-ack a': 'doAck',
    'click .js-resolve a': 'doResolve',
    'click .js-escalate a': 'doEscalate',
    'click ul[class^=js-incidents-] [type=checkbox]': 'selectRow',
    'change ul[class^=js-incidents-] [type=checkbox]': 'selectionChange',
    'click .js-load-trig': function(e) {
      this.loadLatest(e, triggered)
    },
    'click .js-load-ackd': function(e) {
      this.loadLatest(e, acked)
    },
  },

  triggerFilter: function(key) {
    var self = this
    self.filterIncidents(triggered, key, key)
    self.filterIncidents(acked, key, key)
    self.filterIncidents(resolved, key, key)
    self.$el.animate(
      {
        scrollTop: 0,
      },
      250
    )
  },

  getVisibleCount: function(coll) {
    if (coll.filterator && coll.filterator.split) {
      return coll.select(coll.filterator, coll).length
    }

    return undefined
  },

  getAllCount: function(coll) {
    return coll.length
  },

  getButtonShouldBeVisible: function(coll) {
    var count = this.getAllCount(coll)
    var visible = this.getVisibleCount(coll)

    return _.isUndefined(visible) ? count > 0 : visible > 0
  },

  updateCount: function(el, model, coll) {
    var count = this.getAllCount(coll)
    var visible = this.getVisibleCount(coll)
    var $el = $(el)
    var key = coll.key === 'triggered' ? 'triggered' : 'acked'

    if (_.isUndefined(visible)) {
      $el.text(count).removeAttr('title')
    } else {
      $el
        .empty()
        .attr(
          'title',
          'Viewing ' + visible + ' of ' + count + ' ' + key + ' incidents'
        )
      $el.append("<span class='incidentcounter-visible'>" + visible + '</span>')
      $el.append("<span class='incidentcounter-total'>" + count + '</span>')
    }
  },

  updateAckButton: function(model, coll) {
    var isVisible = this.getButtonShouldBeVisible(coll)

    this.$trig.toggle(isVisible)
    this.$ack.toggle(isVisible)
  },

  updateEscalateButton: function(model, coll) {
    this.$escalate.toggle(this.getButtonShouldBeVisible(coll))
  },

  updateResolveButton: function(model, coll) {
    var isVisible = this.getButtonShouldBeVisible(coll)

    this.$ackd.toggle(isVisible)
    this.$resolve.toggle(isVisible)
  },

  updateCountReset: function(sel, coll, options) {
    this.updateCount(sel, null, coll, options)
  },

  updateAckButtonReset: function(coll) {
    this.$ack.toggle(this.getButtonShouldBeVisible(coll))
  },

  updateEscalateButtonReset: function(coll) {
    this.$escalate.toggle(this.getButtonShouldBeVisible(coll))
  },

  updateResolveButtonReset: function(coll) {
    this.$resolve.toggle(this.getButtonShouldBeVisible(coll))
  },

  filterIncidents: function(collection, key, filter) {
    collection.changeFilter(filter)
    collection.trigger('filterator', collection, collection)
    collection.trigger('filter:change', collection, collection)
  },

  doAck: function() {
    var $button = this.$ack.find('a')
    var ids = this.getSelectedNames('triggered')
    var ackCount = ids.length ? ids.length : this.getVisibleCount(triggered)

    if (triggered.length > 0) {
      this.confirm(ackCount, false, () => {
        triggered.acknowledge(ids)
        $button.text($button.text().replace('Selected', 'All'))
      })
    }
  },

  doEscalate: function() {
    var $button = this.$escalate.find('a')
    var names = this.getSelectedNames('triggered')
    var ackCount = names.length ? names.length : triggered.length

    if (
      triggered.length > 0 &&
      confirm(
        'About to ESCALATE ' +
          ackCount +
          ' incidents! Are you sure? This cannot be undone.'
      )
    ) {
      triggered.escalate(names)
      $button.text($button.text().replace('Selected', 'All'))
    }
  },

  doResolve: function() {
    var $button = this.$resolve.find('a')
    var ids = this.getSelectedNames('acknowledged')
    var resolveCount = ids.length ? ids.length : this.getVisibleCount(acked)

    if (acked.length > 0) {
      this.confirm(resolveCount, true, () => {
        acked.resolve(ids)
        $button.text($button.text().replace('Selected', 'All'))
      })
    }
  },

  selectRow: function(e) {
    var self = this
    var $input = $(e.target)
    var isChecked = $input.prop('checked')
    var $inputs = $input
      .closest('[class^=js-incidents-]')
      .find('.js-select-row input')
    var index = $input.closest('li').index()

    if (_.isNull(this.rangeSelectStart) || !e.shiftKey) {
      this.rangeSelectStart = index
    }

    if (e.shiftKey) {
      $inputs
        .slice(
          Math.min(this.rangeSelectStart, index),
          Math.max(this.rangeSelectStart, index) + 1
        )
        .prop('checked', isChecked)
      // fire selectionChange handler too - if we deselect a group the button status needs updating _after_ all items are unchecked.
      self.selectionChange(e)
    }
  },

  selectionChange: function(e) {
    var $input = $(e.target)
    var $button = this.$('.js-phase-' + $input.data('phase')).find('a')
    var selectedLength = $input
      .closest('ul[class^=js-incidents-]')
      .find('.js-select-row input:checked').length

    if (
      !selectedLength ||
      selectedLength ===
        $input
          .closest('ul[class^=js-incidents-]')
          .find('.js-select-row input[type=checkbox]').length
    ) {
      $button.each(function() {
        var $b = $(this)
        $b.text($b.text().replace('Selected', 'All'))
      })
    } else if (selectedLength) {
      $button.each(function() {
        var $b = $(this)
        $b.text($b.text().replace('All', 'Selected'))
      })
    }
  },

  getSelectedIds: function(type) {
    var checked = _.map(
      this.$('.js-incidents-' + type).find('.incident-header input:checked'),
      function(el) {
        // assure that we're returning strings
        return $(el).data('entity-id') + ''
      }
    )
    if (!checked.length) {
      // try constraining to the visible filtered set
      var cloned = data.incidents[type].viewPageItems.clone()
      checked = cloned.pluck('ENTITY_ID')
    }
    return checked
  },

  getSelectedNames: function(type) {
    var checked = _.map(
      this.$('.js-incidents-' + type).find('.incident-header input:checked'),
      function(el) {
        return '' + $(el).data('incident-name')
      }
    )
    if (!checked.length) {
      // try constraining to the visible filtered set
      var cloned = data.incidents[type].viewPageItems.clone()
      checked = cloned.pluck('INCIDENT_NAME')
    }
    return checked
  },

  updateLoadCount: function($el, count) {
    if (count < 1) {
      $el.find('a').addClass('hidden')
    } else {
      var ord = count > 1 ? ' New Incidents' : ' New Incident'
      $el
        .find('a')
        .text(count + ord)
        .removeClass('hidden')
    }
  },

  updateLoad: function($el, fork) {
    if (fork) {
      $el
        .find('a')
        .removeClass('disabled')
        .removeClass('hidden')
    } else {
      $el
        .find('a')
        .addClass('disabled')
        .addClass('hidden')
    }
  },

  loadLatest: function(e, list) {
    var isDisabled = $(e.target).hasClass('disabled')

    if (!isDisabled) {
      list.trigger('reset', list)
    }
  },

  confirm: function(count, resolve, callback) {
    var ackRes = resolve === true ? 'RESOLVE' : 'ACK'
    var confirm = new ConfirmView({
      title: resolve === true ? 'Resolve Incidents?' : 'Acknowledge Incidents?',
      msg:
        'About to ' +
        ackRes +
        ' ' +
        count +
        ' incidents! Are you sure? This cannot be undone.',
    })
    var cleanup = function() {
      vent.trigger('modal:small:empty')
      confirm.remove()
    }

    vent.trigger('modal:small:show', confirm)
    when(confirm.promise)
      .then(callback)
      .then(cleanup)
      .catch(cleanup)
  },
})
