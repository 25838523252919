import { connect } from 'react-redux'

import { getActivePhase } from 'components/store/selectors'

import NoItems from './no-items'

export default connect(function(state) {
  return {
    phase: getActivePhase(state),
  }
})(NoItems)
