import 'setimmediate'
import _ from 'lib/underscore'

// file: mqueue.js
// process a queue asynchronously, giving the browser enough time to actually do things

var MQueue = function(proc, options) {
  var self = this
  options = options || {}

  self.queue = [] // the message queue
  self.idle = true // we're idle to start
  self.proc = proc // the callback

  self.loop = _.bind(doTimerLoop, self) // bound function to loop
  self.timer = options.timer || window.setImmediate // the timer to use

  // if the browser supports it, and we want it, do messages synchronously when the page is hidden
  self.syncWhenBackgrounded =
    options.syncWhenBackgrounded === undefined
      ? true
      : options.syncWhenBackgrounded

  if (self.syncWhenBackgrounded && 'hidden' in document) {
    self.boundVisChange = _.bind(onPageVisibityChange, self)
    document.addEventListener('visibilitychange', self.boundVisChange, false)
  }
}

// push the item into the queue, and start processing them
MQueue.prototype.process = function(item) {
  var self = this
  self.queue.push(item)
  if (self.idle) {
    self.idle = false
    self.timer.call(window, self.loop)
  }
}

// concat the array into the queue, and start processing them
MQueue.prototype.processAll = function(ary) {
  var self = this
  self.queue.push.apply(self.queue, ary)
  if (self.idle) {
    self.idle = false
    self.timer.call(window, self.loop)
  }
}

function onPageVisibityChange() {
  var self = this
  if (self.syncWhenBackgrounded && document.hidden) {
    _.each(self.queue, function(data) {
      self.proc.call(window, data)
    })

    self.queue = []
  }
}

// the loop that processes the messages; if there's more after this one, keep going
function doTimerLoop() {
  var self = this
  var data = self.queue.shift()
  if (!data) {
    // if there's no more messages on the queue, we're done for the moment
    self.idle = true
    return
  }

  // call the processor
  self.proc.call(window, data)

  // loop
  // when the browser window is hidden, just do it in here, otherwise schedule again
  if (self.syncWhenBackgrounded && document.hidden) {
    self.loop()
  } else {
    self.timer.call(window, self.loop)
  }
}

export default MQueue
