// vendor
import _ from 'vendor/lodash'
import Marionette from 'lib/marionette'
import erdFactory from 'element-resize-detector'

// lib
import ChatView from './private-chat'
import InfoView from './user/profile'
import interpolate from 'util/interpolate_engagement'
import log from 'util/_console'
import vent from 'util/vent'

var erd = erdFactory({ strategy: 'scroll' })

export default Marionette.View.extend({
  initialize: function(options) {
    this.options = options

    _.bindAll(this, [
      'fixChatTop',
      'onRender',
      'expandChat',
      'expandChat',
      'collapseChat',
      'cleanUpTransition',
    ])

    // Race conditions, hooray!
    //
    // Listen for an event that occurs when a user model has its
    // "oncall" property mutated. When this view's model has an update
    // to oncall, we need to re-render.
    //
    // This race occurs when starting the app with the user pane open,
    // such as refreshing the page with a url like
    // "org_slug#person/username"
    this.listenTo(vent, 'user:oncall', function(user) {
      if (this.model.id === user.id) {
        log.info('profile-chat:re-render')
        this.render()
      }
    })
  },

  className: 'pane-inner profile-pane',

  ui: {
    chat: '.js-profile-chat-chat',

    chatEditor: '.js-editor',

    chatWrapper: '.js-profile-chat-wrapper',

    header: '.js-profile-chat-profile-header',

    userDetail: '.js-profile-chat-profile-content',
  },

  events: {
    'click @ui.header': 'collapseChat',

    'click @ui.chatEditor': 'expandChat',

    // Reset the chat scroll after it is done transitioning.
    'webkitTransitionEnd @ui.chat': 'cleanUpTransition',
    'msTransitionEnd @ui.chat': 'cleanUpTransition',
    'oTransitionEnd @ui.chat': 'cleanUpTransition',
    'transitionEnd @ui.chat': 'cleanUpTransition',
  },

  regions: {
    chat: '.js-profile-chat-chat',

    content: '.js-profile-chat-profile-content',

    userCard: '.js-profile-chat-user-card',
  },

  bindings: {
    '.js-profile-chat-contact-engagement div': {
      attributes: [
        {
          name: 'class',

          observe: 'ENGAGEMENT',

          onGet: function(engagement) {
            var interpolated = interpolate(engagement)
            var engagementLevel // init

            switch (interpolated) {
              case 1:
                engagementLevel = 'on'
                break

              default:
                engagementLevel = 'off'
                break
            }

            return engagementLevel
          },
        },
      ],
    },

    '.js-profile-chat-contact-oncall span': {
      attributes: [
        {
          name: 'class',

          observe: 'oncall',

          onGet: function(oncalls) {
            var isUserOnCall = oncalls.length > 0

            return isUserOnCall ? 'profile-oncall-on' : 'profile-oncall-off'
          },
        },
      ],
    },
  },

  fixChatTop: function() {
    var contactCardHeight = this.ui.header.outerHeight()
    var summedHeight = contactCardHeight + 72 // 72px offset

    this.ui.chatWrapper.css({ top: summedHeight + 'px' })
  },

  onRender: function() {
    const view = this
    erd.listenTo(this.ui.header, _.bind(this.fixChatTop, this))

    this.stickit()

    this.showChildView('content', new InfoView({ model: this.model }))

    this.showChildView('chat', new ChatView({ model: this.model }))
    vent.trigger('private-chat:scroll:reset')
  },

  // Custom
  // -------------------------------------------------------------------
  expandChat: function() {
    this.ui.header.addClass('profile-header--collapsed')
  },

  collapseChat: function() {
    this.ui.header.removeClass('profile-header--collapsed')
  },

  cleanUpTransition: function() {
    vent.trigger('private-chat:scroll:reset')
  },
})
