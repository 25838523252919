import { createSelector } from 'reselect'
import Immutable from 'immutable'
import addTakenPolicies from './add-taken-policies'
import getMatchingCurrentTake from './get-matching-current-take'
import getEndTime from './get-end-time'

export default createSelector(
  (manualTakeTimer, onCallData, username) => ({
    manualTakeTimer,
    onCallData,
    username,
  }),
  ({ manualTakeTimer, onCallData, username }) => {
    const currentTakes = onCallData.get('CURRENT_TAKES')

    let onCallPolicies = onCallData
      .get('ONCALL_LIST')
      .filter(onCall => onCall.get('USER_ID') === username)
      .map(onCall => {
        const policySlug = onCall.get('POLICY_SLUG')
        const teamSlug = onCall.get('GROUP_ID')
        const currentTake = getMatchingCurrentTake(
          currentTakes,
          username,
          policySlug,
          teamSlug
        )

        return new Immutable.Map({
          endTime: getEndTime(
            onCallData.get('NEXT_OFF_CALL'),
            currentTake,
            policySlug,
            teamSlug
          ),
          onCallUsername: onCall.get('USER_ID'),
          originalOnCallUsername:
            currentTake === undefined ? null : currentTake.get('original_user'),
          policyName: onCall.get('POLICY_NAME'),
          policySlug,
          teamName: onCall.get('TEAM_NAME'),
          teamSlug,
        })
      })

    if (manualTakeTimer) {
      onCallPolicies = addTakenPolicies(username, onCallPolicies, currentTakes)
    }

    return onCallPolicies.groupBy(policy => policy.get('teamName'))
  }
)
