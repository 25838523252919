import React, { PureComponent } from 'react'

import moment from 'moment'

export default class ModalBody extends PureComponent {
  getEndsIn(string, endTime) {
    if (endTime === null) return ''

    const now = moment(Date.now())
    const diff = moment(endTime).diff(now)
    const diffDuration = moment.duration(diff)

    const days = diffDuration.days()
    const hours = diffDuration.hours()
    const minutes = diffDuration.minutes()

    const parts = [string]

    if (days > 0) {
      parts.push(`${days} day${days === 1 ? '' : 's'}`)
    }

    if (hours > 0) {
      parts.push(`${hours} hour${hours === 1 ? '' : 's'}`)
    }

    if (minutes > 0) {
      parts.push(`${minutes} minute${minutes === 1 ? '' : 's'}`)
    }

    return parts.join(' ')
  }

  render() {
    const {
      endTime,
      modalConfirmText,
      onCancel,
      onConfirm,
      policyName,
      takeFromUsername,
      takeToUsername,
      teamName,
    } = this.props

    const getModalBody = () => {
      if (modalConfirmText === 'Take On-call') {
        return (
          <p>
            {`You are taking on-call for @${takeFromUsername} in `}
            <span className='take-timer-policyname'>{`${teamName}:${policyName}`}</span>
            . {this.getEndsIn('Current shift ends in', endTime)}
            {endTime ? '.' : ''}
          </p>
        )
      } else if (modalConfirmText === 'Take Back') {
        return (
          <p>
            You will resume on-call for
            {endTime ? ` the remainder of your shift in` : null}
            <span className='take-timer-policyname'>{` ${teamName}:${policyName}`}</span>{' '}
            {this.getEndsIn('which ends in', endTime)}.
          </p>
        )
      } else if (modalConfirmText === 'Clear Take') {
        return (
          <p>
            {`@${takeToUsername} will resume on-call for the remainder of their shift in `}
            <span className='take-timer-policyname'>{`${teamName}:${policyName}`}</span>{' '}
            {this.getEndsIn('which ends in', endTime)}.
          </p>
        )
      }
    }

    return (
      <div>
        {getModalBody()}

        <ul className='button-list right take-timer-buttons'>
          <li>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onCancel}
            >
              Cancel
            </button>
          </li>

          <li>
            <button
              type='button'
              className='btn btn-outline-primary sel-take-timer-confirm'
              onClick={() => onConfirm(endTime)}
            >
              {modalConfirmText}
            </button>
          </li>
        </ul>
      </div>
    )
  }
}
