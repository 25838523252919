// vendor
import { connect } from 'react-redux'

import debug from 'debug'

// lib
import {
  getActiveAudience,
  getActivePhase,
  getPhaseCount,
  getSelectedIncidentIds,
  getVisibleIncidents,
} from 'components/store/selectors'

import {
  hideModal,
  showModal,
  updateIncidentsState,
} from 'components/store/actions'

import IncidentsActionButton from './incidents-action-button'

const isAdmin = window.VO_CONFIG.isAdmin

// Helpers
// ---------------------------------------------------------------------------

const error = debug('VO:incidents-nav:error')

function getSelectedAndAll(state, selectedIncidentIds) {
  const _selectedIncidentIds = selectedIncidentIds.toList()
  const toSelectedIncidentIds = incident =>
    incident.getIn(['data', 'INCIDENT_NAME'])

  return {
    selected: _selectedIncidentIds,
    all: getVisibleIncidents(state)
      .map(toSelectedIncidentIds)
      .toList(),
  }
}

function getActionName(state, phase, selectedIncidentIds) {
  const _selectedIncidentIds = selectedIncidentIds.toList()

  if (_selectedIncidentIds.size > 0) {
    return { ACKED: 'Resolve Selected', UNACKED: 'Acknowledge Selected' }[
      phase || undefined
    ]
  } else {
    return { ACKED: 'Resolve All', UNACKED: 'Acknowledge All' }[
      phase || undefined
    ]
  }
}

// Connect
// ---------------------------------------------------------------------------

function mapStateToProps(state) {
  const phase = getActivePhase(state)

  return {
    actionName: getActionName(state, phase, getSelectedIncidentIds(state)),
    activeAudience: getActiveAudience(state),
    isAdmin: isAdmin,
    phase: phase,
    phaseCount: getPhaseCount(state),
    selectedAndAll: getSelectedAndAll(state, getSelectedIncidentIds(state)),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: function({ ids, phase }) {
      function buildAction(phase) {
        return function dispatchBuiltAction() {
          const title = `${
            phase === 'ack' ? 'Acknowledge' : 'Resolve'
          } Incidents?`
          const body = `About to ${
            phase === 'ack' ? 'acknowledge' : 'resolve'
          } ${ids.size} incident${
            ids.size === 1 ? '' : 's'
          }! Are you sure? This cannot be undone.`

          const modalConfig = {
            modalType: 'confirm',
            modalProps: {
              title: title,
              body: body,
              onCancel: () => dispatch(hideModal()),
              onConfirm: () =>
                dispatch(
                  updateIncidentsState({
                    incidentIds: ids.toJS(),
                    toPhase: phase === 'ack' ? 'ack' : 'resolve',
                  })
                ),
              confirmButtonType: 'info',
            },
          }

          return dispatch(showModal(modalConfig))
        }
      }

      switch (phase) {
        case 'UNACKED':
          return buildAction('ack')
        case 'ACKED':
          return buildAction('resolve')
        default:
          return () => error('Cannot dispatch for this phase')
      }
    },
  }
}

// Export
// ---------------------------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentsActionButton)
