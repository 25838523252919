// Related Incidents
export const RELATED_INCIDENTS_UPDATE = 'RELATED_INCIDENTS_UPDATE'
export const RELATED_INCIDENTS_GET = 'RELATED_INCIDENTS_GET'
export const RELATED_INCIDENTS_SET_LOADING = 'RELATED_INCIDENTS_SET_LOADING'

export function getRelatedIncidents(payload) {
  return {
    type: RELATED_INCIDENTS_GET,
    payload,
  }
}

export function setRelatedIncidentsLoading(payload) {
  return {
    type: RELATED_INCIDENTS_SET_LOADING,
    payload,
  }
}

export function updateRelatedIncidents(payload) {
  return {
    type: RELATED_INCIDENTS_UPDATE,
    payload,
  }
}
