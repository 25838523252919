var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li\n  data-autocomplete-value="' +
((__t = ( obj.value )) == null ? '' : __t) +
'"\n  data-trigger="' +
((__t = ( obj.trigger )) == null ? '' : __t) +
'"\n>\n  <div class="js-main main-text ' +
((__t = ( obj.className )) == null ? '' : __t) +
'">' +
__e( obj.mainText ) +
'</div>\n  ';
 if (obj.subText) { ;
__p += '\n    <div class="js-sub sub-text">' +
__e( obj.subText ) +
'</div>\n  ';
 } ;
__p += '\n</li>\n';

}
return __p
}