import React from 'react'

import { ToolbarContainer } from './toolbar-container'
import { ToolbarErrorBoundary } from './toolbar-error-boundary'
import { Toolbar as StyledToolbar } from './toolbar-styles'

export const Toolbar = props => (
  <ToolbarErrorBoundary Fallback={<StyledToolbar />}>
    <ToolbarContainer {...props} Fallback={<StyledToolbar />} />
  </ToolbarErrorBoundary>
)
