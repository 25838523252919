// vendor
/* global ga */
import $ from 'jquery'
import _ from 'lodash'

// lib
import client from './app'
import isDebug from 'util/isDebug'
import startIncidentsLoadTimer from 'util/startIncidentsLoadTimer'
import data from './data'
import { logError } from '../util/monitoringService'

var config = window.VO_CONFIG

startIncidentsLoadTimer()

export default function() {
  // not sure if this variable is ever used
  config.debug = isDebug()

  function trackConversion() {
    $.removeCookie('signup_email', { domain: '.victorops.com' })

    ga('wwwTracker.send', 'pageview', {
      page: '/funnel/signup/complete',
      transport: 'beacon',
    })
  }

  // on dom ready, bring the client up
  $(document).ready(function() {
    data.meta
      .getOrgMeta()
      .done(function(orgmeta) {
        if (_.isUndefined(orgmeta['funnel:complete'])) {
          data.meta.putOrgMeta({
            'funnel:complete': true,
          })

          if (_.isUndefined(window.gaLoad)) {
            // Wait for GDPR wrapper callback for inserting script
            $(window).on('gaLoad', trackConversion)
          } else {
            // GDPR wrapper has already run
            trackConversion()
          }
        }
      })
      .fail(function(xhr, status, thrown) {
        var err = {
          jqXHR: xhr,
          textStatus: status,
          errorThrown: thrown,
        }

        logError(err)
      })

    client.start()
  })
}
