// vendor
import Marionette from 'marionette'
import Backbone from 'backbone'
import 'backbone.stickit'

// lib
import ComponentForm from './components'
import ComponentsCollection from '../../../data/collections/statuspage/components'
import Incident from '../../../data/models/statuspage/incident'
import IncidentForm from './incident'
import TemplatesCollection from '../../../data/collections/statuspage/templates'
// var log = require('util/_console');

export default Marionette.View.extend({
  // Overrides
  // -------------------------------------------------------------------

  initialize: function(options) {
    var ViewModel = Backbone.Model.extend({
      defaults: {
        type: null,
      },
    })

    this.model = new ViewModel()

    this.subview = options.state
    this.options = options
  },

  template: require('t/statuspage/modals/container.tpl'),

  regions: {
    content: '.js-statuspage-content',
  },

  bindings: {
    '.js-statuspage-type': {
      observe: 'type',
    },
  },

  onShow: function() {
    this.showInitialView(this.subview)
  },

  onRender: function() {
    this.stickit()
  },

  // Custom
  // -------------------------------------------------------------------

  showInitialView: function(which) {
    var config // init

    // Surface appropriate subview
    switch (which) {
      case 'incident':
        config = this.getSubviewConfig('incident')

        this.showChildView('content', new IncidentForm(config))

        break

      case 'components':
        config = this.getSubviewConfig('components')

        this.showChildView('content', new ComponentForm(config))
        break
    }
  },

  getSubviewConfig: function(type) {
    // The config needed by the subview is a bit different. Both need
    // components, but the incident form need additional data.
    if (type === 'incident') {
      // If an incident model was passed to this view, then use it.
      if (this.options.incident) {
        this.incidentModel = this.options.incident
        this.model.set('type', 'Update A StatusPage.io Incident')
      } else {
        this.incidentModel = new Incident()
        this.model.set('type', 'Create A StatusPage.io Incident')
      }

      return {
        components: new ComponentsCollection(),
        model: this.incidentModel,
        templates: new TemplatesCollection(),
      }
    } else if (type === 'components') {
      this.model.set('type', 'Update Your StatusPage.io Components')

      return {
        collection: new ComponentsCollection(),
      }
    } else {
      return {}
    }
  },
})
