import { Map as iMap } from 'immutable'

import {
  ProcessAlert,
  ProcessAlertResume,
  ProcessAlertSuspend,
  ProcessChat,
  ProcessControlCall,
  ProcessIncident,
  ProcessOnCall,
  ProcessPage,
  ProcessThirdParty,
} from './message-preprocessors'

export function liftSequenceData(timelineMessage) {
  if (timelineMessage.has('SEQUENCING')) {
    return timelineMessage.withMutations(message => {
      message
        .set('series', message.getIn(['SEQUENCING', 'SERIES'])) // TODO: verify the null behavior
        .set('sequence', message.getIn(['SEQUENCING', 'SEQUENCE']))
        .set(
          'seriesseq',
          `${message.getIn(['SEQUENCING', 'SERIES'])}:${message.getIn([
            'SEQUENCING',
            'SEQUENCE',
          ])}`
        )
        .set(
          'timestamp',
          new Date(message.getIn(['SEQUENCING', 'SERVICE_TIME']))
        )
        .set('componentProps', iMap({}))
    })
  } else {
    return timelineMessage.set('componentProps', iMap({}))
  }
}

export function preprocessMessage(type, timelineMessage) {
  const sequenced = liftSequenceData(timelineMessage)

  switch (type) {
    case 'alert':
      return ProcessAlert(sequenced)
    case 'chat':
      return ProcessChat(sequenced)
    case 'controlcall':
      return ProcessControlCall(sequenced)
    case 'incident':
      return ProcessIncident(sequenced)
    case 'oncall':
      return ProcessOnCall(sequenced, type)
    case 'page':
      return ProcessPage(sequenced)
    case 'resume':
      return ProcessAlertResume(sequenced)
    case 'suspend':
      return ProcessAlertSuspend(sequenced)
    case 'thirdparty':
      return ProcessThirdParty(sequenced)

    default:
      return timelineMessage
  }
}

export function sortTimelineMessages(messages) {
  return messages.sort((a, b) => {
    return a.get('sequence') > b.get('sequence') ? -1 : 1
  })
}
