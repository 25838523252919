import '@babel/polyfill'
import 'core-js/stable'
import 'whatwg-fetch'

import fontAwesome from 'util/fontAwesome'
import * as Sentry from '@sentry/react'
import { logError } from './monitoringService'

window.jQuery = require('jquery') // 1000 monkeys

const appInit = (orgSlug, startApp, appTitle) => {
  const goToAuth = () => {
    const currentURL = encodeURIComponent(window.location.href)
    window.location.assign('/membership?redirectTo=' + currentURL)
  }

  const getApiUrl = (prefix, orgSlug, suffix) => {
    const org = orgSlug[1]
    return org
      ? prefix + org.replace(/[#\/].*/, '') + suffix // eslint-disable-line no-useless-escape
      : prefix + suffix
  }

  const configPromise = new Promise((resolve, reject) => {
    const apiUrl = getApiUrl('/api/', orgSlug, '/config.json')
    const errorMsg = 'VO_CONFIG:onerror'
    const request = new XMLHttpRequest()
    request.open('GET', apiUrl, true)
    request.setRequestHeader('Accept', 'application/json')
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        resolve(request)
      } else if (request.status >= 400 && request.status < 500) {
        goToAuth()
      } else {
        // TODO: think about other failures - need landing pages for all of them
        sendError(`VO_CONFIG:${request.status}`)
        goToAuth()
      }
    }
    request.onerror = error => {
      sendError(errorMsg, error)
    }
    request.send()
  })

  configPromise
    .then(request => {
      return JSON.parse(request.responseText)
    })
    .then(config => {
      const isStakeholder = config.context.roles.some(
        role => role.name === 'stakeholder'
      )
      if (isStakeholder) window.location = '/static/pages/403.html'
      const apiUrl = getApiUrl(
        '/api/v1/org/',
        orgSlug,
        `/profile/${config.context.user.username}/methods`
      )
      window.VO_CONFIG = config

      startApp()
    })

  fontAwesome()
}

export default appInit

// Helper functions
// ========================================================================
const sendError = message => {
  class VOError extends Error {
    constructor(message) {
      super(message)

      this.name = 'VOError'
      this.message = message

      typeof Error.captureStackTrace === 'function'
        ? Error.captureStackTrace(this, this.constructor)
        : (this.stack = new Error(message).stack)
    }
  }

  try {
    throw new VOError(message)
  } catch (err) {
    logError(err)
  }
}

export { sendError }
