// vendor
import { Map as _Map, fromJS } from 'immutable'

// lib
import { REMOVE_POLICY, UPDATE_POLICIES } from 'components/store/actions'

export default function policiesReducer(state = _Map({}), action) {
  switch (action.type) {
    case UPDATE_POLICIES:
      return _updatePolicies(state, action.payload)
    case REMOVE_POLICY:
      return _removePolicy(state, action.payload)

    default:
      return state
  }
}

function _updatePolicies(state, payload) {
  const slug = payload.slug

  // @TODO: Remove team/teams
  //
  // There is currently no endpoint to derive a list of policies, and the
  // teams they are associated with. Because of this, the relationships have
  // to be constructed client side.
  //
  // This means that we have to have a piece of data called "teams", that we
  // concatenate subsequent teams to. This has a caching problem, since
  // there is no endpoint that would indicate that a team has been removed
  // from a policy.
  const next = payload.data.reduce(function(acc, el) {
    const prevTeam = state.getIn([el.policy.id.toString(), 'teams']) || []

    acc[el.policy.id] = {
      policy: el.policy,
      steps: el.groups.map(step => step.id),
      team: slug, // fml
      teams: prevTeam.concat(slug), // this is temporary
    }

    return acc
  }, {})

  return state.merge(fromJS(next))
}

function _removePolicy(state, payload) {
  return state.delete(payload.toString())
}
