import { warn } from 'util/_console'

class MQ {
  constructor() {
    this.subscribers = {
      history: [],
      state: [],
      notify: [],
    }

    this.channels = {
      history: [],
      state: [],
      notify: [],
    }

    this.historyIsStarted = false
    this.historyIsDone = undefined
  }

  _getQueue(queue, op) {
    if (!op[queue]) {
      op[queue] = []
    }
    return op[queue]
  }

  _notify(subscribers, queue) {
    const doNotify = () => {
      subscribers.forEach(subCallback => {
        subCallback(queue)
      })
    }

    // hold back others until history queue is drained
    if (queue !== 'history' && this.historyIsDone) {
      this.historyIsDone.then(doNotify)
    } else {
      doNotify()
    }
  }

  subscribe(queue, callback) {
    this._getQueue(queue, this.subscribers).push(callback)
  }

  unsubscribe(queue, callback) {
    const index = this._getQueue(queue, this.subscribers).indexOf(callback)
    if (index > -1) {
      this._getQueue(queue, this.subscribers).splice(index, 1)
    }
  }

  push(queue, msg) {
    const msgQueue = this._getQueue(queue, this.channels)
    const historyQueue = this._getQueue('history', this.channels)
    const subsQueue = this._getQueue(queue, this.subscribers)

    // just bucket everything until history starts piling up;
    // allow history to drain first, then release everything else
    msgQueue.push(msg)

    if (queue === 'history') {
      // now we can wait until it's done
      this.historyIsStarted = true
    } else {
      this.historyIsStarted =
        window.VO_PRIORITYBUFFER_OFF || this.historyIsStarted
    }

    // set up a promise for the history queue.
    // this will be used to block the rest of the queue notifications
    // until history is finished draining.
    if (!this.historyIsDone) {
      this.historyIsDone = new Promise(resolve => {
        let retries = 0
        let retryLimit = 10
        if (
          (window.Storage &&
            window.sessionStorage.getItem('disable-prioritybuffer') ===
              'true') ||
          window.VO_PRIORITYBUFFER_OFF === true
        ) {
          warn('prioritybuffer is OFF')
          resolve()
        } else {
          const timer = setInterval(() => {
            // Allow everything else through after history is done,
            // or if history doesn't fill (brand new empty timeline, since demo no longer runs)
            if (
              (this.historyIsStarted && historyQueue.length === 0) ||
              retries > retryLimit
            ) {
              clearInterval(timer)
              resolve()
            } else {
              retries++
            }
          }, 500)
        }
      })
    }

    this._notify(subsQueue, queue)
  }

  next(queue) {
    const item = this._getQueue(queue, this.channels).pop()
    return item
  }
}

const mq = new MQ()

export default mq
