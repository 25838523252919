import { createSelector } from 'reselect'
import { List } from 'immutable'

const _getSuggestedResponders = (state, { entityID }) =>
  state.suggestedResponders.get(entityID, List())
const _getSelectedOptions = (state, { selectedOptions }) => selectedOptions

export function makeGetSuggestedResponders() {
  return createSelector(
    [_getSuggestedResponders, _getSelectedOptions],
    (suggestedResponders, selectedOptions) =>
      suggestedResponders
        .filterNot(responder =>
          selectedOptions
            .map(option => option.id)
            .includes(responder.get('username'))
        )
        .map(responder => ({
          name: `${responder.get('first_name')} ${responder.get('last_name')}`,
          username: responder.get('username'),
        }))
        .toJS()
  )
}
