import { snakeCase } from 'lodash'
import debug from 'debug'

const error = debug('VO:xhr:error')

function formatError(xhr) {
  const { status, responseText } = xhr

  return { status, responseText }
}

function doXhr(method, url, payload) {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest()

    xhr.open(method, url)

    // @TODO: dis nasty TODO: Can we just test for payload?
    if (method !== 'DELETE' || (method === 'DELETE' && payload)) {
      xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
    }

    xhr.onload = function() {
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          resolve(JSON.parse(xhr.responseText))
        } catch (e) {
          resolve(xhr.responseText)
        }
      } else {
        reject(formatError(xhr))
      }
    }

    xhr.onerror = function() {
      reject(formatError(xhr))
    }

    if (payload) {
      try {
        xhr.send(JSON.stringify(payload))
      } catch (err) {
        error(err)
      }
    } else {
      xhr.send()
    }
  })
}

export function fetch(url) {
  return doXhr('GET', url)
}

export function read(url) {
  return fetch(url) // ???
}

export function create(url, payload) {
  return doXhr('POST', url, payload)
}

export function update(url, payload) {
  return doXhr('PUT', url, payload)
}

export function destroy(url, payload) {
  return doXhr('DELETE', url, payload)
}

export function patch(url, payload) {
  return doXhr('PATCH', url, payload)
}

/**
 * Converts JSON object into a URI compatible query string.
 *
 * @export
 * @param {object} payload
 * @returns string
 *
 * example:
 * payload = { fooBar: 'foo bar', bar: 'bar' }
 * returns '?foo_bar=foo%20bar&bar=bar'
 */
export const serializeJSONtoQuery = payload =>
  `?${Object.keys(payload)
    .map(key => `${snakeCase(key)}=${encodeURIComponent(payload[key])}`)
    .join('&')}`

export const api = {
  create,
  destroy,
  fetch,
  patch,
  read,
  serializeJSONtoQuery,
  update,
}
