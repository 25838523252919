import debug from 'debug'
const error = debug('VO:utils/decodeHtml:error')

export function decodeHtml(encodedHtml) {
  if (!encodedHtml) return ''
  if (typeof document === 'undefined') return encodedHtml
  try {
    const htmlParser = new DOMParser().parseFromString(encodedHtml, 'text/html')
    return htmlParser.documentElement.textContent
  } catch (e) {
    error(e)
  }
}
