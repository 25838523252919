import React, { PureComponent } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAlarmClock,
  faCheck,
  faExternalLink,
  faRetweet,
  faUserPlus,
} from '@fortawesome/fontawesome-pro-regular'

// lib
import timedEventTracker from 'util/timedEventTracker'

// Logging
const phaseActions = new Map([
  ['UNACKED', 'ACK'],
  ['ACKED', 'RESOLVE'],
])
const incidentActionLogger = timedEventTracker('incidents:actions')

export class IncidentActions extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      inActionTransition: false,
    }
    this.handleAction = this.handleAction.bind(this)
  }

  handleAction() {
    const { incidentId, incidentPhase, onActionIncident } = this.props

    // If socket is healthy, log action
    if (window.voSocket.readyState < 2) {
      incidentActionLogger.actionStart(
        incidentId,
        phaseActions.get(incidentPhase)
      )
    }

    this.setState({ inActionTransition: true })

    onActionIncident({ id: incidentId, phase: incidentPhase })
  }

  getPopoutLink = () => {
    const incidentNumber = this.props.incident.get('INCIDENT_NAME', '')

    const url = `https://${window.location.hostname}/ui/${VO_CONFIG.orgslug}/incident/${incidentNumber}/details/`

    return (
      <a
        className='details-action'
        data-ext='incident-details--popout'
        href={url}
        target='_blank'
        title='Go to Incident'
      >
        <FontAwesomeIcon icon={faExternalLink} />
      </a>
    )
  }

  render() {
    const {
      handleAddResponders,
      handleReroute,
      hasMultiResponders,
      hasSnooze,
      incidentId,
      incidentPhase,
      isIncidentDetails,
      isMultiResponder,
      isMuted,
      isStandalone,
      isUpdating,
      onSnooze,
      snoozedUntil,
    } = this.props

    // Log that incident succesfully completed action if it was previously acted upon
    incidentActionLogger.actionComplete(incidentId)

    const actionName = { UNACKED: 'Ack', ACKED: 'Resolve' }[
      incidentPhase || undefined
    ]

    if (incidentPhase !== 'RESOLVED') {
      if (isIncidentDetails) {
        return (
          <div className='details-toolbar--actions'>
            {actionName === 'Ack' ? (
              <div
                className={`details-action ${
                  isUpdating ? 'incident-action-button-updating' : ''
                }`}
                data-ext='incident-details--acknowledge'
                disabled={this.state.inActionTransition}
                onClick={this.handleAction}
                title={'Acknowledge Incident'}
              >
                <FontAwesomeIcon icon={faCheck} /> Acknowledge
              </div>
            ) : (
              <div
                className={`details-action ${
                  isUpdating ? 'incident-action-button-updating' : ''
                }`}
                data-ext='incident-details--resolve'
                disabled={this.state.inActionTransition}
                onClick={this.handleAction}
                title={'Resolve Incident'}
              >
                <span className='fa-layers'>
                  <FontAwesomeIcon icon={faCheck} transform={'right-2'} />
                  <FontAwesomeIcon icon={faCheck} transform={'left-2'} />
                </span>{' '}
                Resolve
              </div>
            )}

            {hasMultiResponders ? (
              <div
                data-ext='incident-details--add-responders'
                onClick={handleAddResponders(incidentId, isMuted)}
                className={`details-action ${
                  isUpdating ? 'incident-action-button-updating' : ''
                }`}
                disabled={this.state.inActionTransition}
                title='Add Responders'
              >
                <FontAwesomeIcon icon={faUserPlus} />
              </div>
            ) : null}

            {isMultiResponder ? null : (
              <div
                className={`details-action ${
                  isUpdating ? 'incident-action-button-updating' : ''
                }`}
                data-ext='incident-details--reroute'
                title={'Reroute Incident'}
                onClick={handleReroute(incidentId, isMuted)}
              >
                <FontAwesomeIcon icon={faRetweet} />
              </div>
            )}

            {!snoozedUntil && hasSnooze && !isMultiResponder && !isMuted ? (
              <div
                className={`details-action ${
                  isUpdating ? 'incident-action-button-updating' : ''
                } sel-snooze-button`}
                data-ext='incident-details--snooze'
                disabled={this.state.inActionTransition}
                onClick={() => onSnooze(incidentId)}
                title='Snooze'
              >
                <FontAwesomeIcon icon={faAlarmClock} />
              </div>
            ) : null}

            {!isStandalone && this.getPopoutLink()}
          </div>
        )
      } else {
        return (
          <div className='incident-actions'>
            <button
              className={`incident-action--${actionName.toLowerCase()}`}
              disabled={this.state.inActionTransition}
              onClick={this.handleAction}
              title={`${actionName} incident`}
            >
              <span className=''>{`${actionName}`}</span>
            </button>

            <div className='incident-action--reroute'>
              <button
                className='incident-action--reroute-btn'
                onClick={handleReroute(incidentId, isMuted)}
              >
                <span>Reroute</span>
              </button>
            </div>
          </div>
        )
      }
    } else if (isIncidentDetails && !isStandalone) {
      return this.getPopoutLink()
    }
    return null
  }
}
