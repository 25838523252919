// vendor
import debug from 'debug'

import { takeEvery, call, put } from 'redux-saga/effects'

// lib
import { fetch } from 'components/__utils/xhr'

import {
  updateIntegrationIcons,
  INTEGRATION_ICON_FETCH,
} from 'components/store/actions'

const error = debug('VO:sagas:error')

export function* watchGetIntegrationIcons() {
  yield takeEvery(INTEGRATION_ICON_FETCH, getIntegrationIcons)
}

export function* getIntegrationIcons() {
  try {
    const tableOfContents = yield call(fetch, `/integrations/logos/toc.json`)
    yield put(updateIntegrationIcons(tableOfContents))
  } catch (err) {
    error(err)
  }
}
