import Backbone from 'lib/backbone'
import _ from 'lib/underscore'
import IncidentUpdates from '../../collections/statuspage/incident_updates'

export default Backbone.Model.extend({
  urlRoot: '/api/v1/org/' + window.VO_CONFIG.orgslug + '/statuspage/incidents',

  idAttribute: 'id',

  defaults: function() {
    return {
      component_ids: [],
      created_at: '',
      created_by: '',
      groups: [],
      // impact: '', // value required if present, but no UI for it.
      message: '',
      monitoring_at: '',
      status: 'investigating', // investigating | identified | monitoring | resolved
      update: '',
      updated_at: '',
      updated_last: '',
    }
  },

  validation: {
    status: {
      oneOf: ['investigating', 'identified', 'monitoring', 'resolved'],
    },
  },

  parse: function(response) {
    var resp = _.clone(response)

    if (response.incident_updates) {
      resp.incident_updates = new IncidentUpdates(response.incident_updates)
    }

    return resp
  },
})
