export const CB_DETAILS_STATES = {
  invalid: -1,
  empty: 0,
  valid: 1,
}

export function checkDetailsValidity(url, phone, notes) {
  if (url.length == 0 && phone.length == 0 && notes.length == 0) {
    return CB_DETAILS_STATES.empty
  }

  // We must have either phone or url (or both) if there are notes
  if (notes.length > 0 && phone.length == 0 && url.length == 0) {
    return CB_DETAILS_STATES.invalid
  }

  // Invalid if http:// or https:// doesn't start the url string
  if (url.length > 0 && /^https?:\/\//.test(url) === false) {
    return CB_DETAILS_STATES.invalid
  }

  return CB_DETAILS_STATES.valid
}
