export const japonica = '#dc7070'
export const crusta = '#f83'
export const voOrange = '#fbb040'
export const cherokee = '#fcd8a3'
export const halfSpanishWhite = '#fef2e0'
export const sprout = '#bfd5a6'
export const japaneseLaurel = 'green'
export const chaletGreen = '#557736'
export const scandal = '#cbf4fa'
export const dodgerBlue = '#38f'
export const ceruleanBlue = '#2659b3'
export const steelBlue = '#4b77be'
export const alabaster = '#f8f8f8'
export const webOrange = '#fa0'
export const white = '#fff'
export const voGoldDark = '#e29e39'
export const voGoldMain = '#fbb040'
export const voGoldLighter = '#fdcf8c'
export const voGoldLighter2 = '#fef7eb'
export const voBlueDark = '#00a7cb'
export const voBlueMain = '#00bce4'
export const voBlueLighter = '#66d6ee'
export const voBlueLighter2 = '#e5f8fc'
export const voGreenDark = '#87ad2e'
export const voGreenMain = '#97c133'
export const voGreenLighter = '#c0d984'
export const voGreenLighter2 = '#f4f8ea'
export const voRedDark = '#d84f2a'
export const voRedMain = '#f1582f'
export const voRedLighter = '#f58a6d'
export const voRedLighter2 = '#fdeeea'
export const voVioletDark = '#4869b5'
export const voVioletMain = '#5175ca'
export const voVioletLighter = '#96acdf'
export const voVioletLighter2 = '#edf1f9'
export const voGrayDark = '#2d2d2d'
export const voGrayMain = '#4d4d4d'
export const voGrayLighter = '#7e7e7e'
export const voGrayLighter2 = '#b9b9b9'
export const voGrayLighter3 = '#d6d6d6'
export const voGrayLighter4 = '#eaeaea'
export const voGrayLighter5 = '#f4f4f4'
export const voGrayLighter6 = '#fafafa'
export const androidGreen = '#a7c83b'
