import _ from 'lodash'
import when from 'when'

import meta from './meta'
var config = window.VO_CONFIG

function _getNeedle() {
  if (config.billing.state === 'paid') {
    return config.billing.tier
  } else {
    return 'trial'
  }
}

// checkPerm :: String -> Boolean
function checkPerm(perm) {
  var accountLevel = config.isAdmin ? 'admin' : 'nonAdmin'

  var _permLookup = {
    nonAdmin: 1,
    admin: 2,
  }

  return _.get(_permLookup, accountLevel, 0) >= _.get(_permLookup, perm, 0)
}

// checkType :: String -> Boolean
function checkType(type) {
  var needle = _getNeedle()

  var _typeLookup = {
    standard: 2,
    enterprise: 3,
    trial: 4,
  }

  return _.get(_typeLookup, needle, 0) >= _.get(_typeLookup, type, 0)
}

function checkBillingVendor(vendor) {
  return config.billing.vendor === vendor
}

function checkBillingPaid(state) {
  return config.billing.state === state
}

function localOverride(feature) {
  /* global Storage, sessionStorage */
  // test features without the feature flag
  return Storage && sessionStorage.getItem(feature) === 'true'
}

var _featureLookup = {
  advancedReporting: function(features) {
    var hasFeature = _.get(features, 'feature:advancedrep', false)
    var hasPerm = checkPerm('nonAdmin')

    return hasFeature && hasPerm
  },

  api: function(features) {
    var hasFeature = _.get(features, 'feature:api', false)
    var hasPerm = checkPerm('admin')

    return hasFeature && hasPerm
  },

  billingModule: function() {
    var hasPerm = checkPerm('admin')

    // trial, not yet paid
    var hasTrial = checkBillingPaid('trial')

    // paid through zuora
    var hasZuora = checkBillingVendor('zuora') && checkBillingPaid('paid')

    return hasPerm && (hasTrial || hasZuora)
  },
  controlCall: function(features) {
    var hasFeature = _.get(features, 'feature:confcall', false)
    var hasPerm = checkPerm('nonAdmin')

    return hasFeature && hasPerm
  },

  devEndpointGeneric: function(features) {
    return _.get(features, 'feature:devendpointgeneric', false)
  },

  devEndpointJenkins: function(features) {
    return _.get(features, 'feature:devendpointjenkins', false)
  },

  devEndpointGithub: function(features) {
    return _.get(features, 'feature:devendpointgithub', false)
  },

  disableIncidentAllActions: function(features) {
    const hasFeature = _.get(
      features,
      'feature:disableincidentallactions',
      false
    )

    return hasFeature
  },

  multiResponders: function(features) {
    const hasFeature = _.get(features, 'feature:multiresponders', false)

    return hasFeature
  },

  nightMode: function(features) {
    return _.get(features, 'feature:nightmode', false)
  },

  outgoingWebhooks: function(features) {
    var hasFeature = _.get(features, 'feature:timelinenotifications', false)
    var hasPerm = checkPerm('admin')

    return hasFeature && hasPerm
  },

  protectResolveAll: function(features) {
    const hasFeature = _.get(features, 'feature:protectresolveall', false)

    return hasFeature
  },

  reactIncidentDetails: function(features) {
    return _.get(features, 'feature:reactincidentdetails', false)
  },

  reactTimeline: function(features) {
    return _.get(features, 'feature:reacttimeline', false)
  },

  servicenow2: function(features) {
    const hasFeature = _.get(features, 'feature:servicenow2', false)

    return hasFeature
  },

  snooze: function(features) {
    const hasFeature = _.get(features, 'feature:snoozeincidents', false)

    return hasFeature
  },

  socketCompression: function(features) {
    const hasFeature = _.get(features, 'feature:socketcompression', false)

    return hasFeature
  },

  statusPage: function(features) {
    var hasFeature = _.get(features, 'feature:statuspageio', false)
    var hasPerm = checkPerm('nonAdmin')

    return hasFeature && hasPerm
  },

  teamAdmin: function(features) {
    return _.get(features, 'feature:teamadmin', false)
  },

  timeBasedPagingPolicies: features =>
    _.get(features, 'feature:timebasedpagingpolicies', false),

  transmogrifier: function(features) {
    var hasFeature = _.get(features, 'feature:annotations', false)
    var hasPerm = checkPerm('nonAdmin')

    return hasFeature && hasPerm
  },

  welcomeModal: function(features) {
    var hasFeature = _.get(features, 'feature:welcome', false)
    var hasPerm = checkPerm('nonAdmin')

    return hasFeature && hasPerm
  },
}

var accountFeatures = when(meta.getOrgMeta()).then(res => res._priv)

// checkFeature :: String -> Promise Boolean | Error
function checkFeature(feature) {
  return when.promise(function(resolve, reject) {
    accountFeatures.then(function(features) {
      var check = _featureLookup[feature]
      if (_.isFunction(check)) {
        resolve(check(features) || localOverride(check.name))
      } else {
        reject(new Error('check:unknown'))
      }
    })
  })
}

export default {
  feature: checkFeature,
  perm: checkPerm,
  type: checkType,
}
