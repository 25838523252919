import { Map as _Map } from 'immutable'

import { TIMELINE_FILTERS_UPDATE } from 'components/store/actions'

export default function timelineMessagesReducer(state = _Map(), action) {
  switch (action.type) {
    case TIMELINE_FILTERS_UPDATE:
      return _updateTimelineFilters(state, action.payload)

    default:
      return state
  }
}

function _updateTimelineFilters(state, payload) {
  return state.merge(payload)
}
