// vendor
import Marionette from 'marionette'
import Backbone from 'backbone'
import $ from 'jquery'

import _ from 'lodash'
import 'backbone.stickit'

// lib
import contact from './contact'
import Loading from './loading'
import data from '../../data'
import interpolate from 'util/interpolate_engagement'
import teamsTeamTpl from 't/rolodex/teams.team.tpl'

const Contact = contact('grouped')
const config = window.VO_CONFIG

export default Marionette.CompositeView.extend({
  initialize: function(options) {
    this.actionList = options.actionList

    // @see: this.render()
    this.collection = new Backbone.Collection()
    this.lazy = data.users

    this.teamState = options.teamsState.findWhere({
      team_slug: this.model.get('GROUP_ID'),
    })
    this.uids = this.model.get('uids')
  },

  // Overrides
  // -------------------------------------------------------------------

  childView: Contact,

  childViewContainer: '.js-team-list',

  childViewOptions: function() {
    return {
      actionList: this.actionList,
      tagName: 'li',
      teamName: this.model.get('GROUP_ID'),
    }
  },

  className: function() {
    var classes = ['rolodex-list-item', 'rolodex-list-item--team']

    if (this.model.id === 'everyone') {
      classes.push('rolodex-list-item--everyone')
    }

    return classes.join(' ')
  },

  events: {
    'click .js-team-header': 'onHeaderClick',
  },

  filter: function(child) {
    // var username = config.auth.user.username;
    // return _.includes(this.uids, child.get('USER_ID')) && child.get('USER_ID') !== username;

    return (
      _.includes(this.uids, child.get('USER_ID')) ||
      _.includes(child.get('oncall'), this.model.id)
    )
  },

  render: function() {
    // If the view persistence indicates "expanded", then use the
    // CompositeView to render. Otherwise, render as an ItemView,
    // which will drop childViews.
    if (this.teamState.get('view_state') === 'expanded') {
      this.$el.removeClass('collapsed')
      this.$el.addClass('expanded')

      // @TODO: wut?
      //
      // This should not be necessary. For some reason, on inital
      // load, ItemView.proto.render(), is rendering the collection
      // and flickering to the correct view later... Very bizarre,
      // and I'm having trouble tracing where/why it would behave
      // this way. As I understand it, ItemView should only be
      // rendering properties of this.model().
      this.collection = this.lazy
      Marionette.CompositeView.prototype.render.apply(this, arguments)
    } else {
      this.$el.removeClass('expanded')
      this.$el.addClass('collapsed')
      this.collection = new Backbone.Collection()
      Marionette.CompositeView.prototype.render.apply(this, arguments)
    }
  },

  serializeData: function() {
    var teamSlug = this.model.get('GROUP_ID')
    var orgSlug = config.auth.org.slug

    return {
      link: '/dash/' + orgSlug + '/#/team/' + teamSlug + '/rotations',
      name: this.model.get('NAME'),
    }
  },

  tagName: 'li',

  template: teamsTeamTpl,

  viewComparator: function(a, b) {
    var oc = this.compareByOnCall(a, b)
    if (oc !== 0) {
      return oc
    }

    var engagement = this.compareByEngagement(a, b)
    if (engagement !== 0) {
      return engagement
    }

    return this.compareByName(a, b)
  },

  // Events
  // -------------------------------------------------------------------

  onRender: function() {
    this.stickit()
  },

  // Custom Event Callbacks
  // -------------------------------------------------------------------

  onHeaderClick: function(e) {
    if ($(e.target).hasClass('rolodex-team-link')) {
      return // bail
    }

    e.preventDefault()

    var prev = this.teamState.get('view_state')
    var next = prev === 'expanded' ? 'collapsed' : 'expanded'

    this.teamState.set('view_state', next)
    this.teamState.save()

    this.render()
  },

  // Custom Comparators
  // -------------------------------------------------------------------

  compareByEngagement: function(a, b) {
    var aEngagement = a.get('ENGAGEMENT') || 0
    var bEngagement = b.get('ENGAGEMENT') || 0

    var aRange = interpolate(aEngagement)
    var bRange = interpolate(bEngagement)

    if (aRange > bRange) {
      return -1
    } else if (aRange < bRange) {
      return 1
    } else {
      return 0
    }
  },

  compareByName: function(a, b) {
    var aLast = a.get('LAST_NAME') || ''
    var bLast = b.get('LAST_NAME') || ''
    var aFirst = a.get('FIRST_NAME') || ''
    var bFirst = b.get('FIRST_NAME') || ''

    var aName = aLast.toLowerCase() + aFirst.toLowerCase()
    var bName = bLast.toLowerCase() + bFirst.toLowerCase()

    return aName.localeCompare(bName)
  },

  compareByOnCall: function(a, b) {
    var team = this.model.get('GROUP_ID')
    var aoc = _.includes(a.get('oncall'), team) ? 1 : 0
    var boc = _.includes(b.get('oncall'), team) ? 1 : 0

    return boc - aoc
  },
})
