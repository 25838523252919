import React, { PureComponent } from 'react'

import { connect } from 'react-redux'

import { CurlRequest } from './curl-request'
import CopyToClipboard from 'components/__utils/copy-to-clipboard'

const mapStateToProps = state => ({
  diFilter: state.timelineFilters.get('insightsQuickFilter'),
  restId: state.integrations.getIn(['rest', 'key'], false),
})

const curlPayload = {
  entity_id: 'ID of the incident',
  message_type: 'critical',
  state_message:
    'This is a more verbose description of the problem that provides more detail to the on-call user.',
}

class blanksSlateForNewTrialUsers extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hasClicked: false,
    }

    this._clickCurl = this._clickCurl.bind(this)
    this._submitCurl = this._submitCurl.bind(this)
  }

  _getCurlString(REST_ENDPOINT_KEY = '', ROUTING_KEY = 'Trial-Example') {
    const host = window.VO_CONFIG.roots['alert-endpoint']
    const url = `https://${host}/integrations/generic/20131114/alert/${REST_ENDPOINT_KEY}/${ROUTING_KEY}`

    const payload = JSON.stringify(curlPayload)
    const curlText = `curl -X POST -d '${payload}' ${url} `

    return <CopyToClipboard icon={'clipboard'} inputText={curlText} />
  }

  _submitCurl() {
    const { restId } = this.props
    const host = window.VO_CONFIG.roots['alert-endpoint']

    const url = `https://${host}/integrations/generic/20131114/alert/${restId}/trial-alert-automated`

    CurlRequest(url, curlPayload)
  }

  _clickCurl() {
    this.setState({ hasClicked: true }, this._submitCurl)
  }

  _getCommand() {
    const { restId } = this.props
    if (restId) {
      return this._getCurlString(restId)
    } else {
      return null
    }
  }

  render() {
    const { hasClicked } = this.state
    const diFilter = this.props.diFilter
    const correctRoom = this.props.roomId !== '*'
    if (correctRoom || diFilter) {
      return null
    }

    return (
      <div className='timeline--blank-slate-wrapper'>
        <section
          className={`timeline--blank-slate ${hasClicked &&
            'timeline--blank-slate-leave'} text-center`}
        >
          <span className='timeline--blank-loader' />

          <h2 className='timeline--blank-heading heading-5'>
            Paste this into your terminal to see a test alert
          </h2>
          <p className='timeline--blank-description text--light'>
            (or <a onClick={this._clickCurl}>click here</a> and we&apos;ll do
            it)
          </p>
          {this._getCommand()}
        </section>
      </div>
    )
  }
}

export const BlanksSlateForNewTrialUsers = connect(mapStateToProps)(
  blanksSlateForNewTrialUsers
)
