import { ALERT_DETAILS, addAlertDetails } from 'components/store/actions'

import { takeEvery, call, put } from 'redux-saga/effects'

// ---------------------------------------------------------------------------

function _alertDetails(api, server) {
  return function*(action) {
    const detailsResponse = yield call(
      [server, server.requestAlert],
      action.payload,
      data => {
        return data
      }
    )

    yield put(
      addAlertDetails(
        detailsResponse.PAYLOAD ? detailsResponse.PAYLOAD : detailsResponse
      )
    )
  }
}

// Watchers
// ---------------------------------------------------------------------------

export function* watchRequestAlertDetails(api, server) {
  yield takeEvery(ALERT_DETAILS, _alertDetails(api, server))
}
