// vendor
import { set, values, some } from 'lodash'

// lib
import frequencyBuffer from 'components/__utils/frequency'

// TODO:
//   Discuss a possible pattern to establish for maintaining server to english
//   translations of data. This `map` seems out of place.
const serverToEnglish = new Map([
  ['UNACKED', 'Triggered'],
  ['ACKED', 'Acknowledged'],
  ['RESOLVED', 'Resolved'],
])

//
export default function(user, notifications) {
  // The backend batches notifications with the "frequency cache", which
  // still feels like a firehose. We're adding another layer of buffering
  // here. It's very aggressive, and will flip to "flood-mode" if you
  // receive more than 1 message every five seconds.
  const config = {
    count: 10,
    floodThresh: 1,
    id: 'incident-notifications',
    time: 5000,
  }

  return (
    frequencyBuffer(notifications.share(), config)
      // Merge all the updates for incidents into a single incident
      .map(function mergeIncidentUpdates(incidents) {
        return (
          incidents

            // Flatten all updates into one large array
            .reduce(function(array, update) {
              return array.concat(update.SYSTEM_ALERT_STATE_LIST)
            }, [])

            // Sequentially merge all the updates into an object
            //    {<incident_id>: <incident>}
            .reduce((merged, update) => {
              return set(merged, update.ENTITY_ID, update)
            }, {})
        )
      })

      .map(function(incidents) {
        const notifications = values(incidents)
          // translate the phase name
          .map(incident => {
            const translatedPhase = serverToEnglish.get(
              incident.CURRENT_ALERT_PHASE
            )

            return Object.assign({}, incident, {
              CURRENT_ALERT_PHASE: translatedPhase,
            })
          })

        if (notifications.length > 3) {
          return { count: notifications.length }
        } else {
          return notifications.filter(incident =>
            some(incident.USERS_PAGED, userPaged => userPaged === user)
          )
        }
      })
  )
}
