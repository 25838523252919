// vendor
import $ from 'jquery'
import Marionette from 'marionette'
import _ from 'lodash'
import 'lib/jquery.cookie'

// lib
import CollectionFlat from './flat.collection'
import CollectionTeams from './teams.collection'
import Loading from './loading'
import RolodexContainer from 't/rolodex/container.tpl'
import Router from '../../app'
import data from '../../data'
import log from 'util/_console'
import teamsStateFactory from '../../data/collections/rolodex/view.teams'
import vent from 'util/vent'

const config = window.VO_CONFIG

export default Marionette.View.extend({
  initialize: function(options) {
    this.subview = options.subview || 'flat'

    this.loading = true

    var $cookie = $.cookie('rolodex')

    if ($cookie) {
      this.subview = $cookie
    } else {
      $.cookie('rolodex', 'flat', { expires: 365 })
    }

    // Expanded/collapsed persistence
    var TeamsState = teamsStateFactory(
      config.orgslug + ':' + config.socketAuth.USER_ID
    )
    this.teamsState = new TeamsState()
    this.teamsState.fetch()

    this.listenTo(
      vent,
      'server:groups:done',
      _.bind(function() {
        log.info('rolodex/container:renderSub')

        this.initTeamsState()

        this.loading = false
        this.renderSub({ which: this.subview })
      }, this)
    )

    this.listenTo(
      vent,
      'actionlist:show',
      _.bind(function(actionList) {
        this.actionList = actionList
        this.renderSub({ which: this.subview })
      }, this)
    )

    this.listenTo(vent, 'actionlist:hide', function() {
      this.actionList = null
      this.renderSub({ which: this.subview })
    })
  },

  // Overrides
  // -----------------------------------------------------------------------

  className: 'pane-inner rolodex-container',

  events: {
    'click .js-rolodex-search-hide': 'onClickHideSearch',
    'click .js-rolodex-search-show': 'onClickShowSearch',
    'click .js-toggle-view': 'onClickToggleView',
    'keyup @ui.searchInput': 'onSearch',
  },

  regions: {
    target: '.js-subview',
  },

  template: RolodexContainer,

  ui: {
    searchInput: '.js-rolodex-search-input',
  },

  // Events
  // -----------------------------------------------------------------------

  onRender: function() {
    if (this.loading) {
      this.showChildView('target', new Loading())
    }
  },

  // Custom Event Callbacks
  // -----------------------------------------------------------------------

  onClickShowSearch: function(e) {
    e.preventDefault()

    this.renderSub({ which: 'flat', hideFilters: true })

    this.$('.js-rolodex-tabs').addClass('hidden')

    this.$('.js-rolodex-search').removeClass('hidden')

    this.$('.js-rolodex-search-input').focus()
  },

  onClickHideSearch: function(e) {
    e.preventDefault()

    this.$('.js-rolodex-search').addClass('hidden')

    this.$('.js-rolodex-tabs').removeClass('hidden')

    this.resetSearch()
  },

  onClickToggleView: function(e) {
    var which = $(e.currentTarget).data('view')

    $.cookie('rolodex', which, { expires: 365 })

    if (this.subview === which) {
      return // bail
    }

    this.subview = which
    this.renderSub({ which: this.subview })
  },

  onSearchDebounced: _.debounce(
    function(e) {
      // defunct
      this.onSearch(e)
    },
    500,
    {
      // 'leading': true,
      // 'trailing': false
    }
  ),

  onSearch: function(e) {
    var searchString = $(e.currentTarget).val()

    var flat // init

    // switch to flat view
    if (this.subview !== 'flat') {
      flat = new CollectionFlat({
        collection: data.users,
      })

      this.showChildView('target', flat)
    }

    const currentView = this.getChildView('target')
    currentView.hideFilters = true // hide filters
    this.filterSub(currentView, searchString)
  },

  onShowPerson: function() {
    var userID = this.model.get('USER_ID')

    Router.router.navigate('person/' + userID, { trigger: true, replace: true })
  },

  // Custom
  // -------------------------------------------------------------------

  resetSearch: function() {
    this.$('.js-rolodex-search-input').val('')

    this.renderSub({ which: this.subview })
  },

  renderSub: function(options) {
    var flat, teams, $toggle // init
    var which = options.which

    if (which === 'flat') {
      flat = new CollectionFlat({
        actionList: this.actionList,
        collection: data.users,
        hideFilters: options.hideFilters,
      })

      $toggle = this.$('[data-view="flat"]')

      $toggle.addClass('current')
      $toggle.siblings().removeClass('current')

      this.showChildView('target', flat)
    } else {
      teams = new CollectionTeams({
        actionList: this.actionList,
        lazy: data.groups,
        teamsState: this.teamsState,
      })

      $toggle = this.$('[data-view="team"]')

      $toggle.addClass('current')
      $toggle.siblings().removeClass('current')

      this.showChildView('target', teams)
    }
  },

  filterSub: function(view, filter) {
    var matchObj = new RegExp('^' + filter.toLowerCase() + '.*')

    view.filter = function(child) {
      var firstName = child.get('FIRST_NAME').toLowerCase()
      var lastName = child.get('LAST_NAME').toLowerCase()
      var userName = child.get('USER_ID').toLowerCase()
      var sources = [firstName, lastName, userName]

      var isMatch = _.some(sources, function(el) {
        return matchObj.test(el)
      })

      // return isMatch && child.get('USER_ID') !== config.auth.user.username;
      return isMatch
    }

    view.render()
  },

  initTeamsState: function() {
    var teams = _.map(data.groups.models, function(group) {
      var isInTeam = _.includes(group.get('uids'), config.auth.user.username)

      return {
        own_team: isInTeam,
        team_slug: group.get('GROUP_ID'),
      }
    })

    this.teamsState.set(teams)

    _.each(this.teamsState.models, function(model) {
      model.save()
    })
  },
})
