// vendor
import _ from 'lodash'

// lib
import isDebug from './isDebug'
import _debug from 'debug'

// Util
// ---------------------------------------------------------------------------

function foldToBool() {
  var args = [].slice.call(arguments)
  var isBools = _.every(args, _.isBoolean)

  // assume false if passed an array that contains non-bools
  //
  // @TODO
  // i'd like to throw an error here, or log something, but given the
  // nature of this utility, it'd have to be abstracted a bit more in
  // order to do so.
  if (!isBools) {
    return false // bail
  }

  return _.every(args)
}

function hasConsole(type) {
  return !(
    typeof console === 'undefined' || typeof console[type] !== 'function'
  )
}

function hasPerformance(type) {
  return !(
    typeof window.performance === 'undefined' ||
    typeof window.performance[type] !== 'function'
  )
}

// Logging
// ---------------------------------------------------------------------------

function consoleBase(type) {
  var fn // init

  if (!foldToBool(isDebug(), hasConsole(type))) {
    return function() {} // bail
  } else {
    fn = console[type]
    return fn.bind(console)
  }
}

function performanceBase(type) {
  var fn

  if (!foldToBool(isDebug(), hasPerformance(type))) {
    return function() {} // bail
  } else {
    fn = window.performance[type]
    return fn.bind(window.performance)
  }
}

// Extra bonus time fun room!!!
// ---------------------------------------------------------------------------

export function compose() {
  var args = [].slice.call(arguments)

  consoleBase('log')(args)

  return args
}

export function state() {
  var log = consoleBase('log')
  var args = [].slice.call(arguments)

  var _stringify = function(list) {
    return _.map(list, function(el) {
      var asAString = JSON.stringify(el)
      var asAFrozenObject // init

      if (_.isUndefined(asAString) || _.isNull(asAString)) {
        return asAString
      } else {
        asAFrozenObject = JSON.parse(asAString)
        return asAFrozenObject
      }
    })
  }

  return log(_stringify(args))
}

export function trace() {
  if (!foldToBool(isDebug(), hasConsole('trace'))) {
    // bail
  } else {
    console.trace()
  }
}

// Export
// -----------------------------------------------------------------------

export const debug = consoleBase('debug')
export const dir = consoleBase('dir')
export const error = _debug('VO:error')
export const info = _debug('VO:info')
export const log = _debug('VO:log')
export const now = performanceBase('now')
export const time = consoleBase('time')
export const timeEnd = consoleBase('timeEnd')
export const warn = _debug('VO:warn')

export default {
  compose: compose,
  state: state,
  trace: trace,
  now: now,

  // logging
  debug: debug,
  dir: dir,
  error: error,
  info: info,
  log: log,
  time: time,
  timeEnd: timeEnd,
  warn: warn,
}
