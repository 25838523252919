import { merge } from 'ramda'

import { info as logInfo } from './_console'

import xlog from './extendedLog.js'

function timedEventTracker(actionName) {
  const events = new Map()

  const timer = {
    actionComplete: function(idForAction) {
      if (!events.has(idForAction)) {
        return
      }

      const payload = merge(events.get(idForAction), {
        endTime: performance.now(),
      })

      logInfo(`page action: ${actionName}, sent with ${payload}`)
      xlog(actionName, payload)

      events.delete(idForAction)
    },

    actionStart: function(idForAction, eventName) {
      const snapShot = {
        startTime: performance.now(),
        event: eventName,
      }

      events.set(idForAction, snapShot)
    },
  }

  return timer
}

export default timedEventTracker
