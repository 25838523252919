import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/fontawesome-pro-solid'
import { voRedDark } from 'style-vars/colors'
import { spaceValue } from 'style-vars/sizes'

const Warning = styled.div`
  margin-top: ${spaceValue / 2}px;
  color: ${voRedDark};

  & .fa-exclamation-triangle {
    margin-right: ${spaceValue / 4}px;
  }
`

export default React.memo(props => {
  const { onCall, selectedOptions } = props

  if (selectedOptions) {
    const selectedUsers = selectedOptions.filter(
      selectedItems => selectedItems.type === 'username'
    )
    const offCallUsers = selectedUsers.filter(
      selectedUser => !onCall.contains(selectedUser.id)
    )

    if (offCallUsers && offCallUsers.length > 0) {
      const userNames = offCallUsers
        .map(offCallUser => offCallUser.label)
        .join(', ')
      const warningText =
        offCallUsers.length === 1
          ? `Warning: ${userNames} is not on-call right now.`
          : `Warning: The following users are not on-call: ${userNames}`

      return (
        <Warning data-ext='reroute__modal__warning'>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          {warningText}
        </Warning>
      )
    }
  }
})
