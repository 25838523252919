import _ from 'lib/underscore'
import Timelines from '../collections/timelines'
import server from '../../server'
import Formatter from './formatter'

// file: timelines.js
// singleton timeline collection

var timelines = new Timelines()

var push = function(type, data, opts) {
  var room = data.ROOM_ID || '*'
  var timeline = timelines.getOrCreate(room)
  var options = opts || {}
  if (data['TEXT']) {
    var txt = data['TEXT']
    var formatter = new Formatter(txt)
    data['TEXT'] = formatter.preformattedText(txt).value()
  }
  if (!_.isUndefined(data.PROVIDER)) {
    options.provider = data.PROVIDER
  }
  if (!_.isUndefined(data.MESSAGE_TYPE)) {
    options.messageType = data.MESSAGE_TYPE
  }
  timeline.push(type, data, options)
}

var pushchat = _.partial(push, 'chat')
var pushalert = _.partial(push, 'alert')
var pushoncall = _.partial(push, 'oncall')
var pushpage = _.partial(push, 'page')
var pushincident = _.partial(push, 'incident')
var pushsuspend = _.partial(push, 'suspend')
var pushresume = _.partial(push, 'resume')
var pushcontrolcall = _.partial(push, 'controlcall')
var push3rdparty = _.partial(push, 'thirdparty')

// when we first come online, we'll get a list of all the timelines
// we've participated in - prepopulate the rooms list, and set the missed flag
// server.on("state:chats", function(data) {
//   _.each(data, function(data) {
//     var timeline = timelines.getOrCreate(data.ROOM_ID);
//     timeline.set("MISSED", data.NUMBER_MISSED);
//   });
// });

// catch incoming data and add it
server.on({
  'notify:chat': pushchat,
  'history:chat': pushchat,
  'notify:alert': pushalert,
  'history:alert': pushalert,
  'notify:oncall': pushoncall,
  'history:oncall': pushoncall,
  'notify:page': pushpage,
  'history:page': pushpage,
  'notify:incident': pushincident,
  'history:incident': pushincident,
  'notify:suspend': pushsuspend,
  'history:suspend': pushsuspend,
  'notify:resume': pushresume,
  'history:resume': pushresume,
  'notify:controlcall': pushcontrolcall,
  'history:controlcall': pushcontrolcall,

  // third party messages...
  'statuspage:incident': push3rdparty,
  'statuspage:component_update': push3rdparty,
})

export default timelines
