import $ from 'lib/jquery'
import _ from 'lib/underscore'

$.textWraps = function($el) {
  var el = $el[0]
  var whiteSpace = window
    .getComputedStyle(el, null)
    .getPropertyValue('white-space')
  var doesWrap

  // TODO: pass in the direction we care about, measure outside of flow

  // clone the $el in the same css scope so it can be rendered invisibly
  // var $clone = $el.clone();

  // $el.after($clone);

  $el.css('white-space', 'nowrap')

  doesWrap = el.scrollWidth > $el.innerWidth()

  $el.css('white-space', whiteSpace)

  return doesWrap
}

$.fn.pasteIntoInput = function(text) {
  var $el = this
  var el = this[0]

  $el.focus()

  if (_.isNumber(el.selectionStart) && _.isNumber(el.selectionEnd)) {
    var val = $el.val()
    var selStart = el.selectionStart

    $el.val(val.slice(0, selStart) + text + val.slice(el.selectionEnd))
    el.selectionEnd = el.selectionStart = selStart + text.length
  } else if (!_.isUndefined(document.selection)) {
    var textRange = document.selection.createRange()

    textRange.text = text
    textRange.collapse(false)
    textRange.select()
  }
}
