import CreateButton from './create-button'
import { connect } from 'react-redux'
import { getFeatureFlags } from 'components/store/selectors'

function mapStateToProps(state) {
  return {
    featureFlags: getFeatureFlags(state),
  }
}

export default connect(mapStateToProps)(CreateButton)
