import React from 'react'
import { connect } from 'react-redux'
import { OrderedMap } from 'immutable'

import { Timeline } from '@victorops/timeline'
import { toJSHOC } from '__utils/immutable-redux'
import {
  flushTimelineMessages,
  requestTimelineMessages,
} from 'components/store/actions'
import { makeGetMessageSequences } from 'components/store/selectors'
import { Row } from './row'
import {
  BlanksSlateForNewTrialUsers,
  DeliveryInsightsBlankSlate,
} from './blank-states'
import { NewDataDisplay } from './new-data-display'
import vent from 'util/vent'

const onMount = () => vent.trigger('timeline:initialized')

const makeMapStateToProps = () => {
  const getMessageSequences = makeGetMessageSequences()

  const mapStateToProps = (state, props) => {
    const { timelines } = state
    const { roomId } = props

    const isIncidentTimeline = roomId.includes('&tag=incident')
    const isUnfilteredTimeline = roomId === '*'
    const mostRecentOldMessage = timelines
      .getIn(['*', 'messages'], OrderedMap())
      .first()
    const mostRecentNewMessage = timelines
      .getIn(['*', 'newMessages'], OrderedMap())
      .first()
    const mostRecentMessage = mostRecentNewMessage || mostRecentOldMessage

    const diIntegrations = state.integrations.filter((value, key) => {
      return key === 'github' || key === 'dev-endpoint' || key === 'jenkins2'
    })
    const hasDeliveryInsightsEnabled =
      state.integrations.size > 0 && diIntegrations.size > 0
    const isTrial = window.VO_CONFIG.billing.state === 'trial'
    const diFilter = state.timelineFilters.get('insightsQuickFilter')
    const BlankState = isTrial
      ? isUnfilteredTimeline
        ? () => <BlanksSlateForNewTrialUsers roomId={roomId} />
        : undefined
      : !hasDeliveryInsightsEnabled && diFilter
      ? DeliveryInsightsBlankSlate
      : undefined

    return {
      BlankState,
      dataIDs: getMessageSequences(state, props),
      dataSetID: roomId,
      hasLastRow: true,
      hasReachedEnd: timelines.getIn([roomId, 'endOfHistory'], false),
      isLoading: timelines.getIn([roomId, 'hasPendingTransaction'], false),
      mostRecentID: mostRecentMessage && mostRecentMessage.get('sequence'),
      newDataCount: timelines.getIn([roomId, 'newMessages'], OrderedMap()).size,
      NewDataDisplay,
      onMount,
      RowRenderer: Row,
      showHiddenDisplays: !isUnfilteredTimeline && !isIncidentTimeline,
    }
  }
  return mapStateToProps
}

const mapDispatchToProps = (dispatch, props) => ({
  fetchMore: lastID =>
    dispatch(
      requestTimelineMessages({
        room: props.roomId,
        sequence: lastID,
      })
    ),
  flushNewData: roomId => dispatch(flushTimelineMessages(roomId)),
})

const _VOTimeline = props => {
  const { BlankState, dataIDs } = props
  const willShowBlankState = dataIDs.length === 0 && BlankState

  return (
    <div className='react-timeline js-timeline-anchor timeline-anchor l-scrollable'>
      <div
        className={`js-timeline ${
          willShowBlankState ? 'timeline-blank-state' : 'timeline'
        }`}
      >
        <Timeline innerListClassName='react-timeline-list' {...props} />
      </div>
    </div>
  )
}

export const VOTimeline = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(toJSHOC(_VOTimeline))
