import moment from 'moment'

export default function processOnCall(timelineMessage) {
  const _timestamp = moment(
    timelineMessage.getIn(['SEQUENCING', 'SERVICE_TIME'])
  ).format('MMM. D - h:mm A')

  return timelineMessage.withMutations(message => {
    message
      .setIn(['componentProps', 'eventType'], 'system')
      .setIn(['componentProps', 'filterType'], 'action-oncall')
      .setIn(
        ['componentProps', 'displayMessage'],
        `${message.get('USER_ID')} is ${message.get(
          'STATE'
        )}-CALL for ${message.get('TEAM_NAME')} : ${message.get('POLICY_NAME')}`
      )
      .setIn(['componentProps', 'timestamp'], _timestamp)
  })
}
