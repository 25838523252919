// vendor
import { curry } from 'lodash'

import { fromJS, Map } from 'immutable'

// lib
import config from 'components/__utils/config'

const user = config.auth.user.username

// Filters
// ---------------------------------------------------------------------------

export function isFlushed(incident, featureFlags) {
  const growlerDisabled = featureFlags
    ? featureFlags.get('feature:disableincidentpanegrowler')
    : false
  return incident.getIn(['viewState', 'isFlushed']) || growlerDisabled
}

export function isPhase(phase, incident) {
  return incident.getIn(['data', 'CURRENT_ALERT_PHASE']) === phase
}

export function isAudience(audience, teams, incident) {
  switch (audience) {
    case 'team':
      return isTeam(teams, incident)
    case 'your':
      return isYour(incident)
    default:
      return true
  }
}

export function isSelectedIncident(incident) {
  return incident.getIn(['viewState', 'isSelected'])
}

export const _isVisibleIncident = curry(function _isVisibleIncident(
  { phase, audience, teams, featureFlags },
  incident
) {
  return (
    isFlushed(incident, featureFlags) &&
    isPhase(phase, incident) &&
    isAudience(audience, teams, incident)
  )
})

// Helpers
// ---------------------------------------------------------------------------

function isYour(incident) {
  const ackUser = incident.getIn(['data', 'ACK_USER'])
  const usersPaged = incident.getIn(['data', 'USERS_PAGED'], user)
  const ackedByUser = () =>
    incident.getIn(['data', 'CURRENT_ALERT_PHASE']) !== 'RESOLVED' &&
    incident.getIn(['data', 'ACK_DATA', 'ACK_USERS'], fromJS([])).includes(user)

  if (ackUser === user || usersPaged.includes(user) || ackedByUser()) {
    return true
  } else {
    return false
  }
}

function isTeam(teams, incident) {
  // The undefined case here means that bad data has been passed to the
  // client from the server, so it would be good to have a count of how
  // often it happens.
  function userInTeam(team) {
    const userIdList = teams.getIn([team, 'USER_ID_LIST']) || Map({})

    return userIdList.includes(user)
  }

  return incident.getIn(['data', 'TEAMS_PAGED']).some(userInTeam)
}
