import { Map as _Map, fromJS } from 'immutable'

import { assign, omit } from 'lodash'

import {
  UPDATE_POLICY_STEPS,
  REMOVE_POLICY_STEP,
} from 'components/store/actions'

export default function policySteps(state = _Map({}), action) {
  switch (action.type) {
    case UPDATE_POLICY_STEPS:
      return _updatePolicySteps(state, action.payload)
    case REMOVE_POLICY_STEP:
      return _removePolicyStep(state, action.payload)

    default:
      return state
  }
}

function _updatePolicySteps(state, payload) {
  const next = payload
    .reduce(function(acc, policy) {
      const steps = policy.groups.map(function(group) {
        return assign({}, omit(group, 'entries'), {
          actions: group.entries.map(entry => entry.id),
        })
      })

      return acc.concat(steps)
    }, [])
    .reduce(function(acc, el) {
      acc[el.id] = el
      return acc
    }, {})

  return state.merge(fromJS(next))
}

function _removePolicyStep(state, payload) {
  return state.delete(payload)
}
