import { call, put, takeLatest } from 'redux-saga/effects'

import config from 'components/__utils/config'
import {
  setServiceNowDetails,
  setServiceNowDetailsError,
  FETCH_SERVICE_NOW_DETAILS,
} from 'components/store/actions'

export const fetchServiceNowDetails = ({ fetch }, error) =>
  function*(action) {
    const {
      payload: { slug, incidentID },
    } = action

    try {
      const data = yield call(
        fetch,
        `/api/v1/org/${config.orgslug}/incidents/integration-details/${slug}`
      )

      const { incidentLink, incidentNumber } = data

      yield put(
        setServiceNowDetails({ incidentLink, incidentNumber, incidentID })
      )
      yield put(setServiceNowDetailsError(false))
    } catch (e) {
      yield call(error, e)
      yield put(setServiceNowDetailsError(true))
    }
  }

export function* watchFetchServiceNowDetails(api, error) {
  yield takeLatest(
    FETCH_SERVICE_NOW_DETAILS,
    fetchServiceNowDetails(api, error)
  )
}
