import React from 'react'
import styled from 'styled-components'

const BannerBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-grow: 6;
`

const ManagementLink = styled.a`
  background-color: $vo-blue-dark;
  color: white;
  text-decoration: none;
  border: 1px solid rgba(255, 255, 255, 0.7); /* stylelint-disable-line plugin/stylelint-no-indistinguishable-colors */
  border-radius: 5px;
  padding: 0.3em 0.6em;
  margin: 0 0.3em;
  font-size: 11px;
  line-height: 100%;
`

function maintenanceHref() {
  const targetUrl = new URL(window.location.href)
  targetUrl.pathname = `/dash/${window.VO_CONFIG.orgslug}`
  targetUrl.hash = '/maintenance-mode'
  return targetUrl.href
}

function BannerLink({ text }) {
  return (
    <BannerBox>
      <ul>
        <li>
          <ManagementLink
            data-test-key='maintenance-mode-banner-button'
            href={maintenanceHref()}
            target={'_blank'}
          >
            {text}
          </ManagementLink>
        </li>
      </ul>
    </BannerBox>
  )
}

export default BannerLink
