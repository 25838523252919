// vendor
import debug from 'debug'

import { Map as iMap } from 'immutable'

import { takeEvery, call, select, put } from 'redux-saga/effects'

// lib
import {
  TIMELINE_MESSAGES_REQUEST,
  setTimelineHasPendingTransaction,
} from 'components/store/actions'

import { getTimeline } from 'components/store/selectors'

const error = debug('VO:sagas:error')

export function watchRequestTimelineHistory(voSocket) {
  return function* watchRequestTimelineHistoryGen() {
    yield takeEvery(
      TIMELINE_MESSAGES_REQUEST,
      _requestTimelineHistory(voSocket)
    )
  }
}

function _requestTimelineHistory(voSocket) {
  function createTimelineRequestMessage(room, sequence) {
    if (sequence && sequence > 0) {
      return {
        MESSAGE: 'TIMELINE_LIST_REQUEST_MESSAGE',
        TRANSACTION_ID: '',
        PAYLOAD: {
          ROOM_ID: room,
          SELECT_BY_SEQUENCE_RANGE: {
            REF_SEQUENCE: sequence - 1,
            LIMIT_SEQUENCE: 0,
            MAGNITUDE: 100,
          },
        },
      }
    } else {
      // likely an incident timeline
      return {
        MESSAGE: 'TIMELINE_LIST_REQUEST_MESSAGE',
        TRANSACTION_ID: '',
        PAYLOAD: {
          ROOM_ID: room,
          SELECT_FROM_END: {
            LIMIT_SEQUENCE: 0,
            MAGNITUDE: 100,
          },
        },
      }
    }
  }

  return function*(action) {
    const timelineData = yield select(getTimeline)
    const chatRoom = timelineData
      ? timelineData.get(action.payload.room)
      : iMap()

    if (
      chatRoom &&
      !chatRoom.get('hasPendingTransaction') &&
      !chatRoom.get('endOfHistory')
    ) {
      try {
        const msg = createTimelineRequestMessage(
          action.payload.room,
          action.payload.sequence
        )
        yield put(
          setTimelineHasPendingTransaction({
            roomId: action.payload.room,
            transactionPending: true,
          })
        )
        yield call(voSocket.send, msg)
      } catch (err) {
        error(err)
      }
    }
  }
}

export const Test = {
  _requestTimelineHistory,
}
