// Breakpoints
export const breakpointS = '608px'
export const breakpointM = '912px'
export const breakpointL = '1312px'
export const supportedWidth = '1200px'

// SPACING
export const spaceValue = 8
export const space = `${space}px` // Standard base sizing. Use for spacing. If you need more do $space*3.

// COMPONENTS
export const headerHeight = '48px'
export const footerHeight = '37px'
export const top = '5000'
export const middle = '1000'
export const bottom = '0'
export const headerZ = '666'
