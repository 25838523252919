var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="js-team-header l-card rolodex-card">\n\n    <div class="rolodex-team-header">\n        <i class="fas fa-chevron-right"></i>\n        <i class="fas fa-chevron-down"></i>\n        <div class="l-card-left">\n            <h2 class="rolodex-team-name">' +
__e( name ) +
'</h2>\n\n            <div class="rolodex-team-link-container">\n                <a class="rolodex-team-link" href="' +
__e( link ) +
'">Schedule</a>\n            </div>\n        </div> <!-- // l-card-left -->\n    </div>\n\n</div> <!-- // l-card -->\n\n<!-- childViewContainer -->\n<ul class="js-team-list rolodex-list--contacts">\n</div>\n';

}
return __p
}