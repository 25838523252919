import server from '../../../../server'
import _ from 'lib/underscore'
import SPComponents from '../../../collections/statuspage/components'

export default SPComponents.extend({
  initialize: function() {
    this.update()
    server.on(
      'statuspage:component_update',
      _.bind(_.debounce(this.update, 1000), this)
    )
  },

  isOkay: function() {
    return this.allClear
  },

  update: function() {
    var self = this
    this.fetch().done(function(resp) {
      if (resp.error) {
        // TODO - UI feedback on certain errors?
        return
      }
      var statuses = self.filter(function(component) {
        return component.get('status') !== 'operational'
      })

      self.allClear = _.isEmpty(statuses)
      self.trigger('statuspage:statechange')
    })
    // noop on fail - server now returns success with error payload, if any
  },
})
