import { Map as _Map, fromJS } from 'immutable'

import { assign } from 'lodash'

import { ROUTES_UPDATE } from 'components/store/actions'

export default function routes(state = _Map({}), action) {
  switch (action.type) {
    case ROUTES_UPDATE:
      return _updateRoutes(state, action.payload)

    default:
      return state
  }
}

function _updateRoutes(state, payload) {
  const next = payload.reduce(function(_routes, route) {
    _routes[route.id] = assign({}, route)

    return _routes
  }, {})
  return state.merge(fromJS(next))
}
