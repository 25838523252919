import React, { Fragment } from 'react'

import Actions from 'components/incidents/view/incidents-table/incident-actions'
import { IncidentIntegrationButtons } from './incident-integration-buttons'
import { IncidentIntegrationContainer } from './incident-integration-displays'
import { Toolbar } from './toolbar-styles'

export const ToolbarView = ({
  activeIntegration,
  incident,
  incidentDetailsSetActiveIntegration,
  integrations,
  isStandalone,
  isUpdating,
}) => {
  // This closes the drawer if the integration has been removed
  if (
    !integrations[activeIntegration] ||
    (!integrations[activeIntegration].linkedID &&
      !integrations[activeIntegration].unlinkedDisplay)
  ) {
    incidentDetailsSetActiveIntegration('')
  }
  return (
    <Fragment>
      <Toolbar>
        <IncidentIntegrationButtons
          activeIntegration={activeIntegration}
          integrations={integrations}
          incidentDetailsSetActiveIntegration={
            incidentDetailsSetActiveIntegration
          }
          incidentPhase={incident.get('CURRENT_ALERT_PHASE', '')}
        />
        <Actions
          isIncidentDetails={'true'}
          incidentId={incident.get('INCIDENT_NAME', '')}
          incidentPhase={incident.get('CURRENT_ALERT_PHASE', '')}
          isMultiResponder={incident.get('IS_MULTI_RESPONDER', false)}
          isStandalone={isStandalone}
          isUpdating={isUpdating}
          snoozedUntil={incident.getIn(['componentProps', 'snoozedUntil'])}
        />
      </Toolbar>

      {activeIntegration && integrations && integrations[activeIntegration] && (
        <IncidentIntegrationContainer
          activeIntegration={activeIntegration}
          incident={incident}
          IntegrationDisplay={integrations[activeIntegration].display}
          UnlinkedIntegrationDisplay={
            integrations[activeIntegration].unlinkedDisplay
          }
          linkedID={integrations[activeIntegration].linkedID}
          incidentPhase={incident.get('CURRENT_ALERT_PHASE', '')}
        />
      )}
    </Fragment>
  )
}
