import React, { memo } from 'react'

import {
  Button,
  Container,
  ButtonContainer,
} from './no-conference-bridge-display-styles'

export const NoConferenceBridgeDisplayView = memo(
  ({ incident, handleAddResponders }) => {
    const incidentId = incident.get('INCIDENT_NAME')
    const isMuted = incident.get('IS_MUTED')

    return (
      <Container data-ext='no-conference-bridge-container'>
        <ButtonContainer>
          <Button
            onClick={handleAddResponders(incidentId, isMuted)}
            data-ext='no-conference-bridge-button'
          >
            Add Conference Bridge
          </Button>
        </ButtonContainer>
      </Container>
    )
  }
)
