// vendor
import Backbone from 'lib/backbone'
import _ from 'lodash'
import $ from 'jquery'

import Group from '../models/group'
import vent from 'util/vent'

var config = window.VO_CONFIG

export default Backbone.Collection.extend({
  initialize: function() {
    this.deferredsCache = {}
    this.deferredLoad = new $.Deferred()

    // this.listenTo(this, 'sort', function() {
    //     log.info('data/collections/users:sort');
    // });

    this.listenTo(
      this,
      'reset',
      _.bind(function(collection) {
        _.each(collection.models, _.bind(this.resolveDeferred, this))
      }, this)
    )

    vent.on(
      'server:groups:done',
      function() {
        this.deferredLoad.resolve(this)
      },
      this
    )
  },

  // Overrides
  // -------------------------------------------------------------------

  comparator: function(a, b) {
    return this.compareByName(a, b)
  },

  model: Group,

  // Comparators
  // -------------------------------------------------------------------

  compareByMyTeam: function(a, b) {
    var amt = _.includes(a.get('uids'), config.auth.user.username) ? 1 : 0
    var bmt = _.includes(b.get('uids'), config.auth.user.username) ? 1 : 0

    return bmt - amt
  },

  compareByName: function(a, b) {
    var aId = a.get('NAME') || ''
    var bId = b.get('NAME') || ''

    var aName = aId.toLowerCase()
    var bName = bId.toLowerCase()

    return aName.localeCompare(bName)
  },

  compareByOnCall: function(a, b) {
    var aoc = _.includes(a.get('oncall'), config.auth.user.username) ? 1 : 0
    var boc = _.includes(b.get('oncall'), config.auth.user.username) ? 1 : 0

    return boc - aoc
  },

  // Custom
  // -------------------------------------------------------------------

  getDeferred: function(id) {
    if (!id) {
      return this.deferredLoad
    }

    if (this.deferredsCache[id]) {
      return this.deferredsCache[id]
    }

    var deferred = new $.Deferred()
    this.deferredsCache[id] = deferred

    return deferred
  },

  resolveDeferred: function(model) {
    var deferred = this.deferredsCache[model.id]

    if (!deferred) {
      deferred = new $.Deferred()
      this.deferredsCache[model.id] = deferred
    }

    if (deferred.state() !== 'resolved') {
      this.deferredsCache[model.id].resolve(model)
    }
  },
})
