// vendor
import Backbone from 'lib/backbone'
import _ from 'lib/underscore'
import { logError } from '../../../util/monitoringService'

// lib

var monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

var tzAbbr = function(dateInput) {
  var matches, err // init
  var dateObject = dateInput || new Date()
  var dateString = dateObject + ''

  // Works for the majority of modern browsers.
  // IE outputs date strings in a different format:
  /* eslint-disable */
  var tzAbbr =
    dateString.match(/\(([^\)]+)\)$/) || dateString.match(/([A-Z]+) [\d]{4}$/)
  /* eslint-enable */

  if (_.isArray(tzAbbr)) {
    // Old Firefox uses the long timezone name (e.g., "Central
    // Daylight Time" instead of "CDT")
    matches = tzAbbr[1].match(/[A-Z]/g)

    if (!_.isNull(matches)) {
      tzAbbr = matches.join('')
    }
  }

  // First seen on: http://stackoverflow.com/a/12496442
  if (!tzAbbr && /(GMT\W*\d{4})/.test(dateString)) {
    return RegExp.$1
  }

  // In the event that we get into some weird null states, just don't
  // return an abbreviation, and log.
  if (_.isNull(tzAbbr)) {
    err = {
      dateInput: dateInput,
      dateObject: dateObject,
      dateString: dateString,
      tzAbbr: tzAbbr,
    }

    logError(err, { groupBy: ['#3865', 'model.helpers:timezone:error'] })

    return ''
  }

  return tzAbbr
}

_.extend(Backbone.Model.prototype, {
  formatTime: function(date, millis) {
    var year = date.getFullYear()
    var month = date.getMonth()
    var day = date.getDate()
    var hour = date.getHours()
    var min = date.getMinutes()
    var sec = date.getSeconds()

    var tz = tzAbbr(date)

    var pad = function(n) {
      return ('0' + n).slice(-2)
    }
    var pad3 = function(n) {
      return ('000' + n).slice(-3)
    }

    var str =
      monthNames[month] +
      ' ' +
      pad(day) +
      ', ' +
      year +
      '  ' +
      pad(hour) +
      ':' +
      pad(min) +
      ':' +
      pad(sec)

    if (millis) {
      var mill = pad3(date.getMilliseconds())
      str = str + '.' + mill
    }

    str = str + ' ' + tz

    return str
  },
})
