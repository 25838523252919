import Backbone from 'lib/backbone'
import _ from 'lib/underscore'
import server from '../../server'
var features = _.extend([], Backbone.Events)

// if a new set of features comes in, drop what we have and load the new set
server.on('state:features notify:features', function(data) {
  _.each(features, function() {
    features.shift()
  })
  _.each(data, function(feature) {
    features.push(feature)
  })
  features.trigger('features:reload')
})

export default features
