// vendor
import Marionette from 'marionette'
import _ from 'lodash'

// lib
import ComponentView from './incident.component'
// import log from 'util/_console'

var NoComponentsView = Marionette.View.extend({
  template: function() {
    return _.template(
      '<div><p>Your StatusPage.io page does not have any components configured.</p></div>'
    )
  },
})

export default Marionette.CollectionView.extend({
  childView: ComponentView,

  emptyView: NoComponentsView,
})
