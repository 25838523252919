import { invert } from 'lodash'

// @TODO: This could also live in the integrations repo

const platformToClient = {
  // 'platform id'         : 'webclient url'
  boundary: 'bmc-truesight-pulse',
  cloudwatch: 'aws-cloudwatch',
  'dynatrace-saas': 'dynatrace',
  'google-voice-email': 'google-voice',
  'jira-email': 'jira',
  jirawebhook: 'jira-webhook',
  'logentries-email': 'logentries',
  'loggly-email': 'loggly',
  pingdom: 'pingdom-email',
  pingdomwebhook: 'pingdom-webhook',
  'prtg-email': 'prtg',
  'rackspace-cloud-mon': 'rackspace',
  uptimerobot: 'uptime-robot',
  'wormly-email': 'wormly',
}

const clientToPlatform = invert(platformToClient)

function translate(type) {
  return id => (type[id] ? type[id] : id)
}

export const translateClientToPlatform = translate(clientToPlatform)
export const translatePlatformToClient = translate(platformToClient)
