/**
 * @file
 * Determine environment
 *
 * @returns {string} the environment;
 */

function getEnvironmentFromLocation() {
  const host = window.location.hostname

  if (host === 'portal.victorops.com') {
    return 'alert.victorops.com'
  } else if (host.split('.')[0] === 'stportal') {
    return 'stalert.victorops.com'
  } else {
    return 'alert.vo.dev'
  }
}

function getEnvironment() {
  let loc // init

  try {
    loc = window.VO_CONFIG.roots['alert-endpoint']
  } catch (err) {
    loc = getEnvironmentFromLocation()
  }

  const environmentLookup = {
    'alert.victorops.com': 'prod',
    'alert.vo.dev': 'dev',
    'stalert.victorops.com': 'stage',
  }

  return environmentLookup[loc]
}

export default getEnvironment()
