import React, { memo } from 'react'

import { Button, Img, Integrations } from './integration-button-styles'

export const IncidentIntegrationButton = ({
  dataExt,
  disabled,
  isActive,
  iconURL,
  onClick,
  title,
}) => (
  <Button
    data-ext={dataExt}
    disabled={disabled}
    isActive={isActive}
    onClick={onClick}
    title={title}
  >
    <Img src={iconURL} />
  </Button>
)

export const IncidentIntegrationButtons = memo(
  ({
    activeIntegration,
    incidentDetailsSetActiveIntegration,
    integrations,
    incidentPhase,
  }) => (
    <Integrations>
      {Object.keys(integrations).map(integration => {
        const {
          linkedID,
          linkedIconURL,
          title,
          unlinkedIconURL,
          unlinkedDisplay,
        } = integrations[integration]

        return (
          <IncidentIntegrationButton
            dataExt={`incident-details--${integration}-button`}
            disabled={!integrations[integration].linkedID && !unlinkedDisplay}
            isActive={integration === activeIntegration}
            iconURL={linkedID ? linkedIconURL : unlinkedIconURL}
            integration={integration}
            key={integration}
            onClick={() =>
              incidentDetailsSetActiveIntegration(
                integration === activeIntegration ? '' : integration
              )
            }
            title={title}
          />
        )
      })}
    </Integrations>
  )
)
