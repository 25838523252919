// vendor
import { Map, fromJS } from 'immutable'

import { assign } from 'lodash'

import { USER_UPDATE, USERS_UPDATE } from 'components/store/actions'

// Reducer
// ---------------------------------------------------------------------------

export default function(state = Map({}), action) {
  switch (action.type) {
    case USER_UPDATE:
      return updateUser(state, action.payload)
    case USERS_UPDATE:
      return updateUsers(state, action.payload)
    default:
      return state
  }
}

// Helpers
// ---------------------------------------------------------------------------

function updateUser(state, payload) {
  const nextState = fromJS({ [payload.USER_ID]: payload })

  return state.merge(nextState)
}

function updateUsers(state, users) {
  const nextState = users.reduce(function(_users, user) {
    // const prev = (state.get(`${user.USER_ID}`) || Map({})).toJS()
    const prev = state.get(`${user.USER_ID}`, Map()).toJS()

    // @TODO: Find a better way to do this.
    //
    // This feels like a weird way to merge multiple objects that'll wind
    // up as an immutablejs Map. @see: http://bit.ly/2dDUfDb
    const next = assign({}, prev, _users[user.USER_ID], user)

    _users[user.USER_ID] = next

    return _users
  }, {})

  return state.merge(fromJS(nextState))
}
