// TODO: look at introducing underscore/lodash string and extending these methods there

export default {
  hasNewlines: function(str) {
    var regex = /[\r\n]+/gm

    return regex.test(str)
  },

  hasTabs: function(str) {
    var regex = /[\t]+/gm

    return regex.test(str)
  },

  isPreformatted: function(str) {
    return this.hasNewlines(str) || this.hasTabs(str)
  },
}
