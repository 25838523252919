import React, { Component } from 'react'

import { connect } from 'react-redux'
import moment from 'moment'

import { showSnoozeForm } from '../incidents/view/incidents-table/incident-actions/snooze/snooze-helpers'

import {
  incidentDetailsRequest,
  updateBackboneContainerRendered,
} from 'components/store/actions'

import {
  getFeatureFlags,
  getIntegrationIcons,
  makeGetIncidentById,
  makeGetIncidentDetails,
} from 'components/store/selectors'

import { ProcessIncident } from 'components/store/reducers/timelines/timelines-helpers/message-preprocessors'

import { Map as iMap } from 'immutable'

import {
  AlertDetails,
  Annotations,
  SelectorTabs,
  PirLink,
  TimelineIncidentCard,
  TimelineProgressLoader,
} from '@victorops/victory'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { decodeHtml as _decodeHtml } from 'components/__utils/html'

import { faTimes } from '@fortawesome/fontawesome-pro-regular'

import TimelineContainer from 'app/views/timeline-container'
import Marionette from 'lib/marionette'

import config from 'components/__utils/config'

import { normalizeString } from 'util/normalize-string'

import RelatedIncidentsTab, { mergeTabOptions } from './related-incidents'
import { Toolbar } from './toolbar'

function mapStateToProps(state, ownProps) {
  const getIncident = makeGetIncidentById()
  const getIncidentDetails = makeGetIncidentDetails(ownProps.incidentId)
  const incidentData = getIncident(state, { id: ownProps.incidentId })

  return {
    annotationsFeature: getFeatureFlags(state).get('feature:annotations'),
    similarIncidentsFeature: getFeatureFlags(state).get(
      'feature:similarincidents'
    ),
    incident: incidentData.get('data', '')
      ? ProcessIncident(incidentData.get('data', ''))
      : iMap({}),
    incidentDetails: getIncidentDetails(state),
    integrationIcons: getIntegrationIcons(state),
    isUpdating: incidentData.get('viewState', '')
      ? incidentData.getIn(['viewState', 'isUpdating'])
      : false,

    timelineOptions: {
      disableFilters: true,
      isPrimary: false,
      reactDomNode: '.js-incident-timeline-react',
      ROOM_ID: ownProps.roomId,
      room: ownProps.roomId,
      subtype: 'incident',
      tags: ['incident' + ownProps.incidentId],
    },
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    containerUpdate: () =>
      dispatch(updateBackboneContainerRendered(Date.now())),
    incidentDetailsRequest: incidentId =>
      dispatch(incidentDetailsRequest(incidentId)),
    onUpdateSnooze: (incidentId, snoozedUntil, snoozedUntilTimestamp) => {
      const modifySnooze = true
      return showSnoozeForm(
        incidentId,
        dispatch,
        modifySnooze,
        snoozedUntil,
        snoozedUntilTimestamp
      )
    },
  }
}

class IncidentDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: props.activeTab || 'alertDetails',
    }

    this.onClick = this.onClick.bind(this)
    this.timelineRegion = {}
  }

  componentDidMount() {
    this.timelineRegion = new Marionette.Region({
      el: '.js-incidentdetails-timeline',
    })
    this.timelineRegion.show(new TimelineContainer(this.props.timelineOptions))
  }

  componentWillUnmount() {
    this.timelineRegion.empty()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.incident.equals(nextProps.incident)) {
      this.props.incidentDetailsRequest(
        parseInt(nextProps.incident.get('INCIDENT_NAME'), 10)
      )
    }

    if (
      nextState.activeTab === 'incidentTimeline' &&
      this.state.activeTab !== 'incidentTimeline'
    ) {
      this.props.containerUpdate()
    }

    return true
  }

  onClick(newState) {
    window.location.hash = `#/incident/${this.props.incidentId}/${newState.activeTab}`
    return this.setState(newState)
  }

  prepareAnnotations(alertJSON) {
    const re = /^(?:details\.)?vo_annotate\.([isu])\.(.*)$/
    const keys = Object.keys(alertJSON).filter(key => {
      return re.test(key)
    })
    return keys.map(key => {
      const annotation = re.exec(key)
      return {
        fieldName: annotation[2],
        fieldValue: _decodeHtml(_decodeHtml(alertJSON[annotation[0]])),
        annotationType: annotation[1],
      }
    })
  }

  getAnnotationsView(annotations, annotationsFeature, monitorType) {
    if (annotations.length) {
      return (
        <div className='incident-details-annotations-scroll-container'>
          <Annotations annotations={annotations} isIncidentDetails='true' />
        </div>
      )
    } else if (monitorType === 'Manual') {
      return (
        <div className='incident-details-no-annotations'>
          <h1>No Annotations</h1>
        </div>
      )
    } else if (
      window.VO_CONFIG.billing.tier === 'enterprise' ||
      annotationsFeature
    ) {
      return (
        <div className='incident-details-no-annotations'>
          <h1>No Annotations</h1>
          <p>There are no annotations for this incident.</p>
          <p>
            You can add runbooks, graphs, and other annotations through{' '}
            <a
              className='app-timeline-link'
              data-ext='app-timeline-link'
              href={`${window.location.origin}/dash/${config.orgslug}/#/alert-rules`}
              target='_blank'
            >
              the Rules Engine
            </a>
            .
          </p>
        </div>
      )
    } else {
      return (
        <div className='incident-details-no-annotations'>
          <h1>No Annotations</h1>
          <p>There are no annotations for this incident.</p>
          <p>
            You can add runbooks, graphs, and other annotations through the{' '}
            <a
              className='app-timeline-link'
              data-ext='app-timeline-link'
              href='https://help.victorops.com/knowledge-base/transmogrifier'
              target='_blank'
            >
              Rules Engine
            </a>{' '}
            with the{' '}
            <a
              className='app-timeline-link'
              data-ext='app-timeline-link'
              href='https://victorops.com/pricing'
              target='_blank'
            >
              Enterprise plan
            </a>
            .
          </p>
        </div>
      )
    }
  }

  getVoAlertJSON() {
    const details = this.props.incidentDetails
    const voFields = details === undefined ? null : details.get('voFields')
    return voFields === null ? null : voFields.toJS()
  }

  getActiveTabComponent(
    alertJSON,
    annotations,
    annotationsFeature,
    monitorType
  ) {
    const { incidentDetails, incidentId } = this.props
    const { activeTab } = this.state

    if (incidentDetails) {
      switch (activeTab) {
        case 'alertDetails':
          return (
            <AlertDetails
              alertJSON={alertJSON}
              annotations={[]}
              isIncidentDetails='true'
              voAlertJSON={this.getVoAlertJSON()}
            />
          )
        case 'alertAnnotations':
          return this.getAnnotationsView(
            annotations,
            annotationsFeature,
            monitorType
          )
        case 'relatedIncidents':
          return <RelatedIncidentsTab incidentId={incidentId} />
        default:
          return null
      }
    }
    return null
  }

  phaseMap = {
    UNACKED: 'Triggered',
    ACKED: 'Acknowledged',
    RESOLVED: 'Resolved',
  }

  getHeaderClasses = () => {
    const detailsState = this.props.incident.getIn([
      'componentProps',
      'snoozedUntil',
    ])
      ? 'SNOOZE'
      : this.props.incident.get('CURRENT_STATE', '')
    const mmMuted = this.props.incident.get('IS_MUTED') ? 'muted-card' : ''

    return `details-header details-${detailsState} ${mmMuted}`
  }

  render() {
    const {
      annotationsFeature,
      similarIncidentsFeature,
      incident,
      integrationIcons,
      isUpdating,
      onUpdateSnooze,
    } = this.props

    const alertJSON =
      this.props.incidentDetails !== undefined
        ? this.props.incidentDetails.get('nonVoFields').toJS()
        : {}
    const annotations = this.prepareAnnotations(alertJSON)
    const monitorType = incident.get('MONITOR_TYPE')
    const normalizedMonitorTool = normalizeString(monitorType)

    const options = [
      {
        label: 'Details',
        id: 'alertDetails',
      },
      {
        label: 'Timeline',
        id: 'incidentTimeline',
      },
      {
        label: `Annotations (${annotations.length})`,
        id: 'alertAnnotations',
      },
    ]
    // hide new tab behind local flag
    // this will be used to demo at .conf
    const flaggedOptions = similarIncidentsFeature
      ? mergeTabOptions(options)
      : options

    return (
      <div className='react-incident-details' data-ext='react-incident-details'>
        <div className={this.getHeaderClasses()}>
          <div className='details-left'>
            <span className='js-incident-pane-close'>
              <FontAwesomeIcon icon={faTimes} size='lg' />
            </span>
            <div className='details-title'>
              Incident #{incident.get('INCIDENT_NAME', '')}{' '}
              {this.phaseMap[incident.get('CURRENT_ALERT_PHASE', '')]}
            </div>
            {PirLink(
              incident.get('INCIDENT_NAME', ''),
              incident.getIn(['componentProps', 'incidentMessage'], ''),
              incident.get('CURRENT_ALERT_PHASE', ''),
              incident.getIn(['componentProps', 'timestamp'], '')
            )}
          </div>
          <div className='details-right'>
            {moment(incident.get('INCIDENT_TIMESTAMP', '')).format('lll')}
          </div>
        </div>

        {incident.size > 0 && (
          <Toolbar incident={incident} isUpdating={isUpdating} />
        )}

        <div id='details-incident-card'>
          {incident.get('INCIDENT_NAME', '') ? (
            <TimelineIncidentCard
              ackData={incident.get('ACK_DATA').toJS()}
              alertCount={incident.get('ALERT_COUNT')}
              annotationCount={incident.get('ANNOTATION_COUNT')}
              currentState={incident.get('CURRENT_STATE', '')}
              host={incident.getIn(['componentProps', 'host'], '')}
              incidentId={incident.get('INCIDENT_NAME', '')}
              incidentMessage={incident.getIn(
                ['componentProps', 'incidentMessage'],
                ''
              )}
              incidentPhase={incident.get('CURRENT_ALERT_PHASE', '')}
              integrationIconPath={integrationIcons.getIn([
                normalizedMonitorTool,
                'icon_url',
              ])}
              integrationName={integrationIcons.getIn([
                normalizedMonitorTool,
                'name',
              ])}
              isIncidentDetails={'true'}
              isMultiResponder={incident.get('IS_MULTI_RESPONDER', false)}
              isMuted={incident.get('IS_MUTED', false)}
              loggedInUser={config.auth.user.username}
              monitorName={incident.get('MONITOR_NAME', '')}
              monitorTool={monitorType}
              monitorType={monitorType}
              onUpdateSnooze={onUpdateSnooze}
              policiesPaged={incident.getIn(
                ['componentProps', 'policiesPaged'],
                ''
              )}
              snoozedUntil={incident.getIn(['componentProps', 'snoozedUntil'])}
              snoozedUntilTimestamp={incident.get('SNOOZED_UNTIL', null)}
              timestamp={incident.getIn(['componentProps', 'timestamp'], '')}
              userAcked={incident.get('ACK_USER', '')}
              usersPaged={incident.getIn(['componentProps', 'usersPaged'], '')}
            />
          ) : (
            <TimelineProgressLoader />
          )}
        </div>

        <SelectorTabs
          options={flaggedOptions}
          activeTab={this.state.activeTab}
          onClick={this.onClick}
        />

        {this.getActiveTabComponent(
          alertJSON,
          annotations,
          annotationsFeature,
          incident.get('MONITOR_TYPE')
        )}
        <div
          className='js-incidentdetails-timeline incidentdetails-filter'
          hidden={this.state.activeTab !== 'incidentTimeline'}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetails)
