// vendor
import Marionette from 'marionette'
import _ from 'lodash'
import $ from 'jquery'

// lib
import Loading from './loading'
import Team from './teams.team'
import data from '../../data'
import log from 'util/_console'
var config = window.VO_CONFIG

export default Marionette.CompositeView.extend({
  // Rendering a large collection blocks the UI pretty horribly, so
  // rather than render a passed collection, we instead render a loading
  // view, then re-render with a different collection later.
  initialize: function(options) {
    this.actionList = options.actionList
    this.firstRender = true
    this.lazy = options.lazy
    this.teamsState = options.teamsState

    if (!this.currentFilter) {
      this.currentFilter = 'teams-all'
    }

    this.debouncedRender = _.debounce(_.bind(this.render, this), 500)

    this.listenTo(options.lazy, 'sort', this.debouncedRender)
  },

  // Overrides
  // -------------------------------------------------------------------

  childView: Team,

  childViewContainer: '.js-rolodex-list-container',

  childViewOptions: function() {
    return {
      actionList: this.actionList,
      teamsState: this.teamsState,
    }
  },

  className: 'rolodex-list rolodex-list--teams',

  emptyView: Loading,

  events: {
    'click .js-rolodex-filters': 'onFilter',
  },

  reorderOnSort: false, // we'll handle this

  tagName: 'div',

  template: require('t/rolodex/teams.collection.tpl'),

  // Events
  // -------------------------------------------------------------------

  onDestroy: function() {
    clearTimeout(this.renderLazyTimer)

    this.debouncedRender.cancel()

    // Re-sort the users collection, as its sort has been mutated by
    // teams.team, which will render in the wrong order for the flat
    // users list.
    data.users.sort()
  },

  onRender: function() {
    // If this is the first time this view is being rendered, then
    // re-render with the real collection. Re-renders should behave
    // correctly on collection sort, as "this.renderLazy()" changes
    // this.collection.
    if (this.firstRender) {
      log.info('rolodex/teams.collection:render')

      this.renderLazyTimer = _.defer(_.bind(this.renderLazy, this))
    }

    this.$('[data-toggle="' + this.currentFilter + '"]').addClass('current')
  },

  // Custom Event Callbacks
  // -------------------------------------------------------------------

  onFilter: function(e) {
    e.preventDefault()

    var $target = $(e.target)

    var which = $target.data('toggle')

    this.currentFilter = which

    switch (which) {
      case 'teams-oncall':
        this.filter = this.filterByOn
        this.render()
        break

      case 'teams-my':
        this.filter = this.filterByMy
        this.render()
        break

      default:
        this.filter = null
        this.render()
        break
    }
  },

  // Custom
  // -------------------------------------------------------------------

  filterByMy: function(child) {
    // Always return "everyone" team, so we can hide it with CSS
    if (child.id === 'everyone') {
      return true
    }

    var username = config.auth.user.username
    var users = child.get('uids')

    return _.includes(users, username)
  },

  filterByOn: function(child) {
    // Always return "everyone" team, so we can hide it with CSS
    if (child.id === 'everyone') {
      return true
    }

    var username = config.auth.user.username
    var users = child.get('oncall')

    return _.includes(users, username)
  },

  renderLazy: function() {
    this.firstRender = false
    this.collection = this.lazy // re-renders will use this

    this.render()
  },
})
