import React from 'react'
import { Growler } from '@victorops/victory'

export const NewDataDisplay = ({ newDataCount, onClick }) => (
  <Growler
    growlerPosition='timeline'
    messageCount={newDataCount}
    scrollFunction={onClick}
  />
)
