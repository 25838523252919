// vendor
import { takeEvery, call, put, select } from 'redux-saga/effects'

// lib
import {
  setRelatedIncidentsLoading,
  updateRelatedIncidents,
  RELATED_INCIDENTS_GET,
} from 'components/store/actions/related-incidents'

import { incidentDetailsToQuery } from './helpers'

import { makeGetIncidentDetails } from 'components/store/selectors'

import { makeHasRelatedIncidents } from 'components/store/selectors/related-incidents'

import config from 'components/__utils/config'

// ---------------------------------------------------------------------------

function getRelatedIncidents(api, logError) {
  return function*(action) {
    const { incidentId } = action.payload

    try {
      const incidentDetails = yield select(makeGetIncidentDetails(incidentId))
      const relatedIncidentsExists = yield select(
        makeHasRelatedIncidents(incidentId)
      )

      if (incidentDetails && !relatedIncidentsExists) {
        yield put(setRelatedIncidentsLoading(true))

        const response = yield call(
          api.fetch,
          `/api/v1/org/${config.orgslug}/ds/similar-incidents/${incidentId}`
        )

        const similarIncidents = response.similarIncidents || []
        yield put(
          updateRelatedIncidents({
            data: similarIncidents,
            incidentId,
          })
        )
        yield put(setRelatedIncidentsLoading(false))
      }
    } catch (err) {
      yield put(setRelatedIncidentsLoading(false))
      yield call(logError, err)
    }
  }
}

export const Test = {
  getRelatedIncidents,
}

// Watchers
// ---------------------------------------------------------------------------

export function* watchGetRelatedIncidents(api, logError) {
  yield takeEvery(RELATED_INCIDENTS_GET, getRelatedIncidents(api, logError))
}
