import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { TimelineProgressLoader } from '@victorops/victory'

import { getIntegrationIcons } from 'components/store/selectors'
import { getRelatedIncidents } from 'components/store/actions/related-incidents'
import { toJSHOC } from '__utils/immutable-redux'
import { normalizeString } from 'util/normalize-string'
import { getFeatureFlags } from 'components/store/selectors'
import { IncidentCard, Related, BlankState } from './components'
import { LoadingContainer } from './components/incident-card-styles'

// helpers for code separation behind the flag
const tabOptions = [
  {
    label: 'Similar Incidents',
    id: 'relatedIncidents',
  },
]

const routerOptions = { relatedIncidents: 'relatedIncidents' }

export const mergeTabOptions = options => options.concat(tabOptions)
export const mergeRouterOptions = options => ({ ...options, ...routerOptions })

const mapStateToProps = (state, props) => {
  const { relatedIncidents } = state
  const { incidentId } = props

  return {
    relatedIncidents: relatedIncidents.getIn([incidentId], []),
    featureFlags: getFeatureFlags(state),
    integrationIcons: getIntegrationIcons(state),
    isLoading: relatedIncidents.get('isLoading'),
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const { incidentId } = props

  return {
    getRelatedIncidents: () => dispatch(getRelatedIncidents({ incidentId })),
  }
}

class RelatedIncidentsTab extends PureComponent {
  componentDidMount() {
    this.props.getRelatedIncidents()
  }

  mapIncidentCards = () => {
    const { featureFlags, integrationIcons, relatedIncidents } = this.props
    const immutableFeatureFlags = fromJS(featureFlags)

    return relatedIncidents.map(incidentContainer => {
      const incident = incidentContainer.incident
      const normalizedMonitorTool = normalizeString(incident.monitorType)
      const integrationIcon = integrationIcons[normalizedMonitorTool]

      return (
        <IncidentCard
          key={incident.incidentId}
          featureFlags={immutableFeatureFlags}
          incident={incident}
          integrationIcon={integrationIcon}
        />
      )
    })
  }

  render() {
    const { mapIncidentCards } = this
    const { isLoading, relatedIncidents } = this.props

    const loadedView =
      relatedIncidents && relatedIncidents.length > 0 ? (
        <Related>{mapIncidentCards()}</Related>
      ) : (
        <BlankState />
      )

    const view = !isLoading ? (
      <div className='related-incidents--wrapper'>
        <div className='related-incidents'>{loadedView}</div>
      </div>
    ) : (
      <div className='related-incidents--wrapper'>
        <div className='related-incidents--loading'>
          <Related>
            <LoadingContainer>
              <TimelineProgressLoader />
            </LoadingContainer>
          </Related>
        </div>
      </div>
    )

    return view
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJSHOC(RelatedIncidentsTab))
