import Backbone from 'lib/backbone'
import _ from 'lib/underscore'
import Incident from '../../models/statuspage/incident'

export default Backbone.Collection.extend({
  url: '/api/v1/org/' + window.VO_CONFIG.orgslug + '/statuspage/incidents',
  model: Incident,

  parse: function(response) {
    if (
      !_.isEmpty(response) &&
      response.PAYLOAD &&
      response.PAYLOAD.INCIDENTS
    ) {
      return response.PAYLOAD.INCIDENTS
    } else {
      return response
    }
  },
})
