var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="profile-leader">\n    <div class="js-profile-pane-close profile-pane-close">\n        <i class="fas fa-times"></i>\n    </div>\n\n    <span class="">@' +
__e( USER_ID ) +
'</span>\n</div>\n\n<div class="profile-header-wrapper">\n    <div  class="js-profile-chat-profile-header l-card profile-pane-header">\n        <div id="js-profile-chat-profile" class="js-profile-chat-profile-content">\n        </div>\n    </div> <!-- // profile-header -->\n</div> <!-- // profile-header-wrapper -->\n\n<div class="profile-chat-wrapper">\n    <!-- <h4>Private chat @' +
__e( USER_ID ) +
'</h4> -->\n\n    <div class="js-profile-chat-wrapper profile-chat">\n        <div class="js-profile-chat-chat profile-chat-content"></div>\n    </div> <!-- // profile-chat -->\n</div>\n';

}
return __p
}