import { call, put, takeLatest } from 'redux-saga/effects'

import config from 'components/__utils/config'
import {
  hideModal,
  fetchConferenceBridgeDetails,
  setConferenceBridgeDetails,
  setConferenceBridgeDetailsError,
  FETCH_CONFERENCE_BRIDGE_DETAILS,
  PUT_CONFERENCE_BRIDGE_DETAILS,
} from 'components/store/actions'

export const fetchConferenceBridgeDetailsSaga = ({ fetch }, error) =>
  function*(action) {
    const {
      payload: { incidentID },
    } = action

    const endpoint = `/api/v1/org/${config.orgslug}/incidents/${incidentID}/conference-bridge`

    try {
      const data = yield call(fetch, endpoint)
      yield put(setConferenceBridgeDetails({ incidentID, ...data }))
      yield put(setConferenceBridgeDetailsError(false))
    } catch (e) {
      yield call(error, e)
      yield put(setConferenceBridgeDetailsError(true))
    }
  }

export const putConferenceBridgeDetailsSaga = ({ update }, error) =>
  function*(action) {
    const {
      payload: { conferenceBridge, incidentId },
    } = action

    const endpoint = `/api/v1/org/${config.orgslug}/incidents/${incidentId}/conference-bridge`

    try {
      const data = yield call(update, endpoint, conferenceBridge)

      yield put(hideModal())
      yield put(fetchConferenceBridgeDetails({ incidentID: incidentId }))
    } catch (e) {
      yield call(error, e)
    }
  }

export function* watchFetchConferenceBridgeDetails(api, error) {
  yield takeLatest(
    FETCH_CONFERENCE_BRIDGE_DETAILS,
    fetchConferenceBridgeDetailsSaga(api, error)
  )
}

export function* watchPutConferenceBridgeDetails(api, error) {
  yield takeLatest(
    PUT_CONFERENCE_BRIDGE_DETAILS,
    putConferenceBridgeDetailsSaga(api, error)
  )
}
