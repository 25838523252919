import debug from 'debug'
import { chunk, curry } from 'lodash'

const log = debug('VO:utils/send-chunks')

// Send
// ---------------------------------------------------------------------------

const sendChunk = curry(function sendChunk(send, messageCreator, chunk) {
  log('sending chunk', chunk)

  send(messageCreator(chunk))

  return Promise.resolve('sent chunk')
})

export const sendChunks = curry(function sendChunks(send, messageCreator, src) {
  const sentMessages = chunk(src, 100).map(sendChunk(send, messageCreator))

  return Promise.all(sentMessages)
})
