// vendor
import { Map, fromJS } from 'immutable'

import { TEAM_UPDATE, TEAMS_UPDATE } from 'components/store/actions'

// Reducer
// ---------------------------------------------------------------------------

export default function(state = Map({}), action) {
  switch (action.type) {
    case TEAM_UPDATE:
      return updateTeam(state, action.payload)
    case TEAMS_UPDATE:
      return updateTeams(state, action.payload)
    default:
      return state
  }
}

// Helpers
// ---------------------------------------------------------------------------

function updateTeam(state, payload) {
  const nextState = fromJS({ [payload.GROUP_ID]: payload })

  return state.merge(nextState)
}

function updateTeams(state, teams) {
  const nextState = teams.reduce(function(_teams, team) {
    _teams[team.GROUP_ID] = team

    return _teams
  }, {})

  return state.merge(fromJS(nextState))
}
