// vendor
import { combineReducers } from 'redux'

// lib
import alerts from '../reducers/alerts'
import backboneContainerRendered from '../reducers/backbone-container-rendered'
import backboneTimelineContainerActive from '../reducers/backbone-timeline-container-active'
import incidentDetails from '../reducers/incident-details'
import incidentRerouting from '../reducers/incident-rerouting'
import incidents from '../reducers/incidents'
import incidentsNav from '../reducers/incidents-nav'
import integrations from '../reducers/integrations'
import integrationIcons from '../reducers/integration-icons'
import maintenance from '../reducers/maintenance-mode'
import manualIncident from '../reducers/manual-incident'
import onCall from '../reducers/oncall'
import orgmeta from '../reducers/orgmeta'
import orgstate from '../reducers/orgstate'
import policies from '../reducers/policies'
import policyActions from '../reducers/policy-actions'
import policySteps from '../reducers/policy-steps'
import relatedIncidents from '../reducers/related-incidents'
import routes from '../reducers/routes'
import suggestedResponders from '../reducers/suggested-responders'
import teams from '../reducers/timeline-teams'
import teamsWithPolicies from '../reducers/teams-with-policies'
import timelineFilters from '../reducers/timeline-filters'
import timelines from '../reducers/timelines'
import uiModal from '../reducers/ui-modal'
import users from '../reducers/timeline-users'

export default combineReducers({
  alerts,
  backboneContainerRendered,
  backboneTimelineContainerActive,
  incidentDetails,
  incidentRerouting,
  incidents,
  incidentsNav,
  integrations,
  integrationIcons,
  maintenance,
  manualIncident,
  onCall,
  orgmeta,
  orgstate,
  policies,
  policyActions,
  policySteps,
  relatedIncidents,
  routes,
  teams,
  teamsWithPolicies,
  timelineFilters,
  timelines,
  suggestedResponders,
  uiModal,
  users,
})
