// vendor
import Backbone from 'lib/backbone'
import _ from 'lib/underscore'

// lib
import users from '../managers/users'

export default Backbone.Model.extend({
  idAttribute: 'GROUP_ID',

  defaults: {
    oncall: [],
    uids: [],
  },

  users: function() {
    return users.filter(
      _.bind(function(u) {
        return _.includes(this.get('uids'), u.id)
      }, this)
    )
  },

  oncall: function() {
    return users.filter(
      _.bind(function(u) {
        return _.includes(this.get('oncall'), u.id)
      }, this)
    )
  },

  setOnCall: function(uid, set) {
    var oncall = this.get('oncall')

    if (set) {
      this.set('oncall', oncall.concat(uid), { silent: true })
    } else {
      this.set('oncall', _.without(oncall, uid), { silent: true })
    }
  },
})
