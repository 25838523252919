import { connect } from 'react-redux'

import { ServiceNowDisplayView } from './service-now-display-view'
import {
  fetchServiceNowDetails,
  setServiceNowDetailsError,
} from 'components/store/actions'

const mapStateToProps = ({ incidentDetails }, ownProps) => {
  const incidentId = ownProps.incident.get('INCIDENT_NAME')
  return {
    error: incidentDetails.get('serviceNowDetailsError'),
    incidentLink: incidentDetails.getIn([
      'serviceNowDetails',
      incidentId,
      'incidentLink',
    ]),
    incidentNumber: incidentDetails.getIn([
      'serviceNowDetails',
      incidentId,
      'incidentNumber',
    ]),
  }
}

const mapDispatchToProps = {
  fetchServiceNowDetails,
  setServiceNowDetailsError,
}

export const ServiceNowDisplayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceNowDisplayView)
