import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/fontawesome-pro-solid'
import { voRedDark } from 'style-vars/colors'
import { spaceValue } from 'style-vars/sizes'

const WarningIconHolder = styled.span`
  color: ${voRedDark};

  & .fa-exclamation-triangle {
    margin-right: ${spaceValue / 4}px;
  }
`

export const SuggestedRespondersView = memo(
  ({
    entityDisplayName,
    entityID,
    fetchSuggestedResponders,
    hostName,
    monitoringTool,
    onSelectedChange,
    responders,
    routingKey,
    selectedOptions,
    onCall,
  }) => {
    useEffect(() => {
      fetchSuggestedResponders({
        entityDisplayName,
        entityID,
        hostName,
        monitoringTool,
        routingKey,
      })
    }, [
      entityDisplayName,
      entityID,
      fetchSuggestedResponders,
      hostName,
      monitoringTool,
      routingKey,
    ])

    const getComma = (i, names) => i !== names.length - 1 && <span>,</span>

    const onClick = responder => {
      return onSelectedChange(
        selectedOptions.concat([
          { id: responder.username, label: responder.name, type: 'username' },
        ])
      )
    }

    const getOffCallWarning = user => {
      if (!onCall.contains(user.username)) {
        return (
          <WarningIconHolder
            title={`Warning: ${user.name} is not on-call right now.`}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </WarningIconHolder>
        )
      }
    }

    const getNames = () => {
      return responders.map((responder, i, responders) => (
        <button
          className='suggested-responders--name'
          onClick={() => onClick(responder)}
          data-ext={`suggested-responders--${responder.username}`}
          key={responder.name}
        >
          {getOffCallWarning(responder)}
          {responder.name}
          {getComma(i, responders)}
        </button>
      ))
    }

    return (
      responders.length > 0 && (
        <div className='suggested-responders'>
          <div>Suggested Responders:</div>
          <div className='suggested-responders--names'>{getNames()}</div>
        </div>
      )
    )
  }
)

SuggestedRespondersView.propTypes = {
  entityID: PropTypes.string.isRequired,
  fetchSuggestedResponders: PropTypes.func.isRequired,
  onSelectedChange: PropTypes.func.isRequired,
  responders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
    })
  ),
  selectedOptions: PropTypes.array.isRequired,
}

SuggestedRespondersView.defaultProps = {
  responders: [],
}
