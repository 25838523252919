import styled from 'styled-components'
import { voBlueDark } from 'style-vars/colors'

export const Button = styled.button`
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 3px 5px;
  color: #4d4d4d;

  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid ${voBlueDark};
    color: ${voBlueDark};
    background: #ececec;
  }
`

export const Container = styled.div`
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`
