function ThirdPartyEvent(data) {
  try {
    var Event = require('../models/third_party/event.' +
      data.PROVIDER.toLowerCase()).default

    data.MESSAGE_BODY.SEQUENCING = data.SEQUENCING

    var eventInstance = new Event(data.MESSAGE_BODY)
    eventInstance.PROVIDER = data.PROVIDER.toLowerCase()
    eventInstance.MESSAGE_TYPE = data.MESSAGE_TYPE.toLowerCase()

    return eventInstance
  } catch (error) {
    require('util/_console').default.error(
      'Problem loading event provider model',
      'data: ' + data
    )
    return false
  }
}

module.exports = ThirdPartyEvent
