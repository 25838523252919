import moment from 'moment'

import { decodeHtml as _decodeHtml } from 'components/__utils/html'

const prepareAnnotations = message => {
  return message
    .get('ANNOTATIONS')
    .toJS()
    .map(annotation => {
      return {
        fieldName: annotation[0],
        fieldValue: _decodeHtml(_decodeHtml(message.get(annotation[1]))),
        annotationType: annotation[2],
      }
    })
}

function _formatAlertTime(timelineMessage) {
  // TODO: should test this...
  const number = Number(timelineMessage.get('VO_ALERT_RCV_TIME'))
  const safeNumber =
    number > 1356998400 && number < 4102444800 ? number * 1000 : number

  return new Date(safeNumber)
}

function _buildAlertfilterType(message) {
  return (
    'timeline-alert-' +
    (message.get('VO_DISPLAY_ALERT_TYPE') || message.get('DISPLAY_ALERT_TYPE'))
  )
}

export default function processAlert(timelineMessage) {
  const annotations = prepareAnnotations(timelineMessage)
  const _timestamp = moment(
    timelineMessage.getIn(['SEQUENCING', 'SERVICE_TIME'])
  ).format('MMM. D - h:mm A')
  const _entityDisplayName = _decodeHtml(
    timelineMessage.get('details.entity_display_name', '')
  )
  const _monitorName = _decodeHtml(
    timelineMessage.get('details.monitor_name', '')
  )
  const _stateMessage = _decodeHtml(
    timelineMessage.get('details.state_message', '')
  )
  const _triggerNameSpace = _decodeHtml(
    timelineMessage.get('details.Trigger.Namespace', '')
  )
  const _triggerRegion = _decodeHtml(timelineMessage.get('details.Region', ''))

  return timelineMessage.withMutations(message => {
    message
      .setIn(['componentProps', 'eventType'], 'alert')
      .setIn(['componentProps', 'alertTime'], _formatAlertTime(message))
      .setIn(['componentProps', 'filterType'], _buildAlertfilterType(message))
      .setIn(['componentProps', 'timestamp'], _timestamp)
      .setIn(['componentProps', 'entityDisplayName'], _entityDisplayName)
      .setIn(['componentProps', 'monitorName'], _monitorName)
      .setIn(['componentProps', 'stateMessage'], _stateMessage)
      .setIn(['componentProps', 'triggerNameSpace'], _triggerNameSpace)
      .setIn(['componentProps', 'triggerRegion'], _triggerRegion)
      .set('annotations', annotations)
  })
}
