export const ADD_SUGGESTED_RESPONDERS = 'ADD_SUGGESTED_RESPONDERS'
export const FETCH_SUGGESTED_RESPONDERS = 'FETCH_SUGGESTED_RESPONDERS'

export const addSuggestedResponders = payload => ({
  payload,
  type: ADD_SUGGESTED_RESPONDERS,
})

export const fetchSuggestedResponders = payload => ({
  payload,
  type: FETCH_SUGGESTED_RESPONDERS,
})
