import { fromJS } from 'immutable'

import { MODAL_HIDE, MODAL_SHOW } from 'components/store/actions'

// Reducer
// ---------------------------------------------------------------------------

const initialState = fromJS({
  modalType: null,
  modalProps: {},
})

export default function(state = initialState, action) {
  switch (action.type) {
    case MODAL_HIDE:
      return initialState
    case MODAL_SHOW:
      return update(state, action.payload)
    default:
      return state
  }
}

function update(state, payload) {
  return fromJS({
    modalType: payload.modalType,
    modalProps: payload.modalProps,
  })
}
