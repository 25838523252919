// vendor
import { createSelector } from 'reselect'

import { List, Map, OrderedMap } from 'immutable'

// lib
import {
  _isVisibleIncident,
  isAudience,
  isFlushed,
  isPhase,
  isSelectedIncident,
} from '../filters'

// ---------------------------------------------------------------------------

const _getOverrides = state => state.overrides
const _getIncidentDetails = state => state.incidentDetails
const _getIncidentPolicyRecipients = state =>
  state.manualIncident.get('createIncidentRecipients').get('policies')
const _getIncidentReroutingState = state => state.incidentRerouting
const _getIncidentTeamRecipients = state =>
  state.manualIncident.get('createIncidentRecipients').get('teams')
const _getIncidentUserRecipients = state =>
  state.manualIncident.get('createIncidentRecipients').get('users')
const _getIncidents = state => state.incidents
const _getIntegrationIcons = state => state.integrationIcons
const _getManualIncident = state => state.manualIncident
const _getManualIncidentCreateStatus = state =>
  state.manualIncident.get('createIncidentFormStatus')
const _getMaintenance = state => state.maintenance
const _getModal = state => state.uiModal
const _getOnCall = state => state.onCall
const _getOrgMeta = state => state.orgmeta
const _getOrgState = state => state.orgstate
const _getPolicies = state => state.policies
const _getPolicyActions = state => state.policyActions
const _getPolicySteps = state => state.policySteps
const _getRoutes = state => state.routes
const _getTeams = state => state.teams
const _getTeamsWithPolicies = state => state.teamsWithPolicies
const _getTimeline = state => state.timelines
const _getUserStatus = state => state.userStatus
const _getUsers = state => state.users
const _getVisibilityFilter = state => state.incidentsNav

// ---------------------------------------------------------------------------

export const getTeams = createSelector([_getTeams], state => {
  return state
})

export const getTimeline = createSelector([_getTimeline], state => state)

export const getUsers = createSelector([_getUsers], state => state)

export const getIncidentPolicyRecipients = createSelector(
  [_getIncidentPolicyRecipients],
  state => state
)

export const getIncidentUserRecipients = createSelector(
  [_getIncidentUserRecipients],
  state => state
)

export const getIncidentTeamRecipients = createSelector(
  [_getIncidentTeamRecipients],
  state => state
)

export const getOrgMeta = createSelector([_getOrgMeta], state => state)

export const getOnCall = createSelector([_getOnCall], state => state)

export const getUserStatus = createSelector([_getUserStatus], state => state)

export const getIncidentReroutingState = createSelector(
  [_getIncidentReroutingState],
  state => state
)

export const getUsersByTeam = createSelector(
  [_getManualIncident],
  manualIncident =>
    manualIncident.getIn(['createIncidentRecipients', 'usersByTeam'])
)

export const getPoliciesByTeam = createSelector(
  [_getManualIncident],
  manualIncident =>
    manualIncident.getIn(['createIncidentRecipients', 'policiesByTeam'])
)

export const getSort = createSelector(
  [_getVisibilityFilter],

  visibilityFilter => {
    const { sort } = visibilityFilter.toJS()

    return sort
  }
)

export const getMICSelected = createSelector(
  [getIncidentPolicyRecipients, getIncidentUserRecipients],
  (policies, users) => {
    return {
      policies,
      users,
    }
  }
)

export const getVisibleIncidents = createSelector(
  [_getVisibilityFilter, _getIncidents, _getTeams, _getOrgMeta],

  (visibilityFilter, incidents, teams, orgMeta) => {
    const { phase, audience } = visibilityFilter.toJS()
    const featureFlags = orgMeta.get('features')

    const config = {
      audience: audience,
      featureFlags: featureFlags,
      phase: phase,
      teams: teams,
    }

    const isVisibleIncident = _isVisibleIncident(config)

    // @TODO: Investigate the appropriate structure to return
    //
    // Returning a Seq causes React to throw some warnings.
    // A transformation later, and React is quiet. Calling `entrySeq` on
    // this Seq at a later time has the desired effect, though the element
    // returned is a tuple.
    //
    // @see: https://github.com/facebook/immutable-js/issues/667
    return incidents.toSeq().filter(isVisibleIncident)
  }
)

export const getPoliciesPaged = incident => {
  const policiesData = incident.getIn(['data', 'POLICIES_PAGED'])
  if (policiesData) {
    const formattedPoliciesPaged = policiesData.map(function(pagedPolicy) {
      return `${pagedPolicy
        .get('TEAM')
        .get('NAME')}: ${pagedPolicy.get('POLICY').get('NAME')}`
    })
    return formattedPoliciesPaged.sort().join(', ')
  }
}

export const getActiveAudience = createSelector(
  [_getVisibilityFilter],
  visibilityFilter => visibilityFilter.get('audience')
)

export const getActivePhase = createSelector(
  [_getVisibilityFilter],
  visibilityFilter => visibilityFilter.get('phase')
)

export const getNonVisibleIncidentCount = createSelector(
  [_getVisibilityFilter, _getIncidents, _getTeams, _getOrgMeta],

  (visibilityFilter, incidents, teams, orgMeta) => {
    const { phase, audience } = visibilityFilter.toJS()
    const featureFlags = orgMeta.get('features')

    return incidents
      .toSeq()
      .filter(function filterIncidents(incident) {
        return (
          !isFlushed(incident, featureFlags) &&
          isPhase(phase, incident) &&
          isAudience(audience, teams, incident)
        )
      })
      .count()
  }
)

export const getCounts = createSelector(
  [_getVisibilityFilter, _getIncidents, _getTeams],

  (visibilityFilter, incidents, teams) => {
    const { audience } = visibilityFilter.toJS()

    return incidents
      .toSeq()
      .filter(function filterIncidents(incident) {
        return isAudience(audience, teams, incident)
      })
      .reduce(function filterIncidents(counts, incident) {
        const phase = incident.getIn(['data', 'CURRENT_ALERT_PHASE'])

        if (phase !== 'RESOLVED') {
          counts = counts.update('UNRESOLVED', count => count + 1)
        }

        return counts.update(phase, count => count + 1)
      }, Map({ UNRESOLVED: 0, UNACKED: 0, ACKED: 0, RESOLVED: 0 }))
  }
)

export const getPhaseCount = createSelector(
  [_getVisibilityFilter, _getIncidents, _getTeams, _getOrgMeta],

  (visibilityFilter, incidents, teams, orgMeta) => {
    const { audience, phase } = visibilityFilter.toJS()
    const featureFlags = orgMeta.get('features')

    const config = {
      audience: audience,
      featureFlags: featureFlags,
      phase: phase,
      teams: teams,
    }

    const isVisibleIncident = _isVisibleIncident(config)

    return incidents
      .toSeq()
      .filter(isVisibleIncident)
      .count()
  }
)

export const getSelectedIncidentIds = createSelector(
  [_getVisibilityFilter, _getIncidents, _getTeams],

  (visibilityFilter, incidents, teams) => {
    const { phase, audience } = visibilityFilter.toJS()

    const config = {
      audience: audience,
      phase: phase,
      teams: teams,
    }

    const isVisibleIncident = _isVisibleIncident(config)

    return incidents
      .toSeq()
      .filter(isVisibleIncident)
      .filter(isSelectedIncident)
      .map(function toSelectedIncidentIds(incident) {
        return incident.getIn(['data', 'INCIDENT_NAME'])
      })
  }
)

export const getModalProps = createSelector([_getModal], modal =>
  modal.get('modalProps').toJS()
)

export const getModalType = createSelector([_getModal], modal =>
  modal.get('modalType')
)

export const getFeatureFlags = createSelector([_getOrgMeta], meta =>
  meta.get('features')
)

export const getIncidentCreateErrorStatus = createSelector(
  [_getManualIncidentCreateStatus],
  err => (err ? err.get('status') : '')
)

export const getIncidentCreateErrorText = createSelector(
  [_getManualIncidentCreateStatus],
  err => (err ? err.get('responseText') : '')
)

export const getTeamsWithPolicies = createSelector(
  [_getTeamsWithPolicies],
  teamsWithPolicies => {
    return teamsWithPolicies.sortBy((_, key) => {
      return key
    })
  }
)

// @TODO: This needs to be a Set
// We don't want duplicate entries in this data.
export const getTeamsWithPoliciesFlat = createSelector(
  [_getTeamsWithPolicies],
  teamsWithPolicies => {
    return teamsWithPolicies
      .toSeq()
      .reduce(function(policies, team) {
        return policies.concat(team.get('_policies'))
      }, List())
      .toMap()
      .mapEntries(([_, v]) => [v.get('slug'), v])
  }
)

export const getPolicies = createSelector(
  [_getPolicies, _getPolicySteps, _getPolicyActions],

  // @TODO: This is gnarly
  // Might need to revisit how this data is shaped. It seems kind of silly
  // to store it atomically, only to stitch it back together again.
  // Normalizr will handle this case beautifully
  (_policies, _steps, _actions) => {
    const _reducer = data => {
      return function(acc, id) {
        const item = data.get(`${id}`)

        return item ? acc.concat([item]) : acc
      }
    }

    const _mapper = (fn, key, _data) => {
      return _data.map(function(item) {
        const next = item.get(`${key}`).reduce(fn, List())

        return item.set(`${key}`, next)
      })
    }

    const steps = _mapper(_reducer(_actions), 'actions', _steps)

    return _mapper(_reducer(steps), 'steps', _policies)
  }
)

export const getOverrideAssignments = createSelector([_getOverrides], state => {
  return state.entities.assignments
})

export const getIntegrationIcons = createSelector(
  [_getIntegrationIcons],
  state => state
)

export const getOverrides = createSelector([_getOverrides], _overrides => {
  const overrides = _overrides.entities.overrides
  const result = _overrides.result

  if (!overrides) return null
  return result.map(id => overrides.get(`${id}`))
})

export const getUsersGroupedByTeam = createSelector(
  [_getTeams, _getUsers],
  (teams, users) => {
    return teams
      .map(function(team) {
        return Map({
          label: team.get('NAME'),
          items: team
            .get('USER_ID_LIST')
            .map(function(user) {
              const _user = users.get(user, Map())

              return Map({
                id: user,
                label: `${_user.get('FIRST_NAME')} ${_user.get('LAST_NAME')}`,
                roles: _user.get('ROLES'),
                type: 'username',
              })
            })
            .filter(user => !(user.get('roles') || []).includes('stakeholder'))
            // globally, we want to sort users by firstnamelastname, all lowercase
            .sortBy(user =>
              user
                .get('label')
                .replace(/\s/g, '')
                .toLowerCase()
            )
            .toList(),
        })
      })
      .sortBy(team => team.get('label').toLowerCase())
      .toList()
  }
)

export const getPoliciesGroupedByTeam = createSelector(
  [getTeamsWithPolicies],
  teamsWithPolicies => {
    return teamsWithPolicies
      .map(function(team, key) {
        return Map({
          label: team.get('name'),
          id: key,
          items: team
            .get('_policies')
            .map(function(policy) {
              return Map({
                id: policy.get('slug'),
                label: policy.get('name'),
                pillLabel: `${team.get('name')} : ${policy.get('name')}`,
                type: 'policy',
              })
            })
            .sortBy(policy => policy.get('label').toLowerCase())
            .toList(),
        })
      })
      .sortBy(team => team.get('label').toLowerCase())
      .toList()
  }
)

export const getPickerOptions = createSelector(
  [getUsersGroupedByTeam, getPoliciesGroupedByTeam],
  (users, policies) => {
    return {
      items: [
        {
          id: 'escalations',
          label: 'Escalation Policies',
          leader:
            'The currently on-call user will be paged for selected escalation policies',
          items: policies.toJS(),
        },
        {
          id: 'users',
          label: 'Users',
          leader: 'Page users by executing their personal paging policy',
          items: users.toJS(),
        },
      ],
    }
  }
)

// Parameterized selectors / Selector makers
// ---------------------------------------------------------------------------
// @see https://github.com/reactjs/reselect#accessing-react-props-in-selectors

const _getFeatureFlag = (state, { id }) => {
  return { state: state.orgmeta.get('features'), id: id }
}
const _getIncidentById = (state, { id }) => {
  return state.incidents.get(id) ? state.incidents.get(id) : Map({})
}
const _getIncidentPolicyRecipient = (state, { id }) =>
  state.manualIncident
    .get('createIncidentRecipients')
    .get('policies')
    .get(id)
const _getIncidentTeamRecipient = (state, { id }) =>
  state.manualIncident
    .get('createIncidentRecipients')
    .get('teams')
    .get(id)
const _getIncidentUserRecipient = (state, { id }) =>
  state.manualIncident
    .get('createIncidentRecipients')
    .get('users')
    .get(id)
const _isUserOnCall = (state, { id }) =>
  state.onCall.find(v => v.includes(id)) !== undefined
const _getIntegrations = state => state.integrations

const _getMessages = (state, { roomId }) =>
  state.timelines.getIn([roomId, 'messages'], OrderedMap())
const _getUsersPaged = (state, incidentId) =>
  state.incidents.getIn([incidentId, 'data', 'USERS_PAGED'])
const _getUsersAcked = (state, incidentId) =>
  state.incidents.getIn([incidentId, 'data', 'ACK_DATA', 'ACK_USERS'])

export const _getInvolvedPolicies = (state, incidentId) =>
  state.incidents.getIn([incidentId, 'data', 'POLICIES_PAGED'])

export const getUninvolvedUsersGroupedByTeam = createSelector(
  [_getTeams, _getUsersPaged, _getUsersAcked, _getUsers],
  (teams, usersPaged, usersAcked, users) => {
    const involvedUsers = usersPaged.concat(usersAcked)
    const unInvolvedUsers = users.filterNot((_, key) =>
      involvedUsers.includes(key)
    )

    return teams
      .map(function(team) {
        return Map({
          label: team.get('NAME'),
          items: team
            .get('USER_ID_LIST')
            .filter(user => unInvolvedUsers.has(user))
            .map(function(user) {
              const _user = users.get(user, Map())

              return Map({
                id: user,
                label: `${_user.get('FIRST_NAME')} ${_user.get('LAST_NAME')}`,
                roles: _user.get('ROLES'),
                type: 'username',
              })
            })
            .filter(user => !(user.get('roles') || []).includes('stakeholder'))
            // globally, we want to sort users by firstnamelastname, all lowercase
            .sortBy(user =>
              user
                .get('label')
                .replace(/\s/g, '')
                .toLowerCase()
            )
            .toList(),
        })
      })
      .sortBy(team => team.get('label').toLowerCase())
      .toList()
  }
)

export const getUninvolvedPoliciesGroupedByTeam = createSelector(
  [getTeamsWithPolicies, _getInvolvedPolicies],
  (teamsWithPolicies, involvedPolicies) => {
    const involvedPoliciesSlugs = involvedPolicies.map(policy =>
      policy.getIn(['POLICY', 'SLUG'])
    )

    return teamsWithPolicies
      .map(function(team, key) {
        return Map({
          label: team.get('name'),
          id: key,
          items: team
            .get('_policies')
            .map(function(policy) {
              return Map({
                id: policy.get('slug'),
                label: policy.get('name'),
                pillLabel: `${team.get('name')} : ${policy.get('name')}`,
                type: 'policy',
              })
            })
            .filterNot(policy =>
              involvedPoliciesSlugs.includes(policy.get('id'))
            )
            .sortBy(policy => policy.get('label').toLowerCase())
            .toList(),
        })
      })
      .sortBy(team => team.get('label').toLowerCase())
      .toList()
  }
)

export function makeGetFilteredPickerOptions() {
  return createSelector(
    [getUninvolvedUsersGroupedByTeam, getUninvolvedPoliciesGroupedByTeam],
    (users, policies) => {
      return {
        items: [
          {
            id: 'escalations',
            label: 'Escalation Policies',
            leader:
              'The currently on-call user will be paged for selected escalation policies',
            items: policies.toJS(),
          },
          {
            id: 'users',
            label: 'Users',
            leader: 'Page users by executing their personal paging policy',
            items: users.toJS(),
          },
        ],
      }
    }
  )
}

export function makeGetIncidentDetailsIntegrations(availableIntegrations) {
  return createSelector([_getIntegrations], integrations =>
    availableIntegrations
      .filter((_, integration) => integrations.has(integration))
      .toJS()
  )
}

export function makeGetMessageSequences() {
  return createSelector([_getMessages], messages => {
    return messages.keySeq().toArray()
  })
}

export function makeGetFeatureFlag() {
  return createSelector([_getFeatureFlag], ({ state, id }) => {
    if (Storage && sessionStorage.getItem(id)) {
      if (sessionStorage.getItem(id) === 'true') {
        return true
      } else if (sessionStorage.getItem(id) === 'false') {
        return false
      }
    }

    return state.get(id)
  })
}

export function makeGetIncidentById() {
  return createSelector([_getIncidentById], state => {
    return state
  })
}

export function makeGetPreviousRoutes() {
  return createSelector([_getIncidentById, _getUsers], (incident, users) => {
    const policiesPaged = incident
      .getIn(['data', 'POLICIES_PAGED'])
      .filter(policy => {
        return !policy
          .getIn(['POLICY', 'SLUG'], '')
          .includes('directUserPolicySlug')
      })
    const usersPaged = incident.getIn(['data', 'USERS_PAGED'])

    const _policies = policiesPaged
      .map(policy => ({
        id: policy.getIn(['TEAM', 'SLUG']),
        label: policy.getIn(['TEAM', 'NAME']),
        pillLabel: `${policy.getIn(['TEAM', 'NAME'])} : ${policy.getIn([
          'POLICY',
          'NAME',
        ])}`,
        type: 'policy',
      }))
      .toList()

    const _users = usersPaged
      .map(user => users.get(user))
      .filterNot(user => user === undefined)
      .map(user => ({
        id: user.get('USER_ID'),
        label: `${user.get('FIRST_NAME')} ${user.get('LAST_NAME')}`,
        type: 'username',
      }))
      .toList()

    return _policies.concat(_users)
  })
}

export function makeGetIncidentTeamRecipient() {
  return createSelector([_getIncidentTeamRecipient], state => state)
}

export function makeGetIncidentPolicyRecipient() {
  return createSelector([_getIncidentPolicyRecipient], state => state)
}

export function makeGetIncidentUserRecipient() {
  return createSelector([_getIncidentUserRecipient], state => state)
}

export function makeIsUserOnCall() {
  return createSelector([_isUserOnCall], state => state)
}

export function makeGetIncidentDetails(incidentId) {
  return createSelector([_getIncidentDetails], state => {
    return state.get(incidentId)
  })
}

export function makeGetMaintenance() {
  return createSelector([_getMaintenance], state => {
    return state
  })
}

export function makeGetRoutesWithPolicies() {
  return createSelector([_getRoutes], routes => {
    const routesOptionsList = routes
      .reduce((acc, route) => {
        const sublabels = route
          .get('targets')
          .map(target => {
            return `${target.get('teamName')} : ${target.get('policyName')}`
          })
          .sort((a, b) => {
            return a - b
          })

        if (route.get('routeKey') === '.+') {
          return acc
        }

        const routeShape = Map({
          id: `${route.get('id')}`,
          label: route.get('routeKey'),
          sublabels: sublabels,
          type: 'key',
        })

        return acc.push(routeShape)
      }, List([]))
      .sortBy(route => route.get('label').toLowerCase())

    return routesOptionsList.toJS()
  })
}
