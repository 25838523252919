// vendor
import Backbone from 'lib/backbone'
import LocalStorage from 'lib/backbone.localstorage'

// Model
// -----------------------------------------------------------------------

var Filter = Backbone.Model.extend({
  idAttribute: 'className',
})

// Collection
// -----------------------------------------------------------------------

export default function(slug) {
  return Backbone.Collection.extend({
    model: Filter,

    localStorage: new LocalStorage(slug + ':timeline-filters'),
  })
}
