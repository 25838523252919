// vendor
var _ = require('lib/underscore')

// lib
var log = require('util/_console').default
var logPerformance = require('@victorops/utils').logPerformance
var xlog = require('util/extendedLog').default

var fixControlCallMsg = function(data) {
  return data['CONF_CALL']
}

// message type lookup
var messagetrans = {
  START_OF_SERIIES: function() {
    return [['protocol:series']]
  },
  MASTER_DATA_VERSION: function(data) {
    return [['protocol:version', data.JS_VERSION]]
  },
  ALERT_NOTIFY_MESSAGE: function(data) {
    return [['notify:alert', data.PAYLOAD.ALERT]]
  },
  CHAT_NOTIFY_MESSAGE: function(data) {
    return [['notify:chat', data.PAYLOAD.CHAT]]
  },
  ONCALL_NOTIFY_MESSAGE: function(data) {
    return [['notify:oncall', data.PAYLOAD.ONCALL]]
  },
  INCIDENT_NOTIFY_MESSAGE: function(data) {
    return [['notify:incident', data.PAYLOAD.INCIDENT]]
  },
  ENTITY_STATE_NOTIFY_MESSAGE: function(data) {
    return [['notify:incidents', data.PAYLOAD]]
  },
  PAGING_NOTIFY_MESSAGE: function(data) {
    return [['notify:page', data.PAYLOAD.PAGE]]
  },
  SYSTEM_ALERT_SUSPENDED_NOTIFY: function(data) {
    return [['notify:suspend', data.PAYLOAD.SYSTEM_ALERT_SUSPENDED]]
  },
  SYSTEM_ALERT_RESUMED_NOTIFY: function(data) {
    return [['notify:resume', data.PAYLOAD.SYSTEM_ALERT_RESUMED]]
  },
  CONF_CALL_STATE_NOTIFY: function(data) {
    return [['notify:controlcall', fixControlCallMsg(data.PAYLOAD)]]
  },
  FEATURE_LIST: function(data) {
    return [['notify:features', data.PAYLOAD.FEAURE_LIST]]
  },

  // timeline aggregation - this gets treated as history response
  TIMELINE_LIST_REPLY_MESSAGE: function(data) {
    // this event is start()ed only one time in server/server.js
    // only the first stop() called on this eventName will take effect, so this call is safe
    logPerformance.stop(xlog, {
      eventName: 'LoadTimelineMessages',
      itemCount: data.PAYLOAD.TIMELINE_LIST.length,
    })

    var evs = []
    _.each(data.PAYLOAD.TIMELINE_LIST, function(data) {
      var history = MessageProtocol.prototype.translate_history(data)
      _.each(history, function(h) {
        evs.splice(0, 0, h)
      })
    })

    return evs
  },

  STATE_NOTIFY_MESSAGE: function(data) {
    return statetrans(data.PAYLOAD)
  },
  THIRD_PARTY_NOTIFY: function(data) {
    return thirdPartyTrans(data.PAYLOAD.THIRD_PARTY_MESSAGE)
  },
}

// map third party messages to an appropriate provider
var thirdPartyTrans = function(data) {
  switch (data.PROVIDER.toLowerCase()) {
    case 'status_page_io_provider':
      try {
        var spioProvider = require('./protocols/thirdparty/status_page_io_provider').default.getInstance()
        return [].concat(spioProvider.getHandlers(data))
      } catch (err) {
        log.warn('Error processing third-party websocket message: ' + err)
        return []
      }
    default:
      return [['devendpoint:incident', data]]
  }
}

// map from state notify messages to [[event, data], ...]
var statetrans = function(data) {
  var handlers = []

  // need all userIds lowercased
  var fixUserIdList = function(data) {
    var outData = _.clone(data)
    if (outData) {
      for (var i = 0; i < outData.length; i++) {
        var userList = outData[i].USER_ID_LIST
        for (var j = 0; j < userList.length; j++) {
          userList[j] = userList[j].toLowerCase()
        }
      }
      return outData
    }
    return data
  }

  // need all userIds lowercased
  var fixUserId = function(data) {
    var outData = _.clone(data)
    if (outData) {
      for (var i = 0; i < outData.length; i++) {
        outData[i].USER_ID = outData[i].USER_ID.toLowerCase()
      }
      return outData
    }
    return data
  }

  var getAccountStateMessage = function(data) {
    var output = []
    _.each(_.keys(data), function(key) {
      switch (key) {
        case 'FEATURE_LIST':
          output = ['state:features', data[key]]
          break
        default:
          output = ['state:account', data[key]]
          break
      }
    })
    return output
  }

  _.each(_.keys(data), function(key) {
    switch (key) {
      case 'ACCOUNT_STATE':
        handlers.push(getAccountStateMessage(data[key]))
        break
      case 'CHAT_CHECKPOINT_LIST':
        handlers.push(['state:chats', data[key]])
        break
      case 'CHAT_VERSION_LIST':
        break // deprecated
      case 'CONF_CALL':
        handlers.push(['state:controlcall', fixControlCallMsg(data)])
        break
      case 'FACILITY_VERSION_LIST':
        break // deprecated
      case 'GROUP_LIST':
        handlers.push(['state:groups', fixUserIdList(data[key])])
        break
      case 'MAINTENANCE_MODE_STATE':
        handlers.push(['state:maintenanceMode', data[key]])
        break
      case 'ONCALL_LIST':
        handlers.push(['state:oncall', fixUserId(data[key])])
        break
      case 'SYSTEM_ALERT_STATE_LIST':
        break // deprecated
      case 'SYSTEM_ALERT_SUSPENSIONS':
        handlers.push(['state:suspension', data[key]])
        break
      case 'SYSTEM_INCIDENT_STATE':
        handlers.push(['state:incidents', data[key]])
        break
      case 'USER_STATUS_LIST':
        handlers.push(['state:status', fixUserId(data[key])])
        break
      case 'USER_VERSION_LIST':
        handlers.push(['state:users', fixUserId(data[key])])
        break
      default:
        log.warn('protocol: unknown state notify: ', key, data[key])
    }
  })

  return handlers
}

// map from message type to [[event, data], ...]
var historytrans = {
  CHAT: function(data) {
    return [['history:chat', data['CHAT']]]
  },

  ALERT: function(data) {
    return [['history:alert', data['ALERT']]]
  },
  ONCALL: function(data) {
    return [['history:oncall', data['ONCALL']]]
  },
  INCIDENT: function(data) {
    return [['history:incident', data['INCIDENT']]]
  },
  PAGE: function(data) {
    return [['history:page', data['PAGE']]]
  },

  SYSTEM_ALERT_SUSPENDED: function(data) {
    return [['history:suspend', data['SYSTEM_ALERT_SUSPENDED']]]
  },
  SYSTEM_ALERT_RESUMED: function(data) {
    return [['history:resume', data['SYSTEM_ALERT_RESUMED']]]
  },
  CONF_CALL: function(data) {
    return [['history:controlcall', fixControlCallMsg(data)]]
  },
  THIRD_PARTY_MESSAGE: function(data) {
    return thirdPartyTrans(data.THIRD_PARTY_MESSAGE)
  },
}

function MessageProtocol() {}

/** translate an incoming message over the socket to events */
MessageProtocol.prototype.translate = function(data) {
  var handler = messagetrans[data.MESSAGE]

  if (_.isFunction(handler)) {
    return handler.call(this, data)
  }

  // log.warn("protocol: unknown message, dropping: ", data.MESSAGE, data);
  return []
}

/** translate a history message */
MessageProtocol.prototype.translate_history = function(data) {
  var key = _.keys(data)[0]
  var handler = historytrans[key]
  if (_.isFunction(handler)) {
    return handler.call(this, data)
  }

  log.warn('protocol: unknown history message, dropping: ', data)
  return []
}

module.exports = MessageProtocol
