import _ from 'lib/underscore'
import $ from 'lib/jquery'
import vent from 'util/vent'
import users from 'app/data/managers/users'
import { getTeamsWithPolicies } from 'components/store/selectors'

let routeKeys = {
  myKeys: [],
  restKeys: [],
}

const cb = function(routes, users, user) {
  const teamsWithPols = getTeamsWithPolicies(window.VO_STORE.getState())
  const currentUser = users.findWhere({ USER_ID: user })
  const myTeams = currentUser ? currentUser.get('group-slugs') || [] : []

  // filter down teamsWithPols to those named in myTeams...
  const myTeamsWithPols = _.filter(teamsWithPols.toList().toJS(), function(
    team
  ) {
    return _.includes(myTeams, team.slug)
  })

  // find the routes that share a policy slug with myTeamsWithPols...
  // filter filter filter... ugh...
  const myRoutes = _.filter(routes, function(route) {
    return myTeamsWithPols.filter(tpol => {
      return tpol._policies.filter(pol => _.includes(route.teamSlugs, pol.slug))
        .length
    }).length
  })

  const otherRoutes = _.difference(routes, myRoutes)

  _.each(myRoutes, function(route) {
    routeKeys.myKeys.push(route)
  })

  _.each(otherRoutes, function(route) {
    routeKeys.restKeys.push(route)
  })
}

export default function(meta) {
  const user = meta.user
  const org = meta.org
  const $ajax = $.get('/api/v1/org/' + org + '/routes')

  $.when(users.deferredLoad, $ajax, window.VO_TEAMPOLS).then(function(
    users,
    routes,
    teampols
  ) {
    // @TODO probably don't need to clone here... paranoia
    cb(_.cloneDeep(routes).shift(0), users, user)
    vent.trigger('route_keys:ready')
  })

  return routeKeys
}
