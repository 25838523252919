var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="statuspage-component">\n    <strong>' +
__e( name ) +
'</strong>: ' +
__e( status ) +
' \n</div>\n';

}
return __p
}