var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<section class="statuspage-form--incident">\n    ';
 if (!isNew) { ;
__p += '\n        <div class="js-statuspage-title statuspage-title">\n            <h1 class="js-statuspage-incident-name statuspage-incident-name">' +
((__t = ( name )) == null ? '' : __t) +
'</h1>\n            <small class="trigger">(edit)</small>\n        </div>\n\n        <div class="js-statuspage-title-edit statuspage-title-edit hidden">\n            <input class="js-statuspage-title-edit-input" type="text" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n            <small class="trigger">(update)</small>\n        </div>\n    ';
 } ;
__p += '\n\n    <form>\n        <div class="js-statuspage-control-group-templates statuspage-control-group">\n            <label class="statuspage-control-group-label">Incident Template</label>\n\n            <div class="">\n                <select class="js-statuspage-templates" >\n                    <option selected="" disabled="">Select A Template</option>\n                </select>\n\n                <div class="help-block">\n                    <a href="#" class="js-statuspage-templates-cancel">Clear selection</a>\n                </div>\n            </div>\n        </div>\n\n        ';
 if (isNew) { ;
__p += '\n            <div class="statuspage-control-group">\n                <input placeholder="Incident Name" type="text" class="js-statuspage-name">\n\n                <div class="help-block">\n                    This incident will be posted to page "VictorOps".\n                </div>\n            </div>\n        ';
 } ;
__p += '\n\n        <div class="statuspage-control-group">\n            <label class="statuspage-control-group-label">Incident Status</label>\n\n            <div class="">\n                <label class="radio inline">\n                    <input name="status" type="radio" value="investigating" class="js-statuspage-status"> Investigating\n                </label>\n\n                <label class="radio inline">\n                    <input name="status" type="radio" value="identified" class="js-statuspage-status"> Identified\n                </label>\n\n                <label class="radio inline">\n                    <input name="status" type="radio" value="monitoring" class="js-statuspage-status"> Monitoring\n                </label>\n\n                <label class="radio inline">\n                    <input name="status" type="radio" value="resolved" class="js-statuspage-status"> Resolved\n                </label>\n            </div>\n        </div>\n\n        <div class="statuspage-control-group">\n            <label class="statuspage-control-group-label">Message</label>\n\n            <textarea placeholder="Message" class="js-statuspage-message"></textarea>\n        </div>\n\n        <div class="statuspage-control-group--alert">\n            <label class="statuspage-control-group-label">\n                Alert Users Subscribed To\n                <small class="js-statuspage-toggle-all"></small>\n            </label>\n\n            <div class="js-statuspage-alerts statuspage-alerts"></div>\n        </div>\n\n        <div class="form-actions">\n            <ul style="margin-top:30px;" class="button-list right">\n                ';
 if (!isNew) { ;
__p += '\n                    <li><button class="button-primary js-statuspage-submit-incident">Update Incident</button></li>\n                ';
 } else { ;
__p += '\n                    <li><button class="button-primary js-statuspage-submit-incident">Create Incident</button></li>\n                ';
 } ;
__p += '\n\n                <li><a class="cancel-delete js-statuspage-cancel">Cancel</a></li>\n            </ul>\n        </div>\n    </form>\n</section>\n';

}
return __p
}