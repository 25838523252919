var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<li class="team-state-' +
((__t = ( state )) == null ? '' : __t) +
'" data-ext="team-state-' +
((__t = ( state )) == null ? '' : __t) +
'">\n    ' +
__e( team ) +
'\n</li>\n';

}
return __p
}