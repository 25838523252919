import Event from '../models/event'
import _ from 'lib/underscore'

// file: event.chat.js
// chat event

export default Event.extend({
  defaults: {
    eventType: 'chat',
  },

  initialize: function() {
    var self = this
    Event.prototype.initialize.apply(self, arguments)

    /* eslint-disable */
    if (/I don\'t know who .* is/.test(self.get('TEXT'))) {
      self.set('parsedText', self.get('TEXT'))
      return
    }
    /* eslint-enable */

    var processed = self
      .get('TEXT')
      .split(' ')
      .map(this.parseLinks)
    var parsed = []

    parsed.push(
      processed
        .map(function(p) {
          return p[0]
        })
        .join(' ')
    )

    parsed.push(
      processed.map(function(p) {
        return p[1][0]
      })
    )
    self.set('parsedText', parsed[0])

    var link = _.filter(parsed[1], function(p) {
      return p && p.url && self.isImageUrl(p.url)
    })
    if (link.length) {
      self.set('parsedImage', link[link.length - 1].url)
    }
  },

  // how this regex works (regex comments aren't a thing in js, boo)
  // relevant RFCs:
  //
  // RFC1035 - Domain Names Specification & Implementation
  // RFC3986 - URI Generic Syntax
  //
  // ([a-z]{3,9}:\/\/)?                       optional - the protocol (we only support http(s) links)
  // ((.+)(:.+)?@)?                           optional - username / password
  // [a-z0-9\-]+\.                            first part of the url (anything but a . so, [google].com or [www].victorops.com, followed by a dot
  // [a-z0-9\-\.]+                            rest of the url, including dots (fails at the first /)
  // (:\d{0,6})?                              optional - port
  // (\/([a-z0-9\-\._~()=]|%[0-9]{2})*)*       the file path (could be empty, but not optional)
  // (\?[^#]*)?                               optional - query string
  // (#.*)?                                   optional - fragment identifier

  // following code is duplicated in:
  //  admin/post-mortem/timeline.js

  /* eslint-disable */
  parseLinks: function(text) {
    var regex = /(?:^[^@#])([A-Za-z]{3,9}:\/\/)?((.+)(:.+)?@)?[A-Za-z0-9\-]+\.[A-Za-z0-9]+[A-Za-z0-9\-\.]+(:\d{0,6})?(\/([A-Za-z0-9\-\._~()=]|%[0-9]{2})*)*(\?[^#]*)?(#.*)?/g
    var links = []

    // There's not a decent way to skip callback arguments in JS and I'm
    // not inclined to mess with the regex to adjust the groupings
    // so we are using underscores of increasing alphabet per skipped argument
    var result = text.replace(regex, function(
      match,
      proto,
      _a,
      userpass,
      _b,
      _c,
      lastpath,
      _d,
      query,
      fragment
    ) {
      var url = match
      var numbers = /^(?:[0-9\.]*)$/
      var inlineMentions = /.*\s+@+.*/g

      // If the match is comprised only of numbers, it's not a link.
      if (numbers.test(match)) {
        return match
      }

      if (inlineMentions.test(match)) {
        return match
      }

      // Add http://
      if (proto == null) {
        url = 'http://' + match
      }

      links.push({ match: match, url: url, path: lastpath })

      return '<a href="' + url + '" target="_blank">' + match + '</a>'
    })

    return [result, links]
  },
  /* eslint-enable */

  // a url points at an image (something we'll try to display) if:
  // it contains a .[exte] (3 or 4 chars) followed by not more letters,
  // and that extension is a common image format. this is dumb. why can't
  // we do cross site HEAD requests, since it's semantically exactly the
  // same as the GET request we _are_ allowed to do.

  // following code is duplicated in:
  //  admin/post-mortem/timeline.js

  isImageUrl: function(url) {
    var regex = /\.([a-z]{3,4})[^a-z]*$/
    var ext = url.toLowerCase().match(regex)
    if (ext) {
      ext = ext[1] // the extension capture group
      return (
        ext === 'jpg' ||
        ext === 'jpeg' ||
        ext === 'png' ||
        ext === 'gif' ||
        ext === 'bmp'
      )
    }
  },
})
