export default payload => {
  const incidentId = payload.getIn(['nonVoFields', 'INCIDENT_ID'])

  let modifiedPayload = payload

  if (incidentId !== undefined) {
    // add INCIDENT_ID to the voFields so it'll appear in the correct section of the UI
    modifiedPayload = modifiedPayload.setIn(
      ['voFields', 'INCIDENT_ID'],
      incidentId
    )

    // re-alphabetize list after INCIDENT_ID is added
    modifiedPayload = modifiedPayload.update('voFields', fields =>
      fields.sortBy((value, key) => key.toLowerCase())
    )
  }

  modifiedPayload = modifiedPayload.set(
    'nonVoFields',
    modifiedPayload
      .get('nonVoFields')
      .filter(
        (value, key) => modifiedPayload.getIn(['voFields', key]) === undefined
      )
  )

  return modifiedPayload
}
