// vendor
import 'react-virtualized/styles.css'

import React, { Component } from 'react'

import { map, every } from 'lodash'

import vent from 'util/vent'

import SmallPane from './small-pane'

import MediumPane from './medium-pane'

import LargePane from './large-pane'

class IncidentsTable extends Component {
  shouldComponentUpdate(nextProps) {
    const isSame = map(nextProps, (val, prop) => {
      if (prop === 'incidents') {
        return this.props.incidents.equals(val)
      } else {
        return this.props[prop] === val
      }
    })

    vent.trigger('incidentsLoadTimer', { count: this.props.incidents.size })
    return !every(isSame, el => el === true)
  }

  render() {
    const { gridType } = this.props

    switch (gridType) {
      case 'medium-card':
        return <MediumPane {...this.props} />
      case 'small-card':
        return <SmallPane {...this.props} />
      case 'table':
        return <LargePane {...this.props} />

      default:
        throw new Error(`No corresponding component for gridType:${gridType}`)
    }
  }
}

export default IncidentsTable
