import _ from 'lib/underscore'
import $ from 'jquery'
import Backbone from 'lib/backbone'

import groups from '../managers/groups'
import Timelines from '../collections/timelines'

var config = window.VO_CONFIG
// file: user.js
// user model
export default Backbone.Model.extend({
  idAttribute: 'USER_ID',

  defaults: {
    gids: [],
    oncall: [],
    ENGAGEMENT: 0,
    STATUS: '',
    MISSED: 0,
  },

  initialize: function() {
    this.set('fullname', this.fullName())
    $.when(groups.deferredLoad).then(
      _.bind(function() {
        this.set('groups', this.groupNames())
        this.set('group-slugs', this.groupSlugs())
      }, this)
    )
    this.deferredsCache = {}
    this.on('change', this.resolveDeferred, this)
  },

  getDeferred: function(id) {
    if (this.deferredsCache[id]) {
      return this.deferredsCache[id]
    }

    var deferred = new $.Deferred()
    this.deferredsCache[id] = deferred

    return deferred
  },

  resolveDeferred: function(model) {
    var key
    for (key in model.changed) {
      var deferred = this.deferredsCache[key]

      if (!deferred) {
        deferred = new $.Deferred()
        this.deferredsCache[key] = deferred
      }

      if (deferred.state() !== 'resolved') {
        this.deferredsCache[key].resolve()
      }
    }
  },

  fullName: function() {
    return this.get('FIRST_NAME') + ' ' + this.get('LAST_NAME')
  },

  groupNames: function() {
    return _.map(this.groups(), function(group) {
      return group.attributes.NAME
    })
  },

  groupSlugs: function() {
    return _.map(this.groups(), function(group) {
      return group.attributes.GROUP_ID
    })
  },

  groups: function() {
    var self = this
    return groups.filter(function(g) {
      return _.includes(g.get('uids'), self.id)
    })
  },

  privateTimeline: function() {
    var timelines = new Timelines()
    return timelines.canonicalizePrivate(this.id)
  },

  isOnCall: function() {
    return !_.isEmpty(this.get('oncall'))
  },

  isCurrentUser: function() {
    return this.get('USER_ID') === config.socketAuth.USER_ID.toLowerCase()
  },

  hasMissedChats: function() {
    return this.get('MISSED') > 0
  },

  onCallFor: function() {
    var self = this
    return groups.filter(function(g) {
      return _.includes(self.get('oncall'), g.id)
    })
  },

  setOnCallFor: function(gid, set) {
    var oncall = this.get('oncall')
    if (set) {
      this.set('oncall', _.uniq(oncall.concat(gid)), { silent: true })
    } else {
      this.set('oncall', _.without(oncall, gid), { silent: true })
    }
  },
})
