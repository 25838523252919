import React from 'react'
import moment from 'moment'
import { TimelineIncidentCard } from '@victorops/victory'

export const getPoliciesPaged = incident => {
  const policiesData = incident.policies
  if (policiesData) {
    const formattedPoliciesPaged = policiesData.map(pagedPolicy => {
      return `${pagedPolicy.TEAM.NAME}: ${pagedPolicy.POLICY.NAME}`
    })
    return formattedPoliciesPaged.sort().join(', ')
  }
}

export const IncidentCard = ({ featureFlags, incident, integrationIcon }) => {
  const {
    alertCount,
    annotationCount,
    incidentPhase,
    entityState,
    hostName,
    incidentId,
    displayName,
    entityId,
    monitorName,
    monitorType,
    ackedBy,
    resolvedBy,
    firstAlertAt,
  } = incident

  const timestamp = moment(parseInt(firstAlertAt)).format('MMM. D - h:mm A')
  const phase =
    incidentPhase.toUpperCase() === 'TRIGGERED' ? 'UNACKED' : incidentPhase

  return (
    <div className='related-incidents-related--card'>
      <TimelineIncidentCard
        alertCount={parseInt(alertCount)}
        annotationCount={parseInt(annotationCount)}
        currentState={entityState}
        detailsPopout
        featureFlags={featureFlags}
        host={hostName || ''}
        incidentId={incidentId.toString()}
        incidentMessage={displayName || entityId}
        incidentPhase={phase}
        integrationName={integrationIcon ? integrationIcon.name : null}
        integrationIconPath={integrationIcon ? integrationIcon.icon_url : null}
        loggedInUser={window.VO_CONFIG.auth.user.username}
        monitorTool={monitorType}
        monitorType={monitorType}
        monitorName={monitorName}
        policiesPaged={getPoliciesPaged(incident)}
        timestamp={timestamp}
        userAcked={resolvedBy || ackedBy}
      />
    </div>
  )
}
