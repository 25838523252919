import React from 'react'

import { connect } from 'react-redux'

import { List } from 'immutable'
import moment from 'moment'

import { getNextOnCall } from 'components/store/actions'

import { makeGetFeatureFlag } from 'components/store/selectors'

import { _confirmationModal } from './oncall-confirmation-modal'

import interpolate from 'util/interpolate_engagement'
import Header from './header'
import PolicyList from './policy-list'
import onCallPoliciesByTeamSelector from './on-call-policies-by-team-selector'

// GET PROPS
function mapDispatchToProps(dispatch, other) {
  return {
    getNextOnCallList: username =>
      dispatch(getNextOnCall({ username: username })),
    confirmationModal: _confirmationModal(dispatch),
  }
}

// GET STATE
function mapStateToProps(state, ownProps) {
  const getFeatureFlag = makeGetFeatureFlag()
  const manualTakeTimer = getFeatureFlag(state, {
    id: 'feature:manualtaketimer',
  })

  return {
    manualTakeTimer,
    nextOnCallList: state.onCall.get('NEXT_ON_CALL'),
    onCallPoliciesByTeam: onCallPoliciesByTeamSelector(
      manualTakeTimer,
      state.onCall,
      ownProps.username
    ),
    teams: _getTeamsContainingUser(state.teams, ownProps.username),
    user: state.users
      .filter(user => user.get('USER_ID') === ownProps.username)
      .first(),
  }
}

function _getTeamsContainingUser(teams, username) {
  return teams.filter(team => {
    return team.get('USER_ID_LIST', []).some(user => user === username)
  })
}

class detailView extends React.Component {
  constructor() {
    super()

    this.state = {
      user: window.VO_CONFIG.auth.user,
    }
  }

  UNSAFE_componentWillMount() {
    this.props.getNextOnCallList(this.props.username)
  }

  _getTeams() {
    const { teams } = this.props
    return teams
      .keySeq()
      .toArray()
      .map(function(team, index) {
        return <li key={index}> {teams.getIn([team, 'NAME'], '')} </li>
      })
  }

  _getEmails() {
    const emailList = this.props.user.get('EMAIL_LIST', List([]))
    if (emailList.size) {
      return (
        <div>
          <h5 className='rolodex--contactinfo--heading'>Email</h5>
          <ul className='rolodex--getemails rolodex--list'>
            {emailList.map(function(email, index) {
              const value = email.get('VALUE', '')
              return (
                <li key={index + value}>
                  <a href={`mailto:${value}`}>{value}</a>
                </li>
              )
            })}
          </ul>
        </div>
      )
    } else {
      return null
    }
  }

  _getPhoneNumbers() {
    const numberList = this.props.user.get('PHONE_LIST', List([]))
    if (numberList.size) {
      return (
        <div>
          <h5 className='rolodex--contactinfo--heading'>Phone</h5>
          <ul className='rolodex--getnumbers rolodex--list'>
            {numberList.map(function(number, index) {
              return <li key={index}>{number.get('VALUE', '')}</li>
            })}
          </ul>
        </div>
      )
    } else {
      return null
    }
  }

  _getNextOnCall() {
    const nextOnCall = this.props.nextOnCallList

    // Sort chronologically
    let newList = nextOnCall.sort((a, b) => a.get('when', 0) - b.get('when', 0))

    if (!nextOnCall.size) {
      return <li>Not on-call in the next 60 days</li>
    } else {
      return newList.map(function(nextOnCall, index) {
        return (
          <li className='row no-gutters' key={index}>
            <span className='oncall--name col col-xs-6'>
              {nextOnCall.get('name', '')}
            </span>
            <span className='oncall--time col col-xs-6'>
              {' '}
              {moment(nextOnCall.get('when')).format('MMM DD, h:mm a')}
            </span>
          </li>
        )
      })
    }
  }

  render() {
    const {
      confirmationModal,
      manualTakeTimer,
      model,
      nextOnCallList,
      onCallPoliciesByTeam,
      teams,
      user,
      username,
    } = this.props

    const emailList = user.get('EMAIL_LIST', List([]))
    const numberList = user.get('PHONE_LIST', List([]))

    return (
      <div className='rolodex--container'>
        <Header user={user} isEngaged={interpolate(model.get('ENGAGEMENT'))} />

        <div className='rolodex--chat-info'>
          {onCallPoliciesByTeam.size !== 0 ? (
            <div className='rolodex--oncall'>
              <h4 className='profile-chat-section-title border--bottom'>
                Current On-call
              </h4>
              <PolicyList
                {...{
                  confirmationModal,
                  manualTakeTimer,
                  onCallPoliciesByTeam,
                  username,
                }}
              />
            </div>
          ) : null}

          {nextOnCallList.size ? (
            <div className='rolodex--nextoncall'>
              <h4 className='profile-chat-section-title border--bottom'>
                Next On-call
              </h4>
              <ul className='rolodex-getnextoncall rolodex--list'>
                {this._getNextOnCall()}
              </ul>
            </div>
          ) : null}

          <div className='rolodex--contactinfo'>
            <h4 className='profile-chat-section-title border--bottom'>
              Contact Methods
            </h4>
            {emailList.size ? <div> {this._getEmails()} </div> : null}
            {numberList.size ? <div> {this._getPhoneNumbers()} </div> : null}
          </div>

          {teams.size ? (
            <div className='rolodex--teams'>
              <h4 className='profile-chat-section-title border--bottom'>
                Teams
              </h4>
              <ul className='rolodex--teams rolodex--list'>
                {this._getTeams()}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(detailView)
