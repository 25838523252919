// vendor
var $ = require('jquery')
var Marionette = require('backbone.marionette')
var bind = require('lodash').bind
var includes = require('lodash').includes
var keys = require('lodash').keys

// err don't think this thing gets called
function insertIt(options) {
  const { start, end, weight, $banners, $element } = options

  const first = $banners[start]
  const second = $banners[end]

  const firstW = $(first).data('weight')
  const secondW = $(second).data('weight')

  if (weight < firstW) {
    $(first).before($element)

    return
  }

  if (weight > firstW && !secondW) {
    $(first).after($element)

    return
  }

  if (weight > firstW && weight < secondW) {
    $(second).before($element)

    return
  }

  if (weight > firstW && weight > secondW) {
    const opts = {
      start: second.index(),
      end: second.index() + 1,
      weight: weight,
      $banners: $banners,
      $element: $element,
    }

    insertIt(opts)
  }
}

function addView(className, name, options) {
  const _Banner = require('./' + className).default
  const banner = new _Banner(options)
  const isOpen = keys(this.banners).length > 0
  const $banners = $('.notification-banner-sub')

  const doInsert = $element => {
    if ($banners.length === 0) {
      this.$el.append($element)

      return
    }

    const opts = {
      start: 0,
      end: 1,
      weight: $element.data('weight'),
      $banners: $banners,
      $element: $element,
    }

    // find the right element to insert before or after
    insertIt(opts)
  }

  this.$el.removeClass('banner-count-' + keys(this.banners).length)

  this.banners[name] = banner

  banner.render()

  doInsert(banner.$el)

  this.render()

  banner.$el.addClass('notification-banner-' + className)

  this.$el.addClass('banner-count-' + keys(this.banners).length)

  let notificationContainerCount =
    document.querySelector('#notifications-container').children[0]
      .childElementCount || 0
  const isTrialOrg = window.VO_CONFIG.billing.state === 'trial'
  const trialOrgBannerHeight = isTrialOrg
    ? document.querySelector('.header--banner').offsetHeight
    : 0

  this.top = String(
    ++notificationContainerCount * 32 + trialOrgBannerHeight + 16 + 'px'
  )

  // animate everything into view
  if (!isOpen) {
    this.$panes.css({
      height: '-=' + this.height,
      top: '+=' + this.height,
    })

    this.$el.css({ height: this.height, top: this.top })

    $('.primary-nav .js-current').addClass('has-notifications-banner')
  }
}

function removeView(className, name) {
  const banner = this.banners[name]

  this.$el.removeClass('banner-count-' + keys(this.banners).length)

  delete this.banners[name]

  this.$el.addClass('banner-count-' + keys(this.banners).length)

  // no more banners to show, roll it up
  if (keys(this.banners).length === 0) {
    this.$panes.css(
      { height: '+=' + this.height, top: '-=' + this.height },
      200
    )

    this.$el.css({ height: 0 })

    $('.primary-nav .js-current').removeClass('has-notifications-banner')
  }

  banner.destroy()

  this.$el.remove('.notification-banner-' + className)
}

module.exports = Marionette.View.extend({
  initialize: function() {
    this.banners = {}

    this.height = '48px'

    this.top = $('#primary--header').height()
    this.$panes = $('.js-application-panes')
  },

  el: '.js-notification-banner',

  addView: function(className, options) {
    const name =
      options && options.model ? className + ':' + options.model.id : className
    const isAlreadyVisible = includes(keys(this.banners), name)

    if (isAlreadyVisible || className === 'maintenance') {
      // noop
    } else {
      bind(addView, this)(className, name, options)
    }
  },

  removeView: function(className, id) {
    const name = id ? className + ':' + id : className
    const isNotVisible = !includes(keys(this.banners), name)

    // not showing this one right now
    if (isNotVisible) {
      // noop
    } else {
      bind(removeView, this)(className, name)
    }
  },
})
