// vendor
import $ from 'jquery'
import _ from 'underscore'

// lib
import timings from 'util/timings'

// file: transmap.js
// transaction map

// create a random transaction id
var createTxnId = function() {
  return `web-${(1e15 - Math.random() * 1e14).toString(36)}`
}

// the txnmap constructor
function TransactionMap() {
  this.txns = {}
}

// the txnmap prototype
TransactionMap.prototype = _.extend(TransactionMap.prototype, {
  // is the given id a valid identifier in the map?
  exists: function(tid) {
    return tid in this.txns
  },

  // create a transaction handler
  push: function(timeout, success, err) {
    var tid = createTxnId()
    var deferred = $.Deferred()

    deferred.then(success, err)

    deferred.done(function() {
      timings.stop('transactions', tid)
      // ga('send', 'timing', 'websocket', data.MESSAGE, elapsed);
    })

    deferred.tid = tid
    this.txns[tid] = deferred

    // install the timeout handler
    setTimeout(function() {
      deferred.rejectWith('timeout')
    }, timeout)

    return deferred
  },

  // pop a transaction handler off the map
  pop: function(tid) {
    var handler = this.txns[tid]
    delete this.txns[tid]
    return handler
  },
})

export default TransactionMap
