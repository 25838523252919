// vendor
import debug from 'debug'

import { takeEvery, call, put } from 'redux-saga/effects'

// lib
import { fetch } from 'components/__utils/xhr'

import { updateHelpContent, HELP_CONTENT_FETCH } from 'components/store/actions'

const error = debug('VO:sagas:error')

export function* watchGetHelpContent() {
  yield takeEvery(HELP_CONTENT_FETCH, getHelpContent)
}

export function* getHelpContent() {
  try {
    window.VO_HELP_CONTENT = {} // Using global for easier access from Backbone
    const tableOfContents = yield call(fetch, `/help/help-content.json`)
    const parsedTableOfContents = JSON.parse(tableOfContents)['help-content']

    yield* Object.keys(parsedTableOfContents).map(function*(filename) {
      const regex = /<h[1-6]>(.*)<\/h[1-6]>[ \n]*([\s\S]+)/
      const response = yield call(
        fetch,
        `/help/${parsedTableOfContents[filename]}`
      )

      if (regex.test(response)) {
        const parsedResponse = regex.exec(response)
        yield put(
          updateHelpContent({
            [filename]: { title: parsedResponse[1], body: parsedResponse[2] },
          })
        )
        window.VO_HELP_CONTENT[filename] = {
          title: parsedResponse[1],
          body: parsedResponse[2],
        }
      } else {
        error('Could not parse help content response', response)
      }
    })
  } catch (err) {
    error(err)
  }
}
