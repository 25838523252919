// vendor
import Marionette from 'lib/marionette'
import reactApp from 'components/__entry'

// lib
var config = window.VO_CONFIG

export default Marionette.View.extend({
  template: false,

  id: 'rolodex-detail-view',

  initialize: function(opts) {
    this.username = opts.model.get('USER_ID')
    this.org = config.auth.org.slug
  },

  onAttach: function() {
    reactApp.attach('rolodexDetailView', {
      username: this.username,
      org: this.org,
      model: this.model,
    })
  },
})
