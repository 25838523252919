import Backbone from 'lib/backbone'
import Event from '../models/event'
import when from 'when'

// file: events.js
// base collection for events

export default Backbone.Collection.extend({
  model: Event,

  comparator: function(a, b) {
    return b.get('sequence') - a.get('sequence')
  },

  newestSequenceNumber: function() {
    return this.at(0).get('sequence')
  },

  // this only works if we only add things sorted
  // BUGFIX: At start-of-day, this is being called by timeline code before the
  // collection is loaded, and it's returning zero prematurely.
  oldestSequenceNumber: function() {
    var getSeq = (resolver, iteration = 0) => {
      if (iteration > 9) {
        // guess it really is the end, not just racing. return zero
        resolver(0)
      } else {
        if (this.length > 0) {
          resolver(this.at(this.length - 1).get('sequence'))
        } else {
          // check again later
          setTimeout(() => {
            getSeq(resolver, ++iteration)
          }, 100)
        }
      }
    }

    return when.promise(resolve => {
      getSeq(resolve)
    })
  },
})
