/**
 * This utility provides the ability to override configuration related functionality through setting flags within
 * local or session storage.
 */

export const DISABLE_SOCKET_COMPRESSION = 'disableSocketCompression'

export const isItemTrue = flag =>
  sessionStorage.getItem(flag) === 'true' ||
  localStorage.getItem(flag) === 'true'

export const isSocketCompressionDisabled = () =>
  isItemTrue(DISABLE_SOCKET_COMPRESSION)
