// vendor
import React from 'react'

import { DropdownButton, MenuItem } from 'react-bootstrap'

// Export
// ---------------------------------------------------------------------------

export default function IncidentsNavPhase({
  // data
  counts,
  phase,
  width,

  // actions
  onClick,
}) {
  const ackedCount = counts.get('ACKED')
  const triggeredCount = counts.get('UNACKED')
  const unresolvedCount = counts.get('UNRESOLVED')

  function getReadablePhase(phase) {
    switch (phase) {
      case 'UNACKED':
        return `Triggered (${triggeredCount}/${unresolvedCount})`
      case 'ACKED':
        return `Acknowledged (${ackedCount}/${unresolvedCount})`
      case 'RESOLVED':
        return `Resolved`
    }
  }

  if (width < 515) {
    return (
      <div
        className={`incidents-filters--dropdown is-selected--${phase.toLowerCase()}`}
      >
        <DropdownButton
          title={getReadablePhase(phase)}
          id='incidents-filters'
          className='no-caret'
          onSelect={phase => onClick({ type: 'phase', value: phase })()}
        >
          <MenuItem disabled={phase === 'UNACKED'} eventKey='UNACKED'>
            {getReadablePhase('UNACKED')}
          </MenuItem>

          <MenuItem disabled={phase === 'ACKED'} eventKey='ACKED'>
            {getReadablePhase('ACKED')}
          </MenuItem>

          <MenuItem disabled={phase === 'RESOLVED'} eventKey='RESOLVED'>
            {getReadablePhase('RESOLVED')}
          </MenuItem>
        </DropdownButton>
      </div>
    )
  } else {
    return (
      <div className={`incidents-filters is-selected--${phase.toLowerCase()}`}>
        <div className='button-group'>
          <button
            data-test-key='incident-filter-unacked'
            className='incident-filter--unacked'
            onClick={onClick({ type: 'phase', value: 'UNACKED' })}
          >
            {getReadablePhase('UNACKED')}
          </button>

          <button
            data-test-key='incident-filter-acked'
            className='incident-filter--acked'
            onClick={onClick({ type: 'phase', value: 'ACKED' })}
          >
            {getReadablePhase('ACKED')}
          </button>

          <button
            data-test-key='incident-filter-resolved'
            className='incident-filter--resolved'
            onClick={onClick({ type: 'phase', value: 'RESOLVED' })}
          >
            {getReadablePhase('RESOLVED')}
          </button>
        </div>
      </div>
    )
  }
}
