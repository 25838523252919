// vendor
import debug from 'debug'

import { takeEvery, call } from 'redux-saga/effects'

import { ON_CALL_TAKE } from 'components/store/actions'

const logError = debug('VO:sagas:error')

export function watchUpdateTakeOnCall(voSocket, server) {
  return function* watchUpdateTakeOnCallGen() {
    yield takeEvery(ON_CALL_TAKE, _takeOnCall(voSocket, server))
  }
}

export function _createTakeOnCallMessage({
  isClear,
  takeFromUsername,
  takeToUsername,
  policySlug,
}) {
  return {
    MESSAGE: 'TAKE_ONCALL_REQUEST_MESSAGE',
    TRANSACTION_ID: '',
    PAYLOAD: {
      IS_CLEAR: isClear,
      TAKE_FROM_POLICY: policySlug,
      TAKE_FROM_USER: takeFromUsername,
      TAKE_USER: takeToUsername,
    },
  }
}

export function _takeOnCall(voSocket, server) {
  return function*(action) {
    const {
      payload: { payload, success, error },
    } = action

    try {
      const requestPayload = yield call(_createTakeOnCallMessage, payload)
      const resp = yield call(voSocket.tSend(10000), requestPayload)
      yield call(checkForError, resp)
      yield call(success)
    } catch (err) {
      yield call(logError, err)
      yield call(error, err)
    }
  }
}

export const Test = {
  _createTakeOnCallMessage,
  _takeOnCall,
}

function checkForError(resp) {
  if (resp.PAYLOAD.STATUS !== '200') {
    throw resp.PAYLOAD.DESCRIPTION
  }
}
