// vendor
import React, { PureComponent } from 'react'
import { faCircle } from '@fortawesome/fontawesome-pro-solid'
import config from 'components/__utils/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hasNeededRole } from '@victorops/utils'

// Bring back once chat is re-written
// import { Chaticon } from 'components/icon-components'

export default class Header extends PureComponent {
  getDisplayName = () => {
    const user = this.props.user

    if (hasNeededRole(config.context.roles, 'admin')) {
      return (
        <a href={`/dash/${config.orgslug}#/users/${user.get('USER_ID')}`}>
          {user.get('FIRST_NAME', '')} {user.get('LAST_NAME', '')}
        </a>
      )
    } else {
      return `${user.get('FIRST_NAME', '')} ${user.get('LAST_NAME', '')}`
    }
  }

  render() {
    const user = this.props.user
    const userIsOnCall = this.props.isEngaged ? 'true' : 'false'

    return (
      <div className='l-card-left rolodex--header no-gutters'>
        <div className='row'>
          <div className='col col-xs-12'>
            <h2 className='strong h5 rolodex--header-fullname '>
              {this.getDisplayName()}
            </h2>
            <div className='rolodex--header-icon'>
              {/*  <Chaticon unread={false} /> */}
            </div>
          </div>
        </div>
        <div className='row'>
          <h3 className='profile-fullname col col-xs-12'>
            <FontAwesomeIcon
              icon={faCircle}
              className={`rolodex--on-call-indicator rolodex--on-call-indicator--${userIsOnCall}`}
            />
            @{user.get('USER_ID', '')}
          </h3>
        </div>
      </div>
    )
  }
}
