import React, { memo, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/fontawesome-pro-regular'

const DisplayContainer = ({ children }) => (
  <div data-ext='incident-details--servicenow2-display'>{children}</div>
)

export const ServiceNowDisplayView = memo(
  ({
    activeIntegration,
    error,
    Fallback,
    fetchServiceNowDetails,
    incidentLink,
    incident,
    incidentNumber,
    linkedID,
    setServiceNowDetailsError,
  }) => {
    useEffect(() => {
      const incidentID = incident.get('INCIDENT_NAME')
      const slug = `${activeIntegration}/${incidentID}?service_now_sys_id=${linkedID}`

      if (!incidentLink) {
        fetchServiceNowDetails({ slug, incidentID })
      }

      return () => setServiceNowDetailsError(false)
    }, [
      activeIntegration,
      fetchServiceNowDetails,
      incidentLink,
      incident,
      linkedID,
      setServiceNowDetailsError,
    ])

    if (error) {
      return Fallback
    }

    if (incidentLink) {
      return (
        <DisplayContainer>
          <span>
            ServiceNow Ticket:{' '}
            <a href={incidentLink} target='_blank'>
              {incidentNumber ? `#${incidentNumber}` : 'View Ticket'}
            </a>
          </span>
        </DisplayContainer>
      )
    }

    return (
      <DisplayContainer>
        <FontAwesomeIcon icon={faSpinnerThird} spin title='loading...' />
      </DisplayContainer>
    )
  }
)
