// vendor
import debug from 'debug'
import { Observable } from 'rxjs'

import { assign, curry, head, map, omit, tail } from 'lodash'

// victorops
import { hotpathProtocol } from '@victorops/message-protocol'

const log = debug('VO:incidentsProtocol')

const transform = curry(function transform(state, obj) {
  const newProps = {
    CURRENT_ALERT_PHASE: state,
    INCIDENT_NAME: obj.NAME,
    INCIDENT_TIMESTAMP: obj.TS,
  }

  return assign(omit(obj, ['NAME', 'TS']), newProps)
})

function pushEntityStateNotifyMessage(observer, payload) {
  payload.SYSTEM_ALERT_STATE_LIST.forEach(el => observer.next(el))
}

function pushIncidentReply(observer, payload) {
  payload.forEach(el => observer.next(el))
}

function pushSystemIncidentState(observer, payload) {
  const incidents = [
    ...map(payload.TRIGGERED_INCIDENT_NAMES, transform('UNACKED')),
    ...map(payload.ACKNOWLEDGED_INCIDENT_NAMES, transform('ACKED')),
    ...map(payload.RESOLVED_INCIDENT_NAMES, transform('RESOLVED')),
  ]

  log(`initial incidents size: ${incidents.length}`)

  observer.next(incidents)
}

// ---------------------------------------------------------------------------

export function _incidentsInitProtocol(source) {
  return Observable.create(function createObserver(observer) {
    hotpathProtocol('STATE_NOTIFY_MESSAGE', source).subscribe(
      function(el) {
        const payload = head(tail(el))

        switch (head(el)) {
          case 'SYSTEM_INCIDENT_STATE':
            pushSystemIncidentState(observer, payload)
            break

          default:
            break
        }
      },

      function onError(err) {
        observer.error(err)
      },

      function onCompleted() {
        observer.complete()
      }
    )
  })
}

export function _incidentsProtocol(source) {
  return Observable.create(function createObserver(observer) {
    const hotStreams = map(
      ['ENTITY_STATE_NOTIFY_MESSAGE', 'INCIDENT_REPLY'],
      type => hotpathProtocol(type, source)
    )

    Observable.merge(...hotStreams).subscribe(
      function(el) {
        const payload = head(tail(el))

        switch (head(el)) {
          case 'ENTITY_STATE_NOTIFY_MESSAGE':
            pushEntityStateNotifyMessage(observer, payload)
            break

          case 'INCIDENT_REPLY':
            pushIncidentReply(observer, payload)
            break

          default:
            break
        }
      },

      function onError(err) {
        observer.error(err)
      },

      function onCompleted() {
        observer.complete()
      }
    )
  })
}
