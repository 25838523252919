import React from 'react'
import { faWrench } from '@fortawesome/fontawesome-pro-solid'
import BannerLink from './gmm-banner-link'
import HelpPopover from './gmm-help-popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function BannerHeader({ children, bannerTop }) {
  return (
    <header
      className='maintenance-mode-banner'
      data-test-key='maintenance-mode-banner'
      style={{ top: bannerTop }}
    >
      {children}
    </header>
  )
}

function BannerWrench() {
  return (
    <FontAwesomeIcon
      icon={faWrench}
      className='maintenance-mode-banner_icon-wrench'
    />
  )
}

function BannerText({ globalMMExists }) {
  const bannerText = globalMMExists
    ? 'Paging is muted for ALL Routing Keys'
    : 'Paging is muted for select Routing Key(s)'

  return <div className='maintenance-mode-banner_text'>{bannerText}</div>
}

function BannerControl() {
  return <BannerLink text='Manage Maintenance Mode' />
}

function MaintenanceModeBanner({ bannerTop, maintenance }) {
  const globalMMExists = maintenance.some((mmInstance, _) =>
    mmInstance.get('isGlobal')
  )

  return (
    <BannerHeader bannerTop={bannerTop}>
      <BannerWrench />
      <BannerText globalMMExists={globalMMExists} />
      <HelpPopover />
      <BannerControl />
    </BannerHeader>
  )
}

export default MaintenanceModeBanner
