import {
  ON_CALL_UPDATE,
  ON_CALL_LIST_UPDATE,
  NEXT_ON_CALL_UPDATE,
} from 'components/store/actions'

import { Map, List, fromJS } from 'immutable'

const defaultState = fromJS({
  CURRENT_TAKES: [],
  NEXT_OFF_CALL: [],
  NEXT_ON_CALL: [],
  ONCALL_LIST: [],
  ONCALL: {},
})

export default function onCallReducer(state = defaultState, action) {
  switch (action.type) {
    case NEXT_ON_CALL_UPDATE:
      return state.merge({
        CURRENT_TAKES: fromJS(action.payload.current_takes),
        NEXT_OFF_CALL: fromJS(action.payload.next_off_call),
        NEXT_ON_CALL: fromJS(action.payload.next_on_call),
      })
    case ON_CALL_UPDATE:
      return state.set(
        'ONCALL',
        updateOnCall(state.get('ONCALL'), action.payload)
      )
    case ON_CALL_LIST_UPDATE:
      return state.set('ONCALL_LIST', fromJS(action.payload))
    default:
      return state
  }
}

function updateOnCall(state, payload) {
  const list = payload.reduce((prev, group) => {
    const exists = prev.get(group.GROUP_ID, List())
    return prev.set(group.GROUP_ID, exists.push(group.USER_ID))
  }, Map())
  return state.merge(fromJS(list))
}
