// vendor
import $ from 'jquery'
import Backbone from 'lib/backbone'

// lib
import User from '../models/user'
import interpolate from 'util/interpolate_engagement'

var config = window.VO_CONFIG

export default Backbone.Collection.extend({
  initialize: function() {
    this.deferredsCache = {}
    this.deferredLoad = new $.Deferred()

    this.listenTo(this, 'adduser', this.resolveDeferred, this)

    this.listenTo(
      this,
      'users:loaded',
      function() {
        this.deferredLoad.resolve(this)
      },
      this
    )
  },

  // Overrides
  // -------------------------------------------------------------------

  comparator: function(a, b) {
    var you = this.compareByIsYou(a, b)
    if (you !== 0) {
      return you
    }

    var oc = this.compareByOnCall(a, b)
    if (oc !== 0) {
      return oc
    }

    var engagement = this.compareByEngagement(a, b)
    if (engagement !== 0) {
      return engagement
    }

    return this.compareByName(a, b)
  },

  model: User,

  // Comparators
  // -------------------------------------------------------------------

  compareByEngagement: function(a, b) {
    var aEngagement = a.get('ENGAGEMENT') || 0
    var bEngagement = b.get('ENGAGEMENT') || 0

    var aRange = interpolate(aEngagement)
    var bRange = interpolate(bEngagement)

    if (aRange > bRange) {
      return -1
    } else if (aRange < bRange) {
      return 1
    } else {
      return 0
    }
  },

  compareByIsYou: function(a, b) {
    var username = config.auth.user.username

    var aName = a.get('USER_ID') === username ? 1 : 0
    var bName = b.get('USER_ID') === username ? 1 : 0

    return bName - aName
  },

  compareByName: function(a, b) {
    var aLast = a.get('LAST_NAME') || ''
    var bLast = b.get('LAST_NAME') || ''
    var aFirst = a.get('FIRST_NAME') || ''
    var bFirst = b.get('FIRST_NAME') || ''

    var aName = aLast.toLowerCase() + aFirst.toLowerCase()
    var bName = bLast.toLowerCase() + bFirst.toLowerCase()

    return aName.localeCompare(bName)
  },

  compareByOnCall: function(a, b) {
    var aoc = a.isOnCall() ? 1 : 0
    var boc = b.isOnCall() ? 1 : 0

    return boc - aoc
  },

  // Custom
  // -------------------------------------------------------------------

  getDeferred: function(id) {
    // Deferred object indicating we have been loaded yet or not.
    if (!id) {
      return this.deferredLoad
    }

    if (this.deferredsCache[id]) {
      return this.deferredsCache[id]
    }

    var deferred = new $.Deferred()
    this.deferredsCache[id] = deferred

    return deferred
  },

  resolveDeferred: function(model) {
    var deferred = this.deferredsCache[model.id]

    if (!deferred) {
      deferred = new $.Deferred()
      this.deferredsCache[model.id] = deferred
    }

    if (deferred.state() !== 'resolved') {
      this.deferredsCache[model.id].resolve(model)
    }
  },
})
