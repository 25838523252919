// vendor
import _ from 'lib/underscore'
import $ from 'jquery'

// lib
import log from 'util/_console'
import server from '../../server'
import Users from '../collections/users'
import vent from 'util/vent'
var config = window.VO_CONFIG

var users = new Users()

users.deferredOnCall = new $.Deferred()

var findVersion = function(v) {
  var finder = { USER_ID: v.USER_ID, VERSION: v.VERSION }

  return users.findWhere(finder)
}

var missingVersion = function(v) {
  return _.isUndefined(findVersion(v))
}

var triggerResetOnCollection = _.debounce(function(collection) {
  collection.trigger('reset')
}, 300)

var fetchObject = function(id) {
  return server.requestUser(id, function(data) {
    // ensure user_id is delivered lowercase
    var outData = _.clone(data.PAYLOAD.USER)

    outData.USER_ID = outData.USER_ID.toLowerCase()
    users.trigger('adduser', users.add(outData, { silent: true, sort: false }))
    triggerResetOnCollection(users)
  })
}

var updateUserStatus = function(uid, status, engagement) {
  var deferred = users.getDeferred(uid)

  $.when(deferred).then(function(user) {
    user.set({ ENGAGEMENT: engagement, STATUS: status })
  })
}

var sortUsers = _.debounce(
  function(users) {
    log.info('data/managers/users:sortUsers')
    if (users && users.length > 1) {
      users.sort()
    }
  },
  500,
  {
    // leading: true
  }
)

var setUserOnCall = function(uid, gid, on) {
  var deferred = users.getDeferred(uid)

  return $.when(deferred).then(function(user) {
    user.setOnCallFor(gid, on === 'ON')
    vent.trigger('user:oncall', user)
    sortUsers(users)
  })
}

// on-connect sock message won't take user off call, msg for off-call
// doesn't fire on connect.  We need this for disconnect / reconnect
const auditUsers = function(sockData) {
  const onCallMap = new Map()
  const allDeferred = users.getDeferred()

  _.forEach(sockData, function(onCall) {
    if (!onCallMap.get(onCall.USER_ID)) {
      onCallMap.set(onCall.USER_ID, [onCall.GROUP_ID])
    } else {
      onCallMap.get(onCall.USER_ID).push(onCall.GROUP_ID)
    }
  })

  return $.when(allDeferred).then(function(users) {
    // Unfortunately we need to look at each user to make a diff
    // in case we're coming off of a disconnect / reconnect
    _.forEach(users.models, function(user) {
      const uid = user.get('USER_ID')
      const newOnCalls = onCallMap.get(uid) || []
      const currentOnCalls = _.map(user.onCallFor(), function(callObj) {
        return callObj.get('GROUP_ID')
      })

      _.forEach(currentOnCalls.concat(newOnCalls), function(call) {
        user.setOnCallFor(call, _.includes(newOnCalls, call))
        vent.trigger('user:oncall', user)
      })
    })

    sortUsers(users)
  })
}

const loadOnCallState = function(server) {
  // we need to track whether this is a new connection
  let reconnects = 0
  return function(sockData) {
    if (reconnects === 0 || server.connectCount >= reconnects) {
      // &&
      reconnects++
      return auditUsers(sockData)
    }
  }
}

var setChatMissed = function(room, missed) {
  var userNames = room.split(';')
  var userName = _.head(_.without(userNames, config.auth.user.username))

  if (!userName) {
    return // bail
  }

  var deferred = users.getDeferred(userName)

  $.when(deferred).then(function(user) {
    user.set('MISSED', missed)
  })
}

// on user state change, update the users
server.on('state:users', function(data) {
  var deferreds = []

  // fetch updated users
  _.chain(data)
    .filter(missingVersion)
    .map('USER_ID')
    .each(function(d) {
      deferreds.push(fetchObject(d))
    })
    .value()

  // remove users that aren't in the data list
  var dataNames = _.map(data, 'USER_ID')
  var userNames = users.map('USER_ID')
  var removeNames = _.difference(userNames, dataNames)

  _.each(removeNames, function(name) {
    users.remove(users.get(name))
  })

  $.when.apply(this, deferreds).then(function() {
    users.trigger('users:loaded')
    vent.trigger('peoplePane:usersLoaded', users.length)
  })
})

// on user status change, update the users
// any user that's not in the status message isn't connected to the server,
// and hence should have their engagement moved to 0 automatically.

var userStateCache // init

server.on('state:status', function(data) {
  var userStateString = JSON.stringify(data)

  if (userStateCache === userStateString) {
    log.warn('user_status:no change')
    return // bail
  }

  userStateCache = userStateString

  var currentIds = users.pluck('USER_ID')
  var incomingIds = _.map(data, 'USER_ID')
  var noStatusIds = _.difference(currentIds, incomingIds)

  _.each(noStatusIds, function(id) {
    data.push({ USER_ID: id, STATUS: '', ENGAGEMENT: 0 })
  })

  // now, every user is in data, but users that weren't there originally
  // have 0 engagement
  _.each(data, function(data) {
    updateUserStatus(data.USER_ID, data.STATUS, data.ENGAGEMENT)
  })

  sortUsers(users)

  log.info('user_status:updated')
})

// on oncall change, update the users
// handle the state changed for new connections (we use the notifies to get OFFs)
server.on('state:oncall', loadOnCallState.call(this, server))

server.on('state:chats', function(data) {
  _.each(data, function(data) {
    setChatMissed(data.ROOM_ID, data.NUMBER_MISSED)
  })
})

// handle on call notifies (this lets us handle OFF and ON states)
server.on('notify:oncall', function(data) {
  setUserOnCall(data.USER_ID, data.GROUP_ID, data.STATE)
})

// Update the users group membership. Use the deferred object
// to ensure that we have the users loaded first.
server.on('state:groups', function(data) {
  for (var i = 0; i < data.length; i++) {
    var group = data[i]
    var uids = group.uids || group.USER_ID_LIST

    for (var j = 0; j < uids.length; j++) {
      var deferredUser = users.getDeferred(uids[j])

      $.when(deferredUser).then(function(user) {
        user.set('groups', user.groupNames())
        user.set('group-slugs', user.groupSlugs())
      })
    }
  }
})

export default users
