// vendor
import debug from 'debug'

import { takeEvery, put, call } from 'redux-saga/effects'

// lib
import config from 'components/__utils/config'

import { updateNextOnCall, NEXT_ON_CALL_FETCH } from 'components/store/actions'

const error = debug('VO:sagas:error')

// Watchers
// ---------------------------------------------------------------------------
export function* watchUpdateNextOnCall(api) {
  yield takeEvery(NEXT_ON_CALL_FETCH, _getNextOnCall(api))
}

//  Local Functions
// ---------------------------------------------------------------------------

function _getNextOnCall(api) {
  return function*(action) {
    if (action.payload.username) {
      const onCallEndpoint = `/api/v1/user/${action.payload.username}/${config.orgslug}/next_on_call`

      try {
        const oResponse = yield call(api.fetch, onCallEndpoint)
        if (oResponse) {
          yield put(updateNextOnCall(oResponse))
        }
      } catch (err) {
        error(err)
      }
    }
  }
}

export const Test = {
  _getNextOnCall,
}
