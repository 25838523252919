import Event from '../models/event'

// file: event.suspend.js
// suspend event

export default Event.extend({
  defaults: {
    eventType: 'suspend',
  },
})
