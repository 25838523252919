import styled from 'styled-components'

export const Button = styled.button`
  background-color: transparent;
  border-bottom: 2px solid;
  border-bottom-color: ${({ isActive }) =>
    isActive ? '#fbb040' : 'transparent'};
  border-left: none;
  border-right: none;
  border-top: none;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  width: 42px;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    filter: invert(0.5) sepia(1) hue-rotate(150deg) saturate(4) brightness(1);

    &:disabled {
      cursor: default;
    }
  }
`

export const Img = styled.img`
  padding: 6px 0;
  max-width: 100%;
  max-height: 100%;
`

export const Integrations = styled.div`
  display: flex;
  flex: 1 0 auto;
`
