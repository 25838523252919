var computerToHuman = {
  // incident states
  investigating: 'Investigating',
  identified: 'Identified',
  monitoring: 'Monitoring',
  resolved: 'Resolved',

  // component states
  operational: 'Operational',
  degraded_performance: 'Degraded Performance',
  under_maintenance: 'Under Maintenance',
  partial_outage: 'Partial Outage',
  major_outage: 'Major Outage',
}

export default function(computer) {
  var human = computerToHuman[computer]

  if (!human) {
    return computer
  }

  return human
}
