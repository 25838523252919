import Immutable from 'immutable'

export default (username, onCallPolicies, currentTakes) => {
  const takenPolicies = currentTakes
    .filter(currentTake => currentTake.get('original_user') === username)
    .map(
      currentTake =>
        new Immutable.Map({
          endTime: currentTake.get('end_time'),
          onCallUsername: currentTake.get('override_user'),
          originalOnCallUsername: currentTake.get('original_user'),
          policyName: currentTake.get('policyName'),
          policySlug: currentTake.get('policySlug'),
          teamName: currentTake.get('name'),
          teamSlug: currentTake.get('slug'),
        })
    )

  return takenPolicies.concat(onCallPolicies)
}
