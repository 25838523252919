/**
 * TESTING ONLY! -- example: simulate funky network conditions on the socket:
 * delayedFunction = delayWrapper('hotel-mode')(myOriginalFunction, bindTo)
 * sessionStorage.setItem('hotel-mode', true) // induces random delay before calling orginal function
 * sessionStorage.setItem('hotel-mode-lag', xxxxx) // set delay to known value, in milliseconds
 **/
export const delayWrapper = function(key, delayValueKey) {
  return function(fn, that) {
    return function() {
      const args = arguments
      if (Storage && sessionStorage.getItem(key)) {
        const timeout =
          +sessionStorage.getItem(delayValueKey) ||
          Math.floor(Math.random() * 30000)
        setTimeout(function() {
          fn.apply(that, args)
          console.info(
            'induced connection lag: %O delayed %i ms',
            args,
            timeout
          )
        }, timeout)
      } else {
        fn.apply(that, args)
      }
    }
  }
}
