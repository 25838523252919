// vendor
import Marionette from 'marionette'
import _ from 'lodash'
import $ from 'jquery'

// lib
import contact from './contact'
import Loading from './loading'
import log from 'util/_console'
import flatCollectionTpl from 't/rolodex/flat.collection.tpl'

const Contact = contact('ungrouped')
const config = window.VO_CONFIG

export default Marionette.CompositeView.extend({
  initialize: function(options) {
    this.hideFilters = options.hideFilters
    this.actionList = options.actionList

    if (!this.currentFilter) {
      this.currentFilter = 'flat-all'
    }
  },

  onAttach: function() {
    // We'll handle re-renders on sort by creating our own event
    // listener. This allows us to debounce subsequent re-renders,
    // which we can then cancel if this view is destroyed.
    this.debouncedRender = _.debounce(
      _.bind(function() {
        this.render()
      }, this),
      500,
      {
        // 'leading': true, // do the first one immediately
        // 'trailing': false
      }
    )
    this.listenTo(this.collection, 'sort', this.debouncedRender)
  },

  // Overrides
  // -------------------------------------------------------------------

  childView: Contact,

  childViewContainer: '.js-rolodex-list-container',

  childViewOptions: function() {
    return {
      actionList: this.actionList,
      tagName: 'li',
    }
  },

  className: 'rolodex-list rolodex-list--contacts',

  emptyView: Loading,

  events: {
    'click .js-rolodex-filters': 'onFilter',
  },

  reorderOnSort: false, // we'll handle this

  tagName: 'div',

  template: flatCollectionTpl,

  // Events
  // -------------------------------------------------------------------

  onDestroy: function() {
    this.debouncedRender.cancel()
  },

  onRender: function() {
    log.info('rolodex/flat.collection:render')

    if (!this.myself) {
      this.myself = this.collection // async blech
        .findWhere({ USER_ID: config.auth.user.username })
    }

    if (this.hideFilters) {
      this.$('.js-rolodex-filters').hide()
    } else {
      this.$('[data-toggle="' + this.currentFilter + '"]').addClass('current')
    }
  },

  // Custom Event Callbacks
  // -------------------------------------------------------------------

  onFilter: function(e) {
    e.preventDefault()

    var $target = $(e.target)
    var which = $target.data('toggle')

    this.currentFilter = which

    switch (which) {
      case 'flat-oncall':
        this.filter = this.filterByOnCall
        this.render()
        break

      case 'flat-online':
        this.filter = this.filterByOnline
        this.render()
        break

      case 'flat-teammates':
        this.filter = this.filterByTeammates
        this.render()
        break

      default:
        this.filter = null
        this.render()
        break
    }
  },

  // Custome Event Callbacks
  // -------------------------------------------------------------------
  filterByOnCall: function(child) {
    return child.get('oncall').length > 0
  },

  filterByOnline: function(child) {
    return child.get('ENGAGEMENT') > 74
  },

  filterByTeammates: function(child) {
    var same = _.intersection(
      child.get('group-slugs'),
      _.without(this.myself.get('group-slugs'), 'everyone')
    )

    return same.length > 0
  },
})
