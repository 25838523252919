import moment from 'moment'

export default function processControlCall(timelineMessage) {
  const _timestamp = moment(
    timelineMessage.getIn(['SEQUENCING', 'SERVICE_TIME'])
  ).format('MMM. D - h:mm A')

  return timelineMessage.withMutations(message => {
    message
      .setIn(['componentProps', 'eventType'], 'system')
      .setIn(['componentProps', 'filterType'], 'action-controlcall')
      .setIn(['componentProps', 'timestamp'], _timestamp)

    if (message.get('STATE') === 'INITIALIZED') {
      message.setIn(
        ['componentProps', 'displayMessage'],
        `Control Call ${message.get('NAME')} started by ${message.get(
          'INITIATOR'
        )}`
      )
    } else if (message.get('STATE') === 'ENDED') {
      message.setIn(
        ['componentProps', 'displayMessage'],
        `Control Call ${message.get('NAME')} ended`
      )
    } else if (message.get('STATE') === 'FAILED') {
      message.setIn(
        ['componentProps', 'displayMessage'],
        `An error occurred while trying to set up the Control Call. Please try again later.`
      )
    } else {
      const participantsMessages = message
        .get('PARTICIPANTS')
        .map(participant => {
          if (participant.get('IN_CALL')) {
            return `${participant.get('USER_ID') ||
              participant.get('PHONE_NUM')} joined ${message.get('NAME')}`
          } else {
            return `${participant.get('USER_ID') ||
              participant.get('PHONE_NUM')} left ${message.get('NAME')}`
          }
        })
      message.setIn(['componentProps', 'displayMessage'], participantsMessages)
    }
  })
}
