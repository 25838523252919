var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class=\'js-statuspage-alert-label\'>' +
((__t = ( label )) == null ? '' : __t) +
'</span>\n<i class="js-statuspage-alert-state">\n  <i class="far fa-square"></i>\n  <i class="far fa-check-square"></i>\n</i>\n';

}
return __p
}