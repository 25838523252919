// vendor
import { Map, fromJS } from 'immutable'

// lib
import {
  MANUAL_INCIDENT_CLEAR_STATUS,
  MANUAL_INCIDENT_CREATE_FAILED,
  MANUAL_INCIDENT_RECIPIENTS,
  MANUAL_INCIDENT_RECIPIENTS_RESET,
  MANUAL_INCIDENT_RESET,
  MANUAL_INCIDENT_UPDATE,
} from 'components/store/actions'

const defaultcreateIncidentRecipients = {
  teams: {},
  users: {},
  usersByTeam: {},
  policies: {},
  policiesByTeam: {},
}

// TODO: maybe we can get rid of the hardcoded keys?
const defaultState = fromJS({
  createIncidentFormFields: {},
  createIncidentFormStatus: {},
  createIncidentRecipients: defaultcreateIncidentRecipients,
})

export default function manualIncidentReducer(state = defaultState, action) {
  switch (action.type) {
    case MANUAL_INCIDENT_CLEAR_STATUS:
      return clearCreateIncidentStatus(state, action.payload)
    case MANUAL_INCIDENT_CREATE_FAILED:
      return updateCreateIncidentStatus(state, action.payload)
    case MANUAL_INCIDENT_RECIPIENTS:
      return updateManualIncidentRecipients(state, action.payload)
    case MANUAL_INCIDENT_RECIPIENTS_RESET:
      return resetRecipients(state, action.payload)
    case MANUAL_INCIDENT_RESET:
      return resetFormAndStatus(state, action.payload)
    case MANUAL_INCIDENT_UPDATE:
      return updateCreateIncidentForm(state, action.payload)
    default:
      return state
  }
}

function updateCreateIncidentForm(state, payload) {
  return state.merge(fromJS({ createIncidentFormFields: payload }))
}

function updateCreateIncidentStatus(state, payload) {
  return state.merge(fromJS({ createIncidentFormStatus: payload }))
}

function clearCreateIncidentStatus(state, payload) {
  return state.merge(fromJS({ createIncidentFormStatus: payload }))
}

function resetFormAndStatus(state) {
  // TODO: again, with the hardcoded keys here. Must be a way out of that.
  return state.merge({
    createIncidentFormStatus: {},
    createIncidentRecipients: defaultcreateIncidentRecipients,
  })
}

function resetRecipients(state) {
  return state.merge({
    createIncidentRecipients: defaultcreateIncidentRecipients,
  })
}

function updateManualIncidentRecipients(state, payload) {
  const recipients = state.get(
    'createIncidentRecipients',
    Map(defaultcreateIncidentRecipients)
  )

  function mapUpdates(key) {
    return Map({ [key]: recipients.get(key, Map()).merge(payload[key]) })
  }

  function reduceUpdates(prev, curr) {
    return prev.concat(curr)
  }

  const nextState = Object.keys(payload)
    .map(mapUpdates)
    .reduce(reduceUpdates, Map())

  return state.merge(fromJS({ createIncidentRecipients: nextState }))
}
