var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="l-card control-call-card">\n    <div class="control-call-card-username" data-ext="control-call-card-username">\n        <span>@' +
((__t = ( USER_ID )) == null ? '' : __t) +
'</span>\n    </div>\n\n    <span class="js-member-remove"><i class="fas fa-times"></i></span>\n</div>\n';

}
return __p
}