// vendor
import React from 'react'
import { isEqual } from 'lodash'

// lib
import Dimensions from 'components/__utils/dimensions'
import IncidentsNav from './incidents-nav'
import IncidentsTable from './incidents-table'

// Export
// ---------------------------------------------------------------------------

export default class IncidentsPane extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.state, nextState)
  }

  render() {
    const Navigation = Dimensions(IncidentsNav)
    const Incidents = Dimensions(IncidentsTable, 'incidents-table-container')

    return (
      <div className='module-incidents-pane'>
        <div className='l-inner'>
          <div className='module-incidents-pane--fix-zindex-wrapper'>
            <Navigation />

            <Incidents />
          </div>
        </div>
      </div>
    )
  }
}
