import { Map as iMap, fromJS } from 'immutable'

import { MAINTENANCE_UPDATE } from 'components/store/actions'

export default function maintenance(state = iMap({}), action) {
  switch (action.type) {
    case MAINTENANCE_UPDATE:
      return _updateMaintenance(state, action.payload)

    default:
      return state
  }
}

export function _updateMaintenance(state, payload) {
  const next = payload.activeInstances.reduce((acc, instance) => {
    acc[instance.instanceId] = instance
    return acc
  }, {})
  return fromJS(next)
}
