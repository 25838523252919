import React, { Component } from 'react'

import { Chat } from '@victorops/victory'
import { logError } from '../../../util/monitoringService'

export class RowErrorBoundary extends Component {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, componentStack) {
    const { message } = this.props

    const customData = { ...componentStack, message }
    const tags = ['react', 'errorBoundary', 'timeline']

    logError(error, { groupBy: tags, data: customData })
  }

  getErrorMessage = () => (
    <span>
      This message cannot be displayed right now. For help, please contact
      support via live chat or open a case on the{' '}
      <a href='https://login.splunk.com/'>Splunk Support Portal</a>.
    </span>
  )

  render() {
    const { resize } = this.props

    return this.state.hasError ? (
      <Chat
        chatChannel={''} // we do not have the data from backend yet. pass when available.
        chatMessage={this.getErrorMessage()}
        chatTimestamp={''}
        chatType={''}
        onImageLoaded={resize}
        isRobot
        parsedMessage={[]}
        userID={'victorops'}
      />
    ) : (
      this.props.children
    )
  }
}
