import moment from 'moment'

import { decodeHtml as _decodeHtml } from 'components/__utils/html'

function _buildChatfilterType(message) {
  if (message.get('IS_ROBOT')) {
    return 'robot-chat'
  } else if (message.get('IS_ONCALL')) {
    return 'oncall-chat'
  } else {
    return 'person-chat'
  }
}

function _isImageUrl(url) {
  var regex = /\.([a-z]{3,4})[^a-z]*$/
  var ext = url.toLowerCase().match(regex)

  if (ext) {
    ext = ext[1] // the extension capture group
    return (
      ext === 'jpg' ||
      ext === 'jpeg' ||
      ext === 'png' ||
      ext === 'gif' ||
      ext === 'bmp' ||
      ext === 'svg'
    )
  }
}

function _parseLinks(text) {
  /* eslint-disable */
  var regex = /(?:^[^@#])([A-Za-z]{3,9}:\/\/)?((.+)(:.+)?@)?[A-Za-z0-9\-]+\.[A-Za-z0-9]+[A-Za-z0-9\-\.]+(:\d{0,6})?(\/([A-Za-z0-9\-\._~()=]|%[0-9]{2})*)*(\?[^#]*)?(#.*)?/g
  const parsedValue = {}

  // There's not a decent way to skip callback arguments in JS and I'm
  // not inclined to mess with the regex to adjust the groupings
  // so we are using underscores of increasing alphabet per skipped argument
  var result = text.replace(regex, function(
    match,
    proto,
    _a,
    userpass,
    _b,
    _c,
    lastpath,
    _d,
    query,
    fragment
  ) {
    var url = match
    var numbers = /^(?:[0-9\.]*)$/
    var inlineMentions = /.*\s+@+.*/g
    /* eslint-enable */

    // If the match is comprised only of numbers, it's not a link.
    if (numbers.test(match)) {
      return match
    }

    if (inlineMentions.test(match)) {
      return match
    }

    // Add http://
    if (proto == null) {
      url = 'http://' + match
    }

    if (!_isImageUrl(url)) {
      lastpath = null
    }

    parsedValue.url = url

    if (lastpath) {
      parsedValue.type = 'image'
    } else {
      parsedValue.type = 'link'
    }

    return match
  })

  parsedValue.text = result

  if (!parsedValue.type) {
    parsedValue.type = 'text'
  }
  return parsedValue
}

/* eslint-enable */

export default function processChat(timelineMessage) {
  const chatContent = _decodeHtml(timelineMessage.get('TEXT'))
  const _timestamp = moment(
    timelineMessage.getIn(['SEQUENCING', 'SERVICE_TIME'])
  ).format('MMM. D - h:mm A')

  if (!chatContent) {
    return timelineMessage.withMutations(message => {
      message.setIn(['componentProps', 'timestamp'], _timestamp)
    })
  } else if (
    timelineMessage.has('TAGS') &&
    timelineMessage.get('TAGS').includes('typechatreroutednotify')
  ) {
    return timelineMessage.withMutations(message => {
      message
        .setIn(['componentProps', 'displayMessage'], chatContent)
        .setIn(['componentProps', 'filterType'], _buildChatfilterType(message))
        .setIn(['componentProps', 'timestamp'], _timestamp)
        .setIn(['componentProps', 'eventType'], 'system')
    })
    /* eslint-disable */
  } else if (/I don\'t know who .* is/.test(timelineMessage.get('TEXT'))) {
    // TODO: could probably get rid of this
    /* eslint-enable */
    return timelineMessage.withMutations(message => {
      message
        .setIn(['componentProps', 'displayMessage'], chatContent)
        .setIn(['componentProps', 'eventType'], 'chat')
        .setIn(['componentProps', 'timestamp'], _timestamp)
        .setIn(['componentProps', 'filterType'], _buildChatfilterType(message))
    })
  }

  var processed = chatContent.split(' ').map(_parseLinks)

  return timelineMessage.withMutations(message => {
    message
      .setIn(['componentProps', 'parsedMessage'], processed)
      .setIn(['componentProps', 'eventType'], 'chat')
      .setIn(['componentProps', 'filterType'], _buildChatfilterType(message))
      .setIn(['componentProps', 'timestamp'], _timestamp)
  })
}
