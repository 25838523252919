import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { SuggestedResponders } from './suggested-responders'
import { getFeatureFlags } from 'components/store/selectors'

import { Picker, Pill } from '@victorops/victory'

import OffCallWarning from './off-call-warning'
import CBDetails from './cb-details'
import {
  CB_DETAILS_STATES,
  checkDetailsValidity,
} from 'components/incidents/view/incidents-table/incident-actions/cb-details/cb-details-utils'

const successModal = (dismiss, props) => ({
  modalType: 'confirm',
  modalProps: {
    actionBar: false,
    component: (
      <div>
        <p className='reroute-success'>
          These teams or individuals have been added to paging for Incident #
          {props.incidentId}:
        </p>
        <ul className='success-modal--list'>
          {props.selectedOptions.map(function(item) {
            return (
              <li key={item.id}>
                <Pill
                  name={`${item.pillLabel ? item.pillLabel : item.label}`}
                  pillType={item.type}
                  slug={item.id}
                  swallowClick
                />
              </li>
            )
          })}
        </ul>

        <ul className='button-list right'>
          <li>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={dismiss}
            >
              Close
            </button>
          </li>
        </ul>
      </div>
    ),
    onCancel: dismiss,
    title: 'Responders Added',
  },
})

const failureModal = (dismiss, props) => ({
  modalType: 'confirm',
  modalProps: {
    actionBar: false,
    component: (
      <div>
        <p>Could not add responders to incident #{props.incidentId}</p>
        <ul className='button-list right'>
          <li>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={dismiss}
            >
              Close
            </button>
          </li>
        </ul>
      </div>
    ),
    onCancel: dismiss,
  },
})

function mapStateToProps(state) {
  return {
    isConferenceBridgesEnabled: getFeatureFlags(state).get(
      'feature:conferencebridges'
    ),
  }
}

const AddRespondersComponent = props => {
  const {
    entityDisplayName,
    entityID,
    filter,
    hasSuggestedResponders,
    hostName,
    incidentId,
    isConferenceBridgesEnabled,
    isMuted,
    monitoringTool,
    onAddResponders,
    onAddConferenceBridge,
    onCancel,
    onFilterChange,
    onSelectedChange,
    options,
    placeholder,
    routingKey,
    selectedOptions,
    service,
  } = props

  const payload = {
    incidentId: incidentId,
    selected: selectedOptions,
    failureModal: failureModal(onCancel, props),
    successModal: successModal(onCancel, props),
  }

  const [detailsAreValid, setDetailsAreValid] = useState(
    CB_DETAILS_STATES.empty
  )
  const [url, setUrl] = useState('')
  const [phone, setPhone] = useState('')
  const [notes, setNotes] = useState('')

  useEffect(() => {
    setDetailsAreValid(checkDetailsValidity(url, phone, notes))
  }, [url, phone, notes])

  // Determine whether we are adding responders, conf bridge details, or both
  const onAddClick = () => {
    const confBridgePayload = {
      incidentId,
      conferenceBridge: {
        url: url.trim(),
        phone: phone.trim(),
        notes: notes.trim(),
      },
    }

    // If we need to add responders AND update the conf bridge details, use a promise to chain them
    // this promise should not be cancelled on destroy of this component. This modal will be destroyed
    // before we have the chance to resolve the promise.  @TODO refactor if at all possible.
    if (
      selectedOptions.length > 0 &&
      detailsAreValid === CB_DETAILS_STATES.valid
    ) {
      new Promise((resolve, reject) => {
        payload.addRespondersPromise = {
          resolve,
          reject,
        }
        onAddResponders(payload)
      }).then(() => {
        onAddConferenceBridge(confBridgePayload)
      })
    }

    // only make the call to server for the appropriate information the user typed out.
    else {
      if (selectedOptions.length > 0) {
        onAddResponders(payload)
      }

      if (detailsAreValid === CB_DETAILS_STATES.valid) {
        onAddConferenceBridge(confBridgePayload)
      }
    }
  }

  return (
    <div>
      {isMuted ? (
        <p className='alert alert-warning'>
          This incident is currently muted. Adding responders to this incident
          will remove it from Maintenance Mode and start paging the selected
          escalation policies and/or users.
        </p>
      ) : null}
      <p>Page additional teams or individuals to join this incident.</p>
      <Picker
        filter={filter}
        focusOnRender
        options={options}
        placeholder={placeholder}
        selectedOptions={selectedOptions}
        onFilterChange={onFilterChange}
        onSelectedChange={onSelectedChange}
      />

      <OffCallWarning selectedOptions={props.selectedOptions} />

      {hasSuggestedResponders && (
        <SuggestedResponders
          entityDisplayName={entityDisplayName}
          entityID={entityID}
          hostName={hostName}
          monitoringTool={monitoringTool}
          onSelectedChange={onSelectedChange}
          routingKey={routingKey}
          selectedOptions={selectedOptions}
          service={service}
        />
      )}

      {isConferenceBridgesEnabled && (
        <CBDetails
          {...{
            headerText: 'Conference Bridge',
            setNotes,
            setPhone,
            setUrl,
          }}
        />
      )}

      <ul className='button-list right'>
        <li>
          <button
            className='btn btn-secondary'
            data-test-key='add-responders--cancel'
            onClick={onCancel}
            type='button'
          >
            Cancel
          </button>
        </li>

        <li>
          <button
            className='btn btn-outline-primary'
            data-test-key='add-responders--add'
            disabled={
              (selectedOptions.length < 1 &&
                detailsAreValid !== CB_DETAILS_STATES.valid) || // empty CB details only ok if you select a responder
              (selectedOptions.length > 0 &&
                detailsAreValid === CB_DETAILS_STATES.invalid)
            }
            onClick={onAddClick}
            type='button'
          >
            Add
          </button>
        </li>
      </ul>
    </div>
  )
}

export default connect(mapStateToProps)(AddRespondersComponent)
