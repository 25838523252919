// vendor
import Rx from 'rxjs'

import { head, map, tail } from 'lodash'

// victorops
import { hotpathProtocol } from '@victorops/message-protocol'

export default function(source, tsend) {
  return Rx.Observable.create(function createObserver(observer) {
    const userTypes = ['STATE_NOTIFY_MESSAGE', 'USER_REPLY_MESSAGE']
    const hotStreams = map(userTypes, type => hotpathProtocol(type, source))
    const usersStream = Rx.Observable.merge(...hotStreams).filter(el =>
      head(tail(el))
    )

    let cache = null

    function checkUserStatusList(payload) {
      const newMessage = JSON.stringify(payload)

      if (cache !== newMessage) {
        cache = newMessage

        payload.forEach(function(user) {
          observer.next(user)
        })
      }
    }

    // @TODO: This observable published mixed concerns, and should be
    // broken up into multiple observables that have corresponding Redux
    // actions.
    usersStream.subscribe(
      function next(el) {
        const type = head(el)
        const payload = head(tail(el))

        switch (type) {
          case 'USER_REPLY_MESSAGE':
            observer.next(payload)
            break
          case 'USER_STATUS_LIST':
            checkUserStatusList(payload)
            break

          // This duplicates some functionality that we currently
          // get as a side-effect of the old application code.
          // Therefore, we don't need to explicitly fetch user data
          // until that code dies.
          //
          // @see: data/managers/users.js fetchObject()
          // case 'USER_VERSION_LIST'  : pushUserVersionList(observer, tsend, payload); break
          default:
            break
        }
      },

      function onError(err) {
        observer.error(err)
      },

      function onCompleted() {
        observer.complete()
      }
    )
  })
}
