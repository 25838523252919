import { connect } from 'react-redux'
import {
  fetchConferenceBridgeDetails,
  setConferenceBridgeShowDetailsView,
  setConferenceBridgeDetailsError,
} from 'components/store/actions'

import { ConferenceBridgeDisplayViewContainer } from './conference-bridge-display-view'

const mapStateToProps = ({ incidentDetails }, ownProps) => {
  const incidentId = ownProps.incident.get('INCIDENT_NAME')
  return {
    error: incidentDetails.get('conferenceBridgeDetailsError'),
    details: incidentDetails.getIn([
      'conferenceBridgeDetails',
      incidentId,
      'details',
    ]),
    isViewExpanded: incidentDetails.getIn(
      ['conferenceBridgeDetails', incidentId, 'showDetails'],
      false
    ),
  }
}

const mapDispatchToProps = {
  fetchConferenceBridgeDetails,
  setConferenceBridgeShowDetailsView,
  setConferenceBridgeDetailsError,
}

export const ConferenceBridgeDisplayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConferenceBridgeDisplayViewContainer)
