import { fromJS } from 'immutable'

import { ORG_STATE, TOGGLE_OFFLINE_MODE } from 'components/store/actions'

const defaultState = fromJS({
  COMPANY_SUSPENDED: false,
  COMPANY_OFFLINE: false,
})

export default function orgstateReducer(state = defaultState, action) {
  switch (action.type) {
    case ORG_STATE:
      return updateOrgState(state, action.payload)
    case TOGGLE_OFFLINE_MODE:
      return toggleOfflineMode(state, action.payload)
    default:
      return state
  }
}

function updateOrgState(state, status) {
  return state.set('COMPANY_SUSPENDED', status[1].COMPANY_SUSPENDED)
}

function toggleOfflineMode(state, status) {
  return state.set('COMPANY_OFFLINE', status.COMPANY_OFFLINE)
}
