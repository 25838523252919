// vendor
import React from 'react'

// lib
import CreateButton from 'components/manual-incident/create-button'
import IncidentsNavAudience from './incidents-nav-audience'
import IncidentsNavCounter from './incidents-nav-counter'
import IncidentsNavPhase from './incidents-nav-phase'
import IncidentsActionButton from './incidents-action-button'

// Export
// ---------------------------------------------------------------------------
export default function IncidentsNav(dimensions) {
  const { containerWidth } = dimensions
  const minifiedClass =
    containerWidth >= 460 && containerWidth < 625 ? 'minified' : ''

  return (
    <div className='incidents-nav'>
      <IncidentsNavAudience width={containerWidth} />

      <div className={`incidents-subnav ${minifiedClass}`}>
        <div className='l-first'>
          <IncidentsNavPhase width={containerWidth} />

          <IncidentsActionButton />

          <CreateButton />
        </div>

        <div className='l-second'>
          <IncidentsNavCounter />
        </div>
      </div>
    </div>
  )
}
