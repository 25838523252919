// vendor
import { Map, fromJS } from 'immutable'

import { assign } from 'lodash'

import { TEAMS_WITH_POLICIES_UPDATE } from 'components/store/actions'

// import debug from 'debug'

export default function teamsWithPolicies(state = Map({}), action) {
  switch (action.type) {
    case TEAMS_WITH_POLICIES_UPDATE:
      return _updateTeamsWithPolicies(action.payload)
    default:
      return state
  }
}

// @TODO: Normalize this data with normalizr
//
// _policies are nested in teams. They should be normalized instead.

// @TODO: This duplicates admin-store/reducers/teams. We should just
// consolidate all reducers into the same folder, come up with good names, and
// then let the apps use combineReducers() the way that they like.
export function _updateTeamsWithPolicies(payload) {
  const next = fromJS(
    payload.reduce(function(acc, el) {
      acc[el.slug] = assign({}, el, {
        _policies: el._policies.map(policy =>
          assign({}, policy, { teamSlug: el.slug, teamName: el.name })
        ),
      })

      return acc
    }, {})
  )

  return next
}
