// vendor
import Backbone from 'lib/backbone'
import LocalStorage from 'lib/backbone.localstorage'

// Model
// -----------------------------------------------------------------------

var Team = Backbone.Model.extend({
  defaults: {
    own_team: false,
    team_slug: null,
    view_state: 'collapsed',
  },

  idAttribute: 'team_slug',
})

// Collection
// -----------------------------------------------------------------------

export default function(slug) {
  return Backbone.Collection.extend({
    model: Team,

    localStorage: new LocalStorage(slug + ':Teams'),

    initialize: function() {},
  })
}
