// vendor
import Marionette from 'lib/marionette'
import _ from 'lib/underscore'

// lib
import BaseNotification from './base-notification'
import ControlCallEnd from '../modals/controlcall-end'
import ControlCallTpl from './templates/controlcall.tpl'
import data from '../../data'
import vent from 'util/vent'

var config = window.VO_CONFIG

export default BaseNotification.extend({
  template: ControlCallTpl,

  initialize: function() {
    _.bindAll(this, ['onRender', 'serializeData', 'endCall'])

    this.listenTo(
      this.model,
      'change:STATE',
      _.bind(function() {
        if (this.model.get('STATE') === 'ENDED') {
          vent.trigger('banner:hide', 'controlcall')
        }
      }, this)
    )

    data.users.getDeferred(this.model.get('INITIATOR')).done(
      _.bind(function() {
        this.model.trigger('change:INITIATOR', this.model)
      }, this)
    )

    // sort order position
    this.$el.data('weight', 0)
  },

  events: {
    'click .js-action-endcall': 'endCall',
    'click .dropdown-menu': function(e) {
      // bootstrap dropdown closes on click, but here we want the ability to copy call details
      e.stopPropagation()
    },
  },

  bindings: {
    '.js-controlcall-owner': {
      observe: 'INITIATOR',
      onGet: function() {
        if (
          !this.model.get('INITIATOR_FULLNAME') &&
          this.model.get('INITIATOR')
        ) {
          var owner = data.users.findWhere({
            USER_ID: this.model.get('INITIATOR'),
          })
          this.model.set(
            'INITIATOR_FULLNAME',
            owner ? owner.get('fullname') : ''
          )
        }
        return this.model.get('INITIATOR_FULLNAME')
      },
    },

    '.js-controlcall-name': 'NAME',

    '.js-controlcall-dialin': {
      observe: 'DIAL_IN',
      onGet: function() {
        var number = this.model.get('DIAL_IN')
        if (number && number.length === 10) {
          var groups = /([0-9]{3})([0-9]{3})([0-9]{4})/.exec(number)
          return groups[1] + '-' + groups[2] + '-' + groups[3]
        }
        return number
      },
    },

    '.js-controlcall-accesscode': 'ACCESS_CODE',

    '.js-controlcall-count': 'PARTICIPANT_COUNT',

    '.js-controlcall-people': {
      observe: 'PARTICIPANT_COUNT',
      onGet: function() {
        return this.model.get('PARTICIPANT_COUNT') === 1
          ? 'Participant'
          : 'Participants'
      },
    },
  },

  onRender: function() {
    this.$('.dropdown-toggle').dropdown()

    if (this.model.get('INITIATOR') === config.auth.user.username) {
      this.$('.js-controlcall-endbutton').show()
    } else {
      this.$('.js-controlcall-endbutton').hide()
    }

    this.stickit()
  },

  serializeData: function() {
    var data = Marionette.View.prototype.serializeData.apply(this, arguments)

    return data
  },

  endCall: function() {
    var view = new ControlCallEnd({ model: this.model })

    vent.trigger('modal:small:show', view)
  },
})
