import _ from 'lodash'

function StatusPageIoProvider() {}

StatusPageIoProvider.prototype.getHandlers = function(data) {
  var handlers = []
  if (_.isString(data.MESSAGE_TYPE) && _.isFunction(this[data.MESSAGE_TYPE])) {
    handlers = handlers.concat(this[data.MESSAGE_TYPE](data))
  }
  return handlers
}

StatusPageIoProvider.prototype.INCIDENT_NOTIFY = function(data) {
  var handlers = []
  _.each(_.keys(data), function(key) {
    switch (key) {
      case 'MESSAGE_BODY':
        handlers.push(['statuspage:incident', data])
        break
    }
  })
  return handlers
}

StatusPageIoProvider.prototype.COMPONENT_UPDATE_NOTIFY = function(data) {
  var handlers = []
  _.each(_.keys(data), function(key) {
    switch (key) {
      case 'MESSAGE_BODY':
        handlers.push(['statuspage:component_update', data])
        break
    }
  })
  return handlers
}

var provider

export default {
  getInstance: function() {
    if (_.isUndefined(provider)) {
      provider = new StatusPageIoProvider()
    }
    return provider
  },
}
