import React, { Component } from 'react'
import { connect } from 'react-redux'
import { makeGetFeatureFlag } from 'components/store/selectors'

function mapStateToProps(state) {
  const getFeatureFlag = makeGetFeatureFlag()

  return {
    disableIncidentAllActions: getFeatureFlag(state, {
      id: 'feature:disableincidentallactions',
    }),
    protectResolveAll: getFeatureFlag(state, {
      id: 'feature:protectresolveall',
    }),
  }
}

class IncidentsActionButton extends Component {
  render() {
    const {
      actionName,
      activeAudience,
      disableIncidentAllActions,
      isAdmin,
      onClick,
      phase,
      phaseCount,
      protectResolveAll,
      selectedAndAll,
    } = this.props

    if (
      disableIncidentAllActions ||
      (protectResolveAll &&
        !isAdmin &&
        !selectedAndAll.selected.size &&
        activeAudience !== 'your')
    ) {
      return null
    } else if (phase !== 'RESOLVED' && phaseCount > 0) {
      return (
        <div className='incidents-action'>
          <div className='l-inner'>
            <button
              className='btn btn-sm btn-secondary'
              onClick={onClick({
                ids: selectedAndAll.selected.size
                  ? selectedAndAll.selected
                  : selectedAndAll.all,
                phase: phase,
              })}
            >
              {actionName}
            </button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default connect(mapStateToProps)(IncidentsActionButton)
