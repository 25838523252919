import _ from 'lodash'
import isDebug from './isDebug'
import log from './_console'

var timings = {
  transactions: {},
}

// If we're debugging, create a global record
if (isDebug()) {
  window.all_timings = timings
}

var start = _.curry(function(type, id) {
  var times // init

  try {
    times = timings[type]
  } catch (e) {
    return // bail
  }

  if (!times[id]) {
    times[id] = {
      begin: log.now(),
    }
  } else {
    log.error('transaction already initialized')
  }
})

var stop = _.curry(function(type, id) {
  var times, timer // init

  try {
    times = timings[type]
    timer = times[id]
  } catch (e) {
    return // bail
  }

  timer.end = log.now()
  timer.time = timer.end - timer.begin

  return timer
})

var base = function(type) {
  var lookup = {
    start: start,
    stop: stop,
  }

  var fn = lookup[type]

  return fn
}

export default {
  start: base('start'),
  stop: base('stop'),
}
