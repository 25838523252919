import { PureComponent } from 'react'
import { logError } from '../../../../../util/monitoringService'

export class ConferenceBridgeDisplayErrorBoundary extends PureComponent {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, componentStack) {
    const { message } = this.props

    const data = { ...componentStack, message }
    const tags = [
      'react',
      'errorBoundary',
      'incident details: conference bridge',
    ]

    logError(error, { groupBy: tags, data })
  }

  render() {
    const { hasError } = this.state
    const { children, Fallback } = this.props

    return hasError ? Fallback : children
  }
}
