import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/fontawesome-pro-regular'

export const DeliveryInsightsBlankSlate = () => (
  <div className='delivery-insights-blankslate'>
    <FontAwesomeIcon icon={faCode} className='delivery-insights-facode' />
    <p>
      This action filters for Jenkins, GitHub and delivery insights API
      messages, but you do not have these integrations enabled.
    </p>
    <p>
      <a
        href={`/dash/${window.VO_CONFIG.orgslug}/#/advanced/di`}
        target='_blank'
        title='integrations'
      >
        Enable them now
      </a>{' '}
      on the integrations page.
    </p>
  </div>
)
