// vendor
import { fork } from 'redux-saga/effects'

import debug from 'debug'

// lib
import { watchRequestAlertDetails } from '../sagas/alerts'

import {
  _watchUpdateIncidentRouting,
  _watchUpdateIncidentResponders,
  watchCreateIncident,
  watchIncidentDetailsRequest,
  watchSnoozeIncident,
  watchUpdateAndFlushIncidentsPane,
  watchUpdateIncidentRouting,
  watchUpdateIncidentsState,
} from '../sagas/incidents'

import {
  watchFetchServiceNowDetails,
  watchFetchConferenceBridgeDetails,
  watchPutConferenceBridgeDetails,
} from '../sagas/incident-integrations'

import { watchGetPolicies, watchGetTeamsWithPolicies } from '../sagas/policies'

import { watchRequestTimelineHistory } from '../sagas/timeline'

import { watchGetRelatedIncidents } from '../sagas/related-incidents'

import { watchGetOrgMeta } from '../sagas/org-meta'
import { watchUpdateNextOnCall } from '../sagas/on-call'
import { watchUpdateTakeOnCall } from '../sagas/on-call-take'
import { watchGetHelpContent } from '../sagas/help-content'
import { watchGetIntegrationIcons } from '../sagas/integration-icons'
import { watchMaintenanceMode } from '../sagas/maintenance-mode'
import { watchFetchSuggestedResponders } from '../sagas/suggested-responders'

const error = debug('VO:sagas:error')

export default function makeRootSaga(voSocket, api, server) {
  return function* rootSaga() {
    yield fork(_watchUpdateIncidentRouting(voSocket))
    yield fork(_watchUpdateIncidentResponders(voSocket))
    yield fork(watchCreateIncident)
    yield fork(watchRequestTimelineHistory(voSocket))
    yield fork(watchGetHelpContent)
    yield fork(watchGetIntegrationIcons)
    yield fork(watchGetOrgMeta)
    yield fork(watchGetRelatedIncidents, api, error)
    yield fork(watchGetPolicies(api))
    yield fork(watchGetTeamsWithPolicies(api))
    yield fork(watchIncidentDetailsRequest, voSocket)
    yield fork(watchMaintenanceMode(api))
    yield fork(watchSnoozeIncident(voSocket))
    yield fork(watchUpdateAndFlushIncidentsPane)
    yield fork(watchUpdateIncidentRouting(voSocket))
    yield fork(watchUpdateIncidentsState(voSocket))
    yield fork(watchUpdateNextOnCall, api)
    yield fork(watchUpdateTakeOnCall(voSocket, server))
    yield fork(watchRequestAlertDetails, api, server)
    yield fork(watchFetchSuggestedResponders, api, error)
    yield fork(watchFetchServiceNowDetails, api, error)
    yield fork(watchFetchConferenceBridgeDetails, api, error)
    yield fork(watchPutConferenceBridgeDetails, api, error)
  }
}
