import Backbone from 'backbone'

// alert model (independant of timeline)

export default Backbone.Model.extend({
  idAttribute: 'VO_UUID',

  output: function() {
    if (this.get('VO_ALERT_TYPE') === 'SERVICE') {
      return this.get('SERVICEOUTPUT')
    } else {
      return this.get('HOSTOUTPUT')
    }
  },
})
