import * as pako from 'pako'
import { logError } from './monitoringService'

/**
 * Coordinate with the chat server and its integer representation of compression options
 * for which type of compression it is expecting, pako has other compression algos
 * available if we want to tweak this in the future
 * 0 = gzip
 * 1 = xxx etc...
 * @param {String} msg
 */
export function compress(msg) {
  return pako.gzip(JSON.stringify(msg))
}

/**
 * Decompress expects a Blob of binary data
 * @param {Blob} msg
 */
export function decompress(msg) {
  return new Response(msg)
    .arrayBuffer()
    .then(result => pako.inflate(result, { to: 'string' }))
    .catch(e => {
      logError(e, { data: { msg } })
    })
}
