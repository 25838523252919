// vendor
import reactApp from 'components/__entry'
import $ from 'jquery'

import Marionette from 'lib/marionette'
import _ from 'lib/underscore'
import 'lib/stickit'
import 'vendor/setimmediate'

// lib
import data from '../data'
import server from '../server'
import IncidentInfoTpl from 't/incident.info.tpl'
import AlertDetails from './alertdetails'
import TimelineContainer from './timeline-container'

export default Marionette.View.extend({
  initialize: function(options) {
    this.options = options
    if (!this.model.collection) {
      this.model = this.collection.get(this.model.id)
    }

    this.on('before:destroy', function() {
      reactApp.detach('incidentdetail')
    })
  },

  // Overrides
  // -----------------------------------------------------------------------

  bindings: {
    '.incident-leader .name': 'INCIDENT_NAME',

    '.incident-leader .entity': {
      observe: 'ENTITY_ID',

      update: function($el, val) {
        $el.html(val).attr('title', val)
      },
    },

    '.incident-leader .phase': {
      observe: 'CURRENT_ALERT_PHASE',

      update: function($el, val) {
        if (val === 'UNACKED') {
          $el.text('phase: TRIGGERED')
        } else {
          $el.text(`phase: ${val}`)
        }
      },
    },

    '.incident-leader .alert-count': {
      observe: 'ALERT_COUNT',

      update: function($el, val) {
        this.filterCountTxt = 'alerts: ' + val
        $el.text(this.filterCountTxt)
      },
    },

    '.incident-leader .actions': {
      observe: 'CURRENT_ALERT_PHASE',

      update: function($el, val) {
        $el.removeClass(
          'incident-state-unacked incident-state-acked incident-state-resolved'
        )
        $el.addClass('incident-state-' + val.toLowerCase())
      },
    },

    '.incident-leader': {
      observe: 'CURRENT_STATE',

      update: function($el, val) {
        $el.removeClass('critical warning ok')
        $el.addClass(val.toLowerCase())
      },
    },
  },

  className: 'pane-inner incident-container incidentdetails',

  events: {
    'click .action-ack': 'doAcknowledge',
    'click .action-resolve': 'doResolve',
    'click .js-alert-filter-trigger': 'doAlertFilter',
  },

  regions: {
    alert: '.js-incidentdetails-alert',
    timeline: '.js-incidentdetails-timeline',
  },

  serializeData: function() {
    return this.model
  },

  template: IncidentInfoTpl,

  // Events
  // -----------------------------------------------------------------------

  onRender: function() {
    var deferred // init
    var onSuccess = function() {
      var self = this
      self.doRender.call(this)
    }

    if (this.model.get('isDigest') === true) {
      deferred = this.model.getIncidentDetails()

      $.when(deferred).then(_.bind(onSuccess, this))
    } else {
      const self = this
      self.doRender.call(this)
    }

    this.$el.find('.js-alert-filter').removeClass('very-hidden')

    setTimeout(
      function() {
        reactApp.attach('incidentdetail', {
          incidentName: this.model.get('INCIDENT_NAME'),
        }) // React component!
      }.bind(this),
      0
    )
  },

  // Custom
  // -----------------------------------------------------------------------

  doAcknowledge: function() {
    this.model.acknowledge()
  },

  doRender: function() {
    this.stickit()
    var tags = _.map(['incident' + this.model.get('INCIDENT_NAME')], function(
      t
    ) {
      return data.timelines.slugify(t)
    })
    var room = data.timelines.canonicalizeTags('*', tags)

    // TODO: Design "incident details"
    //
    // Had some feedback that we need to think more about the display of
    // incidents before replacing "last alert". Unfortunately, that means
    // introducing "server" to this file again.
    // this.alert.show(new (require('./alertdetails'))({
    //      model: _.omit(this.model.attributes, function(val, key) {
    //          return  /^[a-z]/.test(key);
    //      })
    // }));
    server.requestAlert(
      this.model.get('LAST_UUID'),
      _.bind(function(data) {
        this.showChildView('alert', new AlertDetails({ model: data }))
      }, this)
    )

    var opts = {
      reactDomNode: '.js-incident-timeline-react',
      ROOM_ID: room,
      room: room,
      subtype: 'incident',
      tags: tags,
    }

    this.timelinecontainer = new TimelineContainer(opts)
    this.showChildView('timeline', this.timelinecontainer)
  },

  doAlertFilter: function(e) {
    e.preventDefault()

    var $target = $(e.target)
    var isFiltered = $target.data('filtered') === true

    if (isFiltered) {
      $target.data('filtered', false)

      $target.text('Filter')
    } else {
      $target.data('filtered', true)

      $target.text('Disable Filter')
    }

    this.timelinecontainer.trigger('timeline:alertfilter')
  },

  doResolve: function() {
    this.model.resolve()
  },

  updateRouteTeams: function() {
    var self = this
    this.$el.find('ul.incident-route-teams').each(function() {
      var $list = $(this)
      var paging = self.model.get('TEAMS_PAGED')

      $list.empty()
      self.options.groups.each(function(g) {
        var id = g.get('GROUP_ID')
        var rnd = Math.floor(Math.random() * 1000000)
        var $teamLabel = $('<label>', {
          for: 'rgroup' + rnd,
          text: g.get('NAME'),
        })

        var $team = $(
          "<li data-gid='" +
            id +
            "'><input id='rgroup-" +
            rnd +
            "' type='checkbox' /> " +
            $teamLabel.html() +
            '</li>'
        )

        $list.append($team)

        if (_.includes(paging, id)) {
          window.setImmediate(function() {
            $team.find('input').prop('checked', true)
          })
        }
      })
    })
  },
})
