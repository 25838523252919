// vendor
import React, { PureComponent } from 'react'

import config from 'components/__utils/config'
import formatEndTime from './format-end-time'
import { checkTeamAdminPermissions } from '@victorops/utils'

export default class PolicyList extends PureComponent {
  getCurrentTake = (teamSlug, policySlug) =>
    this.props.currentTakes.find(
      take =>
        take.get('policySlug') === policySlug && take.get('slug') === teamSlug
    )

  getAction(policy) {
    const { manualTakeTimer, username: usernameBeingViewed } = this.props

    const {
      endTime,
      onCallUsername,
      originalOnCallUsername,
      policyName,
      policySlug,
      teamName,
      teamSlug,
    } = policy.toJS()

    const modalConfig = {
      endTime,
      isClear: false,
      manualTakeTimer,
      policyName,
      policySlug,
      takeFromUsername: onCallUsername,
      takeToUsername: config.auth.user.username,
      teamName,
    }

    const basicTakeOnCallAction = {
      onClick: () =>
        this.props.confirmationModal({
          ...modalConfig,
          modalConfirmText: 'Take On-call',
          modalTitle: 'Take On-call?',
        }),
      text: 'Take On-call',
    }

    if (manualTakeTimer === true) {
      if (originalOnCallUsername === config.auth.user.username) {
        return {
          onClick: () =>
            this.props.confirmationModal({
              ...modalConfig,
              modalConfirmText: 'Take Back',
              modalTitle: 'Take Back On-call?',
            }),
          text: 'Take Back',
        }
      } else {
        const activeUserIsAdminOrPolicyTeamAdmin = checkTeamAdminPermissions(
          config.context.roles,
          teamSlug
        )
        const activeUserIsViewingSelf =
          usernameBeingViewed === config.auth.user.username
        const activeUserIsOnCall = onCallUsername === config.auth.user.username

        // this is a take
        if (originalOnCallUsername !== null) {
          if (
            activeUserIsAdminOrPolicyTeamAdmin ||
            activeUserIsViewingSelf ||
            activeUserIsOnCall
          ) {
            // only admins, team admins, and the user themself can clear a take
            return {
              onClick: () =>
                this.props.confirmationModal({
                  ...modalConfig,
                  isClear: true,
                  modalConfirmText: 'Clear Take',
                  modalTitle: 'Clear Take?',
                  takeToUsername: originalOnCallUsername,
                }),
              text: 'Clear Take',
            }
          } else {
            // users cannot take an on-call that has already been taken
            return null
          }
        } else {
          // you can't take from yourself
          if (activeUserIsViewingSelf) {
            return null
          }

          return basicTakeOnCallAction
        }
      }
    } else {
      if (onCallUsername === config.auth.user.username) {
        return null
      }

      return basicTakeOnCallAction
    }
  }

  renderTakeOnCallButton(options) {
    const action = this.getAction(options)

    if (action !== null) {
      return (
        <div className='rolodex--policy--action'>
          <a
            onClick={action.onClick}
            className='btn btn-secondary btn-xs rolodex--policy--action--btn'
          >
            {action.text}
          </a>
        </div>
      )
    }

    return null
  }

  getOffCallInfoText(policy) {
    const { endTime, onCallUsername, originalOnCallUsername } = policy.toJS()

    const { username: usernameBeingViewed } = this.props

    let text = 'On-call'

    // this logic was loosely copied from iOS's rolodex logic

    // this oncall was taken by someone
    if (originalOnCallUsername !== null) {
      // i am the user who took this
      if (onCallUsername === config.auth.user.username) {
        text = `You are on-call for <b>@${originalOnCallUsername}</b>`
        // i am not the user who took this
      } else {
        // this user is on call for me
        if (originalOnCallUsername === config.auth.user.username) {
          if (usernameBeingViewed === onCallUsername) {
            text = `On-call for you`
          } else {
            text = `<b>@${onCallUsername}</b> is on-call for you`
          }
          // this user is on call for someone else
        } else {
          // i am viewing the on-call user
          if (usernameBeingViewed === onCallUsername) {
            text = `On-call for <b>@${originalOnCallUsername}</b>`
            // i am viewing the a user this oncall was taken from
          } else {
            text = `<b>@${onCallUsername}</b> is on-call`
          }
        }
      }
    }

    if (endTime === null) {
      return null
    }

    return `${text}${formatEndTime(endTime)}`
  }

  renderOffCallInfo(policy) {
    const text = this.getOffCallInfoText(policy)

    if (this.props.manualTakeTimer !== true || text === null) {
      return null
    }

    return (
      <p
        className='rolodex--policy--offcall-info'
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }

  // svg hack for IE11 from https://css-tricks.com/a-complete-guide-to-svg-fallbacks/
  getIcon = iconPathSuffix => (
    <svg
      className='rolodex--oncall-icon'
      data-test-key='people-pane-oncall-icon'
      height='16'
      width='16'
    >
      <image
        height='16'
        src={`/static/img/vo-logo-graphic${iconPathSuffix}.svg`}
        width='16'
        xlinkHref={`/static/img/vo-logo-graphic${iconPathSuffix}.svg`}
      />
    </svg>
  )

  renderPolicyList = teams =>
    teams.map((policy, key) => {
      const isTakenFromUser =
        policy.get('originalOnCallUsername') === this.props.username
      const nameClassModifier = isTakenFromUser ? 'inactive' : 'active'
      const iconPathSuffix = isTakenFromUser ? '-inactive' : ''

      return (
        <li className='rolodex--policy' key={key}>
          <div className='rolodex--policy--name-and-action'>
            {this.getIcon(iconPathSuffix)}
            <p className={`rolodex--policy--name --${nameClassModifier}`}>
              {policy.get('policyName')}
            </p>
            {this.renderTakeOnCallButton(policy)}
          </div>
          {this.renderOffCallInfo(policy)}
        </li>
      )
    })

  getList() {
    const { onCallPoliciesByTeam } = this.props

    return onCallPoliciesByTeam
      .keySeq()
      .toArray()
      .map((teamName, index) => {
        const teams = onCallPoliciesByTeam.get(teamName)
        if (teams.size) {
          return (
            <div className='rolodex--policygroup' key={teamName}>
              <span className='rolodex--oncall-teamname'>{teamName}</span>
              <ul className='profile-list--oncall'>
                {this.renderPolicyList(teams)}
              </ul>
            </div>
          )
        } else {
          return null
        }
      })
  }

  render() {
    return (
      <div className='rolodex-list--oncall rolodex--list'>{this.getList()}</div>
    )
  }
}
