import Backbone from 'backbone'

export default Backbone.Model.extend({
  defaults: {
    id: undefined,
    phone_num: undefined,
    location: undefined,
    user_id: undefined,
    in_call: undefined,
  },
})
