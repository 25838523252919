// vendor
import _ from 'lib/underscore'
import Marionette from 'lib/marionette'

// lib
import timelines from '../data/managers/timelines'
import server from '../server'
import log from 'util/_console'
import vent from 'util/vent'

// Views
import ChatView from './timeline-chat'
import ReactFiltersView from './r-timeline-filters'
import GrowlerView from './timeline-growler'
import TimelineView from './r-timeline'
import TimelineContainerTpl from 't/timeline/timeline-container.tpl'

// Filtering
import typesFactory from '../data/timeline_filters'
import routesFactory from '../data/collections/routes'
import groupsFactory from '../data/collections/routeGroups'
import filtersGroupFactory from '../data/collections/filters'
import quickFilterFactory from '../data/collections/quickFilters'

const config = window.VO_CONFIG

var typeFilterCategories = new Map([
  ['toggle-chats', true],
  ['toggle-alerts', true],
  ['toggle-dev-endpoint', true],
  ['timeline-alert-unknown', true],
])

var typeFilterTagMap = new Map([
  ['action-oncall', 'typeoncallnotify'],
  ['action-incident', 'typeincidentnotify'],
  ['action-page', 'typepagingnotify'],
  ['action-controlcall', 'typeconfcallstatenotify'],
  ['action-maintmode', 'typemaintenancemodenotify'],
  ['oncall-chat', 'typechatnotify'],
  ['robot-chat', 'typechatrobotnotify'],
  ['person-chat', 'typechathumannotify'],
  ['timeline-alert-critical', 'typecriticalalertnotify'],
  ['timeline-alert-warning', 'typewarningalertnotify'],
  ['timeline-alert-recovery', 'typerecoveryalertnotify'],
  ['timeline-alert-info', 'typeinfoalertnotify'],
  ['timeline-alert-thirdparty', 'statuspagemessagenotify'],
  ['developer-branch', 'typedeveventbranch'],
  ['developer-merge', 'typedeveventmerge'],
  ['developer-pull-request', 'typedeveventpullrequest'],
  ['developer-build', 'typedeveventbuild'],
  ['developer-deploy', 'typedeveventdeploy'],
])

// Serialize room information from localStorage. Basically, looks at
// whether or not a group is on, and if so, builds an array of tags
// prefixed with "routing". This will later be serialized using
// data.canonicalizeTags()
var getRoomFromStorage = function(filtersGroup, groups, routes, types) {
  var routesFilter = filtersGroup.findWhere({ groupKey: 'filterRoutes' })
  var routesFilterState = routesFilter.get('state') // this is where I'm shifting things

  var isOnGroups = _.map(groups.where({ state: 'on' }), function(model) {
    if (routesFilterState === 'filtered') {
      return model.get('routeGroupKey')
    } else {
      // noop
    }
  })

  var isOnRoutes = _.map(isOnGroups, function(group) {
    var onSet = _.map(
      routes.where({ routeGroupKey: group, state: 'on' }),
      function(model) {
        return 'routing' + model.get('routeKey')
      }
    )

    return onSet
  })

  var isAllGroups = _.map(groups.where({ state: 'off' }), function(model) {
    if (routesFilterState === 'filtered') {
      return model.get('routeGroupKey')
    } else {
      // noop
    }
  })

  var isAllRoutes = _.map(isAllGroups, function(group) {
    var AllSet = _.map(routes.where({ routeGroupKey: group }), function(model) {
      return 'routing' + model.get('routeKey')
    })

    return AllSet
  })

  var typeFilterTags = _.reduce(
    types.where({ selected: false }),
    function(collection, model) {
      var tag =
        model.get('className') === 'oncall-chat'
          ? ''
          : typeFilterTagMap.get(model.get('className'))

      if (tag && !typeFilterCategories.get(model.get('className'))) {
        collection.push(tag)
      }

      return collection
    },
    []
  )

  // both of these reductions iterate and produce the exact same input list...?
  var on = _.reduce(
    isOnRoutes,
    function(acc, el) {
      return acc.concat(el)
    },
    []
  )
  var all = _.reduce(
    isAllRoutes,
    function(acc, el) {
      return acc.concat(el)
    },
    []
  )

  return [on.concat(all), typeFilterTags]
}

export default Marionette.View.extend({
  initialize: function(opts) {
    this.panes = opts.paneState
    this.ROOM_ID = opts.ROOM_ID
    this.subtype = opts.subtype
    this.tags = opts.tags || []
    this.reactDomNode = opts.reactDomNode
    this.defaultReactDomNode = '.js-timeline-react'

    if (this.subtype === 'primary') {
      this.initPrimary()
      this._bindToTimelinePane()
    }

    this.on('timeline:alertfilter', this.alertFilters)
  },

  _bindToTimelinePane: function() {
    const timelinePaneModel = this.panes.models.find(
      x => x.get('pane_id') === 'timeline'
    )

    timelinePaneModel.bind('change', () => {
      const state = timelinePaneModel.get('view_state')
      if (state === 'active') {
        this.showPrimary()
      } else {
        this.destroyTimeline()
      }
    })
  },

  // Overrides
  // -------------------------------------------------------------------

  className: 'timeline-container',

  regions: {
    chat: '.js-timeline-chat',
    filters: '.js-timeline-filters',
    growler: '.js-timeline-growler',
    timeline: '.js-timeline-content',
  },

  template: TimelineContainerTpl,

  // Events
  // -------------------------------------------------------------------

  onRender: function() {
    if (this.subtype === 'primary' && this._timelineIsActive()) {
      this.showPrimary()
    } else {
      this.showIncident()
    }
  },

  // Custom
  // -------------------------------------------------------------------

  initPrimary: function() {
    var groups = [{ routeGroupKey: 'myKeys' }, { routeGroupKey: 'restKeys' }]
    var filtersGroup = [
      { groupKey: 'filterTypes' },
      { groupKey: 'filterRoutes' },
    ]
    var quickFilterKey = [
      { quickFilterKey: 'deliveryInsights' },
      { quickFilterKey: 'chat' },
    ]

    // Filter groups
    var FiltersGroup = filtersGroupFactory(
      config.orgslug + ':' + config.socketAuth.USER_ID
    )
    this.filtersGroup = new FiltersGroup()

    this.filtersGroup.fetch()
    this.filtersGroup.set(filtersGroup, { merge: false })

    // Groups
    var Groups = groupsFactory(config.orgslug + ':' + config.socketAuth.USER_ID)
    this.groups = new Groups()
    this.groups.fetch()
    this.groups.set(groups, { merge: false })

    // Routes
    var Routes = routesFactory(config.orgslug + ':' + config.socketAuth.USER_ID)
    this.routes = new Routes()
    this.routes.fetch()

    var QuickFilters = quickFilterFactory(
      config.orgslug + ':' + config.socketAuth.USER_ID
    )
    this.quickFilters = new QuickFilters()
    this.quickFilters.fetch()
    this.quickFilters.set(quickFilterKey, { merge: false })

    this.initGlobalFilters() // noop
    this.initReset()
    this.initTypeFilters()
    this.initRouteFilters()

    this.listenTo(vent, 'route_keys:ready', this.updateRouteFilters)

    // need to listen to changes on routes
    this.listenTo(
      vent,
      'filters:render',
      _.bind(function() {
        var room = getRoomFromStorage(
          this.filtersGroup,
          this.groups,
          this.routes,
          this.types
        )
        vent.trigger('filters:reset', room)

        this.routes.each(function(model) {
          model.set('routeIsNew', false)
          // model.save() // already has a change event listener that does this.
        })
      }, this)
    )
  },

  showPrimary: function() {
    var optsTimeline = {
      ROOM_ID: this.ROOM_ID,
      filterTypes: this.types,
      filtersGroup: this.filtersGroup,
      model: timelines.getOrCreate(this.ROOM_ID),
      isPrimary: true,
      subtype: this.subtype,
      reactDomNode: this.defaultReactDomNode,
      dropdownOptions: {
        containerId: this.cid,
        options: {},
      },
    }

    var optsFilters = {
      ROOM_ID: this.ROOM_ID,
      collection: this.types,
      filtersGroup: this.filtersGroup,
      routeGroups: this.groups,
      routes: this.routes,
    }

    // Add sub-views to regions
    this.showFilters(optsFilters)
    this.showGrowler({ subtype: this.subtype })
    this.mountPrimaryTimeline(optsTimeline)

    this.showChat({
      containerId: this.cid,
      subtype: this.subtype,
      quickFilterEnabled: true,
      quickFilters: this.quickFilters,
    })
    this.reactFiltersView.updateIcon()
    vent.trigger('dropdowns:init', optsTimeline.dropdownOptions)

    // Initialize the filter states based on filtersGroup
    this.listenTo(
      this.filtersGroup,
      'change:state',
      _.bind(function() {
        vent.trigger('filters:toggleAllTypes', this.subtype)
      }, this)
    )
  },

  showIncident: function() {
    this.initTypeFilters()

    var optsTimeline = {
      ROOM_ID: this.ROOM_ID,
      filterTypes: this.types,
      disableFilters: true,
      model: timelines.getOrCreate(this.ROOM_ID, { tags: this.tags }),
      reactDomNode: this.reactDomNode,
      subtype: this.subtype,
      dropdownOptions: {
        containerId: this.cid,
        options: {
          team: 3,
          user: 5,
        },
      },
    }

    this.showGrowler({ subtype: this.subtype })
    this.mountIncidentTimeline(optsTimeline)
    this.showChat({ containerId: this.cid, subtype: this.subtype })
    vent.trigger('dropdowns:init', optsTimeline.dropdownOptions)
  },

  showFilters: function(opts) {
    opts.diBool = true
    if (this.reactFiltersView) this.reactFiltersView.destroy()

    this.reactFiltersView = new ReactFiltersView(opts)
    this.showChildView('filters', this.reactFiltersView)
  },

  showChat: function(opts) {
    this.showChildView('chat', new ChatView(opts))
  },

  showGrowler: function(opts) {
    this.showChildView('growler', new GrowlerView(opts))
  },

  destroyTimeline: function() {
    this.timeline = this.getChildView('timeline')
    if (this.timeline && this.timeline.destroy) {
      this.timeline.destroy()
    }
  },

  mountIncidentTimeline: function(opts) {
    this.destroyTimeline()
    this.showChildView('timeline', new TimelineView(opts))
  },

  mountPrimaryTimeline: function(opts) {
    if (this._timelineIsActive()) {
      this.destroyTimeline()
      this.showChildView('timeline', new TimelineView(opts))
    }
  },

  updateRouteFilters: function() {
    var routes = _.reduce(
      config.routeKeys,
      function(acc, el, prop) {
        var group = _.map(el, function(route) {
          var routeKey = route.routeKey

          if (routeKey === '.+') {
            routeKey = 'default'
          }

          return { routeGroupKey: prop, routeKey: routeKey }
        })

        return acc.concat(group)
      },
      []
    )

    this.routes.set(routes, { merge: false }) // don't merge

    // Custom merge
    _.each(
      config.routeKeys.myKeys,
      _.bind(function(fresh) {
        var routeKey = fresh.routeKey

        if (routeKey === '.+') {
          routeKey = 'default'
        }

        var model = this.routes.findWhere({ routeKey: routeKey })

        if (model.get('routeGroupKey') !== 'myKeys') {
          model.set('routeGroupKey', 'myKeys')
          model.save()
        }
      }, this)
    )

    _.each(
      config.routeKeys.restKeys,
      _.bind(function(fresh) {
        var routeKey = fresh.routeKey

        if (routeKey === '.+') {
          routeKey = 'default'
        }

        var model = this.routes.findWhere({ routeKey: routeKey })

        if (model.get('routeGroupKey') !== 'restKeys') {
          model.set('routeGroupKey', 'restKeys')
          model.save()
        }
      }, this)
    )

    vent.trigger('route_keys:render')
  },

  initRouteFilters: function() {
    var room = getRoomFromStorage(
      this.filtersGroup,
      this.groups,
      this.routes,
      this.types
    )

    // @TODO
    //
    // magic! There was a bit of a race that occured when initializing
    // a fresh timeline that was filtered. We were trying to grab DOM
    // elements that did not yet exist, which blew up... 'cause
    // elements didn't exist. Wrapping this in a defer is obviously
    // brittle, but at least wins the day... Something, something,
    // marionette upgrade...
    _.defer(function() {
      vent.trigger('filters:reset', room)
      vent.trigger('route_keys:render')
    })
  },

  initGlobalFilters: function() {
    // noop
  },

  initTypeFilters: function() {
    var Types = typesFactory(config.orgslug + ':' + config.socketAuth.USER_ID)
    this.types = new Types()

    // Add defaults to collection
    this.types.reset([
      { className: 'toggle-actions', selected: false },
      { className: 'toggle-chats', selected: false },
      { className: 'toggle-alerts', selected: false },
      { className: 'toggle-dev-endpoint', selected: false },

      { className: 'action-incident', selected: false },
      { className: 'action-page', selected: false },
      { className: 'action-oncall', selected: false },
      { className: 'action-controlcall', selected: false },
      { className: 'action-maintmode', selected: false },

      { className: 'person-chat', selected: false },
      { className: 'oncall-chat', selected: false },
      { className: 'robot-chat', selected: false },

      { className: 'timeline-alert-critical', selected: false },
      { className: 'timeline-alert-warning', selected: false },
      { className: 'timeline-alert-recovery', selected: false },
      { className: 'timeline-alert-unknown', selected: false },
      { className: 'timeline-alert-info', selected: false },
      { className: 'timeline-alert-thirdparty', selected: false },

      { className: 'developer-branch', selected: false },
      { className: 'developer-pull-request', selected: false },
      { className: 'developer-merge', selected: false },
      { className: 'developer-build', selected: false },
      { className: 'developer-deploy', selected: false },
    ])

    // Merge collection with extant collection that was persisted in
    // localstorage.
    this.types.fetch({ remove: false })
  },

  _timelineIsActive: function() {
    return (
      this.panes &&
      this.panes.models
        .find(x => x.get('pane_id') === 'timeline')
        .get('view_state') === 'active'
    )
  },

  initReset: function() {
    this.listenTo(
      vent,
      'filters:reset',
      _.bind(function(msg) {
        var room = '*'

        const typesGroup = this.filtersGroup.get('filterTypes')
        const routesGroup = this.filtersGroup.get('filterRoutes')

        if (this.quickFilters.get('deliveryInsights').get('state') === 'on') {
          const devTypes = [
            'developer-branch',
            'developer-merge',
            'developer-pull-request',
            'developer-build',
            'developer-deploy',
          ]

          const filterDev = devTypes.filter(el => {
            const typeModel = this.types.get(el)
            return !typeModel.get('selected')
          })

          const devOpts = () => {
            if (filterDev.length && filterDev.length !== 5) {
              return filterDev.map(el => {
                return typeFilterTagMap.get(el)
              })
            } else {
              return ['typedevtimelinemessage']
            }
          }

          room = timelines.canonicalizeTags('*', [devOpts(), []])
        } else if (this.quickFilters.get('chat').get('state') === 'on') {
          room = timelines.canonicalizeTags('*', [['typechatnotify'], []])
        } else if (
          routesGroup.get('state') === 'filtered' &&
          typesGroup.get('state') === 'filtered'
        ) {
          if (!msg[1].length) {
            room = '*&tag=nothing'
          } else if (!msg[0].length) {
            room = timelines.canonicalizeTags('*', [['nothing'], msg[1]])
          } else {
            room = timelines.canonicalizeTags('*', msg)
          }
        } else if (
          routesGroup.get('state') === 'filtered' &&
          typesGroup.get('state') !== 'filtered'
        ) {
          msg[0].push('typechatnotify')
          msg[0].push('typeconfcallstatenotify')
          msg[0].push('typeoncallnotify')
          msg[0].push('typemaintenancemodenotify')
          msg[0].push('typedevtimelinemessage')
          room = timelines.canonicalizeTags('*', [msg[0], []])
        } else if (
          routesGroup.get('state') !== 'filtered' &&
          typesGroup.get('state') === 'filtered'
        ) {
          if (!msg[1].length) {
            room = '*&tag=nothing'
          } else {
            room = timelines.canonicalizeTags('*', [msg[1], []])
          }
        }

        log.info('timeline-container:reset', {
          tags: msg,
          to_room: room,
          from_room: this.ROOM_ID,
        })

        // If we're already in the room, no need to reflow
        if (this.ROOM_ID === room) {
          return
        } else {
          // Unsubscribe from room if it is not "*"
          if (this.ROOM_ID !== '*') {
            server.unsubscribeFromRoom(this.ROOM_ID)
          }
        }

        // Set this.ROOM_ID for showPrimary. We were in a weird race
        // with ourself because showPrimary renders whatever ROOM_ID
        // is set to, which in the case of first render is always *.
        this.ROOM_ID = room

        var initTimeline = {
          ROOM_ID: room,
          filterRoutes: this.routes,
          filterTypes: this.types,
          filtersGroup: this.filtersGroup,
          model: timelines.getOrCreate(room, msg),
          subtype: this.subtype,
          tags: msg,
          reactDomNode: this.defaultReactDomNode,
          dropdownOptions: {
            containerId: this.cid,
            options: {},
          },
        }

        this.showGrowler({ subtype: this.subtype })
        this.mountPrimaryTimeline(initTimeline)
        // TODO: Replace. This is only here as sad glue between redux and old-world route filters
        setTimeout(() => {
          vent.trigger('reacttimeline:backBoneContainerRendered', Date.now())
        }, 0)
      }, this)
    )
  },

  alertFilters: function() {
    var filterOn =
      this.types.filterOn === undefined ? true : this.types.filterOn

    this.types.map(function(m) {
      m.set('selected', filterOn)
    })

    if (filterOn) {
      this.types
        .filter(function(m) {
          return /timeline-alert-.*/.test(m.get('className'))
          // { className: 'timeline-alert-critical', selected: true },
          // { className: 'timeline-alert-warning', selected: true },
          // { className: 'timeline-alert-recovery', selected: true },
          // { className: 'timeline-alert-unknown', selected: true },
          // { className: 'timeline-alert-info', selected: true }
        })
        .map(function(m) {
          m.set('selected', !filterOn)
        })
    }
    this.types.filterOn = !filterOn
  },
})
