import React from 'react'

import { compose } from 'recompose'

import { connect } from 'react-redux'

import { range } from 'lodash'

import moment from 'moment'

import { hideModal, showModal, snoozeIncident } from 'components/store/actions'

import SnoozeForm from './snooze-form'

// Private
// ---------------------------------------------------------------------------

function handleSnoozeError(incidentId, dispatch) {
  const incidentMessage = `We were unable to snooze incident #${incidentId}.`
  const modalConfig = {
    modalType: 'confirm',
    modalProps: {
      component: (
        <span>
          {incidentMessage} Please try again or contact support via live chat,
          or open a case on the{' '}
          <a href='https://login.splunk.com/'>Splunk Support Portal</a>.
        </span>
      ),
      cancelButtonText: 'Ok',
      cancelButtonType: 'info',
      onCancel: () => dispatch(hideModal()),
      title: 'Oh Snap!',
    },
  }
  dispatch(showModal(modalConfig))
}

function makeSnoozeFormContainer(_Form, dispatch) {
  return compose(
    connect(() => ({
      onCancel: () => dispatch(hideModal()),
      onConfirm: (incidentId, timestamp) => () =>
        dispatch(
          snoozeIncident({
            incidentIds: [incidentId],
            snoozeUntil: timestamp,
            success: () => dispatch(hideModal()),
            error: () => handleSnoozeError(incidentId, dispatch),
          })
        ),
    }))
  )(_Form)
}

const convertTimeToTimestamp = time => {
  const currentTime = new Date()
  if (
    currentTime.getHours() > time.hours ||
    (currentTime.getHours() === time.hours &&
      currentTime.getMinutes() > time.minutes)
  ) {
    return new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate() + 1,
      time.hours,
      time.minutes,
      0
    ).getTime()
  } else {
    return new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate(),
      time.hours,
      time.minutes,
      0
    ).getTime()
  }
}

// Export
// ---------------------------------------------------------------------------

const hoursInADay = range(24)
export const timesList = hoursInADay.flatMap(hour => {
  const period = hour < 12 ? 'am' : 'pm'
  const hourPeriod = hour < 13 ? hour : hour - 12
  const hourStr = hourPeriod === 0 ? '12' : hourPeriod.toString()
  return [
    {
      value: convertTimeToTimestamp({ hours: hour, minutes: 0 }),
      label: hourStr + ':00 ' + period,
      className: 'indent-option',
    },
    {
      value: convertTimeToTimestamp({ hours: hour, minutes: 30 }),
      label: hourStr + ':30 ' + period,
      className: 'indent-option',
    },
  ]
})

export const getDefaultTime = () => {
  const now = new Date()
  now.setMinutes(Math.ceil(now.getMinutes() / 30) * 30)
  now.setSeconds(0)
  return {
    value: convertTimeToTimestamp({
      hours: now.getHours(),
      minutes: now.getMinutes(),
    }),
    label: moment(now).format('h:mm a'),
  }
}

export function showSnoozeForm(
  incidentId,
  dispatch,
  modifySnooze,
  snoozedUntil,
  snoozedUntilTimestamp
) {
  const Component = makeSnoozeFormContainer(SnoozeForm, dispatch)

  const modalConfig = {
    modalType: 'confirm',
    modalProps: {
      modalClass: 'snooze-modal',
      suppressOutsideClick: true,
      actionBar: false,
      component: (
        <Component
          incidentId={incidentId}
          modifySnooze={modifySnooze}
          snoozedUntil={snoozedUntil}
          snoozedUntilTimestamp={snoozedUntilTimestamp}
        />
      ),
      title: modifySnooze ? 'Modify Snooze' : 'Snooze Incident',
    },
  }

  dispatch(showModal(modalConfig))
}
