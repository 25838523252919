import { fromJS } from 'immutable'

import { ADD_SUGGESTED_RESPONDERS } from '../../actions/suggested-responders'

export default (state = fromJS({}), { payload, type }) => {
  switch (type) {
    case ADD_SUGGESTED_RESPONDERS:
      return addSuggestedResponders(state, payload)
    default:
      return state
  }
}

const addSuggestedResponders = (state, payload) =>
  state.set(payload.entityID, fromJS(payload.data))
