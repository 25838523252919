import Backbone from 'lib/backbone'
import _ from 'lib/lodash'
import 'backbone.validation'
_.extend(Backbone.Model.prototype, Backbone.Validation.mixin)

export default Backbone.Model.extend({
  // Override
  // --------------------------------------------------------------------
  urlRoot: '/api/v1/org/' + window.VO_CONFIG.orgslug + '/statuspage/components',

  idAttribute: 'id',

  defaults: {
    created_at: '',
    created_by: '',
    group_id: null,
    name: '',
    position: 0,
    status: 'major_outage',
    updated_at: '',
    updated_by: '',
  },

  validation: {
    status: {
      oneOf: [
        'degraded_performance',
        'major_outage',
        'operational',
        'partial_outage',
        'under_maintenance',
      ],
    },
  },
})
