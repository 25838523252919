// vendor
import { Map as _Map, fromJS } from 'immutable'

import { assign } from 'lodash'

import {
  INTEGRATIONS_UPDATE,
  INTEGRATION_OPTS_UPDATE,
  INTEGRATION_REMOVE,
  INTEGRATION_SETTINGS_UPDATE,
} from 'components/store/actions'

import { translatePlatformToClient as translate } from 'components/__utils/transform-id'

// Reducer
// ---------------------------------------------------------------------------

export default function integrationsReducer(state = _Map({}), action) {
  switch (action.type) {
    case INTEGRATIONS_UPDATE:
      return _updateIntegrations(state, action.payload)
    case INTEGRATION_OPTS_UPDATE:
      return _updateIntegrationOptions(state, action.payload)
    case INTEGRATION_REMOVE:
      return _removeIntegration(state, action.payload)
    case INTEGRATION_SETTINGS_UPDATE:
      return _updateIntegrationSettings(state, action.payload)

    default:
      return state
  }
}

// ---------------------------------------------------------------------------

function _updateIntegrations(state, payload) {
  const next = payload.reduce(function(_integrations, integration) {
    _integrations[translate(integration.integration)] = assign(
      {},
      integration,
      { options: state.getIn([integration.integration, 'options']) }
    )

    return _integrations
  }, {})

  // @TODO: This should probably not be a merge.
  return state.merge(fromJS(next))
}

function _updateIntegrationOptions(state, payload) {
  const next = payload.reduce(function(_integrations, integration) {
    _integrations[translate(integration.integration)] = integration

    return _integrations
  }, {})

  return state.merge(fromJS(next))
}

function _removeIntegration(state, payload) {
  return state.delete(translate(payload))
}

function _updateIntegrationSettings(state, payload) {
  return state
}
