import React from 'react'

import { ServiceNowDisplayContainer } from './service-now-display-container'
import { ServiceNowDisplayErrorBoundary } from './service-now-display-error-boundary'

const Fallback = () => <div>Failed to retrieve link</div>

export const ServiceNowDisplay = props => (
  <ServiceNowDisplayErrorBoundary Fallback={<Fallback />}>
    <ServiceNowDisplayContainer {...props} Fallback={<Fallback />} />
  </ServiceNowDisplayErrorBoundary>
)
