import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'

import { toJSHOC } from '__utils/immutable-redux'
import { normalizeString } from 'util/normalize-string'
import {
  requestAlertDetails,
  toggleTimelineMessageExpand,
} from 'components/store/actions'
import { RowMessage } from './row-message'
import { HiddenDataDisplay } from './hidden-data-display'
import { RowErrorBoundary } from './row-error-boundary'

const mapStateToProps = (state, props) => {
  const { alerts, integrationIcons, timelines } = state
  const { dataSetID, id } = props
  const message = timelines.getIn(
    [dataSetID, 'messages', id],
    timelines.getIn([dataSetID, 'newMessages', id])
  )
  const voUUID = message && message.get('VO_UUID')
  const monitorType = message && normalizeString(message.get('MONITOR_TYPE'))
  const provider = message && normalizeString(message.get('PROVIDER'))

  return {
    alertVoFields: alerts.getIn([voUUID, 'voFields'], null),
    alertNonVoFields: alerts.getIn([voUUID, 'nonVoFields'], null),
    hasAlertLoaded: !!alerts.get(voUUID),
    hasReachedEnd: timelines.getIn([dataSetID, 'endOfHistory'], false),
    integrationIconPath: integrationIcons.getIn([
      monitorType || provider,
      'icon_url',
    ]),
    integrationName: integrationIcons.getIn([monitorType || provider, 'name']),
    isExpanded: timelines
      .getIn([dataSetID, 'expandedMessages'], List())
      .includes(id),
    isLoading: timelines.getIn([dataSetID, 'hasPendingTransaction'], false),
    message,
  }
}

const mapDispatchToProps = dispatch => ({
  expandRow: (dataSetID, sequence) =>
    dispatch(toggleTimelineMessageExpand({ roomId: dataSetID, sequence })),
  getAlertDetails: uuid => dispatch(requestAlertDetails(uuid)),
})

class _GetRowRenderer extends PureComponent {
  componentDidUpdate(prevProps) {
    const {
      hasAlertLoaded,
      hasReachedEnd,
      isLoading,
      message,
      resize,
    } = this.props

    const hasNewAlertData = hasAlertLoaded !== prevProps.hasAlertLoaded
    const hasSwitchedFromLoadingToEnd =
      !message &&
      (hasReachedEnd !== prevProps.hasReachedEnd ||
        isLoading !== prevProps.isLoading)
    if (hasNewAlertData || hasSwitchedFromLoadingToEnd) {
      resize()
    }
  }

  onAlertExpandClicked = () => {
    const {
      dataSetID,
      expandRow,
      getAlertDetails,
      message,
      resize,
    } = this.props

    expandRow(dataSetID, message.sequence)
    resize()
    getAlertDetails(message.VO_UUID)
  }

  onDevExpandClicked = () => {
    const { dataSetID, expandRow, message, resize } = this.props

    expandRow(dataSetID, message.sequence)
    resize()
  }

  render() {
    const {
      alertNonVoFields,
      alertVoFields,
      getAlertDetails,
      hasReachedEnd,
      hiddenCount,
      integrationIconPath,
      integrationName,
      isExpanded,
      isLoading,
      message,
      resize,
      style,
    } = this.props
    const styleModifications = { width: '98%' }
    const finalStyle = { ...style, ...styleModifications }
    const isNotLoadingCard = message || hasReachedEnd

    return (
      <div
        className='timeline-row'
        data-ext='timeline-row'
        style={finalStyle}
        data-sequence={message ? message.sequence : null}
      >
        {hiddenCount > 0 && isNotLoadingCard ? (
          <HiddenDataDisplay hiddenCount={hiddenCount} />
        ) : null}
        <RowErrorBoundary message={message} resize={resize}>
          <RowMessage
            alertVoFields={alertVoFields}
            alertNonVoFields={alertNonVoFields}
            hasReachedEnd={hasReachedEnd}
            integrationIconPath={integrationIconPath}
            integrationName={integrationName}
            isExpanded={isExpanded}
            isLoading={isLoading}
            getAlertDetails={getAlertDetails}
            message={message}
            onAlertExpandClicked={this.onAlertExpandClicked}
            onDevExpandClicked={this.onDevExpandClicked}
            resize={resize}
          />
        </RowErrorBoundary>
      </div>
    )
  }
}

export const Row = connect(
  mapStateToProps,
  mapDispatchToProps
)(toJSHOC(_GetRowRenderer))
