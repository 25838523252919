import { call, put, takeLatest } from 'redux-saga/effects'

import { serializeJSONtoQuery } from 'components/__utils/xhr'
import config from 'components/__utils/config'
import {
  addSuggestedResponders,
  FETCH_SUGGESTED_RESPONDERS,
} from 'components/store/actions/suggested-responders'

export const fetchSuggestedResponders = ({ fetch }, error) =>
  function*(action) {
    const { payload } = action

    const strippedPayload = Object.keys(payload).reduce((acc, currentKey) => {
      acc[currentKey] = payload[currentKey] || ''
      return acc
    }, {})

    try {
      const data = yield call(
        fetch,
        `/api-reporting/v1/org/${
          config.orgslug
        }/suggestedResponders${serializeJSONtoQuery(strippedPayload)}`
      )

      yield put(addSuggestedResponders({ data, entityID: payload.entityID }))
    } catch (e) {
      yield call(error, e)
    }
  }

export function* watchFetchSuggestedResponders(api, error) {
  yield takeLatest(
    FETCH_SUGGESTED_RESPONDERS,
    fetchSuggestedResponders(api, error)
  )
}
