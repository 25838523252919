// vendor
import React from 'react'

// Export
// ---------------------------------------------------------------------------

export default function IncidentsNavCounter({
  // data
  count,
  phase,

  // actions
  onClickCounter,
  reactTimeline,
}) {
  if (count > 0 && !reactTimeline) {
    return (
      <div className='incidents-counter'>
        <span className='incidents-counter--dispatch' onClick={onClickCounter}>
          Load {count} New {phase} Incident{count === 1 ? '' : 's'}!
        </span>
      </div>
    )
  } else {
    return null
  }
}
