import React, { memo, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { destroy, update } from 'components/__utils/xhr'

import {
  Container,
  Header,
  NotesContainer,
  MenuItem,
  MenuToggle,
  MenuEllipses,
} from './conference-bridge-display-styles'

import Dropdown from '@splunk/react-ui/Dropdown'
import Menu from '@splunk/react-ui/Menu'
import { faEllipsisV } from '@fortawesome/fontawesome-pro-solid'

import {
  hideModal,
  showModal,
  fetchConferenceBridgeDetails,
} from 'components/store/actions'
import config from 'components/__utils/config'
import CBDetails from '../../../../incidents/view/incidents-table/incident-actions/cb-details'
import {
  CB_DETAILS_STATES,
  checkDetailsValidity,
} from 'components/incidents/view/incidents-table/incident-actions/cb-details/cb-details-utils'

const areYouSureText =
  'Are you sure you want to remove the conference bridge from this incident?'

function renderCancelButton(buttonText, dispatch) {
  return (
    <li>
      <button
        className='btn btn-secondary'
        data-ext='conference-bridge-delete-modal-confirmation-close'
        onClick={() => dispatch(hideModal())}
        onKeyPress={() => dispatch(hideModal())}
        type='button'
        autoFocus
      >
        {buttonText}
      </button>
    </li>
  )
}
function mapDispatchToProps(dispatch) {
  const deleteConferenceBridge = incidentID => {
    const route = `/api/v1/org/${config.orgslug}/incidents/${incidentID}/conference-bridge`
    destroy(route)
      .then(response => {
        dispatch(hideModal())
      })
      .catch(() => {
        dispatch(hideModal())

        const modalBody = (
          <>
            <p>{areYouSureText}</p>

            <p className='error-text'>
              The conference bridge could not be deleted, please refresh and try
              again.
            </p>

            <ul className='button-list right'>
              {renderCancelButton('Close', dispatch)}
            </ul>
          </>
        )

        const modalConfig = {
          modalType: 'acknowledge',
          modalProps: {
            title: `Delete Conference Bridge from incident #${incidentID}`,
            body: modalBody,
            onCancel: () => {
              dispatch(hideModal())
            },
            onConfirm: () => null,
            suppressOutsideClick: true,
            actionBar: false,
          },
        }

        dispatch(showModal(modalConfig))
      })
  }
  return {
    openModalEditConferenceBridge: (
      incidentID,
      url = '',
      phone = '',
      notes = ''
    ) => {
      let detailsAreValid = false
      let cancelButtonText = 'Cancel'
      let hideConfirm = false
      const initialUrl = url
      const initialPhone = phone
      const initialNotes = notes

      const setUrl = changedUrl => {
        url = changedUrl
        validateDetails()
      }

      const setPhone = changedPhone => {
        phone = changedPhone
        validateDetails()
      }
      const setNotes = changedNotes => {
        notes = changedNotes
        validateDetails()
      }
      const onCancel = () => {
        dispatch(hideModal())
      }
      const onConfirm = () => {
        const route = `/api/v1/org/${config.orgslug}/incidents/${incidentID}/conference-bridge`
        const payload = {
          url: url.trim(),
          phone: phone.trim(),
          notes: notes.trim(),
        }

        update(route, payload)
          .then(() => {
            dispatch(fetchConferenceBridgeDetails({ incidentID }))
            dispatch(hideModal())
          })
          .catch(() => {
            dispatch(hideModal())
            cancelButtonText = 'Close'
            hideConfirm = true
            modalConfig.modalProps.body = (
              <>
                {getBodyContent()}
                {getErrorText()}
                {getActionBar(detailsAreValid, cancelButtonText, hideConfirm)}
              </>
            )

            dispatch(showModal(modalConfig))
          })
      }
      const getErrorText = () => (
        <p className='error-text'>
          The conference bridge could not be edited, please refresh and try
          again.
        </p>
      )

      const getBodyContent = () => (
        <div>
          <CBDetails
            {...{
              notes,
              setNotes,
              phone,
              setPhone,
              url,
              setUrl,
            }}
          />
        </div>
      )
      const getActionBar = (submitDisabled, cancelButtonText, hideConfirm) => {
        return (
          <ul className='button-list right'>
            <li>
              <button
                className='btn btn-secondary'
                data-test-key='edit-conference-bridge--cancel'
                onClick={onCancel}
                type='button'
              >
                {cancelButtonText}
              </button>
            </li>
            {hideConfirm ? (
              ''
            ) : (
              <li>
                <button
                  className='btn btn-outline-primary'
                  data-test-key='edit-conference-bridge--save'
                  disabled={submitDisabled}
                  onClick={onConfirm}
                  type='button'
                >
                  Save
                </button>
              </li>
            )}
          </ul>
        )
      }
      const modalConfig = {
        modalType: 'acknowledge',
        modalProps: {
          title: `Edit Conference Bridge`,
          body: (
            <>
              {getBodyContent()}
              {getActionBar(!detailsAreValid, cancelButtonText, hideConfirm)}
            </>
          ),
          actionBar: false,
          modalClass: 'reroute-modal',
          confirmButtonText: 'Save',
          confirmButtonType: 'info',
          suppressOutsideClick: true,
        },
      }

      const validateDetails = () => {
        const oldDetailsAreValid = detailsAreValid
        const initialValuesModified =
          initialUrl !== url || initialPhone !== phone || initialNotes !== notes
        detailsAreValid =
          checkDetailsValidity(url, phone, notes) === CB_DETAILS_STATES.valid &&
          initialValuesModified

        if (oldDetailsAreValid !== detailsAreValid) {
          modalConfig.modalProps.body = (
            <>
              {getBodyContent()}
              {getActionBar(!detailsAreValid, cancelButtonText, hideConfirm)}
            </>
          )
          dispatch(hideModal())
          dispatch(showModal(modalConfig))
        }
      }

      validateDetails()
      return dispatch(showModal(modalConfig))
    },

    openModalDeleteConferenceBridge: incidentID => {
      const modalBody = (
        <>
          <p>{areYouSureText}</p>
          <ul className='button-list right'>
            {renderCancelButton('Cancel', dispatch)}

            <li>
              <button
                className='btn btn-outline-primary'
                data-ext='conference-bridge-delete-modal-confirmation-delete'
                onClick={deleteConferenceBridge.bind(this, incidentID)}
                onKeyPress={deleteConferenceBridge.bind(this, incidentID)}
                type='button'
              >
                Delete
              </button>
            </li>
          </ul>
        </>
      )

      const modalConfig = {
        modalType: 'acknowledge',
        modalProps: {
          title: `Delete Conference Bridge from incident #${incidentID}`,
          body: modalBody,
          onCancel: () => {
            dispatch(hideModal())
          },
          onConfirm: () => null,
          suppressOutsideClick: true,
          actionBar: false,
        },
      }
      dispatch(showModal(modalConfig))
    },
  }
}

const FetchingView = () => (
  <div data-ext='conference-bridge--loading'>Fetching Details</div>
)

const ConferenceBridgeDisplayView = memo(
  ({
    incident,
    isViewExpanded,
    openModalDeleteConferenceBridge,
    openModalEditConferenceBridge,
    setConferenceBridgeShowDetailsView,
    setConferenceBridgeDetailsError,
    fetchConferenceBridgeDetails,
    details,
    error,
  }) => {
    const incidentID = incident.get('INCIDENT_NAME')

    useEffect(() => {
      if (!details) {
        fetchConferenceBridgeDetails({ incidentID })
      }

      return () => setConferenceBridgeDetailsError(false)
    }, [
      details,
      fetchConferenceBridgeDetails,
      incident,
      incidentID,
      setConferenceBridgeDetailsError,
    ])

    const changeDisplayLevel = useCallback(() => {
      const incidentID = incident.get('INCIDENT_NAME')
      setConferenceBridgeShowDetailsView({
        showDetails: !isViewExpanded,
        incidentID,
      })
    }, [isViewExpanded, incident, setConferenceBridgeShowDetailsView])

    if (details) {
      const urlHeader = details.has('url') ? (
        <div>
          Conference Bridge URL:{' '}
          <a
            href={details.get('url')}
            target='_blank'
            data-ext='conference-bridge--url'
          >
            {details.get('url')}
          </a>
        </div>
      ) : null
      const phoneHeader = details.has('phone') ? (
        <div>
          Conference Bridge Phone Number:{' '}
          <a
            href={`tel:${details.get('phone')}`}
            data-ext='conference-bridge--phone-number'
          >
            {details.get('phone')}
          </a>
        </div>
      ) : null

      const title = (
        <div>
          {urlHeader}
          {phoneHeader}
        </div>
      )

      const notes =
        details.has('notes') && isViewExpanded ? (
          <NotesContainer data-ext='conference-bridge--notes'>
            {details.get('notes')}
          </NotesContainer>
        ) : null

      const detailsVisibilityText = isViewExpanded
        ? 'Hide Details'
        : 'Show Details'

      return (
        <Container data-ext='conference-bridge--container'>
          <Header>
            {title}

            <Dropdown
              toggle={
                <MenuToggle data-ext='conference-bridge-menu-toggle'>
                  <MenuEllipses icon={faEllipsisV} />
                </MenuToggle>
              }
              data-ext='conference-bridge-menu'
            >
              <Menu style={{ width: 120 }}>
                {details.get('notes') && (
                  <MenuItem
                    onClick={changeDisplayLevel}
                    data-ext='conference-bridge--details-toggle-notes'
                  >
                    {detailsVisibilityText}
                  </MenuItem>
                )}

                <MenuItem
                  data-ext='conference-bridge-details-edit'
                  onClick={openModalEditConferenceBridge.bind(
                    this,
                    incidentID,
                    details.get('url'),
                    details.get('phone'),
                    details.get('notes')
                  )}
                >
                  Edit Bridge
                </MenuItem>
                <MenuItem
                  data-ext='conference-bridge-details-delete'
                  onClick={openModalDeleteConferenceBridge.bind(
                    this,
                    incidentID
                  )}
                >
                  Delete Bridge
                </MenuItem>
              </Menu>
            </Dropdown>
          </Header>
          {notes}
        </Container>
      )
    } else {
      return <FetchingView />
    }
  }
)

export const ConferenceBridgeDisplayViewContainer = connect(
  null,
  mapDispatchToProps
)(ConferenceBridgeDisplayView)
