import React, { useState } from 'react'
import styled from 'styled-components'
import { baseFontFamily } from 'style-vars/fonts'
import { voGrayLighter, voGrayLighter3, voRedMain } from 'style-vars/colors'

const CBDetailsShell = styled.div`
  font-family: ${baseFontFamily};
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
`
const CBDetailsFieldLabel = styled(CBDetailsShell)`
  margin-top: 15px;
`
const CBDetailsField = styled.input`
  font-weight: normal;
  width: 100%;
  border: 1px solid ${voGrayLighter3};
  border-radius: 3px;
  padding: 8px;
`
const CBDetailsTextArea = styled.textarea`
  font-weight: normal;
  width: 100%;
  border: 1px solid ${voGrayLighter3};
  box-sizing: border-box;
  border-radius: 3px;
  resize: none;
  padding: 8px;
`
const CBDetailsInfoLabel = styled(CBDetailsShell)`
  margin-top: 10px;
  font-size: 11px;
  color: ${voGrayLighter};
`

const CBDetailsWarning = styled(CBDetailsShell)`
  margin-top: 4px;
  font-size: 11px;
  color: ${voRedMain};
  display: ${props => (props.visible ? 'block' : 'none')};
`

export default props => {
  const {
    setUrl,
    setPhone,
    setNotes,
    url = '',
    phone = '',
    notes = '',
    headerText,
  } = props

  const [showURLWarning, setShowURLWarning] = useState(false)

  const validateURLString = url => {
    if (url.length > 0) {
      setShowURLWarning(!/^https?:\/\//.test(url))
    } else {
      setShowURLWarning(false)
    }
  }

  const onDetailsChange = e => {
    switch (e.target.id) {
      case 'cbDetailsURLField':
        validateURLString(e.target.value)
        setUrl(e.target.value)
        break
      case 'cbDetailsPhoneField':
        setPhone(e.target.value)
        break
      case 'cbDetailsNotesField':
        setNotes(e.target.value)
        break
    }
  }

  return (
    <CBDetailsShell>
      <p></p>
      {headerText ? (
        <CBDetailsFieldLabel>{headerText}</CBDetailsFieldLabel>
      ) : (
        ''
      )}

      <CBDetailsFieldLabel>URL</CBDetailsFieldLabel>
      <CBDetailsField
        type='text'
        id='cbDetailsURLField'
        onChange={onDetailsChange}
        defaultValue={url}
        autoFocus
      ></CBDetailsField>

      <CBDetailsWarning visible={showURLWarning}>
        The URL field must start with http:// or https://
      </CBDetailsWarning>

      <CBDetailsFieldLabel>Phone Number</CBDetailsFieldLabel>
      <CBDetailsField
        type='text'
        id='cbDetailsPhoneField'
        onChange={onDetailsChange}
        defaultValue={phone}
      ></CBDetailsField>

      <CBDetailsFieldLabel>Notes</CBDetailsFieldLabel>
      <CBDetailsTextArea
        rows='4'
        id='cbDetailsNotesField'
        onChange={onDetailsChange}
        defaultValue={notes}
      ></CBDetailsTextArea>

      <CBDetailsInfoLabel>
        Note: If you have international phone numbers or other instructions, add
        them here.
      </CBDetailsInfoLabel>
    </CBDetailsShell>
  )
}
