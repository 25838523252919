import { fromJS } from 'immutable'

import { ORG_META_UPDATE } from 'components/store/actions'

const defaultState = fromJS({
  features: {},
  metaflags: {},
})

export default function orgMetaReducer(state = defaultState, action) {
  switch (action.type) {
    case ORG_META_UPDATE:
      return updateOrgMeta(state, action.payload)
    default:
      return state
  }
}

function updateOrgMeta(state, payload) {
  const clone = Object.assign({}, payload)
  delete clone._priv
  return state.merge(fromJS({ features: payload._priv, metaflags: clone }))
}
