export default (function() {
  if (global && global.VO_CONFIG) {
    return window.VO_CONFIG
  } else {
    return {
      orgslug: 'testorg',
      auth: {
        user: {
          username: 'test_user',
        },
        org: {
          slug: 'testorg',
        },
      },

      socketAuth: {
        COMPANY_ID: 'test_org',
        NONCE: '',
        RECONNECTS: 0,
        SESSIONDURATION: 0,
        SESSIONSTART: 0,
        SIGNATURE: '',
        TIMESTAMP: 0,
        TZ: 'America/Denver',
        USER_ID: 'test_user',
      },
    }
  }
})()
