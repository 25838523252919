var _ = require('lodash');
module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<label class="statuspage-control-group-label">' +
((__t = ( name )) == null ? '' : __t) +
'</label>\n\n<div class="statuspage-control-group-radios">\n    <label class="radio inline">\n        <input name="component-' +
((__t = ( id )) == null ? '' : __t) +
'-status" type="radio" value="operational" class="js-statuspage-component-status"> Operational\n    </label>\n\n    <label class="radio inline">\n        <input name="component-' +
((__t = ( id )) == null ? '' : __t) +
'-status" type="radio" value="under_maintenance" class="js-statuspage-component-status"> Under Maintenance\n    </label>\n\n    <label class="radio inline">\n        <input name="component-' +
((__t = ( id )) == null ? '' : __t) +
'-status" type="radio" value="degraded_performance" class="js-statuspage-component-status"> Degraded Performance\n    </label>\n\n    <label class="radio inline">\n        <input name="component-' +
((__t = ( id )) == null ? '' : __t) +
'-status" type="radio" value="partial_outage" class="js-statuspage-component-status"> Partial Outage\n    </label>\n\n    <label class="radio inline">\n        <input name="component-' +
((__t = ( id )) == null ? '' : __t) +
'-status" type="radio" value="major_outage" class="js-statuspage-component-status"> Major Outage\n    </label>\n</div>\n';

}
return __p
}