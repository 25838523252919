// do fetch of statuspage incidents into manager collection...
// listen for statuspage timeline messages...
// update incidents in manager collection...
// fire status event to change icon color if anything is other than normal
import server from '../../../../server'
import _ from 'lib/underscore'
import SPIncidents from '../../../collections/statuspage/incidents'

export default SPIncidents.extend({
  initialize: function() {
    this.update()
    server.on(
      'statuspage:incident',
      _.bind(_.debounce(this.update, 1000), this)
    )
  },

  isOkay: function() {
    return this.allClear
  },

  update: function() {
    var self = this
    this.fetch().done(function(resp) {
      if (resp.error) {
        // TODO - UI feedback on certain errors?
        return
      }
      var statuses = self.filter(function(incident) {
        return incident.get('status') !== 'resolved'
      })

      self.allClear = _.isEmpty(statuses)
      self.trigger('statuspage:statechange')
    })
    // noop on fail - server now returns success with error payload, if any
  },
})
