import React from 'react'

export default function getNoIncidentModal() {
  return (
    <span>
      Double check the URL. Make sure your organization name and incident number
      are correct. If you feel that you incorrectly received this message,
      please contact support via live chat or open a case on the{' '}
      <a href='https://login.splunk.com/'>Splunk Support Portal</a>.
    </span>
  )
}
