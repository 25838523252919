import React, { PureComponent } from 'react'
import styled from 'styled-components'
import server from '../../app/server'

const Banner = styled.div`
  background-color: #fbb040;
  left: 0%;
  right: 20%;
  width: 100%;
  z-index: 5999;
  position: absolute;
  height: 32px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 7px;
  padding-left: 16px;
  top: ${props => props.bannerTop || '48px'};
`

const TryAgain = styled.a`
  text-decoration: underline;
  color: white;
  padding-left: 5px;
`

export default class OfflineBanner extends PureComponent {
  onClick = () => {
    server.reconnect(true)
  }

  render() {
    return (
      <Banner data-test-id='offline-banner' bannerTop={this.props.bannerTop}>
        You seem to be offline. We'll keep trying to reconnect, or
        <TryAgain onClick={this.onClick}> try now</TryAgain>
      </Banner>
    )
  }
}
