import Backbone from 'lib/backbone'

import server from '../../server'
import log from 'util/_console'
import data from '../../data'

// TODO: I think we can remove this...
export default Backbone.Model.extend({
  idAttribute: 'INCIDENT_NAME',

  defaults: {
    ENTITY_ID: '',
    LAST_UUID: '',
    SERVICE: '',
    HOST: '',
    MONITOR_TYPE: '',
    CURRENT_STATE: '',
    ACK_USER: '',
  },

  initialize: function() {
    this.set(
      'formattedTime',
      this.formatTime(new Date(this.get('INCIDENT_TIMESTAMP')))
    )
  },

  last_alert: function() {
    return data.alerts.get(this.get('LAST_UUID'))
  },

  acknowledge: function(message) {
    this.collection.ackOrResolve([this.get('INCIDENT_NAME')], message, false)
  },

  resolve: function(message) {
    this.collection.ackOrResolve([this.get('INCIDENT_NAME')], message, true)
  },

  escalate: function() {
    server.escalateIncident(this.get('INCIDENT_NAME'))
  },

  reroute: function(teams) {
    this.collection.reroute([this.get('INCIDENT_NAME')], teams)
  },

  getIncidentDetails: function() {
    var self = this
    return server.getIncidents([this.id], function(_data) {
      try {
        self.set(_data.PAYLOAD.INCIDENTS[0])
        self.set('isDigest', false)
      } catch (err) {
        log.error(err.message)
      }
    })
  },
})
