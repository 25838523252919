import React from 'react'

import {
  AlertCard,
  Chat,
  DevEndpoint,
  SPIO,
  SystemMessage,
  TimelineIncidentCard,
  TimelineProgressLoader,
} from '@victorops/victory'

export const RowMessage = props => {
  const {
    alertNonVoFields,
    alertVoFields,
    hasReachedEnd,
    integrationIconPath,
    integrationName,
    isExpanded,
    isLoading,
    message,
    onAlertExpandClicked,
    onDevExpandClicked,
    resize,
  } = props

  if (message) {
    const componentProps = message.componentProps || {}
    const { eventType } = componentProps || message

    if (eventType === 'incident') {
      return (
        <TimelineIncidentCard
          alertCount={message.ALERT_COUNT || ''}
          annotationCount={message.ANNOTATION_COUNT || 0}
          currentState={message.ENTITY_STATE || ''}
          host={componentProps.host || ''}
          incidentId={message.INCIDENT_ID || ''}
          incidentMessage={componentProps.incidentMessage || ''}
          incidentPhase={message.CURRENT_PHASE || ''}
          integrationName={integrationName}
          integrationIconPath={integrationIconPath}
          isMuted={componentProps.isMuted}
          monitorName={message.MONITOR_NAME || ''}
          monitorTool={message.MONITOR_TYPE}
          monitorType={message.MONITOR_TYPE}
          policiesPaged={componentProps.policiesPaged || ''}
          snoozedUntil={componentProps.snoozedUntil}
          timestamp={componentProps.timestamp || ''}
          userAcked={message.USER || ''}
          usersPaged={componentProps.usersPaged || ''}
          isMultiResponder={message.IS_MULTI_RESPONDER}
          ackData={message.ACK_DATA || {}}
        />
      )
    } else if (eventType === 'chat') {
      return (
        <Chat
          chatChannel={''} // we do not have the data from backend yet. pass when available.
          chatMessage={componentProps.displayMessage || ''}
          chatTimestamp={componentProps.timestamp || ''}
          chatType={message.TAGS[0] || ''}
          isRobot={message.IS_ROBOT}
          onImageLoaded={resize}
          parsedMessage={componentProps.parsedMessage || []}
          userID={message.USER_ID || ''}
        />
      )
    } else if (eventType === 'system') {
      if (
        message.componentProps &&
        typeof componentProps.displayMessage !== 'string'
      ) {
        const systemMessages =
          componentProps &&
          componentProps.displayMessage.map(message => {
            return (
              <SystemMessage
                messageText={message}
                methods={componentProps.methods || ''}
                pagingObj={componentProps.pagingObj || {}}
                timestamp={componentProps.timestamp || ''}
              />
            )
          })
        return systemMessages
      }
      return (
        <SystemMessage
          messageText={componentProps.displayMessage || ''}
          methods={componentProps.methods || ''}
          pagingObj={componentProps.pagingObj || {}}
          timestamp={componentProps.timestamp || ''}
        />
      )
    } else if (eventType === 'alert') {
      return (
        <AlertCard
          alarmName={message['details.AlarmName'] || ''}
          alertJSON={alertNonVoFields || {}}
          alertType={message.VO_DISPLAY_ALERT_TYPE || ''}
          alertUrl={message['details.alert_url'] || ''}
          annotationCount={message.annotations ? message.annotations.length : 0}
          annotations={message.annotations || []}
          awsOutput={message.AWSOUTPUT || ''}
          entityDisplayName={componentProps.entityDisplayName || ''}
          hostDisplayName={message.HOSTDISPLAYNAME || ''}
          hostOutput={message.HOSTOUTPUT || ''}
          hostState={message.HOSTSTATE || ''}
          incidentNumber={message.INCIDENT_NAME || ''}
          integrationName={integrationName}
          integrationIconPath={integrationIconPath}
          isExpanded={isExpanded}
          monitorName={componentProps.monitorName || ''}
          monitorType={message.MONITOR_TYPE}
          notificationType={message.NOTIFICATIONTYPE || ''}
          onExpandClicked={onAlertExpandClicked}
          onToggleNullFieldsClicked={resize}
          serviceDisplayName={message.SERVICEDISPLAYNAME || ''}
          serviceOutput={message.SERVICEOUTPUT || ''}
          serviceState={message.SERVICESTATE || ''}
          stateMessage={componentProps.stateMessage || ''}
          timestamp={componentProps.timestamp || ''}
          triggerNameSpace={componentProps.triggerNameSpace || ''}
          triggerRegion={componentProps.triggerRegion || ''}
          voAlertJSON={alertVoFields}
        />
      )
    } else if (eventType === 'thirdparty') {
      const provider = message.PROVIDER

      if (provider === 'STATUS_PAGE_IO_PROVIDER') {
        return (
          <SPIO
            timestamp={componentProps.timestamp || ''}
            name={message.MESSAGE_BODY ? message.MESSAGE_BODY.NAME : ''}
            message={message.MESSAGE_BODY ? message.MESSAGE_BODY.MESSAGE : ''}
            status={message.MESSAGE_BODY ? message.MESSAGE_BODY.STATUS : ''}
            createdAt={componentProps.createdAt || ''}
            updatedAt={componentProps.updatedAt || ''}
            updatedBy={
              message.MESSAGE_BODY ? message.MESSAGE_BODY.UPDATED_BY : ''
            }
            integrationName={integrationName}
            integrationIconPath={integrationIconPath}
          />
        )
      }
      return (
        <DevEndpoint
          eventType={
            message.MESSAGE_BODY ? message.MESSAGE_BODY.EVENT_TYPE : ''
          }
          integrationName={integrationName}
          integrationIconPath={integrationIconPath}
          isExpanded={isExpanded}
          json={componentProps.messageBody || ''}
          longMessage={
            message.MESSAGE_BODY ? message.MESSAGE_BODY.LONG_MESSAGE : ''
          }
          onExpandClicked={onDevExpandClicked}
          shortMessage={componentProps.shortMessage || ''}
          source={message.MESSAGE_BODY ? message.MESSAGE_BODY.SOURCE : ''}
          timestamp={componentProps.timestamp || ''}
        />
      )
    } else {
      return null
    }
  } else {
    return hasReachedEnd && !isLoading ? (
      <Chat
        chatChannel={''} // we do not have the data from backend yet. pass when available.
        chatMessage={'You have reached the end of your history'}
        chatTimestamp={''}
        chatType={''}
        onImageLoaded={resize}
        isRobot
        parsedMessage={[]}
        userID={'victorops'}
      />
    ) : (
      <TimelineProgressLoader />
    )
  }
}
