import { Map } from 'immutable'

import { INCIDENT_REROUTING } from 'components/store/actions'

const initialState = {
  showRerouteTool: false,
  incidentId: true,
  coords: {
    bottom: 0,
    left: 0,
  },
}

export default function incidentRerouting(state = Map(initialState), action) {
  switch (action.type) {
    case INCIDENT_REROUTING:
      return _updateIncidentReroutingState(state, action.payload)
    default:
      return state
  }
}

function _updateIncidentReroutingState(state, payload) {
  return state.merge(payload)
}
