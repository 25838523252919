// Import
// ---------------------------------------------------------------------------

// vendor
import React from 'react'
import { Picker, Pill } from '@victorops/victory'
import OffCallWarning from './off-call-warning'

const successModal = (dismiss, props) => ({
  modalType: 'confirm',
  modalProps: {
    actionBar: false,
    component: (
      <div>
        <p className='reroute-success'>
          Incident #{props.incidentId} has been rerouted from:
        </p>
        <ul className='success-modal--list'>
          {props.previous.map(function(item) {
            return (
              <li key={`${item.pillLabel ? item.pillLabel : item.label}`}>
                <Pill
                  key={item.id}
                  name={`${item.pillLabel ? item.pillLabel : item.label}`}
                  pillType={item.type}
                  slug={item.id}
                  swallowClick
                />
              </li>
            )
          })}
        </ul>

        <p className='reroute-success'>To:</p>

        <ul className='success-modal--list'>
          {props.selectedOptions.map(function(item) {
            return (
              <li key={item.id}>
                <Pill
                  name={`${item.pillLabel ? item.pillLabel : item.label}`}
                  pillType={item.type}
                  slug={item.id}
                  swallowClick
                />
              </li>
            )
          })}
        </ul>

        <ul className='button-list right'>
          <li>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={dismiss}
            >
              Close
            </button>
          </li>
        </ul>
      </div>
    ),
    onCancel: dismiss,
    title: 'Incident Rerouted',
  },
})

const failureModal = (dismiss, props) => ({
  modalType: 'confirm',
  modalProps: {
    actionBar: false,
    component: (
      <div>
        <p>Could not reroute incident #{props.incidentId}</p>
        <ul className='button-list right'>
          <li>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={dismiss}
            >
              Close
            </button>
          </li>
        </ul>
      </div>
    ),
    onCancel: dismiss,
  },
})

export default props => {
  const payload = {
    incidentId: props.incidentId,
    selected: props.selectedOptions,
    failureModal: failureModal(props.onCancel, props),
    successModal: successModal(props.onCancel, props),
  }

  return (
    <div>
      {props.isMuted ? (
        <p className='alert alert-warning'>
          This incident is currently muted. Rerouting this incident will remove
          it from Maintenance Mode and start paging the selected escalation
          policies and/or users.
        </p>
      ) : null}

      <Picker
        filter={props.filter}
        focusOnRender
        options={props.options}
        placeholder={props.placeholder}
        selectedOptions={props.selectedOptions}
        onFilterChange={props.onFilterChange}
        onSelectedChange={props.onSelectedChange}
      />

      <OffCallWarning selectedOptions={props.selectedOptions} />

      <ul className='button-list right'>
        <li>
          <button
            type='button'
            className='btn btn-secondary'
            onClick={props.onCancel}
          >
            Cancel
          </button>
        </li>

        <li>
          <button
            disabled={props.selectedOptions.length < 1}
            type='button'
            className='btn btn-outline-primary'
            onClick={props.onReroute(payload)}
          >
            Reroute
          </button>
        </li>
      </ul>
    </div>
  )
}
