import { PureComponent } from 'react'
import { logError } from '../../../util/monitoringService'

export class ToolbarErrorBoundary extends PureComponent {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, componentStack) {
    const { message } = this.props

    const customData = { ...componentStack, message }
    const tags = ['react', 'errorBoundary', 'incident details: toolbar']

    logError(error, { groupBy: tags, data: customData })
  }

  render() {
    const { hasError } = this.state
    const { children, Fallback } = this.props

    return hasError ? Fallback : children
  }
}
