// vendor
import { Map, fromJS } from 'immutable'

import moment from 'moment-timezone'
import { decodeHtml as _decodeHtml } from 'components/__utils/html'

import {
  INCIDENT_PUSH_TO_REROUTE,
  INCIDENT_TOGGLE,
  INCIDENT_UPDATE,
  INCIDENT_UPDATING,
  INCIDENTS_UPDATE,
  INCIDENTS_UPDATING,
  INCIDENTS_FLUSH,
} from 'components/store/actions'

import debug from 'debug'

const log = debug('VO:incident-reducer')

// Reducer
// ---------------------------------------------------------------------------

export default function incidentsReducer(state = Map(), action) {
  switch (action.type) {
    // Singular
    case INCIDENT_PUSH_TO_REROUTE:
      return pushRe(state, action.payload)
    case INCIDENT_TOGGLE:
      return toggleIncident(state, action.payload)
    case INCIDENT_UPDATE:
      return updateIncident(state, action.payload)
    case INCIDENT_UPDATING:
      return updatingIncident(state, action.payload)

    // Plural
    case INCIDENTS_FLUSH:
      return flushIncidents(state)
    case INCIDENTS_UPDATE:
      return updateIncidents(state, action.payload)
    case INCIDENTS_UPDATING:
      return updatingIncidents(state, action.payload)

    default:
      return state
  }
}

// Helpers
// ---------------------------------------------------------------------------

function getViewState(incident, isFlushed) {
  return {
    isSelected: false,
    isUpdating: false,

    // persisted
    isFlushed: isFlushed,
    rerouteTo: incident.TEAMS_PAGED,
  }
}

function processIncident(incident) {
  function getPoliciesFormat(policiesPaged) {
    const teamPolicy = policiesPaged.reduce((arr, el) => {
      if (!el.POLICY.SLUG.includes('directUserPolicySlug')) {
        arr.push(` ${el.TEAM.NAME} : ${el.POLICY.NAME}`)
        return arr
      } else {
        return arr
      }
    }, [])

    return teamPolicy.join(',')
  }

  return fromJS({
    host: _decodeHtml(incident.HOST),
    incidentMessage: _decodeHtml(incident.SERVICE),
    incidentPolicies: getPoliciesFormat(incident.POLICIES_PAGED),
    isMuted: incident.IS_MUTED || false,
    usersPaged: incident.USERS_PAGED.join(', '),
    incidentTimestamp: moment(incident.INCIDENT_TIMESTAMP).format(
      `MMM. D - h:mm A`
    ),
    snoozedUntil: incident.SNOOZED_UNTIL
      ? moment(incident.SNOOZED_UNTIL).format(`MMM. D, h:mm A`)
      : null,
  })
}

// @TODO: Remove template strings, they are unecessary

function updateIncident(state, payload) {
  const prev = state.getIn([
    `${payload.INCIDENT_NAME}`,
    'data',
    'CURRENT_ALERT_PHASE',
  ])
  const next = payload.CURRENT_ALERT_PHASE

  let incident
  if (prev === next) {
    incident = {
      [payload.INCIDENT_NAME]: {
        componentProps: processIncident(payload),
        data: payload,
        viewState: getViewState(
          payload,
          state.getIn([`${payload.INCIDENT_NAME}`, 'viewState', 'isFlushed'])
        ),
      },
    }
  } else if (next !== 'UNACKED') {
    incident = {
      [payload.INCIDENT_NAME]: {
        componentProps: processIncident(payload),
        data: payload,
        viewState: getViewState(payload, false),
      },
    }
  } else {
    incident = {
      [payload.INCIDENT_NAME]: {
        componentProps: processIncident(payload),
        data: payload,
        viewState: getViewState(payload, false),
      },
    }
  }

  return state.merge(fromJS(incident))
}

// Reducers
// ---------------------------------------------------------------------------

function updateIncidents(state, incidents) {
  log('updateIncidents', state)

  const nextState = incidents.reduce(function(_incidents, incident) {
    const prev = state.getIn([
      `${incident.INCIDENT_NAME}`,
      'data',
      'CURRENT_ALERT_PHASE',
    ])
    const next = incident.CURRENT_ALERT_PHASE

    if (prev === next) {
      _incidents[incident.INCIDENT_NAME] = {
        componentProps: processIncident(incident),
        data: incident,
        viewState: getViewState(
          incident,
          state.getIn([`${incident.INCIDENT_NAME}`, 'viewState', 'isFlushed'])
        ),
      }
    } else if (next !== 'UNACKED') {
      _incidents[incident.INCIDENT_NAME] = {
        componentProps: processIncident(incident),
        data: incident,
        viewState: getViewState(incident, true),
      }
    } else {
      _incidents[incident.INCIDENT_NAME] = {
        componentProps: processIncident(incident),
        data: incident,
        viewState: getViewState(incident, false),
      }
    }

    return _incidents
  }, {})

  return state.merge(fromJS(nextState))
}

function updatingIncident(state, incidentId) {
  return state.updateIn(
    [`${incidentId}`, 'viewState', 'isUpdating'],
    () => true
  )
}

function updatingIncidents(state, incidentIds) {
  const updateState = function updateState(incidents, incident) {
    const isUpdating = incidents.getIn([
      `${incident}`,
      'viewState',
      'isUpdating',
    ])

    return incidents.updateIn(
      [`${incident}`, 'viewState', 'isUpdating'],
      () => !isUpdating
    )
  }

  return incidentIds.reduce(updateState, state)
}

function flushIncidents(state) {
  const updateState = function updateState(incident) {
    return incident.updateIn(['viewState', 'isFlushed'], () => true)
  }

  return state.map(updateState)
}

function toggleIncident(state, incidentId) {
  const prevState = state.getIn([`${incidentId}`, 'viewState', 'isSelected'])

  return state.updateIn(
    [`${incidentId}`, 'viewState', 'isSelected'],
    () => !prevState
  )
}

function pushRe(state, { teamId, incidentId }) {
  const rerouteTo = state.getIn([`${incidentId}`, 'viewState', 'rerouteTo'])
  const shouldRemove = rerouteTo.includes(teamId)

  let nextState
  if (shouldRemove) {
    nextState = rerouteTo.filter(function(el) {
      return teamId !== el
    })
  } else {
    nextState = rerouteTo.push(teamId)
  }

  return state.updateIn(
    [`${incidentId}`, 'viewState', 'rerouteTo'],
    () => nextState
  )
}
