// vendor
import { fromJS } from 'immutable'

import {
  INCIDENTS_PANE_SORT,
  INCIDENTS_PANE_UPDATE,
} from 'components/store/actions'

const defaultState = fromJS({
  audience: 'all',
  phase: 'UNACKED',
  sort: {
    sortBy: 'time',
    sortDirection: 'DESC',
  },
})

// Reducer
// ---------------------------------------------------------------------------

export default function incidentNav(state = defaultState, action) {
  switch (action.type) {
    case INCIDENTS_PANE_SORT:
      return sort(state, action.payload)
    case INCIDENTS_PANE_UPDATE:
      return update(state, action.payload)
    default:
      return state
  }
}

// Helpers
// ---------------------------------------------------------------------------

function sort(state, payload) {
  return state.merge(fromJS({ sort: payload }))
}

function update(state, payload) {
  return state.merge(fromJS(payload))
}
