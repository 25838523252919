// vendor
import debug from 'debug'
import observableSocket from 'observable-socket'
import { Subject } from 'rxjs/Rx'

// victorops
import transactionMap from '@victorops/transaction-map'
import { oLog } from '@victorops/utils'

const log = debug('VO:socket')
const error = debug('VO:socket:error')
import { compress, decompress } from 'util/compression'
import check from 'util/check'
import { isIE } from 'util/ie'
import { isSocketCompressionDisabled } from 'util/configOverrides'
import { logError } from '../../util/monitoringService'

function makeConnectionLoop(socketEmitter, send, receive) {
  log('makeConnectionLoop:start')

  // At the moment, we are piggybacking on the WebSocket created by
  // connection.js. To transport that WebSocket to the new app, an
  // EventEmitter feels alright.
  //
  // The downside to using an EventEmitter is that this listener needs to be
  // registered before the WebSocket is created.
  //
  // This will get super messy/bad if there are multiple instances of
  // server.js emitting.
  socketEmitter.once('socket:connect', function onSocketEmit(wSocket) {
    const oSocket = observableSocket(wSocket)
    const sendSubscription = send.subscribe(msg => oSocket.up(msg).catch(error))

    log('heard socket:connect', wSocket)
    oLog('VO_OBSERVABLE:source', oSocket.down)

    oSocket.down.subscribe(
      function onNext(messageEvent) {
        const message = messageEvent.data

        if (typeof message === 'string') {
          receive.next(JSON.parse(message.slice(message.indexOf('{'))))
        } else {
          decompress(message)
            .then(decompressedMsg => {
              receive.next(
                JSON.parse(decompressedMsg.slice(decompressedMsg.indexOf('{')))
              )
            })
            .catch(e => {
              logError(e, { attributes: { action: 'onNext' } })
            })
        }
      },

      function onError(err) {
        error(err)
        sendSubscription.unsubscribe()

        makeConnectionLoop(socketEmitter, send, receive)
      },

      function onComplete() {
        log('makeConnectionLoop:complete')
        sendSubscription.unsubscribe()

        makeConnectionLoop(socketEmitter, send, receive)
      }
    )
  })
}

export default function(socketEmitter) {
  const _send = new Subject()
  const _receive = new Subject()

  makeConnectionLoop(socketEmitter, _send, _receive)

  // you probably want to make sure any changes made to this socket send are mirrored
  // in the app/server/connection.js send

  // due to the heavily coupled nature of this socket, we've decided not to implement LOGIN_REPLY_MESSAGE here. For more info see VIR-314.
  const send = msg => {
    check
      .feature('socketCompression')
      .then(hasFeature => {
        const outMessage =
          hasFeature && !isIE() && !isSocketCompressionDisabled()
            ? compress(msg, 'gzip')
            : JSON.stringify(msg)
        _send.next(outMessage)
      })
      .catch(e => {
        const err = {
          err: e,
          message: msg,
        }

        xlog('connection:send', err)
        logError(e, { attributes: { action: 'send' } })
      })
  }
  const receive = _receive.asObservable()

  return {
    send: send,
    read: receive,

    // transaction mapped send
    tSend: transactionMap()(receive, send),
  }
}
