import Backbone from 'lib/backbone'
import LocalStorage from 'lib/backbone.localstorage'

// Model
// -----------------------------------------------------------------------

var Group = Backbone.Model.extend({
  defaults: {
    state: 'off',
  },

  idAttribute: 'routeGroupKey',
})

// Collection
// -----------------------------------------------------------------------

export default function(slug) {
  return Backbone.Collection.extend({
    model: Group,

    localStorage: new LocalStorage(slug + ':RoutesGroup'),

    initialize: function() {
      if (
        !(
          window.Storage &&
          window.sessionStorage.getItem('disable-filter-persistence') === 'true'
        )
      ) {
        this.listenTo(this, 'remove', function(model) {
          model.destroy()
        })

        this.listenTo(this, 'add change', function(model) {
          model.save()
        })
      }
    },
  })
}
