// vendor
import Marionette from 'backbone.marionette'
import { forEach, keys } from 'lodash'
import $ from 'jquery'

// alert detail table view

export default Marionette.View.extend({
  initialize(options) {
    this.options = options
  },

  // Overrides
  // -----------------------------------------------------------------------

  className: 'selectable',

  serializeData() {
    return this.model
  },

  tagName: 'table',

  template() {
    return ''
  },

  // Events
  // -----------------------------------------------------------------------

  onRender() {
    if ('incident' in this.options) {
      this.$el.append(this._buildTableRows(this.options.incident))
    }

    this.$el.append(this._buildTableRows(this.model))
  },

  // Custom
  // -----------------------------------------------------------------------

  _buildTableRows(data) {
    const body = $('<tbody></tbody>')

    forEach(keys(data).sort(), function(k) {
      const v = data[k]

      body.append(`
                <tr>
                    <td class="alert-label">${k}</td>

                    <td class="alert-data">${v}</td>
                </tr>
            `)
    })

    return body
  },
})
