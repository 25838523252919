import moment from 'moment'

import { Map } from 'immutable'

export default function processThirdParty(timelineMessage) {
  const readableStatus = new Map({
    // incident states
    investigating: 'Investigating',
    identified: 'Identified',
    monitoring: 'Monitoring',
    resolved: 'Resolved',

    // component states
    operational: 'Operational',
    degraded_performance: 'Degraded Performance',
    under_maintenance: 'Under Maintenance',
    partial_outage: 'Partial Outage',
    major_outage: 'Major Outage',
  })

  const filterTypes = new Map({
    Branch: 'developer-branch',
    Build: 'developer-build',
    Deploy: 'developer-deploy',
    Merge: 'developer-merge',
    PullRequest: 'developer-pull-request',
  })

  const _timestamp = moment(
    timelineMessage.getIn(['SEQUENCING', 'SERVICE_TIME'])
  ).format('MMM. D - h:mm A')

  return timelineMessage.withMutations(message => {
    message
      .setIn(['componentProps', 'eventType'], 'thirdparty')
      .setIn(
        ['componentProps', 'filterType'],
        filterTypes.get(message.get('MESSAGE_TYPE', ''))
      )
      .setIn(
        ['componentProps', 'status'],
        readableStatus.get(message.get('STATUS'), message.get('STATUS'))
      )
      .setIn(['componentProps', 'timestamp'], _timestamp)

    if (timelineMessage.get('PROVIDER') === 'STATUS_PAGE_IO_PROVIDER') {
      const _createdAt = moment(
        timelineMessage.getIn(['MESSAGE_BODY', 'CREATED_AT'])
      ).format('h:mm A - MMM. D, YYYY')
      const _updatedAt = moment(
        timelineMessage.getIn(['MESSAGE_BODY', 'UPDATED_AT'])
      ).format('h:mm A - MMM. D, YYYY')

      message
        .setIn(['componentProps', 'createdAt'], _createdAt)
        .setIn(['componentProps', 'updatedAt'], _updatedAt)
        .setIn(['componentProps', 'filterType'], 'timeline-alert-thirdparty')
    } else {
      const _shortMessage = timelineMessage
        .getIn(['MESSAGE_BODY', 'SHORT_MESSAGE'])
        .toJS()
      const _messageBody = timelineMessage
        .get('MESSAGE_BODY')
        .delete('JSON')
        .toJS()

      message
        .setIn(['componentProps', 'shortMessage'], _shortMessage)
        .setIn(['componentProps', 'messageBody'], _messageBody)
    }
  })
}
