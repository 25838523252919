// vendor
import Marionette from 'marionette'

// lib
import vent from 'util/vent'

export default Marionette.View.extend({
  // Overrides
  // -------------------------------------------------------------------

  template: require('t/statuspage/modals/prompt.tpl'),

  events: {
    'click .js-update-components': function() {
      vent.trigger('statuspage:prompt:empty')
      vent.trigger('statuspage:container:show', 'components')
    },
  },

  // Custom
  // -------------------------------------------------------------------
})
