import React, { PureComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faWrench } from '@fortawesome/fontawesome-pro-solid'

import { StartModal as StartMaintenanceModal } from 'components/maintenance-mode'

export default class GranularIncidentsNav extends PureComponent {
  getMaintenanceAction = () => {
    return (
      <li
        title='Start Maintenance Mode'
        data-test-key='start-maintenance-mode'
        onClick={this.startMaintenance}
      >
        <a>
          <FontAwesomeIcon icon={faWrench} />
        </a>
      </li>
    )
  }

  startMaintenance = () => {
    const modalConfig = {
      modalType: 'confirm',
      modalProps: {
        actionBar: false,
        component: this.getMMForm(),
        modalClass: 'maintenance-mode--modal',
        suppressOutsideClick: true,
        title: this.getMMFormTitle(),
      },
    }

    return this.props.showModal(modalConfig)
  }

  getMMForm = () => {
    return <StartMaintenanceModal />
  }

  getMMFormTitle = () => {
    return (
      <span key='mmStartTitle'>
        <span key='mmTitleIcon' className='maintenance-mode--modal_icon'>
          <FontAwesomeIcon icon={faWrench} />
        </span>
        Start Maintenance Mode
      </span>
    )
  }

  render() {
    const {
      audience,
      hasConferenceBridges,
      hasControlCall,
      hasMaintenanceModePermission,
      onClick,
      width,
    } = this.props
    return (
      <div className='incidents-tabs-container'>
        <ul className={`incidents-tabs is-selected--${audience.toLowerCase()}`}>
          <li
            className='incident-tab--all'
            data-ext='incident-tab--all'
            onClick={onClick({ type: 'audience', value: 'all' })}
          >
            {`All${width < 475 ? '' : ' Incidents'}`}
          </li>

          <li
            className='incident-tab--your'
            onClick={onClick({ type: 'audience', value: 'your' })}
            data-ext='incident-tab--your'
          >
            {`Your${width < 475 ? '' : ' Incidents'}`}
          </li>

          <li
            className='incident-tab--team'
            onClick={onClick({ type: 'audience', value: 'team' })}
            data-ext='incident-tab--team'
          >
            {`Team${width < 475 ? '' : ' Incidents'}`}
          </li>
        </ul>

        <ul className='incidents-otheractions orgstate-actions'>
          <li
            className={
              hasControlCall && !hasConferenceBridges
                ? 'dropdown-wrapper dropdown'
                : 'dropdown-wrapper dropdown hidden'
            }
          >
            <a
              className='js-setup-control-call controlcall-icon'
              data-ext='setup-control-call-icon'
            >
              <FontAwesomeIcon icon={faPhone} />
            </a>
          </li>
          {hasMaintenanceModePermission ? this.getMaintenanceAction() : null}
        </ul>
      </div>
    )
  }
}
