import _ from 'lib/underscore'

// file: filter.js
// defines a filter for a backbone collection, that exposes
// only models that pass a criteria check, but correctly forwards
// events.

export default function Filtered(collection, criteria, options) {
  var filtered = new collection.constructor([], options)
  filtered._callbacks = {}

  /** check to see if the criteria matches the passed in model */
  filtered.matches = function(model) {
    if (filtered._criteria) {
      if (_.isFunction(filtered._criteria)) {
        return filtered._criteria.bind(filtered)(model)
      } else {
        for (var key in filtered._criteria) {
          if (filtered._criteria[key] !== model.get(key)) {
            return false
          }
        }
      }
    }

    return true
  }

  /** update the collection to be filtered on the new criteria */
  filtered.filter = function(criteria) {
    var items

    filtered._criteria = criteria

    if (filtered._criteria) {
      // if criteria is a function, use filter against it
      if (_.isFunction(filtered._criteria)) {
        items = collection.filter(filtered._criteria, collection)
      } else {
        items = collection.where(filtered._criteria)
      }
    } else {
      items = collection.models
    }

    filtered.reset(items)
  }

  // reset when the base is reset
  collection.on('reset', function() {
    filtered.filter(filtered._criteria)
  })

  // when something is added to the base, maybe we have to bubble that up?
  collection.on('add', function(model) {
    if (filtered.matches(model)) {
      filtered.add(model)
    }
  })

  // when something is removed from the base, we have to get rid of it if we have it
  collection.on('remove', function(model) {
    var mine = filtered.get(model.id)
    if (!_.isUndefined(mine)) {
      filtered.remove(mine)
    }
  })

  // when an attribute changes on a model, there's a possibility that the model no longer matches
  collection.on('change', function(model) {
    if (!filtered.matches(model)) {
      filtered.remove(model)
    } else if (!filtered.contains(model)) {
      filtered.add(model)
    }
  })

  collection.on('sort', function() {
    filtered.sort()
  })

  // filter first
  filtered.filter(criteria)

  return filtered
}
