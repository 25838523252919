// vendor
import { OrderedMap, Map as iMap, fromJS } from 'immutable'

import { decodeHtml as _decodeHtml } from 'components/__utils/html'

import {
  INCIDENT_DETAILS_ADD,
  INCIDENT_DETAILS_SET_ACTIVE_INTEGRATION,
  SET_SERVICE_NOW_DETAILS,
  SET_SERVICE_NOW_DETAILS_ERROR,
  SET_CONFERENCE_BRIDGE_DETAILS,
  SET_CONFERENCE_BRIDGE_DETAILS_ERROR,
  SET_CONFERENCE_BRIDGE_SHOW_DETAILS_VIEW,
} from 'components/store/actions'

import dedupeAlertAndIncidentFields from 'components/store/reducers/__utils/dedupe-alert-and-incident-fields'

// Reducer
// ---------------------------------------------------------------------------

export default function incidentsReducer(state = iMap({}), action) {
  switch (action.type) {
    case INCIDENT_DETAILS_ADD:
      return addIncidentDetails(state, action.payload)
    case INCIDENT_DETAILS_SET_ACTIVE_INTEGRATION:
      return setActiveIntegration(state, action.payload)
    case SET_SERVICE_NOW_DETAILS:
      return setServiceNowDetails(state, action.payload)
    case SET_SERVICE_NOW_DETAILS_ERROR:
      return setServiceNowDetailsError(state, action.payload)
    case SET_CONFERENCE_BRIDGE_DETAILS:
      return setConferenceBridgeDetails(state, action.payload)
    case SET_CONFERENCE_BRIDGE_DETAILS_ERROR:
      return setConferenceBridgeDetailsError(state, action.payload)
    case SET_CONFERENCE_BRIDGE_SHOW_DETAILS_VIEW:
      return setConferenceBridgeShowDetailsView(state, action.payload)

    default:
      return state
  }
}

// Helpers
// ---------------------------------------------------------------------------

const unescapeFields = fields =>
  OrderedMap(fields).map(data => _decodeHtml(data))

function addIncidentDetails(state, payload) {
  if (payload) {
    let unescapedPayload = iMap({
      nonVoFields: unescapeFields(payload.INCIDENT_DETAILS),
      voFields:
        payload.VO_INCIDENT_DETAILS === null
          ? null
          : unescapeFields(payload.VO_INCIDENT_DETAILS),
    })

    unescapedPayload = dedupeAlertAndIncidentFields(unescapedPayload)

    return state.merge({
      [payload.INCIDENT_DETAILS.INCIDENT_ID]: unescapedPayload,
    })
  } else {
    return state
  }
}

const setActiveIntegration = (state, payload) => {
  const incident = payload.incident
  const activeIntegration = payload.integration

  return state.setIn(['activeIntegrations', incident], activeIntegration)
}

const setServiceNowDetails = (
  state,
  { incidentLink, incidentNumber, incidentID }
) => {
  return state.setIn(
    ['serviceNowDetails', incidentID],
    fromJS({ incidentLink, incidentNumber })
  )
}

const setServiceNowDetailsError = (state, payload) =>
  state.set('serviceNowDetailsError', payload)

const setConferenceBridgeDetails = (state, { incidentID, ...details }) => {
  return state.setIn(
    ['conferenceBridgeDetails', incidentID, 'details'],
    fromJS(details)
  )
}

const setConferenceBridgeDetailsError = (state, payload) =>
  state.set('conferenceBridgeDetailsError', payload)

const setConferenceBridgeShowDetailsView = (
  state,
  { incidentID, showDetails }
) =>
  state.setIn(
    ['conferenceBridgeDetails', incidentID, 'showDetails'],
    showDetails
  )
