// vendor
// ---------------------------------------------------------------------------

import { takeEvery, put, call } from 'redux-saga/effects'
import { startMaintenanceMode } from 'util/urls'

import debug from 'debug'

// lib
// ---------------------------------------------------------------------------

import { MAINTENANCE_START, maintenanceUpdate } from 'components/store/actions'

import config from 'components/__utils/config'

const errorVO = debug('VO:sagas:error')

// ---------------------------------------------------------------------------

export function _startMM(api) {
  return function*(action) {
    const { payload, success, error } = action.payload
    try {
      const response = yield call(
        api.create,
        startMaintenanceMode(config.orgslug),
        payload
      )
      yield put(maintenanceUpdate(response))
      yield call(success)
    } catch (err) {
      yield call(error, err)
      yield call(errorVO, err)
    }
  }
}

// Watchers
// ---------------------------------------------------------------------------

export function watchMaintenanceMode(api) {
  return function*() {
    yield takeEvery(MAINTENANCE_START, _startMM(api))
  }
}
