// vendor
import _ from 'lib/underscore'
import Backbone from 'lib/backbone'

// lib
import Timeline from '../models/timeline'
var config = window.VO_CONFIG

// file: timelines.js
// collection for timelines

export default Backbone.Collection.extend({
  model: Timeline,

  getOrCreate: function(id, options) {
    var timeline = this.get(id)

    if (_.isUndefined(timeline)) {
      timeline = new Timeline(_.extend({}, options, { ROOM_ID: id }))
      this.push(timeline)
    }

    return timeline
  },

  canonicalizePrivate: function(other) {
    var names = [other, config.socketAuth.USER_ID.toLowerCase()].sort()
    return names.join(';')
  },

  canonicalizeTags: function(id, tags) {
    // these are the only typeFilters that can be tag2
    const filtersTagMap = new Map([
      ['typeincidentnotify', true],
      ['typepagingnotify', true],
      ['typecriticalalertnotify', true],
      ['typewarningalertnotify', true],
      ['typerecoveryalertnotify', true],
      ['typeinfoalertnotify', true],
    ])

    const filteredTags2 = tags[1].filter(el => {
      if (!filtersTagMap.get(el)) {
        // chats and devInsights have to end up in the tag and tag2
        // lists to come through correctly.
        tags[0].push(el)
      }
      return true
    })

    const tags1 = tags[0].map(el => {
      const cased = el.toLowerCase()
      return '&tag=' + cased
    })

    const tags2 = filteredTags2.map(el => {
      const cased = el.toLowerCase()
      return '&tag2=' + cased
    })

    const roomId = id + tags1.sort().join('') + tags2.sort().join('')

    return roomId
  },

  slugify: function(value) {
    // 1) convert to lowercase
    // 2) remove dashes and pluses
    // 3) replace spaces with dashes
    // 4) remove everything but alphanumeric characters and dashes
    return value
      .toLowerCase()
      .replace(/-+/g, '')
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '')
  },
})
