import React from 'react'
import styled from 'styled-components'

const BlankStateContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  text-align: center;
`

const BlankStateSubtextContainer = styled.div`
  width: 75%;
  text-align: center;
`

export const BlankState = () => (
  <BlankStateContainer>
    <h1>No Similar Incidents</h1>
    <BlankStateSubtextContainer>
      If incidents have similar alerts, titles, and other data to this incident,
      they would show up here. At this time, none are similar enough.{' '}
      <a
        href='https://help.victorops.com/knowledge-base/similar-incidents'
        target='_blank'
      >
        Learn More
      </a>
    </BlankStateSubtextContainer>
  </BlankStateContainer>
)
