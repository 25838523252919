import { Map as _Map, fromJS } from 'immutable'

import { flattenDeep } from 'lodash'

import {
  UPDATE_POLICY_ACTIONS,
  REMOVE_POLICY_ACTION,
} from 'components/store/actions'

export default function policyActions(state = _Map({}), action) {
  switch (action.type) {
    case UPDATE_POLICY_ACTIONS:
      return _updatePolicyActions(state, action.payload)
    case REMOVE_POLICY_ACTION:
      return _removePolicyAction(state, action.payload)

    default:
      return state
  }
}

function _updatePolicyActions(state, payload) {
  const next = flattenDeep(
    payload.map(policy => policy.groups.map(step => step.entries))
  ).reduce(function(actions, action) {
    actions[action.id] = action

    return actions
  }, {})

  return state.merge(fromJS(next))
}

function _removePolicyAction(state, payload) {
  return state.delete(payload)
}
