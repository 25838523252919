// vendor
import { connect } from 'react-redux'

// lib
import IncidentsNavPhase from './incidents-nav-phase'

import { getActivePhase, getCounts } from 'components/store/selectors'

import { updateAndFlushIncidentsPane } from 'components/store/actions'

// Connect
// ---------------------------------------------------------------------------

function mapStateToProps(state, ownProps) {
  const { width } = ownProps

  return {
    counts: getCounts(state),
    phase: getActivePhase(state),
    width: width,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: function onClick({ type, value }) {
      return () => dispatch(updateAndFlushIncidentsPane({ [type]: value }))
    },
  }
}

// Export
// ---------------------------------------------------------------------------

export default connect(mapStateToProps, mapDispatchToProps)(IncidentsNavPhase)
