import Alerts from '../collections/alerts'
import server from '../../server'

// singleton alert collection

var alerts = new Alerts()

alerts.requestAlert = function(uuid) {
  server.requestAlert(uuid, function() {})
}

var pushalert = function(data) {
  alerts.add(data)
}

// catch incoming data and add it
server.on({
  'notify:alert': pushalert,
  'history:alert': pushalert,
})

export default alerts
